import { Button, InputValidation } from "components";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";
import { BaseInput, Datepicker, SelectStudentTo } from "components/v2";
import SelectTrainingOrder from "components/v2/Form/SelectInput/training-order";
import { fetchAPI, getErrorMessage } from "helpers";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { CgClose, CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import { putScheduleMeet, reScheduleMeet } from "services/meet";

const SchedulePopup = React.forwardRef(
  ({ data, onRefresh, title = "", type = null, ...props }, ref) => {
    const modalConfirm = useRef();

    const [cooperation_date, setCooperationDate] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [dataToSend, setDataToSend] = useState(null);

    const [createData, setCreateData] = useState({
      isLoading: false,
      isSuccess: false,
      isError: false,
      data: null,
      errorMessage: "",
    });

    const [isOpenModal, setIsOpenModal] = useState(false);

    const methods = useFormContext({
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      clearErrors,
      setValue,
    } = methods;

    useEffect(() => {
      if (data?.id) {
        reset({
          name_schedule: data?.name_schedule,
          date: moment(data?.datetime).format("YYYY-MM-DD"),
          time: moment(data?.datetime).format("h:mm A"),
        });
        setCooperationDate(new Date(data?.datetime));
        setSchedule(new Date(data?.datetime));
      }
    }, [data]);

    const closeHandle = () => {
      ref?.current?.click();
      reset({
        name_schedule: "",
        date: "",
        time: "",
      });
    };

    const onCreate = (dataToSend) => {
      if (dataToSend) {
        setDataToSend(dataToSend);
        modalConfirm?.current?.click(dataToSend);
        setIsOpenModal(true);
      }
    };

    const onPostData = useCallback((dataToSend) => {
      let time = moment(`1999-01-01 ${dataToSend?.time}`).format("h:mm a");
      if (time === "Invalid date") {
        time = moment(`${dataToSend?.time}`).format("h:mm a");
      }
      let params = {};
      params.payload = {
        student_id: data?.student_id ?? "",
        student_name: data?.name ?? data?.student_name ?? "",
        name_schedule: dataToSend?.name_schedule,
        datetime: `${moment(dataToSend?.date).format("YYYY-MM-DD")} ${time}`,
      };

      if (data?.id) params.id = data?.id;

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      fetchAPI(
        {
          service: data?.id ? putScheduleMeet : reScheduleMeet,
          params: params,
        },
        (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success(
              data?.id ? "Sukses mengubah data!" : "Sukses menambahkan data!",
            );
            modalConfirm?.current?.click();
            onRefresh();
            reset({ name_schedule: "", date: "", time: "" });
            clearErrors("name_schedule");
            clearErrors("date");
            clearErrors("time");
            closeHandle();
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        },
      );
    });

    const handleChangeCooperationDate = (date) => {
      setCooperationDate(date);
      setValue("date", moment(date).format("YYYY-MM-DD"));
    };

    const handleChangeSchedule = (date) => {
      setSchedule(date);
      setValue("time", moment(date).format("YYYY-MM-DD h:mm a"));
    };

    return (
      <>
        <div>
          <form
            onSubmit={methods.handleSubmit(onCreate)}
            className="flex flex-col max-h-[64vh]"
          >
            <BaseInput
              name="name_schedule"
              type="text"
              label="Nama Agenda"
              placeholder="Nama Agenda"
              className="text-sm"
              disabled={data?.id}
            />
            <Datepicker
              name="date"
              className="text-left"
              label="Tanggal Wawancara"
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
              placeholder="Masukan Tanggal Wawancara"
            />
            <Datepicker
              name="time"
              className="text-left"
              label={"Jam"}
              type="time"
              placeholder="Pilih Jam"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              showTimePicker
            />
            <SelectTrainingOrder
              name="training_order"
              label="Training Order"
              placeholder="Masukan Training Order"
            />
            <SelectStudentTo
              isMulti
              name="student_to"
              label="Siswa"
              placeholder="Masukan Siswa"
              isDisabled={!methods.watch("training_order")}
              id={methods.watch("training_order")?.value}
            />
          </form>
        </div>

        <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
          <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
            <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
              <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
                <h5 className="mb-0 leading-normal">Hapus Data!</h5>
              </div>
              <div className="relative flex-auto p-4">
                <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
              </div>
              <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                <BaseButton
                  variant="base"
                  size="sm"
                  onClick={() => setIsOpenModal((prev) => !prev)}
                >
                  Tidak
                </BaseButton>
                <BaseButton variant="primary2" size="sm" onClick={() => null}>
                  Ya
                </BaseButton>
              </div>
            </div>
          </div>
        </ModalWrapper>
      </>
    );

    // return (
    //   <>
    //     <ConfirmationModal
    //       ref={modalConfirm}
    //       onSubmit={onPostData}
    //       data={dataToSend}
    //     />
    //     <input
    //       ref={ref}
    //       type="checkbox"
    //       id="modal-form"
    //       className="modal-toggle"
    //     />
    //     <div className="modal" style={{ zIndex: 40 }}>
    //       <div className="modal-box w-11/12 max-w-5xl overflow-visible space-y-4">
    //         <div className="flex items-center justify-between">
    //           <h3 className="font-bold text-lg">{title}</h3>
    //           <CgClose
    //             className="cursor-pointer text-2xl"
    //             onClick={() => {
    //               closeHandle();
    //             }}
    //           />
    //         </div>
    //         <div className="py-6">
    //           <form
    //             onSubmit={handleSubmit(onCreate)}
    //             className="flex flex-col justify-between max-h-[64vh] space-y-4"
    //           >
    //             <InputValidation
    //               {...register("name_schedule")}
    //               type="text"
    //               label="Nama Agenda"
    //               placeholder="Nama Agenda"
    //               className="text-sm"
    //               disabled={data?.id}
    //             />
    //             <Datepicker
    //               {...register("date")}
    //               selected={cooperation_date}
    //               onChange={handleChangeCooperationDate}
    //               label="Tanggal Wawancara"
    //               minDate={new Date()}
    //               dateFormat="yyyy-MM-dd"
    //               placeholder="Masukan Tanggal Wawancara"
    //             />
    //             <Datepicker
    //               {...register("time")}
    //               label={"Jam"}
    //               type="time"
    //               selected={schedule}
    //               onChange={handleChangeSchedule}
    //               placeholder="Pilih Jam"
    //               showTimeSelect
    //               showTimeSelectOnly
    //               timeIntervals={15}
    //               timeCaption="Time"
    //               dateFormat="h:mm aa"
    //             />
    //             <div className="modal-action bg-white pt-4">
    //               <Button
    //                 label={"Tidak"}
    //                 size="sm"
    //                 theme="error"
    //                 type="button"
    //                 onClick={() => {
    //                   closeHandle();
    //                 }}
    //               />
    //               <Button label={"Kirim"} size="sm" theme="primary" />
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // );
  },
);

const ConfirmationModal = React.forwardRef(
  ({ data, onSubmit, title, ...props }, ref) => {
    return (
      <>
        <input
          ref={ref}
          type="checkbox"
          id="modal-confirm"
          className="modal-toggle"
        />
        <div className="modal" style={{ zIndex: 50 }}>
          <div className="modal-box">
            <h3 className="font-bold text-lg">Konfirmasi</h3>
            <p className="py-4">
              {`Apakah anda yakin ingin menambahkan data ini?`}
            </p>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                onClick={() => ref?.current?.click()}
              />
              <Button
                label={"Ya"}
                size="sm"
                theme="primary"
                onClick={() => onSubmit(data)}
              />
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default SchedulePopup;
