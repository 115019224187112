import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import { Button, Pagination, Select, Spinner } from "components";
import { useLocation, useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteDataStudent,
  getListStudent,
  putInterviewStudent,
  softDeleteDataStudent,
} from "services/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CgCalendar } from "react-icons/cg";
import { ImCheckboxChecked } from "react-icons/im";
import { AiFillCloseSquare } from "react-icons/ai";
import DummyProfile from "assets/img/dummy-profile-pic.png";
import moment from "moment";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";
import { useQuery } from "react-query";
import PartnerAdmin from "./PartnerAdmin";

const StudentManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modalDelete = useRef();
  const modalForm = useRef();

  const filterFromParams = location?.state?.filter ?? null;

  const { user } = useSelector(({ auth }) => auth);

  const [filter, setFilter] = useState({
    role: null,
    search: "",
    gender: "",
    page: 1,
  });

  const [genderSelected, setGenderSelected] = useState(null);

  const [selectedData, setSelectedData] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (filterFromParams) {
      const gender = _.find(
        genderOptions,
        (go) => go.value === filterFromParams?.gender,
      );
      setGenderSelected(gender);
      setFilter((prevState) => ({ ...prevState, gender: gender?.value }));
    }
  }, [filterFromParams]);

  const genderOptions = useMemo(
    () => [
      { value: "male", label: "男 (Laki-laki)" },
      { value: "female", label: "女性 (Perempuan)" },
    ],
    [],
  );

  const handleChangeGender = (gender) => {
    setGenderSelected(gender);
    setFilter((prevState) => ({ ...prevState, gender: gender?.value }));
  };

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    [
      "student-mitra-list",
      { page: filter.page, search: filter.search, gender: filter.gender },
    ],
    {
      queryFn: async ({ queryKey, signal }) => {
        const params = queryKey[1];
        let response = await getListStudent({ ...params }, signal);
        response = response.data;
        return response;
      },
      enabled: !(user?.role === "partner_jepang" && user?.is_admin),
    },
  );

  const updateInterview = (data) => {
    fetchAPI(
      {
        service: putInterviewStudent,
        params: {
          id: data.student_id,
          payload: { interview: !data.interview },
        },
      },
      (success, response) => {
        if (success) {
          if (!data.interview) {
            toast.success("Data Siswa berhasil di non-aktifkan");
            refetch();
          } else {
            toast.success("Data Siswa berhasil di aktifkan");
          }
        }
      },
    );
  };

  const handleChangeRole = (role) => {
    setFilter((prevState) => ({ ...prevState, role }));
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const onDetail = (params) => {
    navigate("/student-management/detail", {
      state: { id: params?.student_id ?? "" },
    });
  };

  const onEdit = (params) => {
    navigate("/student-management/form", {
      state: {
        id: params?.student_id ?? "",
        total_student: response?.meta?.total ?? 0,
      },
    });
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onClickDeleteData = (params) => {
    modalDelete?.current?.click();
    setIsOpenModal(true);
    setSelectedData(params);
  };

  const onDeleteData = () => {
    fetchAPI(
      // { service: deleteDataStudent, params: selectedData?.student_id ?? null },
      {
        service: softDeleteDataStudent,
        params: {
          id: selectedData?.user_id ?? null,
          payload: { is_deleted: true },
        },
      },
      (success, response) => {
        if (success) {
          setIsOpenModal(false);
        }
        modalDelete?.current?.click();
      },
    );
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={
              user?.role === "lpk_mitra_so"
                ? 10
                : user?.role !== "partner_jepang"
                ? 9
                : 8
            }
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={
              user?.role === "lpk_mitra_so"
                ? 10
                : user?.role !== "partner_jepang"
                ? 9
                : 6
            }
          >
            <div className="flex items-center justify-center">
              <h6>No Data</h6>
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data, (data, key) => {
      const id =
        parseInt(response?.meta?.page) * parseInt(response?.meta?.limit) -
        parseInt(response?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.code ?? "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              <AvatarData data={data} />
            </p>
          </td>

          <td className="px-6 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.ttl ? data?.ttl : "-"}
            </p>
          </td>

          <td className="px-6 leading-normal align-middle bg-transparent border-b text-sm shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.address ? data?.address : "-"}
            </p>
          </td>

          {user?.role === "lpk_mitra_so" && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.datetime ? (
                  moment(data?.datetime).format("DD/MM/YYYY h:mm a")
                ) : (
                  <span className="flex justify-center">
                    <CgCalendar
                      className={`cursor-pointer ${
                        !data?.document_to && "text-gray-400 cursor-default"
                      }`}
                      onClick={() => {
                        if (data?.document_to) {
                          setSelectedData(data);
                          modalForm?.current?.click();
                        }
                      }}
                    />
                  </span>
                )}
              </p>
            </td>
          )}

          {user?.role !== "partner_jepang" && (
            <td className="whitespace-normal bg-transparent border-b">
              <span
                onClick={() => updateInterview(data)}
                className="flex justify-center"
              >
                {data?.interview ? (
                  <AiFillCloseSquare className="text-error text-2xl hover:cursor-pointer" />
                ) : (
                  <ImCheckboxChecked className="text-success text-lg hover:cursor-pointer" />
                )}
              </span>
            </td>
          )}

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.old ? data?.old : "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.entries_date
                  ? moment(data?.entries_date).format("DD MMMM YYYY")
                  : "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Detail
              </a>
              {user?.role !== "partner_jepang" && (
                <>
                  <a
                    className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                    onClick={() => onEdit(data)}
                  >
                    Edit
                  </a>
                  <a
                    className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                    onClick={() => onClickDeleteData(data)}
                  >
                    Delete
                  </a>
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {!(user?.role === "partner_jepang" && user?.is_admin) && (
        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            {user?.role === "partner_jepang" ? (
              <span className="flex space-x-1">
                <h5>候補者リスト</h5>
                <h6>{"(List Siswa)"}</h6>
              </span>
            ) : (
              <h5>List Siswa</h5>
            )}
            <p>Menampilkan semua siswa yang dibuat oleh masing masing mitra.</p>
          </div>
          <div className="flex justify-between px-4 py-2">
            <div className="w-max md:w-120 flex gap-2 items-center">
              <BaseInput
                placeholder="Search..."
                size="lg"
                className="w-7"
                onChange={onChangeSearch}
              />
              <Select
                value={genderSelected}
                onChange={handleChangeGender}
                options={genderOptions}
                inputClassName="text-sm"
                isClearable
                placeholder="性別 (Jenis Kelamin)"
              />
            </div>

            <div>
              {(user?.role === "lpk_mitra" ||
                user?.role === "lpk_mitra_so") && (
                <BaseButton
                  variant="primary2"
                  size="md"
                  onClick={() =>
                    navigate("/student-management/form", {
                      state: { total_student: response?.meta?.total ?? 0 },
                    })
                  }
                >
                  Tambah Data
                </BaseButton>
              )}
            </div>
          </div>
          <div className="flex-auto px-0 pt-0 pb-2 mb-2">
            <div className="p-0 overflow-x-auto">
              <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                <thead className="align-bottom">
                  <tr>
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      番号 (NOMOR)
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "写真 (Foto)"
                        : "写真 (Foto)"}
                    </th>
                    <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "生年月日 (Tempat, Tanggal lahir)"
                        : "生年月日 (Tempat, Tanggal lahir)"}
                    </th>
                    <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "住所 (Alamat Lengkap)"
                        : "住所 (Alamat Lengkap)"}
                    </th>
                    {user?.role === "lpk_mitra_so" && (
                      <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        Tanggal wawancara
                      </th>
                    )}
                    {user?.role !== "partner_jepang" && (
                      <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        Interview
                      </th>
                    )}
                    <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "年 (Usia)"
                        : "年 (Usia)"}
                    </th>
                    <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "研究エントリー日 (Tanggal Masuk Belajar)"
                        : "研究エントリー日 (Tanggal Masuk Belajar)"}
                    </th>
                    <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </table>
            </div>
          </div>
          <div className="w-full p-4 flex justify-end">
            <Pagination
              pageLimit={response?.meta?.limit}
              totalRecords={response?.meta?.total ?? 0}
              currentPage={response?.meta?.page}
              onPageChanged={handleChangePage}
            />
          </div>
        </div>
      )}

      {user?.role === "partner_jepang" && user?.is_admin && <PartnerAdmin />}

      <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export const AvatarData = ({ data }) => {
  const [img, setImg] = useState();
  useEffect(() => {
    if (data.image) {
      setImg(
        `${process.env.REACT_APP_API}/uploads/avatar/${
          data?.user_id
        }/${data?.image?.replace("/public", "")}`,
      );
    } else {
      setImg(DummyProfile);
    }
  }, [data.image]);

  return (
    <img
      alt="avatar-student"
      src={img}
      onError={(e) => setImg(DummyProfile)}
      className="w-16 object-contain"
    />
  );
};

export default StudentManagement;
