import React, { useCallback, useEffect } from "react";
import {
  Button,
  Datepicker,
  InputUploadFile,
  InputValidation,
} from "components";
import { CgClose } from "react-icons/cg";
import { FormProvider, useForm } from "react-hook-form";
import { fetchAPI, getErrorMessage } from "helpers";
import { patchNoteStudent } from "services/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const FormNote = React.forwardRef(
  ({ data, onSubmit, onClearFormNote, title, ...props }, ref) => {
    const methods = useForm({ defaultValues: { note: null } });

    const {
      formState: { errors },
    } = methods;

    useEffect(() => {
      if (data?.note) {
        methods.reset({ note: data?.note });
      }
    }, [data?.note]);

    const doSubmit = (params) => {
      fetchAPI(
        {
          service: patchNoteStudent,
          params: {
            id: data?.student_hired_id,
            payload: { note: params?.note },
          },
        },
        (success, response) => {
          if (success) {
            toast.success("Sukses membuat catatan!");
            ref?.current?.click();
            methods.reset({ note: null });
            onSubmit();
          } else {
            toast.error(getErrorMessage(response));
          }
        },
      );
    };

    return (
      <>
        <input
          ref={ref}
          type="checkbox"
          id="modal-confirm"
          className="modal-toggle"
        />
        <div className="modal" style={{ zIndex: 50 }}>
          <div className="modal-box">
            <FormProvider {...methods}>
              <div className="flex items-center justify-between">
                <h3 className="font-bold text-lg">Catatan</h3>
                <CgClose
                  className="cursor-pointer text-2xl"
                  onClick={() => {
                    ref?.current?.click();
                    methods.reset({ note: null });
                    onClearFormNote();
                  }}
                />
              </div>
              <div className="pt-2">
                <InputValidation
                  {...methods.register("note", {
                    required: {
                      value: true,
                      message: "Catatan wajib diisi",
                    },
                    maxLength: {
                      value: 50,
                      message: "Catatan maksimal 50 karakter",
                    },
                  })}
                  placeholder="Silahkan masukan catatan"
                  isError={{
                    value: errors?.note,
                    message: errors?.note?.message,
                  }}
                />
              </div>
              <div className="modal-action">
                <Button
                  label={"Cancel"}
                  size="sm"
                  theme="error"
                  type="button"
                  onClick={() => {
                    ref?.current?.click();
                    methods.reset({ note: null });
                    onClearFormNote();
                  }}
                />
                <Button
                  label={"Submit"}
                  size="sm"
                  theme="primary"
                  type="button"
                  onClick={methods.handleSubmit(doSubmit)}
                />
              </div>
            </FormProvider>
          </div>
        </div>
      </>
    );
  },
);

export default FormNote;
