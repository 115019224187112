import { useLoader } from "Provider/LoaderProvider";
import { Spinner } from "components";
import BaseButton from "components/atoms/BaseButton";
import { BaseInput, Datepicker } from "components/v2";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getWorkingPlan, putWorkingPlan } from "services/user";

const Workplace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm({
    defaultValues: {
      company_name: "",
      company_address: "",
    },
  });

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const { user } = useSelector(({ auth }) => auth);

  const { data, isLoading } = useQuery(
    ["working-plan-list", location?.state?.id],
    {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getWorkingPlan(params);
        response = response.data;
        return response;
      },
      enabled: !!location?.state?.id,
    },
  );

  const put = useMutation(putWorkingPlan);

  useEffect(() => {
    if (data?.data) {
      methods.reset({
        ...data?.data,
        departure_plan: data?.data?.departure_plan
          ? new Date(moment(data?.data?.departure_plan))
          : "",
        continue_plan: data?.data?.continue_plan
          ? new Date(moment(data?.data?.continue_plan))
          : "",
        arrive_plan: data?.data?.arrive_plan
          ? new Date(moment(data?.data?.arrive_plan))
          : "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      startLoaderOverlay();
    } else {
      stopLoaderOverlay();
    }
  }, [isLoading]);

  const onSubmit = (payload) => {
    const params = {
      student_id: location?.state?.id,
      coorporate_name: payload?.company_name,
      coorporate_address: payload?.company_address,
      address: payload?.address,
      departure_plan: payload?.departure_plan
        ? moment(payload?.departure_plan)?.format("MM-DD-YYYY")
        : "",
      arrive_plan: payload?.arrive_plan
        ? moment(payload?.arrive_plan)?.format("MM-DD-YYYY")
        : "",
      continue_plan: payload?.continue_plan
        ? moment(payload?.continue_plan)?.format("MM-DD-YYYY")
        : "",
      description: payload?.description,
    };
    startLoaderOverlay();
    put.mutate(
      { payload: params, student_id: location?.state?.id },
      {
        onSuccess: () => {
          toast.success("Sukses mengubah data!");
          methods.reset();
          stopLoaderOverlay();
        },
        onError: (err) => {
          stopLoaderOverlay();
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border p-6">
        <BaseInput
          type="text"
          label="Nama Perusahaan"
          placeholder="Masukan nama perusahaan"
          name="company_name"
          disabled
        />
        <BaseInput
          type="text"
          label="Alamat Perusahaan"
          placeholder="Masukan alamat perusahaan"
          name="company_address"
          disabled
        />
        <BaseInput
          type="text"
          label="Alamat Rumah Tinggal di Jepang"
          placeholder="Masukan alamat rumah tinggal di jepang"
          name="address"
          disabled={!(user?.role === "partner_jepang" && user?.is_admin)}
        />
        <Datepicker
          name="departure_plan"
          label="Tanggal Keberangkatan"
          placeholder="Masukan tanggal keberangkatan"
          disabled={!(user?.role === "partner_jepang" && user?.is_admin)}
        />
        <Datepicker
          name="arrive_plan"
          label="Tanggal Kepulangan"
          placeholder="Masukan tanggal kepulangan"
          disabled={!(user?.role === "partner_jepang" && user?.is_admin)}
        />
        <Datepicker
          name="continue_plan"
          label="Tanggal Rencana Lanjut Magang"
          placeholder="Masukan tanggal rencana lanjut magang"
          disabled={!(user?.role === "partner_jepang" && user?.is_admin)}
        />
        <BaseInput
          name="description"
          type="text"
          label="Kondisi Keterangan Anak"
          placeholder="Masukan kondisi keterangan anak"
          disabled={!(user?.role === "partner_jepang" && user?.is_admin)}
        />
      </div>
      {user?.role === "partner_jepang" && user?.is_admin && (
        <div className="flex gap-3 py-4 justify-end px-6">
          <BaseButton
            type="button"
            variant="base"
            disabled={put?.isLoading}
            onClick={() => navigate(-1)}
          >
            Kembali
          </BaseButton>
          <BaseButton
            type="submit"
            variant="primary2"
            disabled={put?.isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            <div className="flex items-center space-x-2">
              <span>Kirim</span>
              {put?.isLoading && <Spinner />}
            </div>
          </BaseButton>
        </div>
      )}
    </FormProvider>
  );
};

export default Workplace;
