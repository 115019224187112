import React, { forwardRef, useImperativeHandle, useState } from "react";
import { CgSpinner } from "react-icons/cg";

function Loader(props, ref) {
  const [loading, setLoading] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        const loadingCount = loading + 1;
        setLoading(loadingCount);
      },
      stop: () => {
        const loadingCount = loading > 0 ? loading - 1 : 0;
        setLoading(loadingCount);
      },
      isLoading: () => loading >= 1,
    }),
    [],
  );

  if (!loading) {
    return null;
  }

  return (
    <div
      className="w-screen h-screen fixed bg-black/80 flex items-center justify-center"
      style={{ zIndex: 999 }}
    >
      <div className="flex items-center space-x-2 text-white animate-bounce">
        <CgSpinner className="animate-spin text-4xl" />
        <label className="text-2xl">Loading...</label>
      </div>
    </div>
  );
}

export default forwardRef(Loader);
