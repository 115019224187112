import React from "react";
import _ from "lodash";
import { LanguageSkill, CertExam, FileItem } from "../Components";
import { useSelector } from "react-redux";

const Document = () => {
  const { user } = useSelector(({ auth }) => auth);
  return (
    <div className="space-y-6">
      <LanguageSkill readOnly={true} />
      <CertExam readOnly={true} />

      {user?.role !== "partner_jepang" && (
        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="flex justify-between items-baseline p-6">
            <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
              <h6>ドキュメントのアップロード</h6>
              <p>(Upload Dokumen)</p>
            </div>
          </div>
          <div className="px-6 pb-6 space-y-3">
            <FileItem
              placeholder="証明書 (Ijazah)"
              label="証明書 (Ijazah)"
              name="ijazah"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="IDカード (KTP)"
              label="IDカード (KTP)"
              name="ktp"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="株式会社 (KK)"
              label="株式会社 (KK)"
              name="kk"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="SKCK (SKCK)"
              label="SKCK (SKCK)"
              name="skck"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="出生証明書 (Akte Kelahiran)"
              label="出生証明書 (Akte Kelahiran)"
              name="akteKelahiran"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="白い画面の写真 (Foto Layar Putih)"
              label="白い画面の写真 (Foto Layar Putih)"
              name="fotoLayarPutih"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="健康情報 (Keterangan Kesehatan)"
              label="健康情報 (Keterangan Kesehatan)"
              name="ketKesehatan"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="親の許可証 (Surat Izin Orang Tua)"
              label="親の許可証 (Surat Izin Orang Tua)"
              name="suratIzinOrtu"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
              label="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
              name="sertifikatKomp1"
              showLabelBesideValue={true}
              readOnly={true}
            />
            <FileItem
              placeholder="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
              label="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
              name="sertifikatKomp2"
              showLabelBesideValue={true}
              readOnly={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Document;
