import axios from "axios";
import { logOut } from "features/auth/authSlice";
import { getErrorMessage } from "helpers";
import { toast } from "react-toastify";

const interceptor = {
  request: (store) => {
    axios.interceptors.request.use(async (request) => {
      const token = store?.getState()?.auth?.token ?? "";
      if (!request?.url?.includes("/login")) {
        request.headers["Authorization"] = "Bearer " + token;
      }
      return Promise.resolve(request);
    });
  },
  response: (store) => {
    axios.interceptors.response.use(
      (response) => {
        if (response?.data?.errors?.name === "TokenExpiredError") {
          store?.dispatch(logOut());
        }
        return response;
      },
      (error) => {
        const messageError = getErrorMessage(error);
        if (messageError !== "canceled")
          toast.error(messageError, { toastId: messageError });
        return Promise.reject(error);
      },
    );
  },
};

export default interceptor;
