import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, QueryClient, useQuery } from "react-query";

import BaseButton from "components/atoms/BaseButton";
import { BaseInput, SelectInput } from "components/v2";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import {
  getAdministratorUserDetail,
  postAdministratorUser,
  putAdministratorUser,
} from "services/administrator";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import SelectPartnerJapan from "components/v2/Form/SelectInput/partner-japan";
import _ from "lodash";
import { useLoader } from "Provider/LoaderProvider";

const DEFAULT_VALUES = {
  name: "",
  email: "",
  password: "",
  partner_japan: [],
};

const AdministratorForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state ?? null;

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const schema = useMemo(
    () =>
      id
        ? yup.object().shape({
            name: yup.string().required("Nama Administrator wajib diisi"),
            email: yup
              .string()
              .required("Email wajib diisi")
              .email("Format email tidak valid"),
          })
        : yup.object().shape({
            name: yup.string().required("Nama Administrator wajib diisi"),
            email: yup
              .string()
              .required("Email wajib diisi")
              .email("Format email tidak valid"),
            password: yup
              .string()
              .required("Password wajib diisi")
              .min(8, "Password minimal 8 karakter"),
            partner_japan: yup
              .array()
              .of(
                yup
                  .object()
                  .shape({
                    value: yup.string().required("Partner Jepang wajib diisi"),
                  })
                  .required("Partner Jepang wajib diisi"),
              )
              .min(1, "Partner Jepang wajib diisi"),
          }),
    [id],
  );

  const [showPass, setShowPass] = useState(false);

  const queryClient = new QueryClient();

  const { data: detail, isLoading } = useQuery(
    ["administrator-user-detail", id],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getAdministratorUserDetail(params, signal);
      },
      onSuccess: () => {
        stopLoaderOverlay();
      },
      onError: () => {
        stopLoaderOverlay();
      },
      enabled: !!id,
    },
  );

  const post = useMutation({
    mutationFn: (payload) => postAdministratorUser(payload),
  });
  const put = useMutation({
    mutationFn: (payload) => putAdministratorUser(payload),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    id && startLoaderOverlay();
  }, [id]);

  useEffect(() => {
    if (detail) {
      stopLoaderOverlay();
      methods.reset({
        name: detail?.data?.data?.name,
        email: detail?.data?.data?.email,
      });
    }
  }, [detail]);

  const onSubmit = (payload) => {
    if (payload) {
      const params = {
        ...payload,
        user_id: _.map(payload?.partner_japan, (partner_japan) =>
          parseInt(partner_japan?.value),
        ).join(","),
        roles: "3",
        province_id: "32",
        city_id: "3217",
        district_id: "321706",
      };
      delete params.partner_japan;
      if (id) {
        delete params.user_id;
        put.mutate(
          { id, payload: params },
          {
            onSuccess: (data) => {
              toast.success("Sukses mengubah data!");
              methods.reset();
              queryClient.invalidateQueries("administrator-user");
              navigate(-1);
            },
          },
        );
      } else {
        post.mutate(params, {
          onSuccess: (data) => {
            toast.success("Sukses menambahkan data!");
            methods.reset();
            queryClient.invalidateQueries("administrator-user");
            navigate(-1);
          },
        });
      }
    }
  };

  const renderPasswordIcon = () => {
    return (
      <span
        className="absolute top-3 right-2 text-gray-600 cursor-pointer h-6 w-6 text-center"
        onClick={() => setShowPass((prev) => !prev)}
      >
        {showPass ? <IoMdEye /> : <IoMdEyeOff />}
      </span>
    );
  };

  return (
    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>{id ? "Edit Administrator" : "Tambah Administrator"}</h6>
        </div>
        <FormProvider {...methods}>
          <form className="p-6">
            <BaseInput
              name="name"
              label="Nama Administrator"
              placeholder="Masukan nama administrator"
            />
            <BaseInput
              name="email"
              label="Email"
              placeholder="Masukan email"
              disabled={id}
            />
            {!id && (
              <BaseInput
                name="password"
                type={!showPass ? "password" : "text"}
                label="Password"
                placeholder="Masukan password"
                suffixIcon={renderPasswordIcon()}
              />
            )}
            {!id && (
              <SelectPartnerJapan
                isMulti
                name="partner_japan"
                label="Partner Jepang"
                placeholder="Masukan Partner Jepang"
              />
            )}
          </form>
        </FormProvider>
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        <BaseButton
          variant="base"
          type="button"
          onClick={() => navigate(-1)}
          disabled={post?.isLoading}
        >
          Kembali
        </BaseButton>
        <BaseButton
          variant="primary2"
          type="button"
          disabled={post?.isLoading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          Kirim{" "}
          {post?.isLoading && (
            <span>
              <CgSpinner className="animate-spin text-sm text-primary" />
            </span>
          )}
        </BaseButton>
      </div>
    </section>
  );
};

export default AdministratorForm;
