import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Select,
  Spinner,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteAttachment,
  getProfile,
  postData,
  putData,
  putProfile,
  uploadAttachment,
  uploadAttachmentStudent,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "services/auth";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import TemplateAngket from "app/TEMPLATE-ANGKET-CALON-MITRA.docx";
import { FileItem } from "pages/Mitra/Form";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import { getProvinces } from "features/core/coreSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ProfileMitra = () => {
  const modalResetPassword = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const attachmentRef = useRef();

  const { provinces, isLoadingProvinces, total_lpk } = useSelector(
    ({ core }) => core,
  );

  const schema = yup.object().shape({
    name: yup.string().required("Nama wajib diisi"),
    email: yup
      .string()
      .required("Email wajib diisi")
      .email("Format email tidak valid"),
    province: yup
      .object()
      .shape({
        value: yup.string().required("Provinsi wajib diisi"),
      })
      .required("Provinsi wajib diisi"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      province: null,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch: watchForm,
  } = methods;

  const {
    register: register_form_reset,
    handleSubmit: handleSubmit_form_reset,
    formState: { errors: errors_form_reset },
    reset: reset_form_reset,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const [attachment, setAttachment] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [greetingSelected, setGreetingSelected] = useState(null);
  const [hp, setHp] = useState("");
  const [wa, setWa] = useState("");
  const [phone, setPhone] = useState("");
  const [join_date, setJoinDate] = useState(null);

  const [angket, setAngket] = useState(null);
  const [structureOrg, setStructureOrg] = useState(null);
  const [nib, setNib] = useState(null);
  const [licenseRegion, setLicenseRegion] = useState(null);

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    fetchDetail();
  }, []);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      reset({
        name: detail?.data?.info?.name,
        email: detail?.data?.info?.email,
        address: detail?.data?.info?.address,
        responsible: detail?.data?.info?.trusted,
        legality: detail?.data?.info?.legalitas,
        hp: detail?.data?.info?.hp,
        wa: detail?.data?.info?.wa,
        phone: detail?.data?.info?.phone,
        province: {
          value: detail?.data?.user?.province_id,
          label: detail?.data?.user?.province_name,
        },
        personal_in_charge_name: detail?.data?.info?.person_in_charge,
        agreement_file: detail?.data?.info?.attachment,
        join_date: detail?.data?.info?.joined_at
          ? moment(detail?.data?.info?.joined_at).format("YYYY-MM-DD")
          : null,
      });
      setAttachment({ name: detail?.data?.info?.attachment });
      setHp(detail?.data?.info?.hp);
      setWa(detail?.data?.info?.wa);
      setPhone(detail?.data?.info?.phone);
      if (detail?.data?.info?.joined_at) {
        setJoinDate(new Date(detail?.data?.info?.joined_at));
      }
    }
  }, [detail]);

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getProfile }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {};
      params.roles = "2";

      params.province_id = methods.watch("province")?.value;
      params.city_id = "3217";
      params.district_id = "321706";

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (data?.join_date) {
        params.joined_at = moment(data?.join_date).format("YYYY-MM-DD");
      }
      if (data?.name) params.name = data?.name;
      if (data?.address) params.address = data?.address;
      if (data?.responsible) params.trusted = data?.responsible;
      if (data?.legality) params.legalitas = data?.legality;
      if (hp) params.hp = hp;
      if (wa) params.wa = wa;
      if (phone) params.phone = phone;
      if (data?.personal_in_charge_name)
        params.person_in_charge = data?.personal_in_charge_name;
      if (data?.agreement_file) params.attachment = data?.agreement_file;
      if (data?.email) params.email = data?.email;
      if (angket) params.angket = angket?.name;
      if (structureOrg) params.structure_org = structureOrg?.name;
      if (nib) params.nib = nib?.name;
      if (licenseRegion) params.license_region = licenseRegion?.name;
      fetchAPI(
        {
          service: putProfile,
          params: { id: detail?.data?.user?.id, payload: params },
        },
        (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses mengubah data!");
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        },
      );
    }
  };

  const onChangeFile = (cb, value) => {
    cb?.(value);
  };

  const handleChangeJoinDate = (date) => {
    setJoinDate(date);
    setValue("join_date", date);
  };

  const onTriggerSelectFile = () => {
    attachmentRef?.current?.click();
  };

  const onSelectFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        const formData = new FormData();
        formData.append("attachment", file);
        fetchAPI(
          { service: uploadAttachmentStudent, params: formData },
          (success, response) => {
            if (success) {
              setAttachment(file);
              if (attachment?.name) {
                fetchAPI({
                  service: deleteAttachment,
                  params: attachment?.name,
                });
              }
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const handleChangeHp = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("phonenumber", value);
      setHp(value);
    }
  };

  const handleChangeWa = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("wa_number", value);
      setWa(value);
    }
  };

  const handleChangePhone = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("telp", value);
      setPhone(value);
    }
  };

  const onPopupResetPassword = () => {
    modalResetPassword?.current?.click();
  };

  const onSubmitResetPassword = (data) => {
    const params = {
      id: detail?.data?.user?.id,
      password: data?.old_password,
      new_password: data?.new_password,
    };
    fetchAPI({ service: updatePassword, params }, (success, response) => {
      if (success) {
        toast.success("Berhasil reset password!");
        modalResetPassword?.current?.click();
        setShowOldPass(false);
        setShowNewPass(false);
        setShowConfirmPass(false);
        reset_form_reset({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
      }
    });
  };

  const renderModalResetPassword = () => (
    <>
      <input
        ref={modalResetPassword}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Reset Password</h3>
          <form onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}>
            <div className="space-y-4 py-4">
              <InputValidation
                {...register_form_reset("old_password", {
                  required: {
                    value: true,
                    message: "Password Lama wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Lama minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showOldPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Lama"
                name="old_password"
                type={showOldPass ? "text" : "password"}
                placeholder="Masukan Password Lama"
                isError={{
                  value: errors_form_reset.old_password,
                  message: errors_form_reset.old_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("new_password", {
                  required: {
                    value: true,
                    message: "Password Baru wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Baru minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showNewPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Baru"
                name="new_password"
                type={showNewPass ? "text" : "password"}
                placeholder="Masukan Password Baru"
                isError={{
                  value: errors_form_reset.new_password,
                  message: errors_form_reset.new_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("confirm_password", {
                  required: {
                    value: true,
                    message: "Konfirmasi Password wajib diisi",
                  },
                  validate: (val) => {
                    if (watch("new_password") != val) {
                      return "Konfirmasi Password tidak sama dengan pasword baru";
                    }
                  },
                  minLength: {
                    value: 8,
                    message: "Konfirmasi Password minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showConfirmPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Konfirmasi Password"
                name="confirm_password"
                type={showConfirmPass ? "text" : "password"}
                placeholder="Masukan Konfirmasi Password"
                isError={{
                  value: errors_form_reset.confirm_password,
                  message: errors_form_reset.confirm_password?.message,
                }}
              />
            </div>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                type="button"
                onClick={() => {
                  setShowOldPass(false);
                  setShowNewPass(false);
                  setShowConfirmPass(false);
                  reset_form_reset({
                    old_password: "",
                    new_password: "",
                    confirm_password: "",
                  });
                  modalResetPassword?.current?.click();
                }}
              />
              <Button label={"Ya"} size="sm" theme="primary" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );

  return (
    // <div>
    //   {renderModalResetPassword()}
    //   <div className="w-full space-y-4">
    //     <div className="flex justify-between items-center">
    //       <Label text={"Profile"} className="font-bold text-2xl" />
    //     </div>

    //     <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
    //       <div className="space-y-8">
    //         <div
    //           className="bg-gray-50 rounded-lg border-gray-500 p-4 grid grid-cols-2 gap-2"
    //           style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //         >
    //           <InputValidation
    //             {...register("name", {
    //               required: { value: true, message: "Nama wajib diisi" },
    //             })}
    //             label="Nama"
    //             type="name"
    //             placeholder="Masukan Nama"
    //             isError={{
    //               value: errors.name,
    //               message: errors.name?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("email", {
    //               required: { value: true, message: "Email wajib diisi" },
    //             })}
    //             autoComplete="new-password"
    //             label="Email"
    //             type="email"
    //             disabled={true}
    //             placeholder="Masukan Email"
    //             isError={{
    //               value: errors.email,
    //               message: errors.email?.message,
    //             }}
    //           />
    //         </div>
    //         <div
    //           className="bg-gray-50 rounded-lg border-gray-500 px-4 pt-4 pb-8"
    //           style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //         >
    //           <Select
    //             label="Provinsi"
    //             onChange={handleChangeProvince}
    //             value={provinceSelected}
    //             options={provinces}
    //             isDisabled={true}
    //             placeholder="Masukan Nama Provinsi"
    //             isError={{
    //               value: errors.province,
    //               message: errors.province?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("phonenumber", {
    //               required: {
    //                 value: false,
    //                 message: "No. Handphone wajib diisi",
    //               },
    //               minLength: {
    //                 value: 8,
    //                 message: "No. Handphone minimal 8 karakter",
    //               },
    //               maxLength: {
    //                 value: 13,
    //                 message: "No. Handphone maximal 13 karakter",
    //               },
    //             })}
    //             label="No. Handphone"
    //             type="phonenumber"
    //             pattern="^[0-9]*$"
    //             value={hp}
    //             onChange={handleChangeHp}
    //             placeholder="Masukan No. Handphone"
    //             isError={{
    //               value: errors.phonenumber,
    //               message: errors.phonenumber?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("wa_number", {
    //               required: {
    //                 value: false,
    //                 message: "No. Whatsapp wajib diisi",
    //               },
    //               minLength: {
    //                 value: 8,
    //                 message: "No. Whatsapp minimal 8 karakter",
    //               },
    //               maxLength: {
    //                 value: 13,
    //                 message: "No. Whatsapp maximal 13 karakter",
    //               },
    //             })}
    //             label="No. Whatsapp"
    //             type="wa_number"
    //             pattern="^[0-9]*$"
    //             value={wa}
    //             onChange={handleChangeWa}
    //             placeholder="Masukan No. Whatsapp"
    //             isError={{
    //               value: errors.wa_number,
    //               message: errors.wa_number?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("telp", {
    //               required: { value: false, message: "Telpon wajib diisi" },
    //               minLength: {
    //                 value: 8,
    //                 message: "Telpon minimal 8 karakter",
    //               },
    //               maxLength: {
    //                 value: 13,
    //                 message: "Telpon maximal 13 karakter",
    //               },
    //             })}
    //             label="Telpon"
    //             type="telp"
    //             pattern="^[0-9]*$"
    //             value={phone}
    //             onChange={handleChangePhone}
    //             placeholder="Masukan Telpon"
    //             isError={{
    //               value: errors.telp,
    //               message: errors.telp?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("address", {
    //               required: { value: false, message: "Alamat wajib diisi" },
    //             })}
    //             label="Alamat"
    //             type="address"
    //             placeholder="Masukan Alamat"
    //             isError={{
    //               value: errors.address,
    //               message: errors.address?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("responsible", {
    //               required: {
    //                 value: false,
    //                 message: "Penanggung Jawab wajib diisi",
    //               },
    //             })}
    //             label="Penanggung Jawab"
    //             type="responsible"
    //             placeholder="Masukan Penanggung Jawab"
    //             isError={{
    //               value: errors.responsible,
    //               message: errors.responsible?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("legality", {
    //               required: { value: false, message: "Legalitas wajib diisi" },
    //             })}
    //             label="Legalitas"
    //             type="legality"
    //             placeholder="Masukan Legalitas"
    //             isError={{
    //               value: errors.legality,
    //               message: errors.legality?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("personal_in_charge_name", {
    //               required: {
    //                 value: false,
    //                 message: "Nama Personal in Charge wajib diisi",
    //               },
    //             })}
    //             label="Nama Personal in Charge"
    //             type="personal_in_charge_name"
    //             placeholder="Masukan Nama Personal in Charge"
    //             isError={{
    //               value: errors.personal_in_charge_name,
    //               message: errors.personal_in_charge_name?.message,
    //             }}
    //           />
    //           <Datepicker
    //             {...register("join_date", {
    //               required: {
    //                 value: false,
    //                 message: "Tanggal Gabung wajib diisi",
    //               },
    //             })}
    //             selected={join_date}
    //             onChange={handleChangeJoinDate}
    //             label="Tanggal Gabung"
    //             placeholder="Masukan Tanggal Gabung"
    //             isError={{
    //               value: errors.join_date,
    //               message: errors.join_date?.message,
    //             }}
    //           />
    //           <InputValidation
    //             {...register("agreement_file", {
    //               required: {
    //                 value: false,
    //                 message: "Landasan Perjanjian wajib diisi",
    //               },
    //             })}
    //             label="Landasan Perjanjian, File (Lampirkan)"
    //             type="agreement_file"
    //             placeholder="Masukan Landasan Perjanjian"
    //             readOnly={false}
    //             value={attachment?.name}
    //             className="cursor-pointer"
    //             onClick={onTriggerSelectFile}
    //             isError={{
    //               value: errors.agreement_file,
    //               message: errors.agreement_file?.message,
    //             }}
    //           />
    //           <input
    //             ref={attachmentRef}
    //             autoComplete="none"
    //             name="videoUpload"
    //             accept="image/png, image/jpg, image/jpeg, application/pdf"
    //             type="file"
    //             onChange={onSelectFile}
    //             onClick={(event) => {
    //               event.target.value = null;
    //             }}
    //             className="opacity-0 hidden"
    //           />
    //         </div>
    //       </div>
    //       <div
    //         className="bg-gray-50 space-y-4 rounded-lg border-gray-500 px-4 pt-4 pb-8"
    //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       >
    //         <div className="text-lg font-medium">Upload Dokumen</div>
    //         <div className="space-y-3">
    //           <FileItem
    //             label={"Angket"}
    //             name="angket"
    //             idUser={detail?.data?.id_partner}
    //             currentFile={detail?.data?.angket}
    //             templateFile={
    //               <a
    //                 href={TemplateAngket}
    //                 download="Template Angket"
    //                 target="_blank"
    //                 className="text-xs hover:text-gray-500"
    //                 rel="noreferrer"
    //               >
    //                 Download Template
    //               </a>
    //             }
    //             value={angket}
    //             onChange={(value) => onChangeFile(setAngket, value)}
    //           />
    //           <FileItem
    //             label={"Izin LPK dari Kabupaten"}
    //             name="license_region"
    //             idUser={detail?.data?.id_partner}
    //             currentFile={detail?.data?.license_region}
    //             value={licenseRegion}
    //             onChange={(value) => onChangeFile(setLicenseRegion, value)}
    //           />
    //           <FileItem
    //             label={"Struktur Organisasi"}
    //             name="license_region"
    //             idUser={detail?.data?.id_partner}
    //             currentFile={detail?.data?.structure_org}
    //             value={structureOrg}
    //             onChange={(value) => onChangeFile(setStructureOrg, value)}
    //           />
    //           <FileItem
    //             label={"NIB"}
    //             name="nib"
    //             idUser={detail?.data?.id_partner}
    //             currentFile={detail?.data?.nib}
    //             value={nib}
    //             onChange={(value) => onChangeFile(setNib, value)}
    //           />
    //         </div>
    //       </div>
    //       <div className="card-actions">
    //         <div className="flex space-x-2 w-full justify-end">
    //           <Button
    //             label={createData?.isLoading ? <Spinner /> : "Ubah"}
    //             disabled={createData?.isLoading}
    //             theme="primary"
    //             type="submit"
    //           />
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>

    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>Profile</h6>
        </div>

        <FormProvider {...methods}>
          <form className="space-y-6 p-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <BaseInput
                label="Nama"
                placeholder="Masukan nama"
                {...register("name", {
                  required: { value: true, message: "Nama wajib diisi" },
                })}
                error={errors.name?.message}
              />
              <BaseInput
                label="Email"
                placeholder="Masukan email"
                {...register("email", {
                  required: { value: true, message: "Email wajib diisi" },
                })}
                disabled
                error={errors.email?.message}
              />
            </div>

            <div className="flex flex-col">
              <Select
                name="province"
                label="Provinsi"
                options={provinces}
                placeholder="Masukan Nama Provinsi"
                onMenuOpen={() => dispatch(getProvinces())}
                isLoading={isLoadingProvinces}
                isDisabled={true}
                isError={{
                  value: methods?.formState?.errors?.province,
                  message: methods?.formState?.errors?.province?.message,
                }}
              />
              <BaseInput
                label="No. Handphone"
                placeholder="Masukan No. Handphone"
                type="number"
                {...register("phonenumber", {
                  required: {
                    value: false,
                    message: "No. Handphone wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "No. Handphone minimal 8 karakter",
                  },
                  maxLength: {
                    value: 13,
                    message: "No. Handphone maximal 13 karakter",
                  },
                })}
                pattern="^[0-9]*$"
                error={errors.phonenumber?.message}
              />
              <BaseInput
                label="No. Whatsapp"
                type="number"
                placeholder="Masukan No. Whatsapp"
                {...register("wa_number", {
                  required: {
                    value: false,
                    message: "No. Whatsapp wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "No. Whatsapp minimal 8 karakter",
                  },
                  maxLength: {
                    value: 13,
                    message: "No. Whatsapp maximal 13 karakter",
                  },
                })}
                pattern="^[0-9]*$"
                error={errors.wa_number?.message}
              />
              <BaseInput
                label="Telpon"
                placeholder="Masukan Telpon"
                type="number"
                {...register("telp", {
                  required: { value: false, message: "Telpon wajib diisi" },
                  minLength: {
                    value: 8,
                    message: "Telpon minimal 8 karakter",
                  },
                  maxLength: {
                    value: 13,
                    message: "Telpon maximal 13 karakter",
                  },
                })}
                error={errors.telp?.message}
              />
              <BaseInput
                label="Alamat"
                placeholder="Masukan alamat"
                {...register("address", {
                  required: { value: false, message: "Alamat wajib diisi" },
                })}
                error={errors.address?.message}
              />
              <BaseInput
                label="Penanggung jawab"
                placeholder="Masukan Penanggung jawab"
                {...register("responsible", {
                  required: {
                    value: false,
                    message: "Penanggung Jawab wajib diisi",
                  },
                })}
                error={errors.responsible?.message}
              />
              <BaseInput
                label="Legalitas"
                placeholder="Masukan legalitas"
                {...register("legality", {
                  required: {
                    value: false,
                    message: "Legalitas wajib diisi",
                  },
                })}
                error={errors.legality?.message}
              />
              <BaseInput
                label="Nama personal in Charge"
                placeholder="Masukan nama personal in Charge"
                {...register("personal_in_charge_name", {
                  required: {
                    value: false,
                    message: "Nama Personal in Charge wajib diisi",
                  },
                })}
                error={errors.personal_in_charge_name?.message}
              />
              <Datepicker
                {...register("join_date", {
                  required: {
                    value: false,
                    message: "Tanggal Gabung wajib diisi",
                  },
                })}
                selected={join_date}
                onChange={handleChangeJoinDate}
                label="Tanggal Gabung"
                placeholder="Masukan Tanggal Gabung"
                isError={{
                  value: errors.join_date,
                  message: errors.join_date?.message,
                }}
              />
              <span className="mt-4"></span>

              <BaseInput
                className="cursor-pointer"
                label="Landasan perjanjian, File (Lampirkan)"
                // type="file"
                value={attachment?.name}
                onClick={onTriggerSelectFile}
                {...register("agreement_file", {
                  required: {
                    value: false,
                    message: "Landasan Perjanjian wajib diisi",
                  },
                })}
                error={errors.agreement_file?.message}
              />

              <input
                ref={attachmentRef}
                autoComplete="none"
                name="videoUpload"
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                type="file"
                onChange={onSelectFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className="opacity-0 hidden"
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border p-6 ">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>Upload Dokumen</h6>
          <div className="space-y-3">
            <FileItem
              label={"Angket"}
              name="angket"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.angket}
              templateFile={
                <a
                  href={TemplateAngket}
                  download="Template Angket"
                  target="_blank"
                  className="text-xs hover:text-gray-500"
                  rel="noreferrer"
                >
                  Download Template
                </a>
              }
              value={angket}
              onChange={(value) => onChangeFile(setAngket, value)}
            />
            <FileItem
              label={"Izin LPK dari Kabupaten"}
              name="license_region"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.license_region}
              value={licenseRegion}
              onChange={(value) => onChangeFile(setLicenseRegion, value)}
            />
            <FileItem
              label={"Struktur Organisasi"}
              name="license_region"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.structure_org}
              value={structureOrg}
              onChange={(value) => onChangeFile(setStructureOrg, value)}
            />
            <FileItem
              label={"NIB"}
              name="nib"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.nib}
              value={nib}
              onChange={(value) => onChangeFile(setNib, value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        <BaseButton variant="primary2" onClick={handleSubmit(onSubmit)}>
          Ubah
        </BaseButton>
      </div>
    </section>
  );
};

export default ProfileMitra;
