import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Select,
  Spinner,
} from "components";
import { BaseInput as Input, NumericInput } from "components/v2";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage, zeroPad } from "helpers";
import {
  deleteAttachment,
  deleteAttachmentStudent,
  getDetail,
  postData,
  putData,
  uploadAttachment,
  uploadAttachmentStudent,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "services/auth";
import { IoMdCloseCircle, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { CgFileDocument, CgSpinner } from "react-icons/cg";
import TemplateAngket from "app/TEMPLATE-ANGKET-CALON-MITRA.docx";
import { FaEye } from "react-icons/fa";
import { getCities, getProvinces } from "features/core/coreSlice";
import BaseInput from "components/atoms/BaseInput";
import ModalWrapper from "components/molecules/ModalWrapper";
import BaseButton from "components/atoms/BaseButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const MitraManagementForm = () => {
  const modalResetPassword = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const attachmentRef = useRef();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Nama wajib diisi"),
        email: yup
          .string()
          .required("Email wajib diisi")
          .email("Format email tidak valid"),
        password: yup.string().when({
          is: () => !id,
          then: (v, schema) =>
            yup
              .string()
              .required("Password wajib diisi")
              .min(8, "Password minimal 8 karakter"),
        }),
        province: yup
          .object()
          .shape({
            value: yup.string().required("Provinsi wajib diisi"),
          })
          .required("Provinsi wajib diisi"),
      }),
    [id],
  );

  const { provinces, isLoadingProvinces, total_lpk } = useSelector(
    ({ core }) => core,
  );

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      province: null,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch: watchForm,
  } = methods;

  const {
    register: register_form_reset,
    handleSubmit: handleSubmit_form_reset,
    formState: { errors: errors_form_reset },
    reset: reset_form_reset,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const [attachment, setAttachment] = useState(null);
  const [hp, setHp] = useState("");
  const [wa, setWa] = useState("");
  const [phone, setPhone] = useState("");
  const [join_date, setJoinDate] = useState(null);

  const [angket, setAngket] = useState(null);
  const [structureOrg, setStructureOrg] = useState(null);
  const [nib, setNib] = useState(null);
  const [mou, setMou] = useState(null);
  const [licenseRegion, setLicenseRegion] = useState(null);

  const [mitraCode, setMitraCode] = useState(null);

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    if (methods.watch("province")) {
      setMitraCode(methods.watch("province")?.code + (total_lpk + 1));
    }
  }, [total_lpk, methods.watch("province")]);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      reset({
        name: detail?.data?.name,
        email: detail?.data?.email,
        address: detail?.data?.address,
        responsible: detail?.data?.trusted,
        legality: detail?.data?.legalitas,
        hp: detail?.data?.hp ? detail?.data?.hp : "",
        wa: detail?.data?.wa ? detail?.data?.wa : "",
        phone: detail?.data?.phone ? detail?.data?.phone : "",
        personal_in_charge_name: detail?.data?.person_in_charge,
        agreement_file: detail?.data?.attachment,
        province: {
          value: detail?.data?.province_id,
          label: detail?.data?.province_name,
        },
        join_date: detail?.data?.joined_at
          ? moment(detail?.data?.joined_at).format("YYYY-MM-DD")
          : null,
      });
      setAttachment({ name: detail?.data?.attachment });
      setHp(detail?.data?.hp);
      setWa(detail?.data?.wa);
      setPhone(detail?.data?.phone);
      if (detail?.data?.joined_at) {
        setJoinDate(new Date(detail?.data?.joined_at));
      }
      detail?.data?.angket &&
        setAngket((prevState) => ({
          ...prevState,
          name: detail?.data?.angket,
        }));
      detail?.data?.license_region &&
        setLicenseRegion((prevState) => ({
          ...prevState,
          name: detail?.data?.license_region,
        }));
      detail?.data?.structure_org &&
        setStructureOrg((prevState) => ({
          ...prevState,
          name: detail?.data?.structure_org,
        }));
      detail?.data?.nib &&
        setNib((prevState) => ({
          ...prevState,
          name: detail?.data?.nib,
        }));
      detail?.data?.mou &&
        setMou((prevState) => ({
          ...prevState,
          name: detail?.data?.mou,
        }));
    }
  }, [detail]);

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getDetail, params: id }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {};
      params.roles = "2";
      params.province_id = methods.watch("province")?.value;
      params.city_id = "3217";
      params.district_id = "321706";
      if (!id) params.code = mitraCode;
      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (id) {
        if (data?.join_date) {
          params.joined_at = moment(data?.join_date).format("YYYY-MM-DD");
        }
        if (data?.name) params.name = data?.name;
        if (data?.address) params.address = data?.address;
        if (data?.responsible) params.trusted = data?.responsible;
        if (data?.legality) params.legalitas = data?.legality;
        if (data?.hp) params.hp = data?.hp;
        if (data?.wa) params.wa = data?.wa;
        if (data?.phone) params.phone = data?.phone;
        if (data?.personal_in_charge_name)
          params.person_in_charge = data?.personal_in_charge_name;
        if (data?.agreement_file) params.attachment = data?.agreement_file;
        if (data?.email) params.email = data?.email;
        if (angket) params.angket = angket?.name;
        if (structureOrg) params.structure_org = structureOrg?.name;
        if (nib) params.nib = nib?.name;
        if (mou) params.mou = mou?.name;
        if (licenseRegion) params.license_region = licenseRegion?.name;
        fetchAPI(
          { service: putData, params: { id, payload: params } },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses mengubah data!");
              navigate(-1);
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      } else {
        params.password = data?.password;
        params.name = data?.name;
        params.address = data?.address;
        params.trusted = data?.responsible;
        params.legalitas = data?.legality;
        params.hp = data?.hp;
        params.wa = data?.wa;
        params.phone = data?.phone;
        params.person_in_charge = data?.personal_in_charge_name;
        params.attachment = data?.agreement_file;
        params.email = data?.email;
        params.joined_at = data?.join_date
          ? moment(data?.join_date).format("YYYY-MM-DD")
          : "";
        params.angket = angket?.name;
        params.structure_org = structureOrg?.name;
        params.nib = nib?.name;
        params.mou = mou?.name;
        params.license_region = licenseRegion?.name;
        fetchAPI({ service: postData, params: params }, (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses menambahkan data!");
            navigate(-1);
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        });
      }
    }
  };

  const onChangeFile = (cb, value) => {
    cb?.(value);
  };

  const handleChangeJoinDate = (date) => {
    setJoinDate(date);
    setValue("join_date", date);
  };

  const onTriggerSelectFile = () => {
    attachmentRef?.current?.click();
  };

  const onSelectFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        const formData = new FormData();
        formData.append("attachment", file);
        fetchAPI(
          { service: uploadAttachment, params: formData },
          (success, response) => {
            if (success) {
              setAttachment(file);
              if (attachment?.name) {
                fetchAPI({
                  service: deleteAttachment,
                  params: attachment?.name,
                });
              }
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const handleChangeHp = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("phonenumber", value);
      setHp(value);
    }
  };

  const handleChangeWa = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("wa_number", value);
      setWa(value);
    }
  };

  const handleChangePhone = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("telp", value);
      setPhone(value);
    }
  };

  const onPopupResetPassword = () => {
    modalResetPassword?.current?.click();
  };

  const onSubmitResetPassword = (data) => {
    const params = {
      id: detail?.data?.id,
      password: data?.old_password,
      new_password: data?.new_password,
    };

    fetchAPI({ service: updatePassword, params }, (success, response) => {
      if (success) {
        toast.success("Berhasil reset password!");
        modalResetPassword?.current?.click();
        setShowOldPass(false);
        setShowNewPass(false);
        setShowConfirmPass(false);
        reset_form_reset({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
      }
    });
  };

  const renderModalResetPassword = () => (
    <>
      <input
        ref={modalResetPassword}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Reset Password</h3>
          <form onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}>
            <div className="space-y-4 py-4">
              <InputValidation
                {...register_form_reset("old_password", {
                  required: {
                    value: true,
                    message: "Password Lama wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Lama minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showOldPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Lama"
                name="old_password"
                type={showOldPass ? "text" : "password"}
                placeholder="Masukan Password Lama"
                isError={{
                  value: errors_form_reset.old_password,
                  message: errors_form_reset.old_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("new_password", {
                  required: {
                    value: true,
                    message: "Password Baru wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Baru minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showNewPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Baru"
                name="new_password"
                type={showNewPass ? "text" : "password"}
                placeholder="Masukan Password Baru"
                isError={{
                  value: errors_form_reset.new_password,
                  message: errors_form_reset.new_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("confirm_password", {
                  required: {
                    value: true,
                    message: "Konfirmasi Password wajib diisi",
                  },
                  validate: (val) => {
                    if (watch("new_password") != val) {
                      return "Konfirmasi Password tidak sama dengan pasword baru";
                    }
                  },
                  minLength: {
                    value: 8,
                    message: "Konfirmasi Password minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showConfirmPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Konfirmasi Password"
                name="confirm_password"
                type={showConfirmPass ? "text" : "password"}
                placeholder="Masukan Konfirmasi Password"
                isError={{
                  value: errors_form_reset.confirm_password,
                  message: errors_form_reset.confirm_password?.message,
                }}
              />
            </div>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                type="button"
                onClick={() => {
                  setShowOldPass(false);
                  setShowNewPass(false);
                  setShowConfirmPass(false);
                  reset_form_reset({
                    old_password: "",
                    new_password: "",
                    confirm_password: "",
                  });
                  modalResetPassword?.current?.click();
                }}
              />
              <Button label={"Ya"} size="sm" theme="primary" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );

  return (
    <section className="flex flex-col gap-6">
      <ModalWrapper
        isOpen={showModalResetPassword}
        setIsOpen={setShowModalResetPassword}
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Reset password</h5>
            </div>
            <div className="relative flex-auto p-4">
              <form
                onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}
                className="space-y-4 w-100"
              >
                <BaseInput
                  label="Password lama"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showOldPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowOldPass((prev) => !prev)}
                    >
                      {showOldPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("old_password", {
                    required: {
                      value: true,
                      message: "Password Lama wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Lama minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.old_password?.message}
                />

                <BaseInput
                  label="Password baru"
                  placeholder="Masukan password baru"
                  size="md"
                  type={showNewPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowNewPass(!showOldPass)}
                    >
                      {showNewPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("new_password", {
                    required: {
                      value: true,
                      message: "Password Baru wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Baru minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.new_password?.message}
                />

                <BaseInput
                  label="Konfirmasi Password"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showConfirmPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowConfirmPass(!showOldPass)}
                    >
                      {showConfirmPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("confirm_password", {
                    required: {
                      value: true,
                      message: "Konfirmasi Password wajib diisi",
                    },
                    validate: (val) => {
                      if (watch("new_password") != val) {
                        return "Konfirmasi Password tidak sama dengan pasword baru";
                      }
                    },
                    minLength: {
                      value: 8,
                      message: "Konfirmasi Password minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.confirm_password?.message}
                />
                <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                  <BaseButton
                    variant="base"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setShowModalResetPassword((prev) => !prev);
                      reset_form_reset();
                    }}
                  >
                    Tidak
                  </BaseButton>
                  <BaseButton variant="primary2" size="sm" type="submit">
                    Ya
                  </BaseButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>{id ? "Edit LPK Mitra" : "Tambah LPK Mitra"}</h6>
        </div>
        <FormProvider {...methods}>
          <form className="space-y-6 p-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
              <Input
                type="text"
                name="name"
                label="Nama"
                placeholder="Masukan nama"
              />
              <Input
                type="email"
                label="Email"
                placeholder="Masukan email"
                name="email"
                disabled={id}
              />
              {!id ? (
                <Input
                  label="Password"
                  placeholder="Masukan password"
                  type="password"
                  name="password"
                />
              ) : null}
            </div>

            <div className="flex flex-col">
              <Select
                name="province"
                label="Provinsi"
                options={provinces}
                placeholder="Masukan Nama Provinsi"
                onMenuOpen={() => dispatch(getProvinces())}
                isLoading={isLoadingProvinces}
                isDisabled={id}
                isError={{
                  value: methods?.formState?.errors?.province,
                  message: methods?.formState?.errors?.province?.message,
                }}
              />
              <NumericInput
                name="hp"
                label="No. Handphone"
                placeholder="Masukan No. Handphone"
              />
              <NumericInput
                name="wa"
                label="No. Whatsapp"
                placeholder="Masukan No. Whatsapp"
              />
              <NumericInput
                name="phone"
                label="Telpon"
                placeholder="Masukan Telpon"
              />
              <BaseInput
                label="Alamat"
                placeholder="Masukan alamat"
                {...register("address", {
                  required: { value: false, message: "Alamat wajib diisi" },
                })}
                error={errors.address?.message}
              />
              <BaseInput
                label="Penanggung jawab"
                placeholder="Masukan Penanggung jawab"
                {...register("responsible", {
                  required: {
                    value: false,
                    message: "Penanggung Jawab wajib diisi",
                  },
                })}
                error={errors.responsible?.message}
              />
              <BaseInput
                label="Legalitas"
                placeholder="Masukan legalitas"
                {...register("legality", {
                  required: {
                    value: false,
                    message: "Legalitas wajib diisi",
                  },
                })}
                error={errors.legality?.message}
              />
              <BaseInput
                label="Nama personal in Charge"
                placeholder="Masukan nama personal in Charge"
                {...register("personal_in_charge_name", {
                  required: {
                    value: false,
                    message: "Nama Personal in Charge wajib diisi",
                  },
                })}
                error={errors.personal_in_charge_name?.message}
              />
              <Datepicker
                {...register("join_date", {
                  required: {
                    value: false,
                    message: "Tanggal Gabung wajib diisi",
                  },
                })}
                selected={join_date}
                onChange={handleChangeJoinDate}
                label="Tanggal Gabung"
                placeholder="Masukan Tanggal Gabung"
                isError={{
                  value: errors.join_date,
                  message: errors.join_date?.message,
                }}
              />
              <span className="mt-4"></span>

              <BaseInput
                className="cursor-pointer"
                label="Landasan perjanjian, File (Lampirkan)"
                // type="file"
                value={attachment?.name}
                onClick={onTriggerSelectFile}
                {...register("agreement_file", {
                  required: {
                    value: false,
                    message: "Landasan Perjanjian wajib diisi",
                  },
                })}
                error={errors.agreement_file?.message}
              />

              <input
                ref={attachmentRef}
                autoComplete="none"
                name="videoUpload"
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                type="file"
                onChange={onSelectFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className="opacity-0 hidden"
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border p-6 ">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>Upload Dokumen</h6>
          <div className="space-y-3">
            <FileItem
              label={"Angket"}
              name="angket"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.angket}
              templateFile={
                <a
                  href={TemplateAngket}
                  download="Template Angket"
                  target="_blank"
                  className="text-xs hover:text-gray-500"
                  rel="noreferrer"
                >
                  Download Template
                </a>
              }
              value={angket}
              onChange={(value) => onChangeFile(setAngket, value)}
            />
            <FileItem
              label={"Izin LPK dari Kabupaten"}
              name="license_region"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.license_region}
              value={licenseRegion}
              onChange={(value) => onChangeFile(setLicenseRegion, value)}
            />
            <FileItem
              label={"Struktur Organisasi"}
              name="license_region"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.structure_org}
              value={structureOrg}
              onChange={(value) => onChangeFile(setStructureOrg, value)}
            />
            <FileItem
              label={"NIB"}
              name="nib"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.nib}
              value={nib}
              onChange={(value) => onChangeFile(setNib, value)}
            />
            <FileItem
              label={"MOU"}
              name="mou"
              idUser={detail?.data?.id_partner}
              currentFile={detail?.data?.mou}
              value={mou}
              onChange={(value) => onChangeFile(setMou, value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        {id ? (
          <BaseButton onClick={() => setShowModalResetPassword(true)}>
            Reset Password
          </BaseButton>
        ) : null}
        <BaseButton variant="base" onClick={() => navigate(-1)}>
          Kembali
        </BaseButton>
        <BaseButton variant="primary2" onClick={handleSubmit(onSubmit)}>
          Kirim
        </BaseButton>
      </div>
    </section>
  );
};

export const FileItem = ({
  label,
  onChange,
  value,
  templateFile,
  name = "",
  idUser = "",
  currentFile = null,
}) => {
  const { state } = useLocation();
  const id = state?.id ?? null;

  const refFile = useRef();
  const modalDeleteRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectFile = async (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (
        (name === "angket" &&
          file.type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          )) ||
        (name !== "angket" &&
          (file.type.includes("jpg") ||
            file.type.includes("jpeg") ||
            file.type.includes("png") ||
            file.type.includes("jfif") ||
            file.type.includes("pdf")))
      ) {
        const formData = new FormData();
        formData.append("attachment", file);
        fetchAPI(
          { service: uploadAttachmentStudent, params: formData },
          (success, response) => {
            if (success) {
              onChange(file);
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteFile = () => {
    try {
      if (value?.name && value?.lastModified) {
        setIsLoading(true);
        fetchAPI(
          {
            service: deleteAttachmentStudent,
            params: value?.name,
          },
          (success) => {
            if (success) {
              onChange(currentFile ? { name: currentFile } : null);
            }
          },
        );
      } else {
        modalDeleteRef?.current?.click();
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteData = () => {
    try {
      setIsLoading(true);
      fetchAPI(
        {
          service: deleteAttachmentStudent,
          params: value?.name,
        },
        (success) => {
          if (success) {
            onChange(null);
          }
        },
      );
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalDeleteDocument = () => (
    <>
      <input
        ref={modalDeleteRef}
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hapus Dokumen!</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menghapus dokumen ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              type="button"
              size="sm"
              theme="error"
              onClick={() => modalDeleteRef?.current?.click()}
            />
            <Button
              label={"Ya"}
              type="button"
              size="sm"
              theme="primary"
              onClick={handleDeleteData}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* {renderModalDeleteDocument()} */}
      <div
        className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 cursor-pointer ${"border-gray-400"}`}
      >
        <div
          className="flex items-center space-x-2 flex-1"
          onClick={() => refFile?.current?.click()}
        >
          <CgFileDocument />
          {value ? (
            <span className="text-gray-500">
              <span>{value?.name ?? ""}</span>
              <small>{` | ${label}`}</small>
            </span>
          ) : (
            <span className="text-gray-500">
              <span>{label ?? ""}</span>
            </span>
          )}
        </div>
        <div className="flex items-center space-x-2 whitespace-nowrap">
          {templateFile && (
            <div className="flex items-center">{templateFile}</div>
          )}
          {value && (
            <a
              href={
                value?.name && value?.lastModified
                  ? URL.createObjectURL(value)
                  : `${process.env.REACT_APP_API}/uploads/attachment/partner/${idUser}/${name}/${value?.name}`
              }
              download={value?.name}
              target={!value?.name?.includes(".doc") ? "_blank" : "_self"}
              className="text-xs hover:text-gray-500"
              rel="noreferrer"
            >
              <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
            </a>
          )}
          {value && value?.lastModified ? (
            isLoading ? (
              <CgSpinner className="animate-spin text-lg text-primary" />
            ) : (
              <IoMdCloseCircle
                className="text-lg hover:scale-110 transition-all cursor-pointer"
                onClick={onDeleteFile}
              />
            )
          ) : null}
        </div>
        <input
          ref={refFile}
          autoComplete="none"
          name="videoUpload"
          accept={
            name === "angket"
              ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              : ""
          }
          type="file"
          onChange={handleSelectFile}
          onClick={(event) => {
            event.target.value = null;
          }}
          className="opacity-0 hidden"
        />
      </div>
    </>
  );
};

export default MitraManagementForm;
