import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Label } from "components";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  getDetailStudent,
  getEvaluation,
  getEvaluationPersonal,
  getStudentProfile,
} from "services/user";
import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

const Academic = () => {
  const navigate = useNavigate();

  const { user } = useSelector(({ auth }) => auth);

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [id, setId] = useState(null);

  useEffect(() => {
    if (user?.id) {
      setId(user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  const options = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        title: {
          display: false,
        },
      },
    };
  }, []);

  const fetchEvaluation = (data) => {
    fetchAPI(
      { service: getEvaluationPersonal, params: { class_id: data?.class?.id } },
      (success, response) => {
        let evaluationScore = response?.data ?? [];
        let mappingData = _.map(data?.class?.leassons, (leasson) => ({
          id: leasson?.id,
          name: leasson?.name,
          subjects: _.map(leasson?.subjects, (subject) => ({
            ...subject,
            attendence: null,
            attitude: null,
            ontime: null,
            punctuality: null,
            score: "",
            total_question: "",
          })),
        }));
        mappingData = _.filter(
          mappingData,
          (eval_score) => eval_score?.subjects?.length > 0,
        );
        if (success) {
          mappingData = _.map(mappingData, (map_data) => {
            const evalData = _.find(
              evaluationScore,
              (eval_data) => eval_data?.id === map_data.id,
            );
            if (evalData?.subjects?.length > 0) {
              return {
                ...evalData,
                subjects: _.map(map_data?.subjects, (subject) => {
                  const subjectMitra = _.find(evalData?.subjects, {
                    name: subject?.name,
                  });
                  if (subjectMitra) {
                    return subjectMitra;
                  }
                  return subject;
                }),
              };
            }
            return map_data;
          });

          const mitra = _.map(mappingData, (mitra) => {
            const grouping = _.groupBy(mitra.subjects, "name");
            return { key: Object.keys(grouping), data: mitra };
          });

          const uniq = _.uniq(_.map(mitra, "key").flat(1));

          const result = _.map(uniq, (key) => {
            const sumScore = _.sumBy(_.map(mitra, "data"), (data) => {
              const grouping = _.groupBy(data?.subjects, "name");
              return _.first(grouping[key])?.score
                ? parseFloat(_.first(grouping[key]).score)
                : 0;
            });
            const sumQuestion = _.sumBy(_.map(mitra, "data"), (data) => {
              const grouping = _.groupBy(data?.subjects, "name");
              return _.first(grouping[key])?.total_question
                ? parseFloat(_.first(grouping[key]).total_question)
                : 0;
            });
            return {
              sumScore,
              sumQuestion,
              key,
              percentage: parseFloat((sumScore / sumQuestion) * 100),
            };
          });

          setChartData({
            labels: _.map(mappingData, "name").concat("Total"),
            datasets: _.sortBy(
              _.uniqBy(
                _.map(mappingData, (evalObj) =>
                  _.map(evalObj?.subjects, (subject) => ({
                    label: subject?.name,
                    data: _.map(
                      mappingData,
                      (evalObj) =>
                        _.find(evalObj?.subjects, {
                          name: subject?.name,
                        })?.score ?? 0,
                    ).concat(_.find(result, { key: subject?.name }).percentage),
                  })),
                ).flat(1),
                "label",
              ),
              (subj) => {
                return parseInt(
                  subj.label.split(" - ")[0]?.replace("BAB ", ""),
                );
              },
            ),
          });
          setDetail((prevState) => ({
            ...prevState,
            isLoading: false,
            data: { ...prevState?.data, evaluation: mappingData },
          }));
        }
      },
    );
  };

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getStudentProfile }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        let mappingData = _.map(data?.class?.leassons, (leasson) => ({
          input_by: "",
          id: leasson?.id,
          name: leasson?.name,
          subjects: _.map(leasson?.subjects, (subject) => ({
            ...subject,
            attendence: null,
            attitude: null,
            ontime: null,
            punctuality: null,
            score: "",
          })),
        }));

        mappingData = _.filter(
          mappingData,
          (eval_score) => eval_score?.subjects?.length > 0,
        );

        setChartData({
          labels: _.map(mappingData, "name"),
          datasets: _.map(mappingData, (evalObj) =>
            _.map(evalObj?.subjects, (subject) => ({
              label: subject?.name,
              data: _.map(
                mappingData,
                (evalObj) =>
                  _.find(evalObj?.subjects, { name: subject?.name })?.score ??
                  0,
              ),
            })),
          ).flat(1),
        });

        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data: { ...prevState?.data, ...data, evaluation: mappingData },
        }));
        fetchEvaluation(data);
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onDetailEvaluation = ({ lesson, subject }) => {
    navigate("/academic/evaluation", {
      state: {
        student_id: id,
        class: detail?.data?.class,
        lesson,
        subject,
      },
    });
  };

  const renderAcademicBody = () => {
    if (!detail?.data?.evaluation || detail?.data?.evaluation?.length <= 0) {
      return (
        <tr>
          {/* <td colSpan={"7"}> */}
          <td colSpan={"2"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(detail?.data?.evaluation, (lesson, key) => (
      <tr key={key}>
        <td>{lesson?.name}</td>
        <td>
          <div className="flex items-center justify-end space-x-2">
            <Button
              label={<MdEdit className="text-white" />}
              size="sm"
              theme="warning"
              onClick={() => onDetailEvaluation({ lesson })}
            />
          </div>
        </td>
      </tr>
    ));
  };

  const renderData = () => {
    if (!detail?.data?.evaluation || detail?.data?.evaluation.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={3}
          >
            <h6>No Data</h6>
          </td>
        </tr>
      );
    }

    return _.map(detail?.data?.evaluation, (lesson, key) => {
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1 gap-4 items-center">
              <h6 className="mb-0 leading-normal text-sm">
                {lesson?.name ?? "-"}
              </h6>
            </div>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => {
                  onDetailEvaluation({ lesson });
                }}
              >
                Detail
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    // <div className="space-y-4">
    //   <div className="flex justify-between items-center">
    //     <Label text={"Akademik"} className="font-bold text-2xl" />
    //   </div>
    //   <div className="border rounded-lg overflow-hidden">
    //     <table className="table table-compact w-full">
    //       <thead>
    //         <tr>
    //           <th>Mata Pelajaran</th>
    //           {/* <th>Kehadiran</th>
    //           <th>Ketepatan Waktu</th>
    //           <th>Sopan Santun</th>
    //           <th>Disiplin Waktu</th>
    //           <th>Nilai</th> */}
    //           <th></th>
    //         </tr>
    //       </thead>
    //       <tbody>{renderAcademicBody()}</tbody>
    //     </table>
    //   </div>
    //   {chartData?.labels?.length > 0 && (
    //     <Bar options={options} data={chartData} height={80} />
    //   )}
    // </div>
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
        <h5>Akademik</h5>
      </div>
      <div className="flex-auto px-0 pt-0 pb-2 mb-2">
        <div className="p-0 overflow-x-auto">
          <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
            <thead className="align-bottom">
              <tr>
                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  Mata Pelajaran
                </th>
                <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
              </tr>
            </thead>
            <tbody>{renderAcademicBody()}</tbody>
          </table>
        </div>
      </div>

      {chartData?.labels?.length > 0 && (
        <Bar options={options} data={chartData} height={80} />
      )}
    </div>
  );
};

export default Academic;
