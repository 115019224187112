import React from "react";
import SelectInput from ".";

const options = [
  {
    value: "キリスト教徒 (Kristen Protestan)",
    label: "キリスト教徒 (Kristen Protestan)",
  },
  {
    value: "キリスト教徒 (Kristen Katholik)",
    label: "キリスト教徒 (Kristen Katholik)",
  },
  { value: "イスラム教 (Islam)", label: "イスラム教 (Islam)" },
  { value: "ヒンドゥー教 (Hindu)", label: "ヒンドゥー教 (Hindu)" },
  { value: "仏 (Buddha)", label: "仏 (Buddha)" },
  { value: "儒教 (Khonghucu)", label: "儒教 (Khonghucu)" },
];

const SelectReligion = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectReligion;
