import React from "react";
import { Link } from "react-router-dom";

const Card = ({ label, icon, value, onClick, cardIndex }) => {
  return (
    <Link onClick={() => onClick(cardIndex)}>
      <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
        <div className="flex-auto h-full p-4">
          <div className="flex flex-wrap -mx-3">
            <div className="flex-none w-2/3 max-w-full px-3">
              <div>
                <p className="mb-2 font-semibold leading-normal text-sm flex flex-col">
                  {label}
                </p>
                <h5 className="mb-0 font-bold">{value}</h5>
              </div>
            </div>
            <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                {icon}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
