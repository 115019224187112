import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import _ from "lodash";
// import { motion } from "framer-motion";

import { CgSpinner } from "react-icons/cg";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { getLocales } from "locales";
import { BaseInput as Input } from "components/v2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { signIn } from "services/auth";
import { logIn } from "features/auth/authSlice";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email wajib diisi")
    .email("Format email tidak valid"),
  password: yup
    .string()
    .required("Password wajib diisi")
    .min(8, "Password minimal 8 karakter"),
});

const Login = () => {
  const { LOGIN } = getLocales();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [showPass, setShowPass] = useState(false);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const login = useMutation({ mutationFn: (payload) => signIn(payload) });

  const onSubmit = (data) => {
    login.mutate(data, {
      onSuccess: (response) => {
        response = response?.data;
        dispatch(logIn(response));
      },
    });
  };

  const renderPasswordIcon = () => {
    return (
      <span
        className="absolute top-3 right-2 text-gray-600 cursor-pointer h-6 w-6 text-center"
        onClick={() => setShowPass((prev) => !prev)}
      >
        {showPass ? <IoMdEye /> : <IoMdEyeOff />}
      </span>
    );
  };

  return (
    <div>
      <div
        className="hero min-h-screen"
        exit={{ y: 50, opacity: 0 }}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.25 }}
      >
        <main className="mt-0 transition-all duration-200 ease-soft-in-out">
          <section>
            <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
              <div className="container z-1">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex flex-col w-full max-w-full px-6 lg:px-12 mx-auto flex-0 md:w-8/12 lg:w-6/12 xl:w-5/12">
                    <div className="relative flex flex-col min-w-0 mt-12 break-words bg-transparent border-0 shadow-none dark:bg-gray-950 rounded-2xl bg-clip-border">
                      <div className="lg:px-6 pb-0 mb-0">
                        <h3 className="relative text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-cyan-400 z-1 ">
                          {LOGIN.TITLE}
                        </h3>
                        <p className="mb-0">{LOGIN.DESCRIPTION}</p>
                      </div>

                      <div className="flex-auto lg:p-6">
                        <FormProvider {...methods}>
                          <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Input
                              label={LOGIN.CARD.EMAIL}
                              type="email"
                              placeholder="Email"
                              name="email"
                            />
                            <Input
                              label={LOGIN.CARD.PASSWORD}
                              type={!showPass ? "password" : "text"}
                              placeholder="Password"
                              name="password"
                              suffixIcon={renderPasswordIcon()}
                            />
                            <div className="text-center">
                              <button
                                type="submit"
                                className="inline-block w-full px-6 py-3 mt-6 mb-0 font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs bg-gradient-to-tl from-blue-600 to-cyan-400 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25"
                              >
                                {LOGIN.CARD.BTN_TEXT}
                              </button>
                            </div>
                          </form>
                        </FormProvider>
                      </div>
                    </div>
                  </div>
                  <div className="w-full max-w-full px-3 my-auto shrink-0 md:flex-0 md:w-6/12">
                    <div className="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block ">
                      <div className="skew-x-10 bg-cover -ml-16 h-full absolute inset-x-0 top-0 bg-[url('assets/img/curved9.jpg')]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Login;
