import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Spinner,
} from "components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteAttachment,
  getDetail,
  postData,
  putData,
  uploadAttachment,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";

const JapanPartnerManagementForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const attachmentRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [attachment, setAttachment] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [greetingSelected, setGreetingSelected] = useState(null);
  const [hp, setHp] = useState("");
  const [wa, setWa] = useState("");
  const [phone, setPhone] = useState("");
  const [cooperation_date, setCooperationDate] = useState(null);

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      reset({
        name: detail?.data?.name,
        email: detail?.data?.email,
        address: detail?.data?.address,
        website: detail?.data?.website,
        leader: detail?.data?.leader,
        cooperation_date: detail?.data?.collab_at
          ? moment(detail?.data?.collab_at).format("YYYY-MM-DD")
          : null,
      });
      if (detail?.data?.collab_at) {
        setCooperationDate(new Date(detail?.data?.collab_at));
      }
    }
  }, [detail]);

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getDetail, params: id }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {};
      params.roles = "3";

      params.province_id = "32";
      params.city_id = "3217";
      params.district_id = "321706";
      params.phone = "081234567890";

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (id) {
        if (data?.cooperation_date) {
          params.collab_at = moment(data?.cooperation_date).format(
            "YYYY-MM-DD",
          );
        }
        if (data?.name) params.name = data?.name;
        if (data?.address) params.address = data?.address;
        if (data?.website) params.website = data?.website;
        if (data?.leader) params.leader = data?.leader;
        if (data?.email) params.email = data?.email;
        fetchAPI(
          { service: putData, params: { id, payload: params } },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses mengubah data!");
              navigate(-1);
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      } else {
        params.password = data?.password;
        params.name = data?.name;
        params.address = data?.address;
        params.website = data?.website;
        params.leader = data?.leader;
        params.email = data?.email;
        params.collab_at = data?.cooperation_date
          ? moment(data?.cooperation_date).format("YYYY-MM-DD")
          : "";
        fetchAPI({ service: postData, params: params }, (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses menambahkan data!");
            navigate(-1);
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        });
      }
    }
  };

  const handleChangeCooperationDate = (date) => {
    setCooperationDate(date);
    setValue("cooperation_date", date);
  };

  return (
    // <div className="w-full space-y-4">
    //   <div className="flex justify-between items-center">
    //     <Label
    //       text={id ? "Edit Partner Jepang" : "Tambah Partner Jepang"}
    //       className="font-bold text-2xl"
    //     />
    //   </div>

    //   <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
    //     <div className="space-y-8">
    //       <div
    //         className="bg-gray-50 rounded-lg border-gray-500 p-4 grid grid-cols-3 gap-2"
    //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       >
    //         <InputValidation
    //           {...register("name", {
    //             required: { value: true, message: "Nama wajib diisi" },
    //           })}
    //           label="Nama"
    //           type="name"
    //           placeholder="Masukan Nama"
    //           isError={{
    //             value: errors.name,
    //             message: errors.name?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("email", {
    //             required: { value: true, message: "Email wajib diisi" },
    //           })}
    //           autoComplete="new-password"
    //           label="Email"
    //           type="email"
    //           disabled={id}
    //           placeholder="Masukan Email"
    //           isError={{
    //             value: errors.email,
    //             message: errors.email?.message,
    //           }}
    //         />
    //         {!id && (
    //           <InputValidation
    //             {...register("password", {
    //               required: {
    //                 value: true,
    //                 message: "Password wajib diisi",
    //               },
    //               minLength: {
    //                 value: 8,
    //                 message: "Password minimal 8 karakter",
    //               },
    //             })}
    //             autoComplete="new-password"
    //             label="Password"
    //             name="password"
    //             type="password"
    //             placeholder="Masukan Password"
    //             isError={{
    //               value: errors.password,
    //               message: errors.password?.message,
    //             }}
    //           />
    //         )}
    //       </div>
    //       <div
    //         className="bg-gray-50 rounded-lg border-gray-500 px-4 pt-4 pb-8"
    //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       >
    //         <InputValidation
    //           {...register("address", {
    //             required: { value: false, message: "Alamat wajib diisi" },
    //           })}
    //           label="Alamat"
    //           type="address"
    //           placeholder="Masukan Alamat"
    //           isError={{
    //             value: errors.address,
    //             message: errors.address?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("website", {
    //             required: { value: false, message: "Website wajib diisi" },
    //           })}
    //           label="Website"
    //           type="website"
    //           placeholder="Masukan Website"
    //           isError={{
    //             value: errors.website,
    //             message: errors.website?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("leader", {
    //             required: {
    //               value: false,
    //               message: "Nama Ketua wajib diisi",
    //             },
    //           })}
    //           label="Nama Ketua"
    //           type="leader"
    //           placeholder="Masukan Nama Ketua"
    //           isError={{
    //             value: errors.leader,
    //             message: errors.leader?.message,
    //           }}
    //         />
    //         <Datepicker
    //           {...register("cooperation_date", {
    //             required: {
    //               value: false,
    //               message: "Tanggal Kerjasama wajib diisi",
    //             },
    //           })}
    //           selected={cooperation_date}
    //           onChange={handleChangeCooperationDate}
    //           label="Tanggal Kerjasama"
    //           placeholder="Masukan Tanggal Kerjasama"
    //           isError={{
    //             value: errors.cooperation_date,
    //             message: errors.cooperation_date?.message,
    //           }}
    //         />
    //       </div>
    //     </div>
    //     <div className="card-actions">
    //       <div className="flex space-x-2 w-full justify-end">
    //         <Button
    //           label={"Kembali"}
    //           disabled={createData?.isLoading}
    //           onClick={() => navigate(-1)}
    //           theme="light"
    //           type="button"
    //         />
    //         <Button
    //           label={createData?.isLoading ? <Spinner /> : "Kirim"}
    //           disabled={createData?.isLoading}
    //           theme="primary"
    //           type="submit"
    //         />
    //       </div>
    //     </div>
    //   </form>
    // </div>

    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>{id ? "Edit Partner Jepang" : "Tambah Partner Jepang"}</h6>
        </div>
        {/* <FormProvider {...methods}> */}
        <form className="space-y-6 p-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
            <BaseInput
              label="Nama"
              placeholder="Masukan nama"
              {...register("name", {
                required: { value: true, message: "Nama wajib diisi" },
              })}
              error={errors.name?.message}
            />
            <BaseInput
              label="Email"
              placeholder="Masukan email"
              {...register("email", {
                required: { value: true, message: "Email wajib diisi" },
              })}
              disabled={id}
              error={errors.email?.message}
            />
            {!id ? (
              <BaseInput
                label="Password"
                placeholder="Masukan password"
                {...register("password", {
                  required: { value: true, message: "Password wajib diisi" },
                  minLength: {
                    value: 8,
                    message: "Password minimal 8 karakter",
                  },
                })}
                error={errors.password?.message}
              />
            ) : null}
          </div>

          <div className="flex flex-col">
            <BaseInput
              label="Alamat"
              placeholder="Masukan alamat"
              {...register("address", {
                required: { value: false, message: "Alamat wajib diisi" },
              })}
              error={errors.address?.message}
            />
            <BaseInput
              label="Website"
              type="website"
              placeholder="Masukan website"
              {...register("website", {
                required: { value: false, message: "Website wajib diisi" },
              })}
              error={errors.website?.message}
            />
            <BaseInput
              label="Nama Ketua"
              placeholder="Masukan nama ketua"
              {...register("leader", {
                required: {
                  value: false,
                  message: "Nama Ketua wajib diisi",
                },
              })}
              error={errors.leader?.message}
            />

            <Datepicker
              {...register("cooperation_date", {
                required: {
                  value: false,
                  message: "Tanggal Kerjasama wajib diisi",
                },
              })}
              selected={cooperation_date}
              onChange={handleChangeCooperationDate}
              label="Tanggal Kerjasama"
              placeholder="Masukan Tanggal Kerjasama"
              isError={{
                value: errors.cooperation_date,
                message: errors.cooperation_date?.message,
              }}
            />
          </div>
        </form>
        {/* </FormProvider> */}
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        <BaseButton variant="base" type="button" onClick={() => navigate(-1)}>
          Kembali
        </BaseButton>
        <BaseButton variant="primary2" onClick={handleSubmit(onSubmit)}>
          Kirim
        </BaseButton>
      </div>
    </section>
  );
};

export default JapanPartnerManagementForm;
