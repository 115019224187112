import React, { useEffect, useState } from "react";
import ImageEmpty from "assets/img/empty-image.png";
import ProfileEmpty from "assets/img/dummy-profile-pic.png";

const ImageHandler = ({ type = "default", className = "", image }) => {
  const [source, setSource] = useState(
    type === "avatar" ? ProfileEmpty : ImageEmpty,
  );
  useEffect(() => {
    image && setSource(image);
  }, [image]);
  return (
    <img
      src={source}
      alt=""
      onError={() => setSource(type === "avatar" ? ProfileEmpty : ImageEmpty)}
      className={className}
    />
  );
};

export default ImageHandler;
