import React, { useEffect, useMemo, useState } from "react";
import _, { debounce, map } from "lodash";
import { useSelector } from "react-redux";
import { HiUserGroup } from "react-icons/hi";
import { FaUserCheck, FaUserGraduate } from "react-icons/fa";
import { MdCallReceived } from "react-icons/md";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { getLesson } from "services/core";
import { getList, getListStudent } from "services/user";
import {
  getDashboard,
  getRecapScore,
  getRecapStudent,
  getRecapStudentPassed,
  getRecapStudentSend,
  getStudentNotSend,
  getStudentPassedSend,
} from "services/dashboard";

import { Label, Pagination, Spinner } from "components";
import { fetchAPI, getErrorMessage } from "helpers";

import TableData from "./table-data";
import TableStudent from "./table-student";
import { useQuery } from "react-query";
import Card from "./card";
import Table from "./table";
import { BaseInput } from "components/v2";

const _Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector(({ auth }) => auth);

  const [page, setPage] = useState({
    recapStudentPage: 1,
    recapStudentHirePage: 1,
    recapStudentGraduatePage: 1,
  });

  const [dashboard, setDashboard] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    count: null,
    lesson: null,
    lpk: null,
    errorMessage: "",
  });

  const [cardAction, setCardAction] = useState();

  const [tab, setTab] = useState("lpk");
  const [tabRecap, setTabRecap] = useState("student");
  const [headerTable, setHeaderTable] = useState("");
  const [bodyTable, setBodyTable] = useState("");

  useEffect(() => {
    fetchData();
    fetchLesson();
    fetchRecapStudent();
    fetchRecapStudentPassed();
    fetchRecapStudentSend();
    fetchRecapScore();
    user?.role === "lpk_induk" && fetchLpk();
  }, []);

  useEffect(() => {
    if (
      user?.role !== "lpk_mitra" &&
      user?.role !== "lpk_mitra_so" &&
      user?.role !== "partner_jepang"
    ) {
      setTab("lpk");
    } else {
      setTab("lesson");
    }
  }, [user?.role]);

  const onClickCard = (type) => {
    switch (type) {
      case "total_student":
      case "total_student_hire":
      case "total_graduate":
        if (user?.role === "lpk_induk") {
          setCardAction(type);
        }
        break;
      case "total_male":
        break;
      case "total_female":
        break;
      case "total_partner":
        navigate("/japan-partner-management");
        break;
    }
  };

  const fetchData = () => {
    setDashboard((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getDashboard }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDashboard((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          count: data,
        }));
      } else {
        setDashboard((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const fetchLpk = (refetch) => {
    !refetch &&
      setDashboard((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      {
        service: getList,
        params: {
          filter: "lpk_mitra",
          limit: 5,
        },
      },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            lpk: data,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchLesson = (refetch) => {
    !refetch &&
      setDashboard((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: getLesson, params: { limit: 5 } },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            lesson: data,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchRecapStudent = (filter) => {
    fetchAPI(
      {
        service: getRecapStudent,
        params: { limit: 10, page: filter?.page ?? page.recapStudentPage },
      },
      (success, response) => {
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            recapStudent: response,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchRecapStudentPassed = (filter) => {
    fetchAPI(
      {
        service: getRecapStudentPassed,
        params: {
          limit: 10,
          page: filter?.page ?? page.recapStudentGraduatePage,
        },
      },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            recapStudentPassed: response,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchRecapStudentSend = (filter) => {
    fetchAPI(
      {
        service: getRecapStudentSend,
        params: { limit: 10, page: filter?.page ?? page.recapStudentHirePage },
      },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            recapStudentSend: response,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchRecapScore = (refetch) => {
    !refetch &&
      setDashboard((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: getRecapScore, params: { limit: 5 } },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            recapScore: data,
          }));
        } else {
          setDashboard((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  return (
    <div className="space-y-6">
      <div className="w-full space-y-1">
        <Label
          text={
            user?.role === "partner_jepang"
              ? `いらっしゃいませ, ${user?.name ?? ""}!`
              : `Selamat Datang, ${user?.name ?? ""}!`
          }
          className="font-bold text-2xl"
        />
        <Label
          text="Silakan untuk menambahkan data guru, siswa, dan kelas."
          className="text-sm text-gray-500"
        />
      </div>
      <div className="grid grid-cols-4 gap-4">
        <Link
          to={
            (user?.role === "lpk_mitra" ||
              user?.role === "lpk_mitra_so" ||
              user?.role === "partner_jepang") &&
            `/student-management`
          }
          onClick={() =>
            !(
              user?.role === "lpk_mitra" ||
              user?.role === "lpk_mitra_so" ||
              user?.role === "partner_jepang"
            ) && onClickCard("total_student")
          }
        >
          <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full h-max">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap -mx-3">
                <div className="flex-none w-2/3 max-w-full px-3">
                  <div>
                    <p className="mb-2 font-semibold leading-normal text-sm flex flex-col">
                      {user?.role === "partner_jepang"
                        ? "学生の数"
                        : "Jumlah Siswa Belajar"}
                      {user?.role === "partner_jepang" && (
                        <span className="text-[10px]">
                          {"(Jumlah Siswa Keseluruhan)"}
                        </span>
                      )}
                    </p>
                    <h5 className="mb-0 font-bold">
                      {user?.role === "lpk_mitra"
                        ? dashboard?.count?.totalStudentUnderLpk ?? "0"
                        : dashboard?.count?.totalStudent ?? "0"}
                    </h5>
                  </div>
                </div>
                <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                  <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                    <HiUserGroup />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        {user?.role === "partner_jepang" && (
          <Link
            to={`/student-management`}
            state={{ filter: { gender: "male" } }}
            onClick={() => onClickCard("total_male")}
          >
            <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-2 font-semibold leading-normal text-sm flex flex-col">
                        {user?.role === "partner_jepang"
                          ? "男性"
                          : "Jumlah Siswa Laki-Laki"}
                        {user?.role === "partner_jepang" && (
                          <span className="text-[10px]">
                            {"(Jumlah Siswa Laki-Laki)"}
                          </span>
                        )}
                      </p>
                      <h5 className="mb-0 font-bold">
                        {dashboard?.count?.totalMaleStudent ?? "0"}
                      </h5>
                    </div>
                  </div>
                  <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                    <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                      <HiUserGroup />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
        {user?.role === "partner_jepang" && (
          <Link
            to={`/student-management`}
            state={{ filter: { gender: "female" } }}
            onClick={() => onClickCard("total_female")}
          >
            <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-2 font-semibold leading-normal text-sm flex flex-col">
                        {user?.role === "partner_jepang"
                          ? "女性"
                          : "Jumlah Siswa Perempuan"}
                        {user?.role === "partner_jepang" && (
                          <span className="text-[10px]">
                            {"(Jumlah Siswa Perempuan)"}
                          </span>
                        )}
                      </p>
                      <h5 className="mb-0 font-bold">
                        {dashboard?.count?.totalFemaleStudent ?? "0"}
                      </h5>
                    </div>
                  </div>
                  <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                    <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                      <HiUserGroup />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
        {user?.role !== "partner_jepang" && (
          <Link
            to={
              (user?.role === "lpk_mitra" || user?.role === "lpk_mitra_so") &&
              `/hire`
            }
            onClick={() => onClickCard("total_student_hire")}
          >
            <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-2 font-semibold leading-normal text-sm">
                        {"Jumlah siswa lulus (Belum berangkat)"}
                      </p>
                      <h5 className="mb-0 font-bold">
                        {dashboard?.count?.totalStudentSend ?? "0"}
                      </h5>
                    </div>
                  </div>
                  <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                    <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                      <FaUserCheck />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
        {user?.role !== "partner_jepang" &&
          user?.role !== "lpk_mitra" &&
          user?.role !== "lpk_mitra_so" && (
            <Link to="/japan-partner-management">
              <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
                <div className="flex-auto p-4">
                  <div className="flex flex-wrap -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-semibold leading-normal text-sm">
                          Jumlah permintaan kumiay
                        </p>
                        <h5 className="mb-0 font-bold">
                          {dashboard?.count?.totalStudentSend ?? "0"}
                        </h5>
                      </div>
                    </div>
                    <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                      <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                        <MdCallReceived />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        {user?.role !== "lpk_mitra" && user?.role !== "lpk_mitra_so" && (
          <Link
            to={user?.role === "partner_jepang" && `/hire`}
            state={{ filter: { status: "graduate" } }}
            onClick={() =>
              user?.role !== "partner_jepang" && onClickCard("total_graduate")
            }
          >
            <div className="relative flex flex-col min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border h-full">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-2 font-semibold leading-normal text-sm flex flex-col">
                        {user?.role === "partner_jepang"
                          ? "合格者数 (講習中)"
                          : "Jumlah Siswa Lulus"}
                        {user?.role === "partner_jepang" && (
                          <span className="text-[10px]">
                            {"(Jumlah Siswa Lulus)"}
                          </span>
                        )}
                      </p>
                      <h5 className="mb-0 font-bold">
                        {dashboard?.count?.totalPassedStudent ?? "0"}
                      </h5>
                    </div>
                  </div>
                  <div className="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                    <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg">
                      <FaUserGraduate />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
      {cardAction === "total_student" && (
        <TableData
          data={dashboard?.recapStudent}
          label="Jumlah Siswa Belajar"
          onChangePage={(pg) =>
            setPage((state) => {
              fetchRecapStudent({ page: pg });
              return { ...state, recapStudentPage: pg };
            })
          }
        />
      )}
      {cardAction === "total_student_hire" && (
        <TableStudent
          data={dashboard?.recapStudentSend}
          label="Jumlah Siswa Terkirim"
          onChangePage={(pg) =>
            setPage((state) => {
              fetchRecapStudentSend({ page: pg });
              return { ...state, recapStudentHirePage: pg };
            })
          }
        />
      )}
      {cardAction === "total_graduate" && (
        <TableData
          data={dashboard?.recapStudentPassed}
          label="Jumlah Siswa Lulus"
          graduate={true}
          onChangePage={(pg) =>
            setPage((state) => {
              fetchRecapStudentPassed({ page: pg });
              return { ...state, recapStudentGraduatePage: pg };
            })
          }
        />
      )}
    </div>
  );
};

const partnerHeaders = [
  {
    id: "name",
    title: "Partner",
    render: (value) => (value ? value : "-"),
  },
  {
    id: "email",
    title: "Email",
    render: (value) => (value ? value : "-"),
  },
  {
    id: "to_date",
    title: "Tanggal Masuk TO",
    render: (value) => "-",
  },
];

const totalStudentUnderMitraHeaders = [
  {
    id: "name",
    title: "Siswa",
    render: (value) => (value ? value : "-"),
  },
];

const mitraHeaders = [
  {
    id: "name",
    title: "Mitra",
    render: (value) => (value ? value : "-"),
  },
  {
    id: "total_student",
    title: "Jumlah Siswa Belajar",
    render: (value) => (value ? value : "-"),
  },
];

const studentHeaders = [
  { id: "name", title: "Nama Siswa", render: (value) => (value ? value : "-") },
  {
    id: "category",
    title: "Kategori",
    render: (value) => (value ? value : "-"),
  },
  {
    id: "",
    title: "Aksi",
    render: (value, data) => {
      return (
        <Link
          to={`/student-management/detail`}
          state={{ id: data?.student_id }}
        >
          Detail
        </Link>
      );
    },
  },
];

const Dashboard = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const { user } = useSelector(({ auth }) => auth);

  const { data: dashboard } = useQuery(["dashboard"], {
    queryFn: async ({ queryKey }) => {
      const params = queryKey[1];
      let response = await getDashboard(params);
      response = response?.data?.data;
      return response;
    },
  });

  const { data: allStudent, isLoading: allStudentLoading } = useQuery(
    ["all-student", { page, search }],
    {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getRecapStudent(params);
        response = response?.data;
        return response;
      },
      enabled: activeCard === 1 && user?.role !== "lpk_mitra",
    },
  );

  const { data: allStudentUnderLpk, isLoading: allStudentUnderLpkLoading } =
    useQuery(["all-student-under-lpk", { page, search }], {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getListStudent(params);
        response = response?.data;
        return response;
      },
      enabled: activeCard === 1 && user?.role === "lpk_mitra",
    });

  const { data: japanPartner, isLoading: japanPartnerLoading } = useQuery(
    ["japan-partner", { filter: "partner_jepang", page, search }],
    {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getList(params);
        response = response?.data;
        return response;
      },
      enabled: activeCard === 2,
    },
  );

  const { data: studentNotSend, isLoading: studentNotSendLoading } = useQuery(
    ["recap-student-notsend", { page, search }],
    {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getStudentNotSend(params);
        response = response?.data;
        return response;
      },
      enabled: activeCard === 3,
    },
  );

  const { data: studentPassedSent, isLoading: studentPassedSentLoading } =
    useQuery(["recap-student-passed-send", { page, search }], {
      queryFn: async ({ queryKey }) => {
        const params = queryKey[1];
        let response = await getStudentPassedSend(params);
        response = response?.data;
        return response;
      },
      enabled: activeCard === 4,
    });

  const onLoading = useMemo(
    () =>
      allStudentLoading ||
      allStudentUnderLpkLoading ||
      studentNotSendLoading ||
      japanPartnerLoading ||
      studentPassedSentLoading,
    [
      allStudentLoading,
      allStudentUnderLpkLoading,
      studentNotSendLoading,
      japanPartnerLoading,
      studentPassedSentLoading,
    ],
  );

  const onChangePage = (page) => {
    setPage(page?.currentPage);
  };

  const onChangeCard = (idx) => {
    setActiveCard(idx);
    setPage(1);
    setSearch("");
  };

  const handleSearch = debounce((evt) => {
    const value = evt?.target?.value;
    setSearch(value);
    setPage(1);
  }, 1000);

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <div className="w-full space-y-1">
          <Label
            text={
              user?.role === "partner_jepang"
                ? `いらっしゃいませ, ${user?.name ?? ""}!`
                : `Selamat Datang, ${user?.name ?? ""}!`
            }
            className="font-bold text-2xl"
          />
          <Label
            text="Silakan untuk menambahkan data guru, siswa, dan kelas."
            className="text-sm text-gray-500"
          />
        </div>
        {/* {activeCard && (
          <div className="flex items-center space-x-4">
            <div className="relative flex items-center min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-lg bg-clip-border h-full">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg transition-transform hover:scale-110 hover:cursor-pointer">
                <HiUserGroup />
              </div>
            </div>
            <div className="relative flex items-center min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-lg bg-clip-border h-full">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg transition-transform hover:scale-110 hover:cursor-pointer">
                <FaUserCheck />
              </div>
            </div>
            <div className="relative flex items-center min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-lg bg-clip-border h-full">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg transition-transform hover:scale-110 hover:cursor-pointer">
                <MdCallReceived />
              </div>
            </div>
            <div className="relative flex items-center min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-lg bg-clip-border h-full">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg transition-transform hover:scale-110 hover:cursor-pointer">
                <HiUserGroup />
              </div>
            </div>
            <div className="relative flex items-center min-w-0 mb-0 break-words bg-white shadow-soft-xl rounded-lg bg-clip-border h-full">
              <div className="inline-flex w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-blue-700 to-cyan-500 shadow-soft-2xl items-center justify-center text-white text-lg transition-transform hover:scale-110 hover:cursor-pointer">
                <FaUserGraduate />
              </div>
            </div>
          </div>
        )} */}
      </div>
      {/* {!activeCard && ( */}
      {user?.role === "partner_jepang" && !user?.is_admin ? (
        <div className="grid grid-cols-5 gap-4">
          <Card
            cardIndex={3}
            onClick={onChangeCard}
            label={"Jumlah Siswa Lulus (Belum berangkat)"}
            icon={<FaUserCheck />}
            value={dashboard?.totalStudentPassedNotSend ?? 0}
          />

          <Card
            cardIndex={4}
            onClick={onChangeCard}
            label={"Jumlah Siswa di Jepang"}
            icon={<HiUserGroup />}
            value={dashboard?.totalStudentInJapan ?? 0}
          />

          <Card
            cardIndex={5}
            onClick={onChangeCard}
            label={"Jumlah Siswa Pulang"}
            icon={<FaUserGraduate />}
            value={dashboard?.totalStudentBack ?? 0}
          />
        </div>
      ) : (
        <div className="grid grid-cols-5 gap-4">
          <Card
            cardIndex={1}
            onClick={onChangeCard}
            label={"Jumlah Siswa Belajar"}
            icon={<HiUserGroup />}
            value={
              user?.role === "lpk_mitra"
                ? dashboard?.totalStudentUnderLpk
                : dashboard?.totalStudent ?? 0
            }
          />

          {user?.role !== "lpk_mitra" && user?.role !== "lpk_mitra_so" && (
            <Card
              cardIndex={2}
              onClick={onChangeCard}
              label={"Jumlah Permintaan Kumiai"}
              icon={<MdCallReceived />}
              value={dashboard?.totalPartner ?? 0}
            />
          )}

          <Card
            cardIndex={3}
            onClick={onChangeCard}
            label={"Jumlah Siswa Lulus (Belum berangkat)"}
            icon={<FaUserCheck />}
            value={dashboard?.totalStudentPassedNotSend ?? 0}
          />

          <Card
            cardIndex={4}
            onClick={onChangeCard}
            label={"Jumlah Siswa di Jepang"}
            icon={<HiUserGroup />}
            value={dashboard?.totalStudentInJapan ?? 0}
          />

          <Card
            cardIndex={5}
            onClick={onChangeCard}
            label={"Jumlah Siswa Pulang"}
            icon={<FaUserGraduate />}
            value={dashboard?.totalStudentBack ?? 0}
          />
        </div>
      )}
      {/* )} */}
      {activeCard && (
        <div className="bg-white shadow-soft-xl rounded-2xl bg-clip-border p-4">
          <div className="mb-2">
            <input
              placeholder="Cari..."
              size="md"
              className="text-sm leading-5.6 ease-soft block w-[400px] appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed px-4 py-2"
              onChange={handleSearch}
            />
          </div>
          {activeCard === 1 && (
            <Table
              isLoading={onLoading}
              headers={
                user?.role === "lpk_mitra"
                  ? totalStudentUnderMitraHeaders
                  : mitraHeaders
              }
              rows={
                user?.role === "lpk_mitra"
                  ? allStudentUnderLpk?.data
                  : allStudent?.data
              }
              pagination={
                user?.role === "lpk_mitra"
                  ? allStudentUnderLpk?.meta
                  : allStudent?.meta
              }
              handleChangePage={onChangePage}
            />
          )}
          {activeCard === 2 && (
            <Table
              isLoading={onLoading}
              headers={partnerHeaders}
              rows={japanPartner?.data}
              pagination={japanPartner?.meta}
              handleChangePage={onChangePage}
            />
          )}
          {activeCard === 3 && (
            <Table
              isLoading={onLoading}
              headers={studentHeaders}
              rows={studentNotSend?.data}
              pagination={studentNotSend?.meta}
              handleChangePage={onChangePage}
            />
          )}
          {activeCard === 4 && (
            <Table
              isLoading={onLoading}
              headers={studentHeaders}
              rows={studentPassedSent?.data}
              pagination={studentPassedSent?.meta}
              handleChangePage={onChangePage}
            />
          )}
          {activeCard === 5 && <Table />}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
