import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Spinner,
} from "components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteAttachment,
  getDetail,
  getProfile,
  postData,
  putData,
  putProfile,
  uploadAttachment,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";

const ProfilePartnerJapan = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const attachmentRef = useRef();

  const { user } = useSelector(({ auth }) => auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [attachment, setAttachment] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [greetingSelected, setGreetingSelected] = useState(null);
  const [hp, setHp] = useState("");
  const [wa, setWa] = useState("");
  const [phone, setPhone] = useState("");
  const [cooperation_date, setCooperationDate] = useState(null);

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    fetchDetail();
  }, [id]);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      reset({
        name: detail?.data?.info?.name,
        email: detail?.data?.info?.email,
        address: detail?.data?.info?.address,
        website: detail?.data?.info?.website,
        leader: detail?.data?.info?.leader,
        cooperation_date: detail?.data?.info?.collab_at
          ? moment(detail?.data?.info?.collab_at).format("YYYY-MM-DD")
          : null,
      });
      if (detail?.data?.info?.collab_at) {
        setCooperationDate(new Date(detail?.data?.info?.collab_at));
      }
    }
  }, [detail]);

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getProfile }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {};
      params.roles = "3";

      params.province_id = "32";
      params.city_id = "3217";
      params.district_id = "321706";
      params.phone = "081234567890";

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (data?.cooperation_date) {
        params.collab_at = moment(data?.cooperation_date).format("YYYY-MM-DD");
      }
      if (data?.name) params.name = data?.name;
      if (data?.address) params.address = data?.address;
      if (data?.website) params.website = data?.website;
      if (data?.leader) params.leader = data?.leader;
      if (data?.email) params.email = data?.email;
      params.password = data?.password;
      params.name = data?.name;
      params.address = data?.address;
      params.website = data?.website;
      params.leader = data?.leader;
      params.email = data?.email;
      params.collab_at = data?.cooperation_date
        ? moment(data?.cooperation_date).format("YYYY-MM-DD")
        : "";

      fetchAPI(
        {
          service: putProfile,
          params: { id: detail?.data?.user?.id, payload: params },
        },
        (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses mengubah data!");
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        },
      );
    }
  };

  const handleChangeCooperationDate = (date) => {
    setCooperationDate(date);
    setValue("cooperation_date", date);
  };

  return (
    // <div className="w-full space-y-4">
    //   <div className="flex justify-between items-center">
    //     {user?.role === "partner_jepang" ? (
    //       <span className="flex space-x-1 items-end">
    //         <Label text="プロフィール" className="font-bold text-2xl" />
    //         <span className="text-sm font-medium text-gray-500">
    //           {"(Profile)"}
    //         </span>
    //       </span>
    //     ) : (
    //       <Label text="Profile" className="font-bold text-2xl" />
    //     )}
    //   </div>

    //   <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
    //     <div className="space-y-8">
    //       <div
    //         className="bg-gray-50 rounded-lg border-gray-500 p-4 grid grid-cols-2 gap-2"
    //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       >
    //         <InputValidation
    //           {...register("name", {
    //             required: { value: true, message: "Nama wajib diisi" },
    //           })}
    //           label="名前 (Nama)"
    //           type="name"
    //           placeholder="Masukan Nama"
    //           isError={{
    //             value: errors.name,
    //             message: errors.name?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("email", {
    //             required: { value: true, message: "Email wajib diisi" },
    //           })}
    //           autoComplete="new-password"
    //           label="メール (Email)"
    //           type="email"
    //           disabled={id}
    //           placeholder="Masukan Email"
    //           isError={{
    //             value: errors.email,
    //             message: errors.email?.message,
    //           }}
    //         />
    //       </div>
    //       <div
    //         className="bg-gray-50 rounded-lg border-gray-500 px-4 pt-4 pb-8"
    //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       >
    //         <InputValidation
    //           {...register("address", {
    //             required: { value: false, message: "Alamat wajib diisi" },
    //           })}
    //           label="住所 (Alamat)"
    //           type="address"
    //           placeholder="Masukan Alamat"
    //           isError={{
    //             value: errors.address,
    //             message: errors.address?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("website", {
    //             required: { value: false, message: "Website wajib diisi" },
    //           })}
    //           label="ウェブサイト (Website)"
    //           type="website"
    //           placeholder="Masukan Website"
    //           isError={{
    //             value: errors.website,
    //             message: errors.website?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("leader", {
    //             required: {
    //               value: false,
    //               message: "Nama Ketua wajib diisi",
    //             },
    //           })}
    //           label="会長の名前 (Nama Ketua)"
    //           type="leader"
    //           placeholder="Masukan Nama Ketua"
    //           isError={{
    //             value: errors.leader,
    //             message: errors.leader?.message,
    //           }}
    //         />
    //         <Datepicker
    //           {...register("cooperation_date", {
    //             required: {
    //               value: false,
    //               message: "Tanggal Kerjasama wajib diisi",
    //             },
    //           })}
    //           selected={cooperation_date}
    //           onChange={handleChangeCooperationDate}
    //           label="協力日 (Tanggal Kerjasama)"
    //           placeholder="Masukan Tanggal Kerjasama"
    //           isError={{
    //             value: errors.cooperation_date,
    //             message: errors.cooperation_date?.message,
    //           }}
    //         />
    //       </div>
    //     </div>
    //     <div className="card-actions">
    //       <div className="flex space-x-2 w-full justify-end">
    //         <Button
    //           label={createData?.isLoading ? <Spinner /> : "Ubah"}
    //           disabled={createData?.isLoading}
    //           theme="primary"
    //           type="submit"
    //         />
    //       </div>
    //     </div>
    //   </form>
    // </div>

    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>プロフィール (Profile)</h6>
        </div>
        {/* <FormProvider {...methods}> */}
        <form className="space-y-6 p-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
            <BaseInput
              label="名前 (Nama)"
              placeholder="Masukan nama"
              {...register("name", {
                required: { value: true, message: "Nama wajib diisi" },
              })}
              error={errors.name?.message}
            />
            <BaseInput
              label="メール (Email)"
              placeholder="Masukan email"
              {...register("email", {
                required: { value: true, message: "Email wajib diisi" },
              })}
              disabled={id}
              error={errors.email?.message}
            />
          </div>

          <div className="flex flex-col">
            <BaseInput
              label="住所 (Alamat)"
              placeholder="Masukan alamat"
              {...register("address", {
                required: { value: false, message: "Alamat wajib diisi" },
              })}
              error={errors.address?.message}
            />
            <BaseInput
              label="ウェブサイト (Website)"
              type="website"
              placeholder="Masukan website"
              {...register("website", {
                required: { value: false, message: "Website wajib diisi" },
              })}
              error={errors.website?.message}
            />
            <BaseInput
              label="会長の名前 (Nama Ketua)"
              placeholder="Masukan nama ketua"
              {...register("leader", {
                required: {
                  value: false,
                  message: "Nama Ketua wajib diisi",
                },
              })}
              error={errors.leader?.message}
            />

            <Datepicker
              {...register("cooperation_date", {
                required: {
                  value: false,
                  message: "Tanggal Kerjasama wajib diisi",
                },
              })}
              selected={cooperation_date}
              onChange={handleChangeCooperationDate}
              label="協力日 (Tanggal Kerjasama)"
              placeholder="Masukan Tanggal Kerjasama"
              isError={{
                value: errors.cooperation_date,
                message: errors.cooperation_date?.message,
              }}
            />
          </div>
        </form>
        {/* </FormProvider> */}
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        <BaseButton variant="primary2" onClick={handleSubmit(onSubmit)}>
          Ubah
        </BaseButton>
      </div>
    </section>
  );
};

export default ProfilePartnerJapan;
