import React from "react";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  Datepicker,
  BaseInput as Input,
  NumericInput,
  PhoneInput,
  SelectBloodType,
  SelectCategory,
  SelectHobby,
  SelectMasteryLanguage,
  SelectReligion,
  SelectSkill,
} from "components/v2";
import { UploadAvatar } from "components/v2";
import RadioGroupForm from "components/v2/Form/RadioGroupForm";
import {
  Education,
  EmploymentHistory,
  FamilyStructure,
  Personality,
  YoutubeLink,
} from "../Components";

const PersonalInfo = () => {
  const methods = useFormContext();
  const { user } = useSelector(({ auth }) => auth);
  return (
    <div className="space-y-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <form className="space-y-6 p-6">
          <div className="flex flex-col lg:flex-row gap-10">
            <UploadAvatar name={"avatar"} label={"Pilih Foto"} readOnly />

            <div className="flex flex-col flex-grow">
              <div className="flex gap-3">
                <Input
                  type="text"
                  label="学生コード (Kode Siswa)"
                  placeholder="Masukan kode siswa"
                  name="student_code"
                  disabled
                />
                {user?.role !== "partner_jepang" && (
                  <Input
                    name="name"
                    label="名前 (Nama)"
                    type="text"
                    placeholder="Masukan nama"
                    disabled
                  />
                )}
              </div>
              <Input
                name="email"
                label="メール (Email)"
                placeholder="Masukan email"
                type="email"
                disabled
              />
            </div>
          </div>
        </form>
      </div>

      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-3">
          <div className="flex items-center gap-2 w-full">
            <Input
              name="birth_place"
              label="出生地 (Tempat Lahir)"
              placeholder="Masukan Tempat Lahir"
              disabled
            />
            <Datepicker
              name="birth_date"
              dateFormat="yyyy-MM-dd"
              label={"生年月日 (Tanggal Lahir)"}
              placeholder="Pilih tanggal lahir"
              disabled
            />
          </div>

          <PhoneInput
            name="phone"
            label="電話番号 (No. Handphone)"
            placeholder="Masukan No. Handphone"
            disabled
          />

          <Input
            name="address"
            label="住所 (Alamat)"
            placeholder="Masukan alamat"
            disabled
          />

          <div className="flex gap-2 w-full">
            <SelectHobby
              name="hobby"
              label="趣味 (Hobi)"
              placeholder="Masukan Nama Hobi"
              isDisabled
              otherOptions={[{ value: "other", label: "Lain-lain" }]}
            />

            {methods?.watch("hobby")?.value === "other" && (
              <Input
                name="other_hobby"
                label={<>&nbsp;</>}
                type="text"
                disabled
                placeholder="Masukan Hobi"
              />
            )}
          </div>

          <NumericInput
            name="old"
            label="年 (Usia)"
            placeholder="Masukan usia"
            disabled
          />

          <SelectMasteryLanguage
            name="mastery_of_language"
            label="母国語以外にできる外国語 (Penguasaan Bahasa)"
            placeholder="Masukan penguasaan bahasa"
            isDisabled
          />

          <Datepicker
            name="entries_date"
            label={"入国日 (Tanggal Masuk)"}
            dateFormat="yyyy-MM-dd"
            placeholder="Pilih Tanggal Masuk"
            disabled
            maxDate={new Date()}
          />

          <Input
            name="apprenticeship"
            label="勉強期間 (Masa Belajar)"
            readOnly={true}
            placeholder="Masukan masa belajar"
            disabled
          />

          <SelectReligion
            name="religion"
            label="宗教 (Agama)"
            placeholder="Masukan Nama Agama"
            isDisabled
          />

          <SelectBloodType
            name="blood_type"
            label="血液型 (Golongan Darah)"
            placeholder="Masukan Nama Golongan Darah"
            isDisabled
          />

          <div className="flex gap-2 w-full">
            <NumericInput
              name="height"
              label="身長 (Tinggi badan)"
              placeholder="Masukan Tinggi badan"
              disabled
            />

            <NumericInput
              name="weight"
              label="重さ (Berat badan)"
              placeholder="Masukan Berat badan"
              disabled
            />
          </div>

          <Input
            name="bmi"
            label="BMI"
            readOnly={true}
            placeholder="Masukan BMI"
            disabled
          />

          <div className="flex gap-2 w-full">
            <SelectCategory
              name="category"
              label="カテゴリー (Kategori)"
              placeholder="Masukan Kategori"
              isDisabled
            />
            {methods?.watch("category")?.value === "Umum(一般職種)" && (
              <Input
                name="category_note"
                label={<>&nbsp;</>}
                disabled
                placeholder="Masukan Catatan"
              />
            )}
          </div>

          <RadioGroupForm
            disabled
            name="gender"
            label={"性別 (Jenis Kelamin)"}
            values={[
              {
                label: "男 (L)",
                value: "L",
              },
              {
                label: "女 (P)",
                value: "P",
              },
            ]}
          />
        </div>
      </div>

      <FamilyStructure readOnly={true} />
      <Personality readOnly={true} />
      <YoutubeLink readOnly={true} />
      <EmploymentHistory readOnly={true} />
      <Education readOnly={true} />
    </div>
  );
};

export default PersonalInfo;
