import BaseButton from "components/atoms/BaseButton";
import { BaseInput } from "components/v2";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgTrash } from "react-icons/cg";
import { useSelector } from "react-redux";
import _ from "lodash";

const EmploymentHistory = ({ readOnly }) => {
  const { control, watch } = useFormContext();

  const { user } = useSelector(({ auth }) => auth);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employment_histories",
  });
  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="flex justify-between items-baseline p-6">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
          <h6>学歴</h6>
          <p>(Riwayat Pekerjaan)</p>
        </div>
        {watch("employment_histories")?.length < 4 &&
          user?.role !== "partner_jepang" &&
          !readOnly && (
            <BaseButton onClick={() => append({ name: "", year: "" })}>
              Tambah
            </BaseButton>
          )}
      </div>

      <div className="px-6">
        <table className="table w-full">
          <tbody>
            {_.map(fields, (fields, index) => (
              <tr>
                <td className="p-1">
                  <BaseInput
                    name={`employment_histories.${index}.name`}
                    placeholder="Nama Perusahaan"
                    type="text"
                    disabled={user?.role === "partner_jepang" || readOnly}
                  />
                </td>
                <td className="p-1" colSpan={index === 0 && 2}>
                  <BaseInput
                    name={`employment_histories.${index}.year`}
                    placeholder="Tahun"
                    type="text"
                    disabled={user?.role === "partner_jepang" || readOnly}
                  />
                </td>
                {index > 0 && user?.role !== "partner_jepang" && !readOnly && (
                  <td className="pr-7 w-6 inline-flex">
                    <BaseButton onClick={() => remove(index)}>
                      <CgTrash size={24} />
                    </BaseButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmploymentHistory;
