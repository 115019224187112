import React, { useEffect, useState } from "react";
import SelectInput from ".";
import { useQuery } from "react-query";
import { capitalize, debounce } from "lodash";
import { getCorporateList } from "services/corporate";

const SelectCorporate = ({ otherOptions = [], ...props }) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: corporateOptions, isFetching } = useQuery(
    ["corporates", { page, limit, search }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getCorporateList({ ...params }, signal);
      },
    },
  );

  useEffect(() => {
    if (!isFetching && corporateOptions?.data) {
      setOptions(
        loadMore
          ? options.concat(
              corporateOptions?.data?.map((data) => ({
                value: data?.id,
                label: capitalize(data?.name),
                address: data?.address,
              })),
            )
          : corporateOptions?.data?.map((data) => ({
              value: data?.id,
              label: capitalize(data?.name),
              address: data?.address,
            })),
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (options?.length < corporateOptions?.data?.meta?.total) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <SelectInput
      {...props}
      isLoading={isFetching}
      options={[...options, ...otherOptions]}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectCorporate;
