import axios from "axios";
import Service from "config";

export const getList = (params) => {
  return axios.get(`${Service.API}/admin/users`, { params });
};

export const postData = (params) => {
  return axios.post(`${Service.API}/admin/users`, params);
};

export const getDetail = (id) => {
  return axios.get(`${Service.API}/admin/users/${id ?? ""}`);
};

export const putData = (params) => {
  return axios.put(
    `${Service.API}/admin/users/${params?.id ?? ""}`,
    params?.payload ?? {},
  );
};

export const deleteData = (id) => {
  return axios.delete(`${Service.API}/admin/users/${id ?? ""}`);
};

export const uploadAttachment = (params) => {
  return axios.post(`${Service.API}/admin/users/attachment`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteAttachment = (filename) => {
  return axios.delete(`${Service.API}/admin/users/attachment/${filename}`);
};

export const uploadAvatar = (params) => {
  return axios.post(`${Service.API}/user/image`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteAvatar = (id) => {
  return axios.delete(`${Service.API}/user/image/${id}`);
};

export const getListStudent = (params) => {
  return axios.get(
    `${Service.API}/user/student?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
    }&search=${params?.search ?? ""}&gender=${
      !params?.gender ? "" : params?.gender === "male" ? "L" : "P"
    }`,
  );
};

export const getDetailStudent = (params) => {
  return axios.get(`${Service.API}/user/student/${params?.id ?? ""}`);
};

export const postDataStudent = (params) => {
  return axios.post(`${Service.API}/user/student`, params);
};

export const putDataStudent = (params) => {
  return axios.put(
    `${Service.API}/user/student/${params?.id ?? ""}`,
    params?.payload ?? {},
  );
};

export const softDeleteDataStudent = (params) => {
  return axios.put(
    `${Service.API}/user/student/delete/${params?.id ?? ""}`,
    params?.payload ?? {},
  );
};

export const deleteDataStudent = (id) => {
  return axios.delete(`${Service.API}/user/student/${id ?? ""}`);
};

export const uploadAttachmentStudent = (params) => {
  return axios.post(`${Service.API}/user/attachment`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadDocTO = (params) => {
  return axios.patch(
    `${Service.API}/admin/partner/document-to/${params?.id}`,
    params?.payload,
  );
};

export const deleteAttachmentStudent = (filename) => {
  return axios.delete(`${Service.API}/user/attachment/${filename}`);
};

export const deleteAttachmentStudentById = (id) => {
  return axios.delete(`${Service.API}/user/student/document/${id}`);
};

export const inviteStudentToClass = ({ id, params }) => {
  return axios.post(`${Service.API}/user/student/invite/${id}`, params);
};

export const getEvaluation = ({ id, params }) => {
  return axios.post(`${Service.API}/user/student/evaluation/${id}`, params);
};

export const postEvaluation = ({ params }) => {
  return axios.post(`${Service.API}/user/student/evaluation`, params);
};

export const putEvaluation = ({ id, params }) => {
  return axios.put(`${Service.API}/user/student/evaluation/${id}`, params);
};

export const getStudentProfile = () => {
  return axios.get(`${Service.API}/user/student/profile`);
};

export const getEvaluationPersonal = ({ class_id }) => {
  return axios.get(`${Service.API}/user/evaluation/${class_id}`);
};

export const postEvaluationPersonal = ({ params }) => {
  return axios.post(`${Service.API}/user/evaluation`, params);
};

export const putEvaluationPersonal = ({ id, params }) => {
  return axios.put(`${Service.API}/user/evaluation`, params);
};

export const putDataStudentProfile = (params) => {
  return axios.put(`${Service.API}/user`, params ?? {});
};

export const getProfile = () => {
  return axios.get(`${Service.API}/user/profile`);
};

export const putProfile = (params) => {
  return axios.put(
    `${Service.API}/user/profile/${params?.id}`,
    params?.payload ?? {},
  );
};

export const getListStudentByMitraId = (params) => {
  return axios.get(
    `${Service.API}/admin/users/student_by_mitra/${params?.id}?page=${
      params?.page ?? 1
    }&limit=${params?.limit ?? 10}${
      params?.passed ? `&passed=${params?.passed}` : ""
    }&search=${params?.search ?? ""}`,
  );
};

export const putInterviewStudent = (params) => {
  return axios.put(
    `${Service.API}/user/student/interview/${params?.id}`,
    params?.payload ?? {},
  );
};

export const downloadCvStudent = async (id) => {
  return axios.get(`${Service.API}/admin/cv/${id}`).then((response) => {
    if (response.data) {
      return { data: response.data, message: "success", success: true };
    } else {
      return { message: "error", success: false };
    }
  });
};

export const patchNoteStudent = (params) => {
  return axios.patch(
    `${Service.API}/admin/users/note/${params?.id}`,
    params?.payload ?? {},
  );
};

export const getWorkingPlan = (id) => {
  return axios.get(`${Service.API}/user/student/working-plan/${id}`);
};

export const putWorkingPlan = (params) => {
  return axios.put(
    `${Service.API}/user/student/working-plan/${params?.student_id}`,
    params?.payload,
  );
};
