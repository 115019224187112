import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";

const BaseInput = React.forwardRef(
  (
    {
      label,
      type,
      placeholder,
      suffixIcon,
      error,
      size = "lg",
      className,
      ...rest
    },
    ref,
  ) => {
    const inputSize = {
      sm: "px-3 py-1",
      md: "px-3 py-2",
      lg: "px-3 py-3",
    };

    return (
      <div className="flex flex-col items-start w-full">
        {label ? (
          <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
            {label}
          </label>
        ) : null}

        <div
          className={cn(
            "mb-4 w-full flex flex-col items-start gap-1",
            className,
          )}
        >
          <div className="relative w-full">
            <input
              placeholder={placeholder}
              type={type}
              className={cn(
                "text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed",
                className,
                inputSize[size],
                { "border-[#ea0606]": error },
                { "border-gray-300": !error },
              )}
              ref={ref}
              {...rest}
            />
            {suffixIcon}
          </div>
          {error ? (
            <small className="text-xs text-red-600">{error}</small>
          ) : null}
        </div>
      </div>
    );
  },
);

BaseInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  suffixIcon: PropTypes.element,
  size: PropTypes.string,
  error: PropTypes.string,
};

export default BaseInput;
