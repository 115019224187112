import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import { BaseInput } from "components/v2";
import { getPassedStudentList } from "services/hire";
import { useQuery } from "react-query";
import { Spinner } from "components";
import moment from "moment";
import { AvatarData } from "..";
import { useNavigate } from "react-router";
import _ from "lodash";

const PartnerAdmin = () => {
  const navigate = useNavigate();

  const filters = useForm({
    defaultValues: {
      search: "",
    },
  });

  const { user } = useSelector(({ auth }) => auth);

  const [search, setSearch] = useState("");

  const {
    data: response,
    isLoading,
    isFetching,
  } = useQuery(["student-passed-list", user?.partner_id, { search }], {
    queryFn: async ({ queryKey, signal }) => {
      const id = queryKey[1];
      const params = queryKey[2];
      let response = await getPassedStudentList({ id, params }, signal);
      response = response.data;
      return response;
    },
    enabled: user?.role === "partner_jepang" && user?.is_admin,
  });

  useEffect(
    _.debounce(() => {
      setSearch(filters.watch("search"));
    }, 1500),
    [filters.watch("search")],
  );

  const onDetail = (params) => {
    navigate("/student-management/detail", {
      state: { id: params?.student_id ?? "" },
    });
  };

  const onEdit = () => {};
  const onClickDeleteData = () => {};

  const renderData = useCallback(() => {
    if (isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={8}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.length <= 0) {
      return (
        <tr>
          <td
            colSpan={8}
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4 text-center"
          >
            <p className="mb-0 font-semibold leading-tight text-xs">No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(response?.data, (data, key) => {
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.code ?? "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              <AvatarData data={data} />
            </p>
          </td>

          <td className="px-6 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.name ? data?.name : "-"}
            </p>
          </td>

          <td className="px-6 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.ttl ? data?.ttl : "-"}
            </p>
          </td>

          <td className="px-6 leading-normal align-middle bg-transparent border-b text-sm shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.address ? data?.address : "-"}
            </p>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.old ? data?.old : "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.entries_date
                  ? moment(data?.entries_date).format("DD MMMM YYYY")
                  : "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Detail
              </a>
              {user?.role !== "partner_jepang" && (
                <>
                  <a
                    className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                    onClick={() => onEdit(data)}
                  >
                    Edit
                  </a>
                  <a
                    className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                    onClick={() => onClickDeleteData(data)}
                  >
                    Delete
                  </a>
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }, [isFetching]);

  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
        <span className="flex space-x-1">
          <h5>候補者リスト</h5>
          <h6>{"(List Siswa)"}</h6>
        </span>
        <p>Menampilkan semua siswa yang dibuat oleh masing masing mitra.</p>
      </div>
      <FormProvider {...filters}>
        <div className="flex justify-between px-4 py-2">
          <div className="w-max md:w-120 flex gap-2 items-center">
            <BaseInput
              name="search"
              placeholder="Search..."
              size="lg"
              className="w-7"
            />
          </div>
        </div>
      </FormProvider>
      <div className="flex-auto px-0 pt-0 pb-2 mb-2">
        <div className="p-0 overflow-x-auto">
          <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
            <thead className="align-bottom">
              <tr>
                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  番号 (NOMOR)
                </th>
                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang"
                    ? "写真 (Foto)"
                    : "写真 (Foto)"}
                </th>
                <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang"
                    ? "名前 (Nama)"
                    : "名前 (Nama)"}
                </th>
                <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang"
                    ? "生年月日 (Tempat, Tanggal lahir)"
                    : "生年月日 (Tempat, Tanggal lahir)"}
                </th>
                <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang"
                    ? "住所 (Alamat Lengkap)"
                    : "住所 (Alamat Lengkap)"}
                </th>
                {user?.role === "lpk_mitra_so" && (
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Tanggal wawancara
                  </th>
                )}
                {user?.role !== "partner_jepang" && (
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Interview
                  </th>
                )}
                <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang" ? "年 (Usia)" : "年 (Usia)"}
                </th>
                <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  {user?.role === "partner_jepang"
                    ? "研究エントリー日 (Tanggal Masuk Belajar)"
                    : "研究エントリー日 (Tanggal Masuk Belajar)"}
                </th>
                <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
              </tr>
            </thead>
            <tbody>{renderData()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PartnerAdmin;
