import React from "react";
import { DatepickerInput, Input } from "components";
import ReactDatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";

const Datepicker = React.forwardRef(
  (
    {
      isError = {
        value: false,
        message: "",
      },
      label = "",
      className,
      type = "default",
      selected,
      onChange,
      dateFormat,
      withPortal,
      showYearPicker,
      placeholder = "Pilih Tanggal",
      showTimeSelect,
      showTimeSelectOnly,
      timeIntervals,
      timeCaption,
      minDate,
      maxDate,
      name,
      disabled,
      ...props
    },
    ref,
  ) => {
    return (
      <div ref={ref} className="form-control w-full flex flex-col mb-4">
        {label && (
          // <label className="label">
          //   <span
          //     className={`label-text ${isError?.value ? "text-error" : ""}`}
          //   >
          //     {label}
          //   </span>
          // </label>
          <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
            {label}
          </label>
        )}
        {/* <div
          className={`${className ?? ""} relative input w-full ${
            isError?.value ? "input-error" : "input-bordered"
          } wrapper-input-react-datepicker__input-container`}
        >
        </div> */}
        {/* <div className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none px-3 py-3"> */}
        <ReactDatePicker
          selected={selected}
          onChange={onChange}
          dateFormat={dateFormat}
          withPortal={withPortal}
          placeholderText={placeholder}
          showYearPicker={showYearPicker}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none px-3 py-3"
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          customInput={<CustomInput isError={isError} disabled={disabled} />}
          minDate={minDate}
          maxDate={maxDate}
          name={name}
          wrapperClassName=""
          disabled={disabled}
        />
        {/* </div> */}
        {isError?.value && (
          <label className="label">
            <span className="label-text-alt text-error">
              {isError?.message}
            </span>
          </label>
        )}
      </div>
    );
  },
);

const CustomInput = ({
  className,
  isError,
  onChange,
  placeholder,
  value,
  isSecure,
  id,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={`${className ?? ""} relative input w-full ${
        isError?.value ? "input-error" : "input-bordered"
      } wrapper-input-react-datepicker__input-container`}
    >
      <div className="flex h-full">
        <input
          className="h-full w-full outline-0"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          isSecure={isSecure}
          id={id}
          onClick={onClick}
          disabled={disabled}
          readOnly={true}
        />
        <div className="flex items-center justify-center right-0 pl-2">
          <AiOutlineCalendar color="#808C92" size={16} />
        </div>
      </div>
    </div>
  );
};

export default Datepicker;
