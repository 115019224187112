import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _, { map } from "lodash";
import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Spinner,
} from "components";
import { useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";
import {
  getList,
  getListHiredOnMitra,
  getListNonHired,
  postData,
} from "services/hire";
import { MdAdd, MdClose, MdEdit } from "react-icons/md";
import { CgCalendar, CgClose } from "react-icons/cg";
import { CgSpinner } from "react-icons/cg";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getFeedback,
  getFeedbackDetail,
  getPartnerFeedback,
  getPartnerFeedbackDetail,
  postFeedback,
  putFeedback,
} from "services/feedback";
import { getMeet, getMeetDetail, reScheduleMeet } from "services/meet";
import SchedulePopup from "pages/StudentManagement/schedulePopup";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";
import { useMutation, useQuery } from "react-query";
import clsx from "clsx";

const thClass =
  "px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70";
const tdClass =
  "p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4";

const Meet = () => {
  const modalForm = useRef();
  const navigate = useNavigate();
  const modalConfirm = useRef();

  const { user } = useSelector(({ auth }) => auth);

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [nonHired, setNonHired] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });

  const [type, setType] = useState("");

  const [search, setSearch] = useState("");

  const [selectedData, setSelectedData] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const scheduleMeet = useMutation(reScheduleMeet);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = methods;

  const { data: detail } = useQuery({
    queryKey: ["student-meet-list-by-id", selectedData?.id],
    queryFn: ({ queryKey }) => getMeetDetail(queryKey[1]),
    enabled: !!selectedData?.id,
  });

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      onChangeSearch.cancel();
    };
  });

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onConfirmData = () => {
    fetchAPI(
      {
        service: postData,
        params: {
          student_id: selectedData?.student_id,
          partner_id: detail?.data?.data?.partner_id,
          corporate_id: selectedData?.training_order?.corporate_id,
        },
      },
      async (success, response) => {
        if (success) {
          await modalConfirm?.current?.click();
          toast.success("Sukses menambahkan siswa!");
          fetchList(filter);
          setSelectedData(null);
          setAddStudentModal(false);
          setDetailModal(false);
        }
      },
    );
  };

  const fetchList = (params) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getMeet,
        params: { search: params?.search, page: params?.page },
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onSubmit = (payload) => {
    let time = moment(`1999-01-01 ${payload?.time}`).format("h:mm a");
    if (time === "Invalid date") {
      time = moment(`${payload?.time}`).format("h:mm a");
    }

    let params = {
      student_id: map(payload?.student_to, (studentTo) => studentTo?.value),
      to_id: payload?.training_order?.value ?? "",
      name_schedule: payload?.name_schedule,
      datetime: moment(
        moment(payload?.date).format("YYYY-MM-DD") +
          " " +
          moment(`${payload?.time}`).format("H:mm"),
      ),
    };

    scheduleMeet.mutate(params, {
      onSuccess: (data) => {
        toast.success("Sukses menambahkan data!");
        methods.reset();
        fetchList(filter);
        setIsOpenModal(false);
      },
      onError: (err) => {
        console.error(err, "ERR");
      },
    });
  };

  const onDetail = (data) => {
    setDetailModal(true);
    setSelectedData(data);
  };

  const onChangeStatus = (data) => {
    setSelectedData({ ...selectedData, student_id: data?.id });
    setAddStudentModal(true);
  };

  const onSuccessReschedule = () => {
    fetchList(filter);
  };

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={"6"}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td colSpan={"6"}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <p className="mb-0 font-semibold leading-tight text-xs">
                Tidak ada data
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      return (
        <tr key={key}>
          <th className={tdClass}></th>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.name_schedule ?? ""}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {moment(list?.datetime).format("DD/MM/YYYY")}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {moment(list?.datetime).format("h:mm a")}
            </p>
          </td>
          <td className={tdClass}>
            <div className="flex items-center justify-center space-x-2">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(list)}
              >
                Detail
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <span className="flex space-x-1">
              <h5>学生にインタビューする</h5>
              <h6>{"(Siswa Wawancara)"}</h6>
            </span>
            <p>
              Menampilkan siswa wawancara agar admin bisa melihat data
              berdasarkan agenda.
            </p>
          </div>
          <BaseButton
            variant="primary2"
            size="md"
            className=""
            onClick={() => {
              reset({
                title: "",
                description: "",
                sugestion: "",
              });
              modalForm?.current?.click();
              setIsOpenModal(true);
            }}
          >
            Tambah Data
          </BaseButton>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className={thClass}></th>
                  <th className={thClass}>Nama Agenda</th>
                  <th className={thClass}>Tanggal Wawancara</th>
                  <th className={thClass}>Jam</th>
                  <th className={thClass}></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.meta?.limit}
            totalRecords={response?.meta?.total ?? 0}
            currentPage={response?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={detailModal}
        setIsOpen={setDetailModal}
        disabledOnCloseOutside
      >
        {/* <div className="relative w-[800px] m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13"> */}
        <div className="relative flex flex-col w-[60vw] bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">
              List siswa - {selectedData?.name_schedule}
            </h5>
            <CgClose onClick={() => setDetailModal(false)} />
          </div>
          <div className="relative flex-auto p-4">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className={thClass}></th>
                  <th className={thClass}>Nama Siswa</th>
                  <th className={thClass}>Status</th>
                  <th className={thClass}></th>
                </tr>
              </thead>
              <tbody>
                {map(detail?.data?.data?.students, (student, k) => (
                  <tr key={k}>
                    <th className={clsx(tdClass, "text-sm")}>{k + 1}</th>
                    <th className={clsx(tdClass, "text-left", "text-sm")}>
                      {student?.name}
                    </th>
                    <th className={clsx(tdClass, "text-left", "text-sm")}>
                      {student?.passed ? "Lulus" : "-"}
                    </th>
                    <th className={tdClass}>
                      {!student?.passed && (
                        <div className="flex items-center space-x-2">
                          <a
                            className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                            onClick={() => onChangeStatus(student)}
                          >
                            Ubah Status
                          </a>
                        </div>
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </ModalWrapper>

      <ModalWrapper
        isOpen={addStudentModal}
        setIsOpen={setAddStudentModal}
        disabledOnCloseOutside
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Tambah Siswa Terkirim</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menambahkan data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                type={"button"}
                onClick={() => {
                  setAddStudentModal((prev) => !prev);
                }}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onConfirmData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        {/* <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 md:max-w-screen-xl sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13"> */}
        <div className="relative m-2 transition-transform duration-300 pointer-events-none sm:m-7 w-[540px] sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <FormProvider {...methods}>
            <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
              <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
                <h5 className="mb-0 leading-normal">Tambah Data</h5>
              </div>
              <div className="relative flex-auto p-4">
                <SchedulePopup />
              </div>
              <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                <BaseButton
                  variant="base"
                  size="sm"
                  onClick={() => setIsOpenModal((prev) => !prev)}
                >
                  Tidak
                </BaseButton>
                <BaseButton
                  variant="primary2"
                  size="sm"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  Ya
                </BaseButton>
              </div>
            </div>
          </FormProvider>
        </div>
      </ModalWrapper>
    </>
  );

  // return (
  //   <div>
  //     <SchedulePopup
  //       ref={modalForm}
  //       type="update"
  //       title="Edit Jadwal"
  //       data={selectedData}
  //       onRefresh={onSuccessReschedule}
  //     />
  //     <div className="w-full space-y-4">
  //       <div className="space-y-1">
  //         <Label text="Siswa Wawancara" className="font-bold text-2xl" />
  //         <Label
  //           text="Menampilkan siswa wawancara agar admin bisa melihat data berdasarkan agenda."
  //           className="text-sm text-gray-500"
  //         />
  //       </div>
  //       <div
  //         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
  //         className="p-9 space-y-8 rounded-xl"
  //       >
  //         <div className="flex items-center space-x-4">
  //           <InputValidation
  //             type="text"
  //             placeholder="Search..."
  //             className="h-8 w-80 text-sm"
  //             onChange={onChangeSearch}
  //           />
  //           {user?.role === "partner_jepang" && (
  //             <Button
  //               theme="primary"
  //               label="Tambah Data"
  //               size="sm"
  //               onClick={() => {
  //                 reset({
  //                   title: "",
  //                   description: "",
  //                   sugestion: "",
  //                 });
  //                 modalForm?.current?.click();
  //               }}
  //             />
  //           )}
  //         </div>
  //         <div className="overflow-x-auto border rounded-xl overflow-hidden">
  //           <table className="table w-full">
  //             <thead>
  //               <tr>
  //                 <th className="w-16"></th>
  //                 <td className="whitespace-normal">Nama Siswa</td>
  //                 <td className="whitespace-normal">Nama Agenda</td>
  //                 <td className="whitespace-normal">Tanggal Wawancara</td>
  //                 <td className="whitespace-normal">Jam</td>
  //                 <td className="whitespace-normal"></td>
  //                 <td></td>
  //               </tr>
  //             </thead>
  //             <tbody>{renderData()}</tbody>
  //           </table>
  //         </div>
  //         <div className="flex justify-end">
  //           {/* <label className="text-sm">Menampilkan 1 - 10</label> */}
  //           <Pagination
  //             pageLimit={response?.data?.meta?.limit}
  //             totalRecords={response?.data?.meta?.total ?? 0}
  //             currentPage={response?.data?.meta?.page}
  //             onPageChanged={handleChangePage}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Meet;
