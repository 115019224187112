import BaseButton from "components/atoms/BaseButton";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getCorporateDetail,
  getCorporateList,
  postCorporate,
  putCorporate,
} from "services/corporate";
import { map } from "lodash";
import { CgSpinner } from "react-icons/cg";
import { Pagination, Spinner } from "components";
import { useSelector } from "react-redux";
import ModalWrapper from "components/molecules/ModalWrapper";
import { BaseInput } from "components/v2";
import { FormProvider, useForm } from "react-hook-form";
import { useLoader } from "Provider/LoaderProvider";
import { BiX } from "react-icons/bi";
import { FiXCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

const thClass =
  "px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70";
const tdClass =
  "p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4";

const Corporate = () => {
  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedData, setSelectedData] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { user } = useSelector(({ auth }) => auth);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      address: "",
      active: true,
    },
  });

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(["corporate-list", { page, limit }], {
    queryFn: ({ queryKey, signal }) => {
      const params = queryKey[1];
      return getCorporateList({ ...params }, signal);
    },
    onError: (err) => console.log(err),
  });

  const { data: detail, isLoading: detailLoading } = useQuery(
    ["corporate-detail", { id: selectedData?.id }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getCorporateDetail({ ...params }, signal);
      },
      onError: (err) => console.log(err),
      enabled: !!selectedData?.id,
    },
  );

  const post = useMutation(postCorporate);
  const put = useMutation(putCorporate);

  useEffect(() => {
    if (!createModal) {
      setSelectedData(null);
      methods.reset({
        active: true,
        name: "",
        address: "",
      });
    }
  }, [createModal]);

  const handleChangePage = (params) => {
    setPage(params.currentPage);
  };

  const onDetail = (data) => {
    setDetailModal(true);
    setSelectedData(data);
  };

  const onEdit = (data) => {
    setCreateModal(true);
    setSelectedData(data);
    methods.reset({
      active: data?.active,
      name: data?.name,
      address: data?.address,
    });
  };

  const onDelete = (data) => {
    setDeleteModal(true);
    setSelectedData(data);
    methods.reset({
      active: data?.active,
      name: data?.name,
      address: data?.address,
    });
  };

  const onDeleteData = () => {
    put.mutate(
      {
        id: selectedData?.id,
        payload: {
          ...methods.getValues(),
          active: false,
          partner_id: user?.partner_id,
        },
      },
      {
        onSuccess: () => {
          stopLoaderOverlay();
          setDeleteModal(false);
          refetch();
        },
        onError: () => {
          stopLoaderOverlay();
        },
      },
    );
  };

  const onSubmit = (payload) => {
    startLoaderOverlay();
    if (selectedData?.id) {
      put.mutate(
        {
          id: selectedData?.id,
          payload: { ...payload, partner_id: user?.partner_id },
        },
        {
          onSuccess: () => {
            stopLoaderOverlay();
            setCreateModal(false);
            refetch();
          },
          onError: () => {
            stopLoaderOverlay();
          },
        },
      );
    } else {
      post.mutate(
        { ...payload, partner_id: user?.partner_id },
        {
          onSuccess: () => {
            stopLoaderOverlay();
            setCreateModal(false);
            refetch();
          },
          onError: () => {
            stopLoaderOverlay();
          },
        },
      );
    }
  };

  const renderTbody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={4}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    }

    if (
      typeof response === "undefined" ||
      response === "" ||
      response?.data?.length <= 0
    ) {
      return (
        <tr>
          <td colSpan={4}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <p className="mb-0 font-semibold leading-tight text-xs">
                Tidak ada data
              </p>
            </div>
          </td>
        </tr>
      );
    }
    return map(response?.data, (list, key) => {
      const id =
        parseInt(response?.meta?.page) * parseInt(response?.meta?.limit) -
        parseInt(response?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.name ? list?.name : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.address ? list?.address : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onEdit(list)}
              >
                Edit
              </a>
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDelete(list)}
              >
                Delete
              </a>
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(list)}
              >
                Detail
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderDataStudents = () => {
    if (detailLoading) {
      return (
        <tr>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <Spinner />
          </td>
        </tr>
      );
    }

    if (detail?.data?.students?.length <= 0) {
      return (
        <tr>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center">
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return map(detail?.data?.students, (data, key) => {
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <Link to={`/student-management/detail`} state={{ id: data?.id }}>
                <h6 className="mb-0 leading-normal text-sm">
                  {data?.name ?? ""}
                </h6>
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <span className="flex space-x-1">
              <h5>会社一覧</h5>
              <h6>{"(Daftar Perusahaan)"}</h6>
            </span>
            <p>Menampilkan semua data perusahaan.</p>
          </div>
          {user?.role === "partner_jepang" && (
            <BaseButton
              variant="primary2"
              size="md"
              className=""
              onClick={() => {
                setCreateModal(true);
                methods.reset();
              }}
            >
              Tambah Data
            </BaseButton>
          )}
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className={thClass}></th>
                  <th className={thClass}>Nama Perusahaan</th>
                  <th className={thClass}>Alamat Perusahaan</th>
                  <th className={thClass}></th>
                </tr>
              </thead>
              <tbody>{renderTbody()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.meta?.limit}
            totalRecords={response?.meta?.total ?? 0}
            currentPage={response?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      <ModalWrapper isOpen={detailModal} setIsOpen={setDetailModal}>
        <div className="relative w-[800px] m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Siswa</h5>
              <FiXCircle
                className="text-2xl"
                onClick={() => setDetailModal(false)}
              />
            </div>
            <div className="relative flex-auto p-4">
              <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                <thead className="align-bottom">
                  <tr>
                    {/* <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th> */}
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      {user?.role === "partner_jepang"
                        ? "学生名 (Nama Siswa)"
                        : "Nama Siswa"}
                    </th>
                  </tr>
                </thead>
                <tbody>{renderDataStudents()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={deleteModal} setIsOpen={setDeleteModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setDeleteModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={createModal} setIsOpen={setCreateModal}>
        <div className="relative flex flex-col w-[720px] bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">
              {selectedData ? "Edit Perusahaan" : "Tambah Perusahaan"}
            </h5>
          </div>
          <div className="relative flex-auto p-4 max-h-80 overflow-y-auto">
            <FormProvider {...methods}>
              <form className="space-y-2">
                <BaseInput
                  label="Nama"
                  placeholder="Masukan nama"
                  name="name"
                />
                <BaseInput
                  label="Alamat"
                  placeholder="Masukan alamat"
                  name="address"
                  textarea
                />
              </form>
            </FormProvider>
          </div>
          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
            <BaseButton
              variant="base"
              type="button"
              size="sm"
              onClick={() => setCreateModal((prev) => !prev)}
            >
              Tidak
            </BaseButton>
            <BaseButton
              variant="primary2"
              size="sm"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Ya
            </BaseButton>
          </div>
        </div>
        {/* <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto ease-soft-out">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0 ">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">
                {selectedData ? "Edit Perusahaan" : "Tambah Perusahaan"}
              </h5>
            </div>
            <div className="relative flex-auto p-4 max-h-80 overflow-y-auto">
              <form className="space-y-2 w-100">
                <FormProvider {...methods}>
                  <BaseInput
                    label="Nama"
                    placeholder="Masukan nama"
                    name="name"
                  />
                  <BaseInput
                    label="Alamat"
                    placeholder="Masukan alamat"
                    name="address"
                    textarea
                  />
                </FormProvider>
              </form>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                type="button"
                size="sm"
                onClick={() => setCreateModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                variant="primary2"
                size="sm"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </div> */}
      </ModalWrapper>
    </>
  );
};

export default Corporate;
