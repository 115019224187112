import BaseButton from "components/atoms/BaseButton";
import { BaseInput } from "components/v2";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgTrash } from "react-icons/cg";
import { useSelector } from "react-redux";
import _ from "lodash";

const YoutubeLink = ({ readOnly }) => {
  const { control } = useFormContext();

  const { user } = useSelector(({ auth }) => auth);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "link_youtube",
  });
  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="flex justify-between items-baseline p-6">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
          <h6>ユーチューブのリンク</h6>
          <p>(Link youtube)</p>
        </div>
        {user?.role !== "partner_jepang" && !readOnly && (
          <BaseButton onClick={() => append({ url: "" })}>Tambah</BaseButton>
        )}
      </div>

      <div className="px-6">
        <table className="table w-full">
          <tbody>
            {_.map(fields, (field, index) => (
              <tr key={field.id} className="w-full">
                <td colSpan={index <= 0 && 2} className="w-full">
                  <BaseInput
                    name={`link_youtube.${index}.url`}
                    type="text"
                    placeholder=""
                    disabled={user?.role === "partner_jepang" || readOnly}
                  />
                </td>
                {index > 0 && user?.role !== "partner_jepang" && !readOnly && (
                  <td className=" pl-3 w-min inline-flex">
                    <BaseButton onClick={() => remove(index)}>
                      <CgTrash size={24} />
                    </BaseButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YoutubeLink;
