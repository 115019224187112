import { Button, InputValidation, Label } from "components";
import React, { useEffect, useRef, useState } from "react";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { MdClose, MdEdit } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { fetchAPI, getErrorMessage } from "helpers";
import { getLanding, postLanding, updateLanding } from "services/landing";
import { CgSpinner } from "react-icons/cg";
import { uploadAvatar } from "services/user";
import cn from "clsx";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";

const Settings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const topElement = useRef();

  const [landing, setLanding] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [banner, setBanner] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [banner2, setBanner2] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [banner3, setBanner3] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [banner_tittle, setBannerTittle] = useState({
    en: "",
    jp: "",
    id: "",
  });
  const [banner_desc, setBannerDesc] = useState({
    en: "",
    jp: "",
    id: "",
  });

  const [organizationImage, setOrganizationImage] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [organizationBackground, setOrganizationBackground] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [organization_tittle, setOrganizationTittle] = useState({
    en: "",
    jp: "",
    id: "",
  });
  const [organization_desc, setOrganizationDesc] = useState({
    en: "",
    jp: "",
    id: "",
  });

  const [about_us_image, setAboutUsImage] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [about_us, setAboutUs] = useState({
    en: "",
    jp: "",
    id: "",
  });

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    fetchLanding();
  }, []);

  useEffect(() => {
    if (landing.data) {
      setBannerTittle({
        en: landing.data.banner_tittle_en,
        jp: landing.data.banner_tittle_jpn,
        id: landing.data.banner_tittle,
      });
      setBannerDesc({
        en: landing.data.banner_desc_en,
        jp: landing.data.banner_desc_jpn,
        id: landing.data.banner_desc,
      });
      setOrganizationTittle({
        en: landing.data.organization_tittle_en,
        jp: landing.data.organization_tittle_jpn,
        id: landing.data.organization_tittle,
      });
      setOrganizationDesc({
        en: landing.data.organization_desc_en,
        jp: landing.data.organization_desc_jpn,
        id: landing.data.organization_desc,
      });
      setAboutUs({
        en: landing.data.about_us_en,
        jp: landing.data.about_us_jpn,
        id: landing.data.about_us,
      });
      setBanner((state) => ({ ...state, name: landing.data.banner }));
      setBanner2((state) => ({ ...state, name: landing.data.banner_2 }));
      setBanner3((state) => ({ ...state, name: landing.data.banner_3 }));
      setOrganizationImage((state) => ({
        ...state,
        name: landing.data.organization_image,
      }));
      setOrganizationBackground((state) => ({
        ...state,
        name: landing.data.organization_background,
      }));
    }
  }, [landing.data]);

  const fetchLanding = () => {
    setLanding((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getLanding }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data: data,
        }));
      } else {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onReset = () => {
    setBannerTittle({
      en: landing.data.banner_tittle_en,
      jp: landing.data.banner_tittle_jpn,
      id: landing.data.banner_tittle,
    });
    setBannerDesc({
      en: landing.data.banner_desc_en,
      jp: landing.data.banner_desc_jpn,
      id: landing.data.banner_desc,
    });
    setOrganizationTittle({
      en: landing.data.organization_tittle_en,
      jp: landing.data.organization_tittle_jpn,
      id: landing.data.organization_tittle,
    });
    setOrganizationDesc({
      en: landing.data.organization_desc_en,
      jp: landing.data.organization_desc_jpn,
      id: landing.data.organization_desc,
    });
    setAboutUs({
      en: landing.data.about_us_en,
      jp: landing.data.about_us_jpn,
      id: landing.data.about_us,
    });
    setBanner((state) => ({ ...state, name: landing.data.banner }));
    setOrganizationImage((state) => ({
      ...state,
      name: landing.data.organization_image,
    }));
    setOrganizationBackground((state) => ({
      ...state,
      name: landing.data.organization_background,
    }));
    topElement.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const onSubmit = (data) => {
    const params = {
      banner: banner?.name ? banner?.name : "",
      banner_2: banner2?.name ? banner2?.name : "",
      banner_3: banner3?.name ? banner3?.name : "",
      banner_tittle: banner_tittle.id ? banner_tittle.id : "",
      banner_tittle_jpn: banner_tittle.jp ? banner_tittle.jp : "",
      banner_tittle_en: banner_tittle.en ? banner_tittle.en : "",
      banner_desc: banner_desc.id ? banner_desc.id : "",
      banner_desc_jpn: banner_desc.jp ? banner_desc.jp : "",
      banner_desc_en: banner_desc.en ? banner_desc.en : "",
      organization_image: organizationImage?.name
        ? organizationImage?.name
        : "",
      organization_background: organizationBackground?.name
        ? organizationBackground?.name
        : "",
      organization_tittle: organization_tittle.id ? organization_tittle.id : "",
      organization_tittle_jpn: organization_tittle.jp
        ? organization_tittle.jp
        : "",
      organization_tittle_en: organization_tittle.en
        ? organization_tittle.en
        : "",
      organization_desc: organization_desc.id ? organization_desc.id : "",
      organization_desc_jpn: organization_desc.jp ? organization_desc.jp : "",
      organization_desc_en: organization_desc.en ? organization_desc.en : "",
      about_us: about_us.id ? about_us.id : "",
      about_us_jpn: about_us.jp ? about_us.jp : "",
      about_us_en: about_us.en ? about_us.en : "",
      about_us_image: about_us_image?.name ? about_us_image?.name : "",
    };
    setCreateData((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: landing.data ? updateLanding : postLanding, params: params },
      (success, response) => {
        if (success) {
          setCreateData((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            data: response,
          }));
          toast.success(
            landing.data ? "Sukses mengubah data!" : "Sukses menambahkan data!",
          );
          fetchLanding();
          topElement.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        } else {
          setCreateData((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            data: response,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  return (
    <div className="space-y-4">
      <div ref={topElement} />
      <div className="w-full space-y-1">
        <Label text={`General Settings`} className="font-bold text-2xl" />
      </div>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <SettingSection
            header={"Banner"}
            data={landing.data}
            image={banner}
            image2={banner2}
            image3={banner3}
            setImage={setBanner}
            setImage2={setBanner2}
            setImage3={setBanner3}
            banner_tittle={banner_tittle}
            setBannerTittle={setBannerTittle}
            banner_desc={banner_desc}
            setBannerDesc={setBannerDesc}
          />
          <SettingSection
            header={"Organisasi"}
            name="organization"
            data={landing.data}
            image={organizationImage}
            setImage={setOrganizationImage}
            background={organizationBackground}
            setBackground={setOrganizationBackground}
            organization_tittle={organization_tittle}
            setOrganizationTittle={setOrganizationTittle}
            organization_desc={organization_desc}
            setOrganizationDesc={setOrganizationDesc}
          />
          <SettingSection
            header={"Tentang Kami"}
            name="about"
            data={landing.data}
            image={about_us_image}
            setImage={setAboutUsImage}
            about_us={about_us}
            setAboutUs={setAboutUs}
          />
          {/* <SettingSection header={"Profile Bitode"} name="profile" /> */}
        </div>
        <div className="flex space-x-2 w-full justify-end">
          <BaseButton variant="primary2" onClick={onReset}>
            Reset
          </BaseButton>
          <BaseButton variant="primary2" type="submit">
            Submit
          </BaseButton>
        </div>
      </form>
    </div>
  );
};

const SettingSection = ({
  header,
  name,
  banner_tittle,
  setBannerTittle,
  banner_desc,
  setBannerDesc,
  organization_tittle,
  setOrganizationTittle,
  organization_desc,
  setOrganizationDesc,
  about_us,
  setAboutUs,
  image,
  image2,
  image3,
  setImage,
  setImage2,
  setImage3,
  background,
  setBackground,
  data = {},
}) => {
  const attachmentRef = useRef();

  const [tab, setTab] = useState("jp");
  const [isBackground, setIsBackground] = useState(false);
  const [bannerNumber, setBannerNumber] = useState(1);

  const onTriggerUpload = (bannerNumber, is_background = false) => {
    if (is_background) {
      setIsBackground(true);
    } else {
      if (!name) {
        setBannerNumber(bannerNumber);
      }
    }
    attachmentRef?.current?.click();
  };

  const onSelectFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif")
      ) {
        const formData = new FormData();
        formData.append("image", file);
        fetchAPI(
          { service: uploadAvatar, params: formData },
          (success, response) => {
            if (success) {
              const objectUrl = URL.createObjectURL(file);
              const filename = file?.name ?? "";
              if (isBackground) {
                setBackground({ file, objectUrl, name: filename });
                setIsBackground(false);
              } else {
                if (!name) {
                  if (bannerNumber === 1) {
                    setImage({ file, objectUrl, name: filename });
                  } else if (bannerNumber === 2) {
                    setImage2({ file, objectUrl, name: filename });
                  } else if (bannerNumber === 3) {
                    setImage3({ file, objectUrl, name: filename });
                  }
                } else {
                  setImage({ file, objectUrl, name: filename });
                }
              }
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const mainRender = () => {
    switch (name) {
      case "about":
        return (
          <div className="space-y-4">
            <div className="form-control space-y-2 w-full">
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                Deskripsi
              </label>
              <textarea
                className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                placeholder="Deskripsi"
                value={about_us[tab] ? about_us[tab] : ""}
                onChange={(e) =>
                  setAboutUs((state) => ({
                    ...state,
                    [tab]: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label className="label">
                <span className={`label-text`}>&nbsp;</span>
              </label>
              <div className="space-y-2 w-full">
                {(image?.objectUrl || data?.about_us_image) && (
                  <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                    <img
                      src={
                        data?.about_us_image && !image?.file
                          ? `${
                              process.env.REACT_APP_API
                            }${data?.about_us_image?.replace("/public", "")}`
                          : image?.objectUrl
                      }
                      alt=""
                      className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                    />
                    {!data?.about_us_image || image?.file ? (
                      <IoMdCloseCircle
                        onClick={() =>
                          setImage({
                            file: null,
                            objectUrl: null,
                            name: null,
                          })
                        }
                        className="absolute right-[16px] top-[16px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                      />
                    ) : null}
                  </div>
                )}
                <BaseButton
                  className="w-full flex gap-2 justify-center items-center"
                  variant="primary2"
                  size="md"
                  onClick={onTriggerUpload}
                  type="button"
                >
                  <IoMdImage size={18} />
                  {image.file || data?.about_us_image
                    ? "Change Image"
                    : "Upload Image"}
                </BaseButton>
              </div>
            </div>
          </div>
        );
      case "profile":
        return (
          <div className="flex space-x-8 pb-8">
            <div className="flex-1 space-y-2">
              {/* <InputValidation label="Nama" placeholder="Nama" /> */}
              <BaseInput label="Nama" placeholder="Nama" />
              <div className="form-control w-full">
                {/* <label className="label">
                  <span className={`label-text`}>Deskripsi</span>
                </label> */}
                <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                  Deskripsi
                </label>
                <textarea
                  className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                  placeholder="Deskripsi"
                ></textarea>
              </div>
            </div>
            <div>
              <div className="w-full h-full">
                <label className="label">
                  <span className={`label-text`}>&nbsp;</span>
                </label>
                <div className="space-y-2 w-full">
                  {image?.objectUrl ? (
                    <div className="relative">
                      <div className="relative rounded-full w-[180px] h-[180px] overflow-hidden">
                        <img
                          src={image?.objectUrl}
                          alt=""
                          className="max-h-[180px] rounded-full h-fit w-full object-cover transform transition-transform aspect-square"
                        />
                        <div
                          className="absolute inset-x-0 bottom-0 bg-black/50 text-white text-xs font-medium flex items-center justify-center space-x-1 py-1 transform transition-transform hover:scale-110 cursor-pointer"
                          onClick={onTriggerUpload}
                        >
                          <FiEdit />
                          <span>Edit</span>
                        </div>
                      </div>
                      <IoMdCloseCircle
                        onClick={() =>
                          setImage({
                            file: null,
                            objectUrl: null,
                            name: null,
                          })
                        }
                        className="absolute right-[8px] top-[8px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                      />
                    </div>
                  ) : (
                    <div
                      className="border border-dashed border-gray-400 text-gray-400 rounded-full w-[180px] h-[180px] p-2 flex items-center justify-center cursor-pointer space-x-2"
                      onClick={onTriggerUpload}
                    >
                      <IoMdImage />
                      <span>Upload Image</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "organization":
        return (
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="flex-1 space-y-2">
                <BaseInput
                  label="Judul"
                  placeholder="Judul"
                  value={organization_tittle[tab]}
                  onChange={(e) =>
                    setOrganizationTittle((state) => ({
                      ...state,
                      [tab]: e.target.value,
                    }))
                  }
                />
                <div className="form-control w-full">
                  <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                    Deskripsi
                  </label>
                  <textarea
                    className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                    placeholder="Deskripsi"
                    value={organization_desc[tab] ? organization_desc[tab] : ""}
                    onChange={(e) =>
                      setOrganizationDesc((state) => ({
                        ...state,
                        [tab]: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="w-full h-full">
                  <label className="label">
                    <span className={`label-text`}>&nbsp;</span>
                  </label>
                  <div className="space-y-2w-full">
                    {image?.objectUrl || data ? (
                      <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                        <img
                          src={
                            data?.organization_image && !image?.file
                              ? `${
                                  process.env.REACT_APP_API
                                }${data?.organization_image?.replace(
                                  "/public",
                                  "",
                                )}`
                              : image?.objectUrl
                          }
                          alt=""
                          className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                        />
                        <div className="absolute right-[14px] bottom-[14px]">
                          <div className="flex items-center space-x-2">
                            {!data?.organization_image || image?.file ? (
                              <Button
                                label="Remove"
                                size="sm"
                                theme="error"
                                className="opacity-70 hover:opacity-100 transition-opacity"
                                type="button"
                                onClick={() =>
                                  setImage({
                                    file: null,
                                    objectUrl: null,
                                    name: null,
                                  })
                                }
                              />
                            ) : null}
                            <Button
                              label="Change Image"
                              size="sm"
                              className="opacity-70 hover:opacity-100 transition-opacity"
                              type="button"
                              onClick={onTriggerUpload}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="border border-dashed border-gray-400 text-gray-400 rounded-lg p-2 flex items-center justify-center w-full h-[180px] cursor-pointer space-x-2"
                        onClick={onTriggerUpload}
                      >
                        <IoMdImage />
                        <span>Upload Image</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="label">
                <span className={`label-text`}>Background</span>
              </label>
              <div className="space-y-2 w-full">
                {(background?.objectUrl || data?.organization_background) && (
                  <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                    <img
                      src={
                        data?.organization_background && !background?.file
                          ? `${
                              process.env.REACT_APP_API
                            }${data?.organization_background?.replace(
                              "/public",
                              "",
                            )}`
                          : background?.objectUrl
                      }
                      alt=""
                      className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                    />
                    {!data?.organization_background || background?.file ? (
                      <IoMdCloseCircle
                        onClick={() =>
                          setImage({
                            file: null,
                            objectUrl: null,
                            name: null,
                          })
                        }
                        className="absolute right-[16px] top-[16px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                      />
                    ) : null}
                  </div>
                )}
                <BaseButton
                  className="w-full flex gap-2 justify-center items-center"
                  variant="primary2"
                  size="md"
                  type="button"
                  onClick={() => onTriggerUpload(null, true)}
                >
                  <IoMdImage size={18} />
                  {background.file || data?.organization_background
                    ? "Change Image"
                    : "Upload Image"}
                </BaseButton>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="space-y-4">
            <BaseInput
              label="Judul"
              placeholder="Judul"
              value={banner_tittle[tab]}
              onChange={(e) =>
                setBannerTittle((state) => ({
                  ...state,
                  [tab]: e.target.value,
                }))
              }
            />
            <div className="form-control w-full">
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                Deskripsi
              </label>
              <textarea
                className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                placeholder="Deskripsi"
                value={banner_desc[tab] ? banner_desc[tab] : ""}
                onChange={(e) =>
                  setBannerDesc((state) => ({
                    ...state,
                    [tab]: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-control w-full space-y-8">
              <div>
                <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                  Banner 1
                </label>
                <div className="space-y-2 w-full">
                  {(image?.objectUrl || data?.banner) && (
                    <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                      <img
                        src={
                          data?.banner && !image?.file
                            ? `${
                                process.env.REACT_APP_API
                              }${data?.banner?.replace("/public", "")}`
                            : image?.objectUrl
                        }
                        alt=""
                        className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                      />
                      {!data?.banner || image?.file ? (
                        <IoMdCloseCircle
                          onClick={() =>
                            setImage({
                              file: null,
                              objectUrl: null,
                              name: null,
                            })
                          }
                          className="absolute right-[16px] top-[16px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                        />
                      ) : null}
                    </div>
                  )}
                  <BaseButton
                    className="w-full flex gap-2 justify-center items-center"
                    variant="primary2"
                    size="md"
                    onClick={() => onTriggerUpload(1)}
                    type="button"
                  >
                    <IoMdImage size={18} />
                    {image.file || data?.banner
                      ? "Change Image"
                      : "Upload Image"}
                  </BaseButton>
                </div>
              </div>
              <div>
                <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                  Banner 2
                </label>
                <div className="space-y-2 w-full">
                  {(image2?.objectUrl || data?.banner_2) && (
                    <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                      <img
                        src={
                          data?.banner && !image2?.file
                            ? `${
                                process.env.REACT_APP_API
                              }${data?.banner_2?.replace("/public", "")}`
                            : image2?.objectUrl
                        }
                        alt=""
                        className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                      />
                      {!data?.banner_2 || image2?.file ? (
                        <IoMdCloseCircle
                          onClick={() =>
                            setImage2({
                              file: null,
                              objectUrl: null,
                              name: null,
                            })
                          }
                          className="absolute right-[16px] top-[16px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                        />
                      ) : null}
                    </div>
                  )}
                  <BaseButton
                    className="w-full flex gap-2 justify-center items-center"
                    variant="primary2"
                    size="md"
                    onClick={() => onTriggerUpload(2)}
                    type="button"
                  >
                    <IoMdImage size={18} />
                    {image2.file || data?.banner2
                      ? "Change Image"
                      : "Upload Image"}
                  </BaseButton>
                </div>
              </div>
              <div>
                <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                  Banner 3
                </label>
                <div className="space-y-2 w-full">
                  {(image3?.objectUrl || data?.banner_3) && (
                    <div className="relative border border-dashed border-gray-400 rounded-lg p-2">
                      <img
                        src={
                          data?.banner && !image3?.file
                            ? `${
                                process.env.REACT_APP_API
                              }${data?.banner_3?.replace("/public", "")}`
                            : image3?.objectUrl
                        }
                        alt=""
                        className="max-h-40 h-fit w-full object-cover hover:h-full transform transition-transform"
                      />
                      {!data?.banner_3 || image3?.file ? (
                        <IoMdCloseCircle
                          onClick={() =>
                            setImage3({
                              file: null,
                              objectUrl: null,
                              name: null,
                            })
                          }
                          className="absolute right-[16px] top-[16px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                        />
                      ) : null}
                    </div>
                  )}
                  <BaseButton
                    className="w-full flex gap-2 justify-center items-center"
                    variant="primary2"
                    size="md"
                    onClick={() => onTriggerUpload(3)}
                    type="button"
                  >
                    <IoMdImage size={18} />
                    {image3.file || data?.banner3
                      ? "Change Image"
                      : "Upload Image"}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>{header}</h5>
          <div className="relative">
            <ul className="relative flex p-1 list-none bg-transparent rounded-xl gap-4">
              <li
                className={cn(
                  "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                  tab === "jp" && "shadow-soft-xxs font-semibold",
                )}
              >
                <a
                  className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                  onClick={() => setTab("jp")}
                >
                  <span className="ml-1">Japan</span>
                </a>
              </li>
              <li
                className={cn(
                  "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                  tab === "en" && "shadow-soft-xxs font-semibold",
                )}
              >
                <a
                  className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                  onClick={() => setTab("en")}
                >
                  <span className="ml-1">English</span>
                </a>
              </li>
              <li
                className={cn(
                  "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                  tab === "id" && "shadow-soft-xxs font-semibold",
                )}
              >
                <a
                  className="z-30 block w-full px-0 py-1 mb-0 transition-colors border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                  onClick={() => setTab("id")}
                >
                  <span className="ml-1">Indonesian</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-auto py-3 mb-2 px-6">{mainRender()}</div>
        <input
          ref={attachmentRef}
          autoComplete="none"
          accept="image/png, image/jpg, image/jpeg, application/pdf"
          type="file"
          onChange={onSelectFile}
          onClick={(event) => {
            event.target.value = null;
          }}
          className="opacity-0 hidden"
        />
      </div>
    </>
  );
};

// const DEFAULT_VALUES = {
//   banner: null,
//   banner_2: null,
//   banner_3: null,
//   banner_tittle: "",
//   banner_desc: "",
//   banner_tittle_jpn: "",
//   banner_desc_jpn: "",
//   banner_tittle_en: "",
//   banner_desc_en: "",
// };

// const Settings = () => {
//   const topElement = useRef();

//   const methods = useForm({
//     mode: "onChange",
//     defaultValues: DEFAULT_VALUES,
//   });

//   const onSubmit = () => {};

//   console.log("banner", methods.watch("banner"));

//   return (
//     <div className="space-y-4">
//       <div ref={topElement} />
//       <div className="w-full space-y-1">
//         <Label text={`General Settings`} className="font-bold text-2xl" />
//       </div>
//       <FormProvider {...methods}>
//         <form className="space-y-6" onSubmit={methods.handleSubmit(onSubmit)}>
//           <div className="form-control space-y-2 w-full">
//             <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
//               Deskripsi
//             </label>
//             <textarea
//               {...methods.register("banner_desc")}
//               className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
//               placeholder="Deskripsi"
//             />
//           </div>
//           <FilePicker name={"banner"}>

//           </FilePicker>
//         </form>
//       </FormProvider>
//     </div>
//   );
// };

// const FilePicker = ({ name, children, onClick }) => {
//   const attachmentRef = useRef();
//   const { control } = useFormContext();

//   const upload = useMutation(uploadAvatar);

//   const onSelectFile = (event, onChange) => {
//     const file = event.target.files[0];
//     if (
//       file.type.includes("jpg") ||
//       file.type.includes("jpeg") ||
//       file.type.includes("png") ||
//       file.type.includes("jfif")
//     ) {
//       const formData = new FormData();
//       formData.append("image", file);
//       upload.mutate(formData, {
//         onSuccess: (data) => {
//           console.log("data", data);
//           const objectUrl = URL.createObjectURL(file);
//           onChange({ ...file, objectUrl });
//         },
//         onError: (err) => {
//           console.log("error", err);
//         },
//       });
//     }
//   };

//   return (
//     <Controller
//       name={name}
//       control={control}
//       defaultValue=""
//       render={({
//         field: { onChange, value },
//         fieldState: { invalid, error },
//       }) => (
//         <>
//           {children}
//           <input
//             ref={attachmentRef}
//             autoComplete="none"
//             accept="image/png, image/jpg, image/jpeg, application/pdf"
//             type="file"
//             value={value}
//             onChange={(e) => onSelectFile(e, onChange)}
//             onClick={(event) => {
//               event.target.value = null;
//             }}
//             className="opacity-0 hidden"
//           />
//         </>
//       )}
//     />
//   );
// };

export default Settings;
