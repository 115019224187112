import axios from "axios";
import Service from "config";

export const getLanding = () => {
  return axios.get(`${Service.API}/user/landing`);
};

export const postLanding = (params) => {
  return axios.post(`${Service.API}/admin/landing`, params);
};

export const updateLanding = (params) => {
  return axios.put(`${Service.API}/admin/landing`, params);
};

export const getArticles = (params) => {
  return axios.get(
    `${Service.API}/user/article?type=${"article"}&page=${
      params.page ?? 1
    }&limit=${params.limit ?? 5}`,
  );
};

export const getArticleTopicDetail = (params) => {
  return axios.get(`${Service.API}/user/article/${params}`);
};

export const getArticleTopicRelated = (params) => {
  return axios.get(
    `${Service.API}/user/article-linked/${params?.id ?? ""}?type=${
      params?.type ?? ""
    }`,
  );
};

export const getAllArticles = (params) => {
  return axios.get(
    `${Service.API}/admin/article?type=${"article"}&page=${
      params?.page ?? 1
    }&limit=${params?.limit ?? 5}`,
  );
};

export const getTopic = (params) => {
  return axios.get(
    `${Service.API}/user/article?type=${"topic"}&page=${
      params?.page ?? 1
    }&limit=${params?.limit ?? 5}`,
  );
};

export const getAllTopic = (params) => {
  return axios.get(
    `${Service.API}/admin/article?type=${"topic"}&page=${
      params?.page ?? 1
    }&limit=${params?.limit ?? 5}`,
  );
};

export const updateArticle = (params) => {
  return axios.put(
    `${Service.API}/admin/article/${params.id ?? ""}`,
    params?.payload,
  );
};

export const postArticle = (params) => {
  return axios.post(`${Service.API}/admin/article`, params);
};

export const getProfileBitode = () => {
  return axios.get(`${Service.API}/user/biodata`);
};

export const getAllProfileBitode = () => {
  return axios.get(`${Service.API}/admin/biodata`);
};

export const postProfileBitode = (params) => {
  return axios.post(`${Service.API}/admin/biodata`, params);
};

export const updateProfileBitode = (params) => {
  return axios.put(
    `${Service.API}/admin/biodata/${params.id ?? ""}`,
    params?.payload,
  );
};

export const submitContactUs = (params) => {
  return axios.post(`${Service.API}/user/contact-us`, params);
};

export const deleteImage = (id) => {
  return axios.delete(`${Service.API}/admin/article/images/${id}`);
};
