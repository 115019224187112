import React from "react";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import logo from "assets/bitode-logo.png";

const GlobalFooter = () => {
  return (
    <footer className="w-full bg-white bottom-0 py-10">
      <div className="container flex flex-col gap-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 place-items-start md:place-items-center">
          <div className="flex flex-col">
            <h5>Bitode Wira Indonesia</h5>
            <p className="m-0 text-sm">Ikuti Sosial media kami:</p>
            <div className="flex gap-3 items-center">
              <a
                href="https://www.instagram.com/bitodecourse/?next=%2F&hl=id"
                target="_blank"
                rel="noreferrer"
                className="text-[24px] hover:text-black"
              >
                <FaInstagram />
              </a>
              <a
                href="https://youtube.com/@bitodewira890"
                target="_blank"
                rel="noreferrer"
                className="text-[28px] hover:text-black"
              >
                <AiOutlineYoutube />
              </a>
              <a
                href="https://www.facebook.com/bitode.wira"
                target="_blank"
                rel="noreferrer"
                className="text-[24px] hover:text-black"
              >
                <FiFacebook />
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h6>INFORMASI</h6>
            <p className="text-sm m-0">
              Jika ada hal yang ingin ditanyakan bisa menghubungi kami disini:
            </p>
            <div className="flex flex-col">
              <span className="text-sm">
                <b>Alamat:</b> Kp. Cukang Akar RT 02 RW 06 Desa Keresek Kec
                Cibatu Kab Garut 44185
              </span>
              <span className="text-sm">
                <b>Telp:</b> 082123999949
              </span>
              <span className="text-sm">
                <b>Jam Kerja:</b> 08:00 - 17:00
              </span>
            </div>
          </div>

          <div className="place-self-center md:col-span-2 lg:col-span-1">
            <img src={logo} className="w-64" />
          </div>
        </div>
        <div className="flex justify-center">
          <p className="m-0 text-sm font-semibold">
            Copyright © Bitode Wira Indonesia
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
