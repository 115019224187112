import BaseButton from "components/atoms/BaseButton";
import { BaseInput, SelectInput, SelectSkill } from "components/v2";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgTrash } from "react-icons/cg";
import { useSelector } from "react-redux";
import _ from "lodash";

const educations = [
  {
    value: "国立小学校 (SDN)",
    label: "国立小学校 (SDN)",
    type: "elementary_school",
  },
  {
    value: "私立小学校 (SD SWASTA)",
    label: "私立小学校 (SD SWASTA)",
    type: "elementary_school",
  },
  {
    value: "マドラサイブティダイヤー小学校等 (MI)",
    label: "マドラサイブティダイヤー小学校等 (MI)",
    type: "elementary_school",
  },
  {
    value: "国立中学校 (SMPN)",
    label: "国立中学校 (SMPN)",
    type: "junior_high_school",
  },
  {
    value: "私立中学校 (SMP SWASTA)",
    label: "私立中学校 (SMP SWASTA)",
    type: "junior_high_school",
  },
  {
    value: "マドラサ　サナウィヤー中学校等 (MTS)",
    label: "マドラサ　サナウィヤー中学校等 (MTS)",
    type: "junior_high_school",
  },
  {
    value: "国立高校 (SMAN)",
    label: "国立高校 (SMAN)",
    type: "senior_vocational_high_school",
  },
  {
    value: "私立高校 (SMA SWASTA)",
    label: "私立高校 (SMA SWASTA)",
    type: "senior_vocational_high_school",
  },
  {
    value: "マドラサアリヤー (MA)",
    label: "マドラサアリヤー (MA)",
    type: "senior_vocational_high_school",
  },
  {
    value: "国立職業高等学校 (SMKN)",
    label: "国立職業高等学校 (SMKN)",
    type: "senior_vocational_high_school",
  },
  {
    value: "私立職業高等学校 (SMK SWASTA)",
    label: "私立職業高等学校 (SMK SWASTA)",
    type: "senior_vocational_high_school",
  },
  {
    value: "ディプロマ　I (D1)",
    label: "ディプロマ　I (D1)",
    type: "collage",
  },
  {
    value: "ディプロマ　II (D2)",
    label: "ディプロマ　II (D2)",
    type: "collage",
  },
  {
    value: "ディプロマIII (D3)",
    label: "ディプロマIII (D3)",
    type: "collage",
  },
  { value: "学士 1 (S1)", label: "学士 1 (S1)", type: "collage" },
  { value: "学士 2 (S2)", label: "学士 2 (S2)", type: "collage" },
  { value: "学士 3 (S3)", label: "学士 3 (S3)", type: "collage" },
];

const Education = ({ readOnly }) => {
  const { control, watch } = useFormContext();

  const { user } = useSelector(({ auth }) => auth);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educations",
  });

  const handleOptionsEducation = () => {
    const listExistEducation = _.filter(
      _.map(watch("educations"), (education) => education?.education),
      (data) => data,
    );
    const typeCollections = _.filter(
      _.uniq(_.map(listExistEducation, "type")),
      (val) => val !== "collage",
    );
    const collageCollection = _.uniq(
      _.map(
        _.filter(listExistEducation, (data) => data.type === "collage"),
        "value",
      ),
    );
    if (listExistEducation?.length > 0) {
      return _.filter(
        educations,
        (data) =>
          !typeCollections.includes(data?.type) &&
          !collageCollection.includes(data?.value),
      );
    }
    return educations;
  };

  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="flex justify-between items-baseline p-6">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
          <h6>学歴</h6>
          <p>(Riwayat Pendidikan)</p>
        </div>
        {watch("educations")?.length < 4 &&
          user?.role !== "partner_jepang" &&
          !readOnly && (
            <BaseButton onClick={() => append({ education: null, name: "" })}>
              Tambah
            </BaseButton>
          )}
      </div>

      <div className="px-6">
        <table className="table w-full">
          <tbody>
            {_.map(fields, (fields, index) => (
              <tr>
                <td className="p-1">
                  <SelectInput
                    name={`educations.${index}.education`}
                    options={handleOptionsEducation()}
                    isDisabled={user?.role === "partner_jepang" || readOnly}
                    placeholder="Riwayat Pendidikan"
                    isClearable
                  />
                </td>
                <td className="p-1">
                  <SelectSkill
                    name={`educations.${index}.skill`}
                    placeholder="Masukan Nama Keterampilan"
                    isDisabled={user?.role === "partner_jepang" || readOnly}
                  />
                </td>
                <td className="p-1" colSpan={index === 0 && 2}>
                  <BaseInput
                    name={`educations.${index}.name`}
                    placeholder="Nama Sekolah/Instansi"
                    type="text"
                    disabled={user?.role === "partner_jepang" || readOnly}
                  />
                </td>
                {index > 0 && user?.role !== "partner_jepang" && !readOnly && (
                  <td className="pr-7 w-6 inline-flex">
                    <BaseButton onClick={() => remove(index)}>
                      <CgTrash size={24} />
                    </BaseButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Education;
