// import React, { useEffect, useState } from "react";
// import _ from "lodash";
// import { Button, InputValidation, Label, Select, Spinner } from "components";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { fetchAPI, getErrorMessage } from "helpers";
// import { postEvaluation, putEvaluation } from "services/user";
// import { useLocation, useNavigate } from "react-router";

// const EvaluationForm = () => {
//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const student_id = state?.student_id ?? null;
//   const lesson = state?.lesson ?? null;
//   const class_id = state?.class?.id ?? null;
//   const class_name = state?.class?.name ?? null;

//   const [tab, setTab] = useState(0);

//   const [evaluationData, setEvaluationData] = useState([]);
//   const [updated, setUpdated] = useState(false);

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//     reset,
//   } = useForm({
//     mode: "onChange",
//   });

//   const [createData, setCreateData] = useState({
//     isLoading: false,
//     isSuccess: false,
//     isError: false,
//     data: null,
//     errorMessage: "",
//   });

//   useEffect(() => {
//     if (lesson?.subjects_mitra?.length > 0) {
//       setEvaluationData(
//         _.map(lesson?.subjects_mitra, (subject) => ({
//           ...subject,
//           id: subject?.id,
//           leasson_id: lesson?.id,
//           evaluation_id: subject?.evaluation_id ?? null,
//           name: subject?.name,
//           attendence: updated
//             ? { value: subject?.attendence, label: subject?.attendence }
//             : {
//                 value: "Hadir",
//                 label: "Hadir",
//               },
//           ontime: updated
//             ? { value: subject?.ontime, label: subject?.ontime }
//             : {
//                 value: "Tepat Waktu",
//                 label: "Tepat Waktu",
//               },
//           attitude: updated
//             ? { value: subject?.attitude, label: subject?.attitude }
//             : {
//                 value: "Sangat Baik",
//                 label: "Sangat Baik",
//               },
//           punctuality: updated
//             ? { value: subject?.punctuality, label: subject?.punctuality }
//             : {
//                 value: "Sangat Baik",
//                 label: "Sangat Baik",
//               },
//           score: updated ? subject?.score : "",
//           total_question: updated ? subject?.total_question : "",
//         })),
//       );
//     }
//   }, [lesson, updated]);

//   useEffect(() => {
//     if (evaluationData[tab]?.evaluation_id) {
//       setUpdated(true);
//     } else {
//       setUpdated(false);
//     }
//   }, [evaluationData[tab]?.evaluation_id]);

//   useEffect(() => {
//     if (updated) {
//       reset({
//         score: evaluationData[tab]?.score,
//         total_question: evaluationData[tab]?.total_question,
//       });
//     }
//   }, [updated, evaluationData[tab]]);

//   const handleChangeScore = (event) => {
//     let { value, name } = event?.target;
//     if (event.target.validity.valid) {
//       if (value.charAt(0) === "0") value = 0;
//       if (parseFloat(value) > 100) value = 100;
//       if (parseFloat(value) < 0) value = 0;
//       setValue(name, value);
//       setEvaluationData((prevState) => {
//         let newState = [...prevState];
//         newState[tab].score = value;
//         return newState;
//       });
//     }
//   };

//   const handleChangeTotalQuestion = (event) => {
//     let { value, name } = event?.target;
//     if (event.target.validity.valid) {
//       if (value.charAt(0) === "0") value = 0;
//       // if (parseFloat(value) > 100) value = 100;
//       if (parseFloat(value) < 0) value = 0;
//       setValue(name, value);
//       setEvaluationData((prevState) => {
//         let newState = [...prevState];
//         newState[tab].total_question = value;
//         return newState;
//       });
//     }
//   };

//   const handleChangeDropdownScore = (value, key) => {
//     setEvaluationData((prevState) => {
//       let newState = [...prevState];
//       newState[tab][key] = value;
//       return newState;
//     });
//   };

//   const onSubmit = (data) => {
//     if (data) {
//       let params = {
//         attendence: evaluationData[tab]?.attendence?.value,
//         ontime: evaluationData[tab]?.ontime?.value,
//         attitude: evaluationData[tab]?.attitude?.value,
//         punctuality: evaluationData[tab]?.punctuality?.value,
//         score: evaluationData[tab]?.score,
//         total_question: evaluationData[tab]?.total_question,
//         leasson_id: evaluationData[tab]?.leasson_id,
//         subject_id: evaluationData[tab]?.subject?.join(","),
//         class_id: class_id,
//         input_by: "student",
//       };

//       let valid =
//         Boolean(evaluationData[tab]?.score) &&
//         Boolean(evaluationData[tab]?.total_question);

//       if (updated) {
//         params.eval_id = evaluationData[tab]?.evaluation_id ?? "";
//       }

//       if (valid) {
//         setCreateData((prevState) => ({ ...prevState, isLoading: true }));
//         fetchAPI(
//           {
//             service: putEvaluation,
//             params: { id: student_id, params },
//           },
//           (success, response) => {
//             if (success) {
//               setCreateData((prevState) => ({
//                 ...prevState,
//                 isLoading: false,
//                 isSuccess: true,
//                 data: response,
//               }));
//               toast.success("Sukses mengubah data!");
//               navigate(-1);
//             } else {
//               setCreateData((prevState) => ({
//                 ...prevState,
//                 isLoading: false,
//                 isError: true,
//                 data: response,
//                 errorMessage: getErrorMessage(response),
//               }));
//             }
//           },
//         );
//       }
//     }
//   };

//   return (
//     <div className="w-full space-y-4">
//       <div className="flex justify-between items-center">
//         <Label
//           text={`Penilaian - ${class_name}`}
//           className="font-bold text-2xl"
//         />
//       </div>

//       <div className="tabs w-full">
//         {_.map(lesson?.subjects_mitra ?? [], (subject, key) => (
//           <a
//             onClick={() => setTab(key)}
//             className={`tab tab-bordered ${tab === key ? "tab-active" : ""}`}
//           >
//             {subject?.name ?? ""}
//           </a>
//         ))}
//       </div>

//       <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
//         <div className="space-y-1">
//           <InputValidation
//             label="Materi"
//             type="text"
//             readOnly
//             disabled
//             value={lesson?.name}
//           />
//           <Select
//             label="Kehadiran"
//             onChange={(value) => handleChangeDropdownScore(value, "attendence")}
//             value={evaluationData[tab]?.attendence}
//             options={[
//               { value: "Hadir", label: "Hadir" },
//               { value: "Tidak Hadir", label: "Tidak Hadir" },
//             ]}
//             placeholder="Masukan Kehadiran"
//           />
//           <Select
//             label="Ketepatan Waktu"
//             onChange={(value) => handleChangeDropdownScore(value, "ontime")}
//             value={evaluationData[tab]?.ontime}
//             options={[
//               { value: "Tepat Waktu", label: "Tepat Waktu" },
//               { value: "Tidak Tepat Waktu", label: "Tidak Tepat Waktu" },
//             ]}
//             placeholder="Masukan Ketepatan Waktu"
//           />
//           <Select
//             label="Sopan Santun"
//             onChange={(value) => handleChangeDropdownScore(value, "attitude")}
//             value={evaluationData[tab]?.attitude}
//             options={[
//               { value: "Sangat Baik", label: "Sangat Baik" },
//               { value: "Baik", label: "Baik" },
//               { value: "Kurang Baik", label: "Kurang Baik" },
//             ]}
//             placeholder="Masukan Sopan Santun"
//           />
//           <Select
//             label="Disiplin Waktu"
//             onChange={(value) =>
//               handleChangeDropdownScore(value, "punctuality")
//             }
//             value={evaluationData[tab]?.punctuality}
//             options={[
//               { value: "Sangat Baik", label: "Sangat Baik" },
//               { value: "Baik", label: "Baik" },
//               { value: "Kurang Baik", label: "Kurang Baik" },
//             ]}
//             placeholder="Masukan Disiplin Waktu"
//           />
//           <InputValidation
//             {...register("score", {
//               required: {
//                 value: true,
//                 message: "Nilai wajib diisi",
//               },
//             })}
//             name="score"
//             label="Nilai"
//             type="phone"
//             pattern="^[0-9]*$"
//             value={evaluationData[tab]?.score}
//             onChange={handleChangeScore}
//             placeholder="0-100"
//             isError={{ value: errors.score, message: errors.score?.message }}
//           />
//           <InputValidation
//             {...register("total_question", {
//               required: {
//                 value: true,
//                 message: "Total soal wajib diisi",
//               },
//             })}
//             name="total_question"
//             label="Total soal"
//             type="phone"
//             pattern="^[0-9]*$"
//             value={evaluationData[tab]?.total_question}
//             onChange={handleChangeTotalQuestion}
//             placeholder="0"
//             isError={{
//               value: errors.total_question,
//               message: errors.total_question?.message,
//             }}
//           />
//         </div>
//         <div className="card-actions justify-end">
//           <Button
//             label={createData?.isLoading ? <Spinner /> : "Kirim"}
//             disabled={createData?.isLoading}
//             className="w-full"
//             theme="primary"
//             type="submit"
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EvaluationForm;

import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Label } from "components";
import { map } from "lodash";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { NumericInput, SelectInput } from "components/v2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BaseButton from "components/atoms/BaseButton";
import clsx from "clsx";
import { useMutation } from "react-query";
import { putEvaluation } from "services/user";
import { toast } from "react-toastify";

const EvaluationForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const class_name = state?.class?.name ?? null;
  const lesson = state?.lesson ?? null;
  const class_id = state?.class?.id ?? null;
  const student_id = state?.student_id ?? null;

  const [tab, setTab] = useState(0);
  const [updated, setUpdated] = useState(false);

  const put = useMutation(putEvaluation);

  const requiredStringCustomYupValidation = useMemo(() => ({
    name: "custom-required",
    message: "Wajib diisi",
    test: (value, schema) => {
      if (!value && schema?.path?.includes(`subjects[${tab}]`)) {
        return false;
      }
      return true;
    },
  }));

  const requiredMixedCustomYupValidation = useMemo(() =>
    yup
      .mixed()
      .nullable()
      .test({
        name: "custom-required",
        message: "Wajib diisi",
        test: (value, schema) => {
          if (!value && schema?.path?.includes(`subjects[${tab}]`)) {
            return false;
          }
          return true;
        },
      }),
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        subjects: yup.array().of(
          yup.object().shape({
            attendence: requiredMixedCustomYupValidation,
            ontime: requiredMixedCustomYupValidation,
            attitude: requiredMixedCustomYupValidation,
            punctuality: requiredMixedCustomYupValidation,
            score: yup
              .string()
              .test({
                message: "Minimal 1",
                test: (v) => !(parseInt(v) < 1),
              })
              .test({
                message: "Maksimal 100",
                test: (v) => !(parseInt(v) > 100),
              })
              .test(requiredStringCustomYupValidation),
            total_question: yup
              .string()
              .test(requiredStringCustomYupValidation),
          }),
        ),
      }),
    [tab],
  );

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      subjects: [],
    },
  });

  const { fields } = useFieldArray({
    name: "subjects",
    control: methods?.control,
  });

  useEffect(() => {
    if (fields[tab]?.evaluation_id) {
      setUpdated(true);
    } else {
      setUpdated(false);
    }
  }, [fields[tab]?.evaluation_id]);

  useEffect(() => {
    const subjects = lesson?.subjects_mitra;
    if (subjects?.length > 0) {
      methods.reset({
        subjects: map(subjects, (subject) => {
          let params = {
            attendence: subject?.attendence
              ? {
                  value: subject?.attendence,
                  label: subject?.attendence,
                }
              : null,
            attitude: subject?.attitude
              ? {
                  value: subject?.attitude,
                  label: subject?.attitude,
                }
              : null,
            ontime: subject?.ontime
              ? { value: subject?.ontime, label: subject?.ontime }
              : null,
            punctuality: subject?.punctuality
              ? {
                  value: subject?.punctuality,
                  label: subject?.punctuality,
                }
              : null,
            score: subject?.score,
            total_question: subject?.total_question,
            leasson_id: lesson?.id,
            subject_id: subject?.subject?.join(","),
            class_id,
            input_by: "mitra",
            name: subject?.name,
          };

          if (subject?.evaluation_id) {
            params.eval_id = subject?.evaluation_id;
          }

          return params;
        }),
      });
    }
  }, [lesson]);

  const onSubmit = (payload) => {
    let params = {
      ...payload?.subjects?.[tab],
      attendence: payload?.subjects?.[tab]?.attendence?.value,
      ontime: payload?.subjects?.[tab]?.ontime?.value,
      attitude: payload?.subjects?.[tab]?.attitude?.value,
      punctuality: payload?.subjects?.[tab]?.punctuality?.value,
    };

    if (updated) {
      params.eval_id = payload?.subjects?.[tab]?.eval_id ?? "";
    }

    put.mutate(
      { id: student_id, params },
      {
        onSuccess: () => {
          toast.success("Sukses mengubah data!");
          navigate(-1);
        },
      },
    );
  };

  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border p-8 space-y-4">
      <div className="flex justify-between items-center">
        <Label
          text={`Penilaian - ${class_name}`}
          className="font-bold text-2xl"
        />
      </div>

      <div className="flex items-center overflow-x-auto border-b border-gray-400">
        {map(fields, (subject, key) => (
          <div
            key={key}
            className={clsx({
              "border-b-4 border-blue-500": key === tab,
            })}
          >
            <div
              onClick={() => setTab(key)}
              className={clsx(
                "px-4 pb-2 whitespace-nowrap",
                {
                  "text-blue-500 font-medium": key === tab,
                },
                "hover:cursor-pointer hover:font-medium",
              )}
            >
              {subject?.name}
            </div>
          </div>
        ))}
      </div>

      <FormProvider {...methods}>
        {map(fields, (field, key) => {
          if (key !== tab) return null;
          return (
            <div className="space-y-4" key={field.id}>
              <SelectInput
                name={`subjects.${key}.attendence`}
                label="Kehadiran"
                placeholder="Masukan Kehadiran"
                menuPortalTarget={document.body}
                options={[
                  { value: "Hadir", label: "Hadir" },
                  { value: "Tidak Hadir", label: "Tidak Hadir" },
                ]}
              />
              <SelectInput
                name={`subjects.${key}.ontime`}
                label="Ketepatan Waktu"
                placeholder="Masukan Ketepatan Waktu"
                menuPortalTarget={document.body}
                options={[
                  { value: "Tepat Waktu", label: "Tepat Waktu" },
                  { value: "Tidak Tepat Waktu", label: "Tidak Tepat Waktu" },
                ]}
              />
              <SelectInput
                name={`subjects.${key}.attitude`}
                label="Sopan Santun"
                placeholder="Masukan Sopan Santun"
                menuPortalTarget={document.body}
                options={[
                  { value: "Tepat Waktu", label: "Tepat Waktu" },
                  { value: "Tidak Tepat Waktu", label: "Tidak Tepat Waktu" },
                ]}
              />
              <SelectInput
                name={`subjects.${key}.punctuality`}
                label="Disiplin Waktu"
                placeholder="Masukan Disiplin Waktu"
                menuPortalTarget={document.body}
                options={[
                  { value: "Sangat Baik", label: "Sangat Baik" },
                  { value: "Baik", label: "Baik" },
                  { value: "Kurang Baik", label: "Kurang Baik" },
                ]}
              />
              <NumericInput
                name={`subjects.${key}.score`}
                label="Nilai"
                placeholder="Masukan Nilai"
              />
              <NumericInput
                name={`subjects.${key}.total_question`}
                label="Total Soal"
                placeholder="Masukan Total Soal"
              />
              <div className="flex gap-3 justify-end">
                <BaseButton
                  variant="base"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Kembali
                </BaseButton>
                <BaseButton
                  variant="primary2"
                  type="button"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  Kirim
                  {/* <span>
                  <CgSpinner className="animate-spin text-sm text-primary" />
                </span> */}
                </BaseButton>
              </div>
            </div>
          );
        })}
      </FormProvider>
    </div>
  );
};

export default EvaluationForm;
