import axios from "axios";
import Service from "config";
import { chunk, map } from "lodash";

export const getTrainingOrder = async (params, signal) => {
  const { data } = await axios.get(`${Service.API}/admin/partner/to`, {
    params,
    signal,
  });

  return data;
};

export const getTrainingOrderDetail = async (params, signal) => {
  const { data } = await axios.get(
    `${Service.API}/admin/partner/to/${params.id}`,
    {
      signal,
    },
  );

  return data;
};

export const postTrainingOrder = async (params) => {
  const { data } = await axios.post(`${Service.API}/admin/partner/to`, params);
  return data;
};
export const deleteTrainingOrder = async (id) => {
  const { data } = await axios.delete(`${Service.API}/admin/partner/to/${id}`);
  return data;
};

export const postAddStudent = async (params) => {
  const { data } = await axios.post(
    `${Service.API}/admin/partner/to/add`,
    params,
  );

  return data;
};

export const deleteStudent = async (student_id) => {
  const { data } = await axios.delete(
    `${Service.API}/admin/partner/to/delete/${student_id}`,
  );

  return data;
};

export const updateStudent = async (params) => {
  const { data } = await axios.patch(
    `${Service.API}/admin/partner/to/update/${params?.student_id}`,
    params?.payload,
  );

  return data;
};

export const passingStudent = async (params) => {
  const { data } = await axios.patch(
    `${Service.API}/admin/partner/to/passed/${params?.to_id}`,
    params?.payload,
  );

  return data;
};
