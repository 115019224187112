import axios from "axios";
import Service from "config";

export const getMeet = (params) => {
  return axios.get(
    `${Service.API}/admin/set-schedule?page=${
      params?.page ?? 1
    }&limit=${10}&search=`,
  );
};

export const getMeetDetail = (params) => {
  return axios.get(`${Service.API}/admin/set-schedule/${params}`);
};

export const reScheduleMeet = (params) => {
  return axios.post(`${Service.API}/admin/set-schedule`, params);
};

export const getToList = (params, signal) => {
  return axios.get(`${Service.API}/admin/list-to`, {
    params,
    signal,
  });
};

export const getStudentToList = (params, signal) => {
  return axios.get(`${Service.API}/admin/list-to/${params?.id}`, {
    params: params?.payload,
    signal,
  });
};

export const putScheduleMeet = (params) => {
  return axios.put(
    `${Service.API}/admin/set-schedule/${params?.id}`,
    params?.payload,
  );
};
