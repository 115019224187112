import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Spinner,
} from "components";
import { useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";
import {
  getList,
  getListHiredOnMitra,
  getListNonHired,
  postData,
} from "services/hire";
import { MdAdd, MdClose, MdEdit } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { CgSpinner } from "react-icons/cg";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getFeedback,
  getFeedbackDetail,
  getPartnerFeedback,
  getPartnerFeedbackDetail,
  postFeedback,
  putFeedback,
} from "services/feedback";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";

const CritismSuggestion = () => {
  const modalForm = useRef();
  const navigate = useNavigate();
  const modalConfirm = useRef();

  const { user } = useSelector(({ auth }) => auth);

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [nonHired, setNonHired] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });

  const [type, setType] = useState("");

  const [search, setSearch] = useState("");

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  useEffect(() => {
    return () => {
      onChangeSearch.cancel();
    };
  });

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onConfirmData = () => {
    fetchAPI(
      { service: postData, params: { student_id: selectedData?.id } ?? null },
      async (success, response) => {
        if (success) {
          await modalConfirm?.current?.click();
          toast.success("Sukses menambahkan siswa!");
          fetchList(filter);
          setSelectedData(null);
        }
      },
    );
  };

  const fetchList = (params) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service:
          user?.role === "lpk_induk" || user?.role === "lpk_mitra_so"
            ? getFeedback
            : getPartnerFeedback,
        params: { search: params?.search, page: params?.page },
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onDetail = (data, typeTemp = "") => {
    reset({
      title: "",
      description: "",
      sugestion: "",
    });
    modalForm?.current?.click();
    fetchAPI(
      {
        service:
          user?.role === "partner_jepang"
            ? getPartnerFeedbackDetail
            : getFeedbackDetail,
        params: data?.id,
      },
      (success, response) => {
        if (success) {
          setSelectedData(response?.data);
          reset({
            title: response?.data?.title ?? "",
            description: response?.data?.description ?? "",
            sugestion: response?.data?.sugestion ?? "",
          });
        }
      },
    );
    setType(typeTemp);
  };

  const onCreate = (data) => {
    if (data) {
      let payload = {
        partner_id: user?.partner_id,
        partner_name: user?.name,
        title: data?.title,
        description: data?.description,
        sugestion: data?.sugestion,
      };

      let params = {
        payload,
      };

      if (type === "edit") params.id = selectedData?.id;

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      fetchAPI(
        {
          service: type === "edit" ? putFeedback : postFeedback,
          params: params,
        },
        (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses menambahkan data!");
            fetchList(filter);
            reset({ title: "", description: "", sugestion: "" });
            clearErrors("title");
            clearErrors("description");
            clearErrors("sugestion");
            modalForm?.current?.click();
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        },
      );
    }
  };

  const renderModalConfirm = () => (
    <>
      <input
        ref={modalConfirm}
        type="checkbox"
        id="modal-confirm"
        className="modal-toggle"
      />
      <div className="modal" style={{ zIndex: 50 }}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">Kritik & Saran</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menambahkan data ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              size="sm"
              theme="error"
              onClick={() => modalConfirm?.current?.click()}
            />
            <Button
              label={"Ya"}
              size="sm"
              theme="primary"
              onClick={onConfirmData}
            />
          </div>
        </div>
      </div>
    </>
  );

  const renderModalForm = () => (
    <>
      {renderModalConfirm()}
      <input
        ref={modalForm}
        type="checkbox"
        id="modal-form"
        className="modal-toggle"
      />
      <div className="modal" style={{ zIndex: 40 }}>
        <div className="modal-box w-11/12 max-w-5xl overflow-y-auto space-y-4">
          <div className="flex items-center justify-between">
            {user?.role === "partner_jepang" ? (
              <span className="flex space-x-1 items-end">
                <h3 className="font-bold text-lg">批判と提案</h3>
                <span className="text-sm font-medium text-gray-500">
                  {"(Kritik & Saran)"}
                </span>
              </span>
            ) : (
              <h3 className="font-bold text-lg">Kritik & Saran</h3>
            )}
            <CgClose
              className="cursor-pointer text-2xl"
              onClick={() => {
                modalForm?.current?.click();
                setType("");
                setTimeout(() => {
                  setSelectedData(null);
                }, 300);
              }}
            />
          </div>
          <div className="py-6">
            <form
              onSubmit={handleSubmit(onCreate)}
              className="flex flex-col justify-between max-h-[64vh] space-y-4"
            >
              <InputValidation
                {...register("title")}
                type="text"
                placeholder="タイトル (Judul)"
                className="text-sm"
                readOnly={type === "detail"}
              />
              <textarea
                {...register("description")}
                className="textarea w-full input-bordered text-sm h-[100px]"
                placeholder="批評家 (Kritik)"
                readOnly={type === "detail"}
              />
              <textarea
                {...register("sugestion")}
                className="textarea w-full input-bordered text-sm h-[100px]"
                placeholder="提案 (Saran)"
                readOnly={type === "detail"}
              />
              {type !== "detail" && (
                <div className="modal-action bg-white pt-4">
                  <Button
                    label={"Tidak"}
                    size="sm"
                    theme="error"
                    type="button"
                    onClick={() => {
                      modalForm?.current?.click();
                      setType("");
                    }}
                  />
                  <Button
                    label={
                      createData?.isLoading ? (
                        <CgSpinner className="animate-spin text-2xl text-primary" />
                      ) : (
                        "Kirim"
                      )
                    }
                    disabled={createData?.isLoading}
                    size="sm"
                    theme="primary"
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={user?.role === "partner_jepang" ? "5" : "4"}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
            colSpan={user?.role === "partner_jepang" ? "5" : "4"}
          >
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.name ?? "-"}
              </h6>
            </div>
          </td>

          {(user?.role === "lpk_induk" || user?.role === "lpk_mitra_so") && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.partner_name ?? "-"}
              </p>
            </td>
          )}

          {user?.role === "partner_jepang" && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.description ?? "-"}
              </p>
            </td>
          )}

          {user?.role === "lpk_induk" && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.sugestion ?? "-"}
              </p>
            </td>
          )}

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Detail
              </a>
              {user?.role === "partner_jepang" ? (
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onDetail(data, "edit")}
                >
                  Delete
                </a>
              ) : null}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={user?.role === "partner_jepang" ? "5" : "4"}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td colSpan={user?.role === "partner_jepang" ? "5" : "4"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <th>{id}</th>
          <td>{list?.title ?? ""}</td>
          {(user?.role === "lpk_induk" || user?.role === "lpk_mitra_so") && (
            <td className="whitespace-normal">{list?.partner_name ?? ""}</td>
          )}
          {user?.role === "partner_jepang" && (
            <td className="whitespace-normal">{list?.description ?? ""}</td>
          )}
          {user?.role === "partner_jepang" && (
            <td className="whitespace-normal">{list?.sugestion ?? ""}</td>
          )}
          <td>
            <div className="flex items-center justify-end space-x-2">
              <Button
                label={<FiEye className="text-white" />}
                size="sm"
                theme="info"
                onClick={() => onDetail(list, "detail")}
              />
              {user?.role === "partner_jepang" && (
                <Button
                  label={<MdEdit className="text-white" />}
                  size="sm"
                  theme="warning"
                  onClick={() => onDetail(list, "edit")}
                />
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    // <div>
    //   {renderModalForm()}
    //   <div className="w-full space-y-4">
    //     <div className="space-y-1">
    //       {user?.role === "partner_jepang" ? (
    //         <span className="flex space-x-1 items-end">
    //           <Label text="批判と提案" className="font-bold text-2xl" />
    //           <span className="text-sm font-medium text-gray-500">
    //             {"(Kritik & Saran)"}
    //           </span>
    //         </span>
    //       ) : (
    //         <Label text="Kritik & Saran" className="font-bold text-2xl" />
    //       )}
    //       <Label
    //         text="Menampilkan kritik & saran untuk dijadikan masukan terhadap admin."
    //         className="text-sm text-gray-500"
    //       />
    //     </div>
    //     <div
    //       style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       className="p-9 space-y-8 rounded-xl"
    //     >
    //       <div className="flex items-center space-x-4">
    //         <InputValidation
    //           type="text"
    //           placeholder="Search..."
    //           className="h-8 w-80 text-sm"
    //           onChange={onChangeSearch}
    //         />
    //         {user?.role === "partner_jepang" && (
    //           <Button
    //             theme="primary"
    //             label="Tambah Data"
    //             size="sm"
    //             onClick={() => {
    //               reset({
    //                 title: "",
    //                 description: "",
    //                 sugestion: "",
    //               });
    //               modalForm?.current?.click();
    //             }}
    //           />
    //         )}
    //       </div>
    //       <div className="overflow-x-auto border rounded-xl overflow-hidden">
    //         <table className="table w-full">
    //           <thead>
    //             <tr>
    //               <th className="w-16"></th>
    //               <td className="whitespace-normal">
    //                 {user?.role === "partner_jepang" ? (
    //                   <span>
    //                     タイトル
    //                     <span className="ml-1 text-[8px]">{"(Judul)"}</span>
    //                   </span>
    //                 ) : (
    //                   "Judul"
    //                 )}
    //               </td>
    //               {(user?.role === "lpk_induk" ||
    //                 user?.role === "lpk_mitra_so") && (
    //                 <td className="whitespace-normal">Partner Jepang</td>
    //               )}
    //               {user?.role === "partner_jepang" && (
    //                 <td className="whitespace-normal">
    //                   <span>
    //                     批評家
    //                     <span className="ml-1 text-[8px]">{"(Kritik)"}</span>
    //                   </span>
    //                 </td>
    //               )}
    //               {user?.role === "partner_jepang" && (
    //                 <td className="whitespace-normal">
    //                   <span>
    //                     提案
    //                     <span className="ml-1 text-[8px]">{"(Saran)"}</span>
    //                   </span>
    //                 </td>
    //               )}
    //               <td></td>
    //             </tr>
    //           </thead>
    //           <tbody>{renderData()}</tbody>
    //         </table>
    //       </div>
    //       <div className="flex justify-end">
    //         <label className="text-sm">Menampilkan 1 - 10</label>
    //         <Pagination
    //           pageLimit={response?.data?.meta?.limit}
    //           totalRecords={response?.data?.meta?.total ?? 0}
    //           currentPage={response?.data?.meta?.page}
    //           onPageChanged={handleChangePage}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          {user?.role === "partner_jepang" ? (
            <span className="flex space-x-1">
              <h5>批判と提案</h5>
              <h6>{"(Kritik & Saran)"}</h6>
            </span>
          ) : (
            <h5>Kritik & Saran</h5>
          )}
          <p>
            Menampilkan kritik & saran untuk dijadikan masukan terhadap admin.
          </p>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="m-max md:w-120">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-7"
              onChange={onChangeSearch}
            />
          </div>

          {user?.role === "partner_jepang" ? (
            <div>
              <BaseButton
                variant="primary2"
                size="md"
                onClick={() => {
                  setIsFormModalOpen(true);
                }}
              >
                Tambah Data
              </BaseButton>
            </div>
          ) : null}
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang" ? (
                      <span>タイトル {"(Judul)"}</span>
                    ) : (
                      "Judul"
                    )}
                  </th>
                  {user?.role === "partner_jepang" ? (
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      批評家 {"(Kritik)"}
                    </th>
                  ) : null}{" "}
                  {user?.role === "partner_jepang" ? (
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      提案 {"(Saran)"}
                    </th>
                  ) : null}{" "}
                  <th className="px-6 w-24 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      {/* DELETE MODAL */}
      {/* <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal dark:text-white">
                Hapus Data!
              </h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl">
              <button
                type="button"
                className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-slate-600 to-slate-300 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </button>
              <button
                type="button"
                className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                onClick={onDeleteData}
              >
                Ya
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper> */}

      {/* FORM MODAL */}
      <ModalWrapper isOpen={isFormModalOpen} setIsOpen={isFormModalOpen}>
        <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0 max-w-180">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">
              {user?.role === "partner_jepang"
                ? "批判と提案 (Kritik & Saran)"
                : "Kritik & Saran"}
            </h5>
          </div>
          <div className="relative flex-auto p-4">
            <form
              className="space-y-2 w-full"
              // onSubmit={onHandleSubmit}
            >
              <BaseInput
                {...register("title")}
                type="text"
                placeholder="タイトル (Judul)"
                className="text-sm"
                readOnly={type === "detail"}
              />

              <textarea
                className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                placeholder="批評家 (Kritik)"
                readOnly={type === "detail"}
                {...register("description")}
              />

              <textarea
                className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                placeholder="提案 (Saran)"
                readOnly={type === "detail"}
                {...register("sugestion")}
              />
            </form>
          </div>
          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
            {type !== "detail" && (
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => {
                  setIsFormModalOpen((prev) => !prev);
                  setType("");
                }}
              >
                Tidak
              </BaseButton>
            )}
            <BaseButton
              variant="primary2"
              size="sm"
              type="submit"
              disabled={createData.isLoading}
            >
              {createData.isLoading ? <CgSpinner /> : "Kirim"}
            </BaseButton>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default CritismSuggestion;
