import DashboardAdmin from "pages/Dashboard";
import ClassAdmin from "pages/Class";
import ClassFormAdmin from "pages/Class/Form";
import Lesson from "pages/Lesson";
import LessonForm from "pages/Lesson/Form";
import MitraManagement from "pages/Mitra";
import MitraManagementForm from "pages/Mitra/Form";
import JapanPartnerManagement from "pages/JapanPartnerManagement";
import JapanPartnerManagementForm from "pages/JapanPartnerManagement/Form";
import Settings from "pages/Settings";
import ArticleSetting from "pages/Settings/Article";
import TopicSetting from "pages/Settings/Topic";
import ProfileBitode from "pages/Settings/ProfileBitode";
import StudentManagement from "pages/StudentManagement";
import StudentManagementForm from "pages/StudentManagement/Form";
import StudentManagementDetail from "pages/StudentManagement/Detail";
import StudentManagementEvaluation from "pages/StudentManagement/Detail/Evaluation";
import PortalAdmin from "pages/Portal";
import Profile from "pages/Profile";
import ProfileMitra from "pages/ProfileMitra";
import ProfilePartnerJapan from "pages/ProfilePartnerJapan";
import Academic from "pages/Academic";
import AcademicEvaluation from "pages/Academic/Evaluation";
import StudentHired from "pages/Hire";

import LandingPage from "pages";
import Registration from "pages/Registration";
import Organization from "pages/Organization";
import AboutUs from "pages/AboutUs";
import Article from "pages/Article";
import ArticleDetail from "pages/Article/Detail";
import ContactUs from "pages/ContactUs";
import Vacancy from "pages/Vacancy";
import DetailVacancy from "pages/Vacancy/Detail";
import TopicDetail from "pages/Topic/Detail";

import ForgotPassword from "pages/ForgotPassword";
import Login from "pages/Login";
import { ROLES } from "helpers";
import MitraStudent from "pages/Mitra/Student";
import CritismSuggestion from "pages/CritismSuggestion";
import Meet from "pages/Meet";

import TrainingOrder from "pages/TrainingOrder";
import TrainingOrderForm from "pages/TrainingOrder/Form";
import TrainingOrderDetail from "pages/TrainingOrder/Detail";

import Administrator from "pages/Administrator";
import AdministratorForm from "pages/Administrator/Form";
import Corporate from "pages/Corporate";

const route = [
  {
    routeType: "public",
    path: "/",
    element: LandingPage,
    exact: true,
    allowRoles: [],
  },
  // {
  //   routeType: "public",
  //   path: "/registration",
  //   element: Registration,
  //   exact: true,
  //   allowRoles: [],
  // },
  // {
  //   routeType: "public",
  //   path: "/organization",
  //   element: Organization,
  //   exact: true,
  //   allowRoles: [],
  // },
  {
    routeType: "public",
    path: "/about-us",
    element: AboutUs,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/article",
    element: Article,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/article/detail",
    element: ArticleDetail,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/contact-us",
    element: ContactUs,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/topic/detail",
    element: TopicDetail,
    exact: true,
    allowRoles: [],
  },
  // {
  //   routeType: "public",
  //   path: "/vacancies",
  //   element: Vacancy,
  //   exact: true,
  //   allowRoles: [],
  // },
  // {
  //   routeType: "public",
  //   path: "/vacancies/detail",
  //   element: DetailVacancy,
  //   exact: true,
  //   allowRoles: [],
  // },
  {
    routeType: "public",
    path: "/login",
    element: Login,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/portal/admin",
    element: PortalAdmin,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "public",
    path: "/forgot-password",
    element: ForgotPassword,
    exact: true,
    allowRoles: [],
  },
  {
    routeType: "private",
    path: "/dashboard",
    element: DashboardAdmin,
    exact: true,
    allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    routeType: "private",
    path: "/class",
    element: ClassAdmin,
    exact: true,
    allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/class/form",
    element: ClassFormAdmin,
    exact: true,
    allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/lesson",
    element: Lesson,
    exact: true,
    allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so"],
  },
  // {
  //   routeType: "private",
  //   path: "/lesson/form",
  //   element: LessonForm,
  //   exact: true,
  //   allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so"],
  // },
  {
    routeType: "private",
    path: "/mitra",
    element: MitraManagement,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/mitra/form",
    element: MitraManagementForm,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/mitra/student",
    element: MitraStudent,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/japan-partner-management",
    element: JapanPartnerManagement,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/japan-partner-management/form",
    element: JapanPartnerManagementForm,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/administrator-user",
    element: Administrator,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/administrator-user/form",
    element: AdministratorForm,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/settings",
    element: Settings,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/setting-articles",
    element: ArticleSetting,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/setting-topics",
    element: TopicSetting,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/profile-bitode",
    element: ProfileBitode,
    exact: true,
    allowRoles: ["lpk_induk"],
  },
  {
    routeType: "private",
    path: "/student-management",
    element: StudentManagement,
    exact: true,
    allowRoles: ["lpk_induk", "lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    routeType: "private",
    path: "/student-management/form",
    element: StudentManagementForm,
    exact: true,
    allowRoles: ["lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    routeType: "private",
    path: "/student-management/detail",
    element: StudentManagementDetail,
    exact: true,
    allowRoles: ["lpk_mitra", "lpk_mitra_so", "partner_jepang", "lpk_induk"],
  },
  {
    routeType: "private",
    path: "/student-management/evaluation",
    element: StudentManagementEvaluation,
    exact: true,
    allowRoles: ["lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/profile",
    element: Profile,
    exact: true,
    allowRoles: ["student"],
  },
  {
    routeType: "private",
    path: "/profile",
    element: ProfileMitra,
    exact: true,
    allowRoles: ["lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/profile",
    element: ProfilePartnerJapan,
    exact: true,
    allowRoles: ["partner_jepang"],
  },
  {
    routeType: "private",
    path: "/academic",
    element: Academic,
    exact: true,
    allowRoles: ["student"],
  },
  {
    routeType: "private",
    path: "/academic/evaluation",
    element: AcademicEvaluation,
    exact: true,
    allowRoles: ["student"],
  },
  {
    routeType: "private",
    path: "/hire",
    element: StudentHired,
    exact: true,
    allowRoles: ["partner_jepang", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/criticism-suggetions",
    element: CritismSuggestion,
    exact: true,
    allowRoles: ["partner_jepang", "lpk_induk", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/training-order",
    element: TrainingOrder,
    exact: true,
    allowRoles: ["partner_jepang", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/training-order/form",
    element: TrainingOrderForm,
    exact: true,
    allowRoles: ["partner_jepang", "lpk_mitra"],
  },
  {
    routeType: "private",
    path: "/training-order/detail",
    element: TrainingOrderDetail,
    exact: true,
    allowRoles: ["partner_jepang", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    routeType: "private",
    path: "/corporate",
    element: Corporate,
    exact: true,
    allowRoles: ["partner_jepang"],
  },
  {
    routeType: "private",
    path: "/meet",
    element: Meet,
    exact: true,
    allowRoles: ["lpk_mitra_so"],
  },
];

export default route;
