import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Select,
  Spinner,
} from "components";
import { useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";
import {
  getList,
  deleteData,
  putData,
  postData,
  getSubjectList,
  getDetail,
} from "services/lesson";
import { MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { CgSpinner } from "react-icons/cg";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import ModalWrapper from "components/molecules/ModalWrapper";
import BaseButton from "components/atoms/BaseButton";
import BaseInput from "components/atoms/BaseInput";

const LessonManagement = () => {
  const modalForm = useRef();
  const navigate = useNavigate();
  const modalDelete = useRef();

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });
  const [subjectOptions, setSubjectOptions] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [subject, setSubject] = useState([]);

  const [search, setSearch] = useState("");

  const [isShowFormModal, setIsShowFormModal] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  const [customValidation, setCustomValidation] = useState({
    subject: {
      value: undefined,
      message: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  useEffect(() => {
    fetchSubjectList();
    return () => {
      onChangeSearch.cancel();
    };
  }, []);

  const customValidate = () => {
    setCustomValidation((cv) => {
      return {
        ...cv,
        subject: {
          value: subject?.length > 0 ? undefined : 1,
          message: subject?.length > 0 ? "" : "Bab Wajib Diisi",
        },
      };
    });
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const onClickDeleteData = (params) => {
    modalDelete?.current?.click();
    setSelectedData(params);
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onDeleteData = () => {
    fetchAPI(
      { service: deleteData, params: selectedData?.id ?? null },
      (success, response) => {
        if (success) {
          fetchList(filter);
          setSelectedData(null);
          setSubject([]);
          setIsOpenModal(false);
          reset({ name: "" });
        }
        modalDelete?.current?.click();
      },
    );
  };

  const handleChangeSubject = (subjects) => {
    setSubject(
      _.sortBy(subjects, (subj) => parseInt(subj.value.split(",")[0])),
    );
    setValue(
      "subject",
      _.sortBy(subjects, (subj) => parseInt(subj.value.split(",")[0])),
    );
  };

  const fetchList = (params) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getList,
        params: { filter: params?.search, page: params?.page },
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const fetchSubjectList = (params) => {
    setSubjectOptions({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getSubjectList,
      },
      (success, response) => {
        if (success) {
          const data = _.map(response?.data, (v) => ({
            value: v.subject.join(","),
            label: v.name,
          }));
          setSubjectOptions({
            ...response,
            isLoading: false,
            isSuccess: true,
            data,
          });
        } else {
          setSubjectOptions({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onDetail = (data) => {
    navigate("/lesson/detail", { state: { data, id: data?.id } });
  };

  const onEdit = (data) => {
    setSubject([]);
    // modalForm?.current?.click();
    setIsShowFormModal(true);
    fetchAPI({ service: getDetail, params: data?.id }, (success, response) => {
      if (success) {
        const data = response?.data;
        setSelectedData(data);
        if (data?.subjects?.length > 0) {
          setSubject(
            _.map(data?.subjects, (v) => ({
              value: v.subject.join(","),
              label: v.name,
            })),
          );
        } else {
          setSubject([]);
        }
        reset({ name: data?.name });
      }
    });
    // navigate("/lesson/form", { state: { data, id: data?.id } });
  };

  const onHandleSubmit = async (event) => {
    event.preventDefault();
    await customValidate();
    handleSubmit(onSubmitLesson)(event);
  };

  const onSubmitLesson = (data) => {
    if (data && subject.length > 0) {
      let params = {
        name: data?.name,
        subject_id: _.map(subject, "value").flat(1).join(","),
      };

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (selectedData?.id) {
        fetchAPI(
          {
            service: putData,
            params: { id: selectedData?.id, payload: params },
          },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses mengubah data!");
              fetchList(filter);
              setSelectedData(null);
              setSubject([]);
              reset({ name: "", subject: [] });
              setIsShowFormModal(false);
              clearErrors("name");
              setCustomValidation((cv) => {
                return {
                  ...cv,
                  subject: {
                    value: undefined,
                    message: "",
                  },
                };
              });
              modalForm?.current?.click();
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      } else {
        fetchAPI({ service: postData, params: params }, (success, response) => {
          if (success) {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isSuccess: true,
              data: response,
            }));
            toast.success("Sukses menambahkan data!");
            fetchList(filter);
            reset({ name: "", subject: [] });
            setIsShowFormModal(false);
            clearErrors("name");
            modalForm?.current?.click();
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              isLoading: false,
              isError: true,
              data: response,
              errorMessage: getErrorMessage(response),
            }));
          }
        });
      }
    }
  };

  const renderModal = () => (
    <>
      <input
        ref={modalDelete}
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hapus Data!</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menghapus data ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              size="sm"
              theme="error"
              onClick={() => {
                modalDelete?.current?.click();
              }}
            />
            <Button
              label={"Ya"}
              size="sm"
              theme="primary"
              onClick={onDeleteData}
            />
          </div>
        </div>
      </div>
    </>
  );

  const renderModalForm = () => (
    <>
      <input
        ref={modalForm}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box overflow-y-visible">
          <form onSubmit={onHandleSubmit}>
            <h3 className="font-bold text-lg">
              {selectedData ? "Edit Mata Pelajaran" : "Tambah Mata Pelajaran"}
            </h3>
            <div className="py-4">
              <InputValidation
                {...register("name", {
                  required: {
                    value: true,
                    message: "Nama Mata Pelajaran wajib diisi",
                  },
                })}
                label="Nama Mata Pelajaran"
                type="text"
                placeholder="Masukan Nama Mata Pelajaran"
                isError={{ value: errors.name, message: errors.name?.message }}
              />
              <Select
                label="Bab"
                onChange={handleChangeSubject}
                value={subject}
                options={subjectOptions?.data}
                closeMenuOnSelect={false}
                placeholder="Masukan Bab"
                isMulti
                isError={customValidation?.subject}
              />
            </div>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                type="button"
                onClick={() => {
                  modalForm?.current?.click();
                  setTimeout(() => {
                    setSelectedData(null);
                    reset({ name: "" });
                    clearErrors("name");
                    setCustomValidation((cv) => {
                      return {
                        ...cv,
                        subject: {
                          value: undefined,
                          message: "",
                        },
                      };
                    });
                  }, 300);
                }}
              />
              <Button
                label={
                  createData?.isLoading ? (
                    <CgSpinner className="animate-spin text-2xl text-primary" />
                  ) : (
                    "Kirim"
                  )
                }
                disabled={createData?.isLoading}
                size="sm"
                theme="primary"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={3}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      <tr>
        <td
          className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
          colSpan={3}
        >
          <h6>No Data</h6>
        </td>
      </tr>;
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.name ?? "-"}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onEdit(data)}
              >
                Edit
              </a>
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => {
                  setSelectedData(data);
                  setIsOpenModal(true);
                }}
              >
                Delete
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={"3"}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td colSpan={"3"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <th>{id}</th>
          <td>{list?.name ?? ""}</td>
          <td>
            <div className="flex items-center justify-end space-x-2">
              {/* <Button
            label={<FiEye className="text-white" />}
            size="sm"
            theme="info"
            onClick={() => onDetail(list)}
          /> */}
              <Button
                label={<MdEdit className="text-white" />}
                size="sm"
                theme="warning"
                onClick={() => onEdit(list)}
              />
              <Button
                label={<IoMdTrash className="text-white" />}
                size="sm"
                theme="error"
                onClick={() => onClickDeleteData(list)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    // <div>
    //   {renderModalForm()}
    //   {renderModal()}
    //   <div className="w-full space-y-4">
    //     <div className="space-y-1">
    //       <Label
    //         text="List Manajemen Mata Pelajaran"
    //         className="font-bold text-2xl"
    //       />
    //       <Label
    //         text="Menampilkan semua mata pelajaran yang akan digunakan di setiap kelas."
    //         className="text-sm text-gray-500"
    //       />
    //     </div>
    //     <div
    //       style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       className="p-9 space-y-8 rounded-xl"
    //     >
    //       <div className="flex items-center space-x-4">
    //         <InputValidation
    //           type="text"
    //           placeholder="Search..."
    //           className="h-8 w-80 text-sm"
    //           onChange={onChangeSearch}
    //         />
    //         <Button
    //           theme="primary"
    //           label="Tambah Data"
    //           size="sm"
    //           onClick={() => {
    //             modalForm?.current?.click();
    //             reset({ name: "" });
    //             setSubject([]);
    //           }}
    //         />
    //       </div>
    //       <div className="overflow-x-auto border rounded-xl overflow-hidden">
    //         <table className="table w-full">
    //           <thead>
    //             <tr>
    //               <th className="w-16"></th>
    //               <th>Nama Mata Pelajaran</th>
    //               <th></th>
    //             </tr>
    //           </thead>
    //           <tbody>{renderData()}</tbody>
    //         </table>
    //       </div>
    //       <div className="flex justify-end mx-4">
    //         <label className="text-sm">Menampilkan 1 - 10</label>
    //         <Pagination
    //           pageLimit={response?.data?.meta?.limit}
    //           totalRecords={response?.data?.meta?.total ?? 0}
    //           currentPage={response?.data?.meta?.page}
    //           onPageChanged={handleChangePage}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>List Manajemen Mata Pelajaran</h5>
          <p>
            Menampilkan semua mata pelajaran yang akan digunakan di setiap
            kelas.
          </p>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="m-max md:w-120">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-7"
              onChange={onChangeSearch}
            />
          </div>

          <div>
            <BaseButton
              variant="primary2"
              size="md"
              onClick={() => {
                setIsShowFormModal(true);
                reset({ name: "" });
                setSubject([]);
              }}
            >
              Tambah Data
            </BaseButton>
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Nama mata pelajaran
                  </th>

                  <th className="px-6 w-24 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      {/* DELETE MODAL */}
      <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                type="button"
                variant="base"
                size="sm"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      {/* FORM MODAL */}
      <ModalWrapper isOpen={isShowFormModal} setIsOpen={setIsShowFormModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0 ">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">
                {selectedData ? "Edit Mata Pelajaran" : "Tambah Mata Pelajaran"}
              </h5>
            </div>
            <div className="relative flex-auto p-4">
              <form className="space-y-2 w-100">
                <BaseInput
                  label="Nama mata pelajaran"
                  placeholder="Masukan nama mata pelajaran"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Nama Mata Pelajaran wajib diisi",
                    },
                  })}
                  error={errors?.name?.message}
                />

                <Select
                  label="Bab"
                  onChange={handleChangeSubject}
                  value={subject}
                  options={subjectOptions?.data}
                  closeMenuOnSelect={false}
                  placeholder="Masukan Bab"
                  isMulti
                  isError={customValidation?.subject}
                />
              </form>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                type="button"
                size="sm"
                onClick={() => setIsShowFormModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onHandleSubmit}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default LessonManagement;
