import React, { useRef, useState } from "react";
import classNames from "classnames";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { FiDownloadCloud, FiFile, FiUploadCloud } from "react-icons/fi";
import DummyProfile from "assets/img/dummy-profile-pic.png";
import BaseButton from "components/atoms/BaseButton";

// const UploadFile = ({ name, label, isRequired, placeholder }) => {
const UploadAvatar = ({
  name,
  label,
  required,
  readOnly = false,
  placeholder,
  onValueChanged,
  ...props
}) => {
  const uploadRef = useRef();
  const { control } = useFormContext();

  const [previewImage, setPreviewImage] = useState();

  const handleOnChange = (event, onChange) => {
    const file = event?.target?.files[0];
    let params = { file, src: URL.createObjectURL(file) };
    onChange(params);
    if (onValueChanged && typeof onValueChanged === "function") {
      onValueChanged(params, (data) => {
        params = {
          ...params,
          result: data,
        };
        onChange(params);
      });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className="flex flex-col items-center mx-10">
          <div className="w-40 h-40 rounded-full overflow-clip flex mb-3">
            <img
              alt="avatar-user"
              src={value?.src ? value?.src : DummyProfile}
              className="w-40 h-40 object-contain"
            />
          </div>

          <input
            ref={uploadRef}
            autoComplete="none"
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            type="file"
            onChange={(event) => handleOnChange(event, onChange)}
            onClick={(event) => {
              event.target.value = null;
            }}
            className="opacity-0 hidden"
          />
          {!readOnly && (
            <BaseButton
              variant="outline"
              type="button"
              onClick={() => uploadRef.current.click()}
            >
              Pilih Foto
            </BaseButton>
          )}
          {invalid && (
            <span className="text-red-400 text-xs mt-2">{error?.message}</span>
          )}
        </div>
      )}
    />
  );
};

export default UploadAvatar;
