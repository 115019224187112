import React from "react";
import { CgSpinner } from "react-icons/cg";
import cn from "clsx";

const Spinner = ({ className = "" }) => {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <CgSpinner className="animate-spin" />
    </div>
  );
};

export default Spinner;
