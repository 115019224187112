import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import cn from "clsx";
import ReactSelect from "react-select";

const SelectInput = React.forwardRef(
  (
    {
      label,
      type,
      suffixIcon,
      size = "lg",
      className = "",
      name,
      inputClassName,
      isClearable = true,
      onValueChanged,
      ...props
    },
    ref,
  ) => {
    const { control } = useFormContext();

    const handleChange = (value, onChange) => {
      onChange(value);
      onValueChanged && onValueChanged(value);
    };

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => (
          <div className="flex flex-col items-start w-full mb-4">
            {label ? (
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                {label}
              </label>
            ) : null}

            <ReactSelect
              {...props}
              onChange={(value) => handleChange(value, onChange)}
              value={value}
              isClearable={isClearable}
              className={`px-3 py-1 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none text-start ${
                invalid ? "border-[#ea0606]" : "border-gray-300"
              } flex justify-center items-start px-1 ${inputClassName}`}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: state.isFocused ? 0 : 0,
                  boxShadow: state.isFocused ? 0 : 0,
                  "&:hover": {
                    border: state.isFocused ? 0 : 0,
                  },
                  cursor: "pointer",
                  width: "100%",
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  zIndex: 100,
                }),
              }}
            />

            {invalid ? (
              <small className="mt-1 text-xs text-red-600">
                {error?.message}
              </small>
            ) : null}
          </div>
        )}
      />
    );
  },
);

export default SelectInput;
