import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Button, Label } from "components";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  downloadCvStudent,
  getDetailStudent,
  getEvaluation,
} from "services/user";
import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { FaDownload } from "react-icons/fa";
import BaseButton from "components/atoms/BaseButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

const Graph = () => {
  const chartRef = useRef();
  const cvRef = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const { user, token } = useSelector(({ auth }) => auth);

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [cv, setCv] = useState(null);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  const options = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (yDatapoint) => {
              let percentageHandler = yDatapoint?.label === "Total" ? "%" : "";
              let dataHandler =
                yDatapoint?.label === "Total"
                  ? (parseFloat(yDatapoint.raw) / 10).toFixed(2)
                  : yDatapoint.raw;
              return dataHandler + percentageHandler;
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        title: {
          display: false,
        },
      },
    };
  }, []);

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: getDetailStudent, params: { id } },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setDetail((prevState) => ({
            ...prevState,
            isLoading: false,
            data: { ...prevState?.data, ...data },
          }));
          fetchEvaluation(data);
        }
      },
    );
  };

  const fetchEvaluation = (data) => {
    const params = { class_id: data?.class?.id };
    fetchAPI(
      { service: getEvaluation, params: { id, params } },
      (success, response) => {
        let evaluationScore = response?.data ?? [];
        let mappingData = _.map(data?.class?.leassons, (leasson) => ({
          id: leasson?.id,
          name: leasson?.name,
          subjects_mitra: _.map(leasson?.subjects, (subject) => ({
            ...subject,
            attendence: null,
            attitude: null,
            ontime: null,
            punctuality: null,
            score: "",
            total_question: "",
          })),
        }));
        mappingData = _.filter(
          mappingData,
          (eval_score) => eval_score?.subjects_mitra?.length > 0,
        );
        if (success) {
          let paramsEval = { mitra: [], student: [] };
          paramsEval.mitra = _.map(mappingData, (map_data) => {
            const evalData = _.find(
              evaluationScore,
              (eval_data) => eval_data?.id === map_data.id,
            );
            if (evalData?.subjects_mitra?.length > 0) {
              return {
                ...evalData,
                subjects_mitra: _.map(map_data?.subjects_mitra, (subject) => {
                  const subjectMitra = _.find(evalData?.subjects_mitra, {
                    name: subject?.name,
                  });
                  if (subjectMitra) {
                    return { ...subjectMitra, id: subjectMitra?.leasson_id };
                  }
                  return subject;
                }),
              };
            }
            return map_data;
          });

          const mitra = _.map(paramsEval.mitra, (mitra) => {
            const grouping = _.groupBy(mitra.subjects_mitra, "name");
            return { key: Object.keys(grouping), data: mitra };
          });

          const uniq = _.uniq(_.map(mitra, "key").flat(1));

          const result = _.map(uniq, (key) => {
            const sumScore = _.sumBy(_.map(mitra, "data"), (data) => {
              const grouping = _.groupBy(data?.subjects_mitra, "name");
              return _.first(grouping[key])?.score
                ? parseFloat(_.first(grouping[key]).score)
                : 0;
            });
            const sumQuestion = _.sumBy(_.map(mitra, "data"), (data) => {
              const grouping = _.groupBy(data?.subjects_mitra, "name");
              return _.first(grouping[key])?.total_question
                ? parseFloat(_.first(grouping[key]).total_question)
                : 0;
            });
            return {
              sumScore,
              sumQuestion,
              key,
              percentage: parseFloat((sumScore / sumQuestion) * 100),
            };
          });

          setChartData({
            labels: _.map(paramsEval.mitra, "name").concat("Total"),
            datasets: _.sortBy(
              _.uniqBy(
                _.map(paramsEval.mitra, (evalObj) =>
                  _.map(evalObj?.subjects_mitra, (subject) => ({
                    label: subject?.name,
                    data: _.map(
                      paramsEval.mitra,
                      (evalObj) =>
                        _.find(evalObj?.subjects_mitra, {
                          name: subject?.name,
                        })?.score ?? 0,
                    ).concat(_.find(result, { key: subject?.name }).percentage),
                  })),
                ).flat(1),
                "label",
              ),
              (subj) => {
                return parseInt(
                  subj.label.split(" - ")[0]?.replace("BAB ", ""),
                );
              },
            ),
          });
          setDetail((prevState) => ({
            ...prevState,
            isLoading: false,
            data: { ...prevState?.data, evaluation: paramsEval },
          }));
        }
      },
    );
  };

  const handleCvDownload = () => {
    fetchAPI(
      { service: downloadCvStudent, params: token },
      (success, response) => {
        const file = new File([response], "cv-siswa.docx", {
          lastModified: Date.now(),
        });
        setCv(file);
        setTimeout(async () => {
          cvRef?.current?.click();
        }, 50);
      },
    );
  };

  const onDetailEvaluation = ({ lesson, subject }) => {
    navigate("/student-management/evaluation", {
      state: {
        student_id: id,
        class: detail?.data?.class,
        lesson,
        subject,
      },
    });
  };

  const renderAcademicBody = (type) => {
    if (
      !detail?.data?.evaluation?.[type] ||
      detail?.data?.evaluation?.[type]?.length <= 0
    ) {
      return (
        <tr>
          {/* <td colSpan={"6"}> */}
          <td colSpan={"2"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(detail?.data?.evaluation?.[type], (lesson, key) => (
      <tr key={key}>
        <td>
          <small>{lesson?.name}</small>
        </td>
        <td>
          <div className="flex items-center justify-end space-x-2">
            {user?.role !== "partner_jepang" &&
              user?.role !== "lpk_induk" &&
              type === "mitra" && (
                <div
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onDetailEvaluation({ lesson })}
                >
                  Edit
                </div>
                // <Button
                //   label={<MdEdit className="text-white" />}
                //   size="sm"
                //   theme="warning"
                //   onClick={() => onDetailEvaluation({ lesson })}
                // />
              )}
          </div>
        </td>
      </tr>
    ));

    // return _.map(detail?.data?.evaluation?.[type], (lesson, key) => (
    //   <Fragment key={key}>
    //     {_.map(lesson?.subjects, (subject, key) => (
    //       <tr key={key}>
    //         {key === 0 && (
    //           <td rowSpan={lesson?.subjects?.length}>{lesson?.name}</td>
    //         )}
    //         <td>
    //           {subject?.attendence ? subject?.attendence : "Belum ada nilai"}
    //         </td>
    //         <td>{subject?.ontime ? subject?.ontime : "Belum ada nilai"}</td>
    //         <td>{subject?.attitude ? subject?.attitude : "Belum ada nilai"}</td>
    //         <td>
    //           {subject?.punctuality ? subject?.punctuality : "Belum ada nilai"}
    //         </td>
    //         <td>
    //           <div className="flex items-center justify-end space-x-2">
    //             {user?.role !== "partner_jepang" && type === "mitra" && (
    //               <Button
    //                 label={<MdEdit className="text-white" />}
    //                 size="sm"
    //                 theme="warning"
    //                 onClick={() => onDetailEvaluation({ lesson, subject })}
    //               />
    //             )}
    //           </div>
    //         </td>
    //       </tr>
    //     ))}
    //   </Fragment>
    // ));
  };

  return (
    // <div className="space-y-4">
    //   <div className="space-y-4">
    //     <Label
    //       text={detail?.data?.name ?? "-"}
    //       className="text-2xl font-bold"
    //     />
    //     <div className="flex justify-between">
    //       <table>
    //         <tr>
    //           <td className="w-56">
    //             {user?.role === "partner_jepang" ? (
    //               <span className="flex space-x-1 items-end">
    //                 <Label text="メール" />
    //                 <span className="text-[10px] font-medium text-gray-500">
    //                   {"(Email)"}
    //                 </span>
    //               </span>
    //             ) : (
    //               <Label text="Email" />
    //             )}
    //           </td>
    //           <td className="w-4">:</td>
    //           <td>
    //             <Label text={detail?.data?.email ?? "-"} />
    //           </td>
    //         </tr>
    //         <tr>
    //           <td className="w-56">
    //             {user?.role === "partner_jepang" ? (
    //               <span className="flex space-x-1 items-end">
    //                 <Label text="生年月日" />
    //                 <span className="text-[10px] font-medium text-gray-500">
    //                   {"(Tempat Tanggal Lahir)"}
    //                 </span>
    //               </span>
    //             ) : (
    //               <Label text="Tempat Tanggal Lahir" />
    //             )}
    //           </td>
    //           <td className="w-4">:</td>
    //           <td>
    //             <Label text={detail?.data?.ttl ?? "-"} />
    //           </td>
    //         </tr>
    //         <tr>
    //           <td className="w-56">
    //             {user?.role === "partner_jepang" ? (
    //               <span className="flex space-x-1 items-end">
    //                 <Label text="学歴" />
    //                 <span className="text-[10px] font-medium text-gray-500">
    //                   {"(Riwayat Pendidikan)"}
    //                 </span>
    //               </span>
    //             ) : (
    //               <Label text="Riwayat Pendidikan" />
    //             )}
    //           </td>
    //           <td className="w-4">:</td>
    //           <td>
    //             <Label
    //               text={_.map(detail?.data?.education_history, "name")?.join(
    //                 ", ",
    //               )}
    //             />
    //           </td>
    //         </tr>
    //       </table>
    //       <Button
    //         label={
    //           <div className="flex items-center space-x-2 text-xs text-white">
    //             <FaDownload />
    //             <div>Unduh CV Siswa</div>
    //           </div>
    //         }
    //         size="sm"
    //         onClick={handleCvDownload}
    //         theme="success"
    //       />
    //     </div>
    //   </div>
    //   {!detail?.isLoading && (
    //     <div className="space-y-4">
    //       <div>
    //         <div className="py-4 px-2 flex items-center justify-between">
    //           {user?.role === "partner_jepang" ? (
    //             <span className="flex space-x-1 items-end">
    //               <Label text="マーク" className="text-xl font-medium" />
    //               <span className="text-xs font-medium text-gray-500">
    //                 {"(Nilai)"}
    //               </span>
    //             </span>
    //           ) : (
    //             <Label text="Nilai" className="text-xl font-medium" />
    //           )}
    //         </div>
    //         <div className="border rounded overflow-hidden">
    //           <table className="table table-compact w-full">
    //             <thead>
    //               <tr>
    //                 <td className="font-bold">
    //                   {user?.role === "partner_jepang" ? (
    //                     <span>
    //                       科目
    //                       <span className="ml-1 text-[8px]">
    //                         {"(Mata Pelajaran)"}
    //                       </span>
    //                     </span>
    //                   ) : (
    //                     "Mata Pelajaran"
    //                   )}
    //                 </td>
    //                 {/* <td className="font-bold">Kehadiran</td>
    //                 <td className="font-bold">Ketepatan Waktu</td>
    //                 <td className="font-bold">Sopan Santun</td>
    //                 <td className="font-bold">Disiplin Waktu</td> */}
    //                 <td className="font-bold"></td>
    //               </tr>
    //             </thead>
    //             <tbody>{renderAcademicBody("mitra")}</tbody>
    //           </table>
    //         </div>
    //         {chartData?.labels?.length > 0 && (
    //           <Bar options={options} data={chartData} height={80} />
    //         )}
    //       </div>
    //       {/* <div>
    //         <div className="py-4 px-2 flex items-center justify-between">
    //           <Label text="Nilai dari Siswa" className="text-xl font-medium" />
    //         </div>
    //         <div className="border rounded overflow-hidden">
    //           <table className="table table-compact w-full">
    //             <thead>
    //               <tr>
    //                 <th>Mata Pelajaran</th>
    //                 <th></th>
    //               </tr>
    //             </thead>
    //             <tbody>{renderAcademicBody("student")}</tbody>
    //           </table>
    //         </div>
    //       </div> */}
    //     </div>
    //   )}
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        {/* <div className="p-6 pb-0 mb-2 bg-white rounded-t-2xl">
          <h5>{detail?.data?.name ?? "-"}</h5>
          <p>Menampilkan semua data mitra.</p>
        </div> */}
        <div className="flex-auto pt-0 pb-2 mb-2 p-6">
          {/* <table>
            <tr>
              <td className="w-56">
                {user?.role === "partner_jepang" ? (
                  <span className="flex space-x-1 items-end">
                    <Label text="メール" />
                    <span className="text-[10px] font-medium text-gray-500">
                      {"(Email)"}
                    </span>
                  </span>
                ) : (
                  <Label text="Email" />
                )}
              </td>
              <td className="w-4">:</td>
              <td>
                <Label text={detail?.data?.email ?? "-"} />
              </td>
            </tr>
            <tr>
              <td className="w-56">
                {user?.role === "partner_jepang" ? (
                  <span className="flex space-x-1 items-end">
                    <Label text="生年月日" />
                    <span className="text-[10px] font-medium text-gray-500">
                      {"(Tempat Tanggal Lahir)"}
                    </span>
                  </span>
                ) : (
                  <Label text="Tempat Tanggal Lahir" />
                )}
              </td>
              <td className="w-4">:</td>
              <td>
                <Label text={detail?.data?.ttl ?? "-"} />
              </td>
            </tr>
            <tr>
              <td className="w-56">
                {user?.role === "partner_jepang" ? (
                  <span className="flex space-x-1 items-end">
                    <Label text="学歴" />
                    <span className="text-[10px] font-medium text-gray-500">
                      {"(Riwayat Pendidikan)"}
                    </span>
                  </span>
                ) : (
                  <Label text="Riwayat Pendidikan" />
                )}
              </td>
              <td className="w-4">:</td>
              <td>
                <Label
                  text={_.map(
                    detail?.data?.education_history,
                    (education) =>
                      `${education?.education?.label} - ${education?.name}`,
                  )?.join(", ")}
                />
              </td>
            </tr>
          </table> */}
          <Button
            label={
              <div className="flex items-center space-x-2 text-xs text-white">
                <FaDownload />
                <div>Unduh CV Siswa</div>
              </div>
            }
            size="sm"
            onClick={handleCvDownload}
            theme="success"
          ></Button>
          {!detail?.isLoading && (
            <div className="space-y-4">
              <div>
                <div className="py-4 flex items-center justify-between">
                  {user?.role === "partner_jepang" ? (
                    <span className="flex space-x-1 items-end">
                      <Label text="マーク" className="text-xl font-medium" />
                      <span className="text-xs font-medium text-gray-500">
                        {"(Nilai)"}
                      </span>
                    </span>
                  ) : (
                    <Label text="Nilai" className="text-xl font-medium" />
                  )}
                </div>
                <div className="border rounded overflow-hidden mb-4">
                  <table className="table w-full px-2">
                    <thead>
                      <tr>
                        <td className="font-bold">
                          {user?.role === "partner_jepang" ? (
                            // <span>
                            //   科目
                            //   <span className="ml-1 text-[8px]">
                            //     {"(Mata Pelajaran)"}
                            //   </span>
                            // </span>
                            <>
                              <h6>科目</h6>
                              <p>(Mata Pelajaran)</p>
                            </>
                          ) : (
                            <h6>Mata Pelajaran</h6>
                          )}
                        </td>
                        {/* <td className="font-bold">Kehadiran</td>
                    <td className="font-bold">Ketepatan Waktu</td>
                    <td className="font-bold">Sopan Santun</td>
                    <td className="font-bold">Disiplin Waktu</td> */}
                        <td className="font-bold"></td>
                      </tr>
                    </thead>
                    <tbody>{renderAcademicBody("mitra")}</tbody>
                  </table>
                </div>
                {chartData?.labels?.length > 0 && (
                  <Bar options={options} data={chartData} height={80} />
                )}
              </div>
              {/* <div>
            <div className="py-4 px-2 flex items-center justify-between">
              <Label text="Nilai dari Siswa" className="text-xl font-medium" />
            </div>
            <div className="border rounded overflow-hidden">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th>Mata Pelajaran</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderAcademicBody("student")}</tbody>
              </table>
            </div>
          </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Graph;
