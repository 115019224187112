import React, { useRef } from "react";
import { DatepickerInput, Input } from "components";
import ReactDatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import { Controller, useFormContext } from "react-hook-form";
import cn from "clsx";

const Datepicker = React.forwardRef(
  (
    {
      isError = {
        value: false,
        message: "",
      },
      label = "",
      className,
      type = "default",
      selected,
      dateFormat,
      withPortal,
      showYearPicker,
      placeholder = "Pilih Tanggal",
      showTimeSelect,
      showTimeSelectOnly,
      timeIntervals,
      timeCaption,
      minDate,
      maxDate,
      name,
      disabled,
      showTimePicker,
      ...props
    },
    ref,
  ) => {
    const datePickerRef = useRef();
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => (
          <div ref={ref} className="form-control w-full flex flex-col mb-4">
            {label && (
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs text-left">
                {label}
              </label>
            )}
            {(showYearPicker && type === "year") ||
            (showTimePicker && type === "time") ? (
              <ReactDatePicker
                selected={value?.toString() === "Invalid Date" ? null : value}
                value={value}
                onChange={onChange}
                dateFormat={dateFormat}
                withPortal={withPortal}
                placeholderText={placeholder}
                showYearPicker={showYearPicker}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                className={cn(
                  "focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none px-3 py-3",
                  invalid ? "border-red-600" : "border-gray-300",
                )}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
                customInput={
                  <CustomInput isError={isError} disabled={disabled} />
                }
                minDate={minDate}
                maxDate={maxDate}
                name={name}
                wrapperClassName=""
                disabled={disabled}
              />
            ) : (
              <div className="w-full">
                <input
                  ref={datePickerRef}
                  type="date"
                  className={cn(
                    "text-sm leading-5.6 h-12 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed p-2",
                    className,
                    invalid ? "border-red-600" : "border-gray-300",
                  )}
                  value={value}
                  min={minDate}
                  max={maxDate}
                  onChange={(event) => onChange(event?.target?.value)}
                  disabled={disabled}
                />
                <span onClick={() => datePickerRef?.current?.showPicker()}>
                  <i
                    className={`fas fa-calendar`}
                    style={{ fontSize: 14, color: "#676767" }}
                  />
                </span>
              </div>
            )}
            {/* </div> */}
            {invalid ? (
              <small className="text-xs text-red-600 mt-1">
                {error?.message}
              </small>
            ) : null}
          </div>
        )}
      />
    );
  },
);

const CustomInput = ({
  className,
  isError,
  onChange,
  placeholder,
  value,
  isSecure,
  id,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={`${className ?? ""} relative input w-full ${
        isError?.value ? "input-error" : "input-bordered"
      } wrapper-input-react-datepicker__input-container`}
    >
      <div className="flex h-full">
        <input
          className="h-full w-full outline-0"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          isSecure={isSecure}
          id={id}
          onClick={onClick}
          disabled={disabled}
          readOnly={true}
        />
        <div className="flex items-center justify-center right-0 pl-2">
          <AiOutlineCalendar color="#808C92" size={16} />
        </div>
      </div>
    </div>
  );
};

export default Datepicker;
