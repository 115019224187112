import React, { useState } from "react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { FiDownloadCloud, FiFile, FiUploadCloud } from "react-icons/fi";

// const UploadFile = ({ name, label, isRequired, placeholder }) => {
const UploadFile = ({
  name,
  label,
  required,
  readOnly,
  placeholder,
  ...props
}) => {
  const { control } = useFormContext();

  const [previewImage, setPreviewImage] = useState();

  const placeholderValue = placeholder || "Pilih Dokumen (Max 2MB)";

  const handleSelectImage = async (event, key) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader?.result);
    });
    fileReader.readAsDataURL(file);
    // if (uploadFile && key) {
    //   try {
    //     let target = { file: file, controllerName };
    //     await uploadFile.mutateAsync(target);
    //   } catch (error) {}
    // }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <div>
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>{label}</span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <div
            className={classNames(
              "flex flex-col justify-center w-full border-gray-200 border-[1px] rounded-lg mt-2",
              readOnly ? "p-4 items-start" : "p-6 items-center",
            )}
          >
            {readOnly ? (
              <div className="flex flex-row gap-4 justify-between w-full">
                <div className="bg-primary-50 rounded-full p-1 self-start">
                  <div className="bg-primary-100 rounded-full p-1">
                    <FiFile className="text-primary-600 cursor-pointer" />
                  </div>
                </div>
                <div className="flex flex-col gap-1 flex-1">
                  <span className="text-sm font-semibold">
                    348674 - Santi.pdf
                  </span>
                  <span className="text-sm">200KB</span>
                  <span className="text-primary-600 mt-2 cursor-pointer text-sm font-semibold">
                    Ganti Dokumen
                  </span>
                </div>
                <FiDownloadCloud
                  className="text-gray-600 cursor-pointer"
                  size={20}
                />
              </div>
            ) : (
              <>
                <div className="bg-gray-50 p-2 rounded-full">
                  <div className="bg-gray-100 p-2 rounded-full">
                    <FiUploadCloud
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                    />
                  </div>
                </div>
                <label className="text-center cursor-pointer mt-2">
                  <p>
                    <span className="text-primary-600 font-bold">
                      Klik untuk upload
                    </span>{" "}
                    atau seret dan lepas kesini
                  </p>
                  <p className="text-sm">PNG, JPG, PDF, Docx up to 10MB</p>
                  <input
                    {...props}
                    type="file"
                    className="hidden"
                    onChange={(e) => onChange(e.target.files)}
                  />
                </label>
                {value && <p className="ml-3">{value[0].name}</p>}
              </>
            )}
          </div>
        </div>
      )}
    />
  );

  // return (
  //   <Controller
  //     name={name}
  //     control={control}
  //     defaultValue=""
  //     render={({
  //       field: { onChange, value, onBlur },
  //       fieldState: { invalid, error },
  //     }) => (
  //       <div className="form-group">
  //         {label && (
  //           <label>
  //             <span className={`mr-1`}>{label}</span>
  //             {isRequired && <span style={{ color: "#F04438" }}>{"*"}</span>}
  //           </label>
  //         )}
  //         <div className="custom-file">
  //           <input
  //             type="file"
  //             className={`custom-file-input ${invalid && "is-invalid"}`}
  //             onClick={(e) => (e.target.value = null)}
  //             onChange={(e) => {
  //               handleSelectImage(e, "upload");
  //               onChange(e.target.files[0]);
  //             }}
  //           />
  //           <label
  //             className="custom-file-label"
  //             htmlFor="customFile"
  //             id="customFileLabel"
  //           >
  //             {value ? value.name : placeholderValue}
  //           </label>
  //         </div>
  //         {invalid && (
  //           <div className="mt-1" style={{ color: "#F04438", fontSize: 12 }}>
  //             {error?.message}
  //           </div>
  //         )}
  //       </div>
  //     )}
  //   />
  // );
};

export default UploadFile;
