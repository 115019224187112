import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Select,
  Spinner,
} from "components";
import { useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { deleteData, getList } from "services/user";
import { MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CgSpinner } from "react-icons/cg";
import moment from "moment";
import { Link } from "react-router-dom";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";

const JapanPartnerManagement = () => {
  const navigate = useNavigate();
  const modalDelete = useRef();

  const { roles } = useSelector(({ core }) => core);

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });

  const [search, setSearch] = useState("");

  const [selectedData, setSelectedData] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  const fetchList = ({ role, page, search }) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      { service: getList, params: { filter: "partner_jepang", page, search } },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const handleChangeRole = (role) => {
    setFilter((prevState) => ({ ...prevState, role }));
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const onDetail = (params) => {
    navigate("/japan-partner-management/form", {
      state: { id: params?.id ?? "" },
    });
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onClickDeleteData = (params) => {
    modalDelete?.current?.click();
    setSelectedData(params);
  };

  const onDeleteData = () => {
    fetchAPI(
      { service: deleteData, params: selectedData?.id ?? null },
      (success, response) => {
        if (success) {
          fetchList(filter);
          setSelectedData(null);
          setIsOpenModal(false);
        }
        modalDelete?.current?.click();
      },
    );
  };

  const renderModal = () => (
    <>
      <input
        ref={modalDelete}
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hapus Data!</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menghapus data ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              size="sm"
              theme="error"
              onClick={() => modalDelete?.current?.click()}
            />
            <Button
              label={"Ya"}
              size="sm"
              theme="primary"
              onClick={onDeleteData}
            />
          </div>
        </div>
      </div>
    </>
  );

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={7}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
            colSpan={7}
          >
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.name ?? "-"}
              </h6>
            </div>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.email ?? "-"}
            </p>
          </td>
          <td className="px-6 leading-normal align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.website?.match(
                /^((ftp|http|https):\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}|(\d{1,3}\.){3}\d{1,3})(:[0-9]{1,5})?(\/([^\s]*))?$/g,
              ) ? (
                <a href={data?.website} className="text-blue-500 underline">
                  {data?.website}
                </a>
              ) : (
                data?.website
              )}
            </p>
          </td>
          <td className="px-6 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.leader ?? "-"}
            </p>
          </td>
          <td className="px-6 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.collab_at
                ? moment(data?.collab_at).format("DD MMMM YYYY")
                : "-"}
            </p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Edit
              </a>
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => {
                  setSelectedData(data);
                  setIsOpenModal(true);
                }}
              >
                Delete
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={"7"}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td colSpan={"7"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <th>{id}</th>
          <td>{list?.name ?? "-"}</td>
          <td>{list?.email ?? "-"}</td>
          {/* <td>{list?.website ?? "-"}</td> */}
          <td>
            {list?.website?.match(
              /^((ftp|http|https):\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}|(\d{1,3}\.){3}\d{1,3})(:[0-9]{1,5})?(\/([^\s]*))?$/g,
            ) ? (
              <a href={list?.website} className="text-blue-500 underline">
                {list?.website}
              </a>
            ) : (
              list?.website
            )}
          </td>
          <td>{list?.leader ?? "-"}</td>
          <td>
            {list?.collab_at
              ? moment(list?.collab_at).format("DD MMMM YYYY")
              : "-"}
          </td>
          <td>
            <div className="flex items-center justify-end space-x-2">
              <Button
                label={<MdEdit className="text-white" />}
                size="sm"
                theme="warning"
                onClick={() => onDetail(list)}
              />
              <Button
                label={<IoMdTrash className="text-white" />}
                size="sm"
                theme="error"
                onClick={() => onClickDeleteData(list)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  // const renderData = () => {
  //   return _.map(Array(5), (list, key) => (
  //     <tr key={key}>
  //       <td>{"John Doe"}</td>
  //       <td>{"JohnDoe@gmail.com"}</td>
  //       <td>{"08123456789"}</td>
  //       <td>{"Jason"}</td>
  //       <td>
  //         <div className="flex items-center justify-end space-x-2">
  //           <Button
  //             label={<MdEdit className="text-white" />}
  //             size="sm"
  //             theme="warning"
  //             onClick={() => onDetail(list)}
  //           />
  //           <Button
  //             label={<IoMdTrash className="text-white" />}
  //             size="sm"
  //             theme="error"
  //             onClick={() => onClickDeleteData(list)}
  //           />
  //         </div>
  //       </td>
  //     </tr>
  //   ));
  // };

  return (
    // <div>
    //   {renderModal()}
    //   <div className="w-full space-y-4">
    //     <div className="space-y-1">
    //       <Label text="List Partner Jepang" className="font-bold text-2xl" />
    //       <Label
    //         text="Menampilkan semua data partner jepang."
    //         className="text-sm text-gray-500"
    //       />
    //     </div>
    //     <div
    //       style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       className="p-9 space-y-8 rounded-xl"
    //     >
    //       <div className="flex items-center space-x-4">
    //         <InputValidation
    //           type="text"
    //           placeholder="Search..."
    //           className="h-8 w-80 text-sm"
    //           onChange={onChangeSearch}
    //         />
    //         <Button
    //           theme="primary"
    //           label="Tambah Data"
    //           size="sm"
    //           onClick={() => navigate("/japan-partner-management/form")}
    //         />
    //       </div>
    //       <div className="overflow-x-auto border rounded-xl overflow-hidden">
    //         <table className="table w-full">
    //           <thead>
    //             <tr>
    //               <th></th>
    //               <td>Nama</td>
    //               <td>Email</td>
    //               <td>Website</td>
    //               <td>Ketua</td>
    //               <td>Tanggal Kerjasama</td>
    //               <td></td>
    //             </tr>
    //           </thead>
    //           <tbody>{renderData()}</tbody>
    //         </table>
    //       </div>
    //       <div className="flex justify-end">
    //         <label className="text-sm">Menampilkan 1 - 10</label>
    //         <Pagination
    //           pageLimit={response?.data?.meta?.limit}
    //           totalRecords={response?.data?.meta?.total ?? 0}
    //           currentPage={response?.data?.meta?.page}
    //           onPageChanged={handleChangePage}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>List Partner Jepang</h5>
          <p>Menampilkan semua data partner jepang.</p>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="w-max md:w-120">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-7"
              onChange={onChangeSearch}
            />
          </div>

          <div>
            <BaseButton
              variant="primary2"
              size="md"
              onClick={() => navigate("/japan-partner-management/form")}
            >
              Tambah Data
            </BaseButton>
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Nama
                  </th>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Email
                  </th>
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Website
                  </th>
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Ketua
                  </th>
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Tanggal Kerjasama
                  </th>
                  <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                type="button"
                variant="base"
                size="sm"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                type="submit"
                variant="primary2"
                size="sm"
                onClick={onDeleteData}
              >
                Ya
              </BaseButton>
              {/* <button
                type="button"
                className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-slate-600 to-slate-300 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </button>
              <button
                type="submit"
                className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                onClick={onDeleteData}
              >
                Ya
              </button> */}
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default JapanPartnerManagement;
