import React, { useEffect, useState } from "react";
import SelectInput from ".";
import { useQuery } from "react-query";
import { getHobies } from "services/core";
import { capitalize, debounce } from "lodash";

const SelectHobby = ({ otherOptions = [], ...props }) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: hobbyOptions, isFetching } = useQuery(
    ["hobbies", { page, limit, search }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getHobies({ ...params }, signal);
      },
    },
  );

  useEffect(() => {
    if (!isFetching && hobbyOptions?.data?.data) {
      setOptions(
        loadMore
          ? options.concat(
              hobbyOptions?.data?.data?.map((data) => ({
                value: data?.h_id,
                label: capitalize(data?.h_name),
              })),
            )
          : hobbyOptions?.data?.data?.map((data) => ({
              value: data?.h_id,
              label: capitalize(data?.h_name),
            })),
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (options?.length < hobbyOptions?.data?.meta?.total) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <SelectInput
      {...props}
      isLoading={isFetching}
      options={[...options, ...otherOptions]}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectHobby;
