import axios from "axios";
import Service from "config";

export const getList = (params) => {
  return axios.get(
    `${Service.API}/admin/partner/hire?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
      // }&filter=${params?.filter ?? ""}&passed=${
      //   !params?.passed ? "" : params?.passed === "graduate" ? 2 : 1
      // }`,
    }&filter=${params?.filter ?? ""}&passed=${
      !params?.passed ? "" : params?.passed === "graduate" ? 2 : 1
    }`,
  );
};

export const getPassedStudentList = (params) => {
  return axios.get(`${Service.API}/user/student/passed/${params?.id}`, {
    params: params?.params,
  });
};

export const getListHiredOnMitra = (params) => {
  return axios.get(
    `${Service.API}/admin/partner/hired?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
    }&filter=${params?.filter ?? ""}&passed=${
      !params?.passed ? "" : params?.passed === "graduate" ? 2 : 1
    }`,
  );
};

export const getListNonHired = (params) => {
  return axios.get(
    `${Service.API}/admin/partner/student?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
    }&search=${params?.filter ?? ""}`,
  );
};

export const postData = (params) => {
  return axios.post(`${Service.API}/admin/partner/hire`, params);
};

export const updateStatusHired = (params) => {
  return axios.put(
    `${Service.API}/admin/partner/hire/${params?.id}`,
    params?.payload,
  );
};
