import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

const Select = React.forwardRef(({ name, ...props }, ref) => {
  const methods = useFormContext();
  if (name && methods?.control) {
    return <FieldContentWithName ref={ref} {...props} name={name} />;
  }
  return <FieldContent ref={ref} {...props} />;
});

const FieldContent = React.forwardRef(({ ...props }, ref) => {
  const {
    isError = {
      value: false,
      message: "",
    },
    label = "",
    onChange,
    value,
    inputClassName = "",
  } = props;
  return (
    <div ref={ref} className="w-full flex flex-col items-start mb-4">
      {label && (
        // <label className="label">
        //   <span className={`label-text ${isError?.value ? "text-error" : ""}`}>
        //     {label}
        //   </span>
        // </label>
        <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
          {label}
        </label>
      )}
      <ReactSelect
        {...props}
        onChange={onChange}
        value={value}
        className={`px-3 py-1 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none text-start ${
          isError?.value ? "border-[#ea0606]" : "border-gray-300"
        } flex justify-center items-start px-1 ${inputClassName}`}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
              border: state.isFocused ? 0 : 0,
            },
            cursor: "pointer",
            width: "100%",
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 100,
          }),
        }}
      />
      {isError?.value && (
        <small className="mt-1 text-xs text-red-600">{isError?.message}</small>
      )}
    </div>
  );
});

const FieldContentWithName = React.forwardRef(({ name, ...props }, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FieldContent ref={ref} {...props} onChange={onChange} value={value} />
      )}
    />
  );
});

export default Select;
