import React, { useEffect, useMemo, useState } from "react";
import { Footer, GlobalHeader } from "components";
import aboutus1 from "assets/img/about-us-1.png";
import aboutus2 from "assets/img/about-us-2.png";
import aboutus3 from "assets/img/about-us-3.png";
import aboutus4 from "assets/img/about-us-4.jpeg";
import aboutus2id from "assets/img/about-us-2-id.png";
import aboutus3id from "assets/img/about-us-3-id.png";
import aboutus4id from "assets/img/about-us-4-id.png";
import { fetchAPI, getErrorMessage } from "helpers";
import { getLanding, getProfileBitode } from "services/landing";
import { useSelector } from "react-redux";
import { getLocales } from "locales";

import owner from "assets/img/md.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

const AboutUs = () => {
  const { LANDING_PAGE } = getLocales();

  const { lang } = useSelector(({ locales }) => locales);

  const [landing, setLanding] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [profileBitode, setProfileBitode] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [chartData, setChartData] = useState({
    labels: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
    datasets: [
      {
        label: "Total",
        data: [27, 87, 186, 322, 412, 629, 821, 1154, 617, 38, 1999],
        borderColor: "#3499ff",
        backgroundColor: "#3499ff",
        borderRadius: 16,
      },
    ],
  });
  const [pieChartData, setPieChartData] = useState({
    labels: [
      "Kaigo",
      "Kensetsu",
      "Yosetsu",
      "Jidosasebi",
      "Nogyo",
      "Sokuhingsayjo",
      "Seijogyo",
    ],
    datasets: [
      {
        label: "Jenis Kerja",
        data: [1, 1, 1, 1, 1, 1, 1],
        borderWidth: 1,
      },
    ],
  });

  const options = useMemo(() => {
    return {
      scales: {
        y: {
          title: {
            display: true,
            text: "Total",
          },
          grid: {
            display: false,
          },
        },
        x: {
          title: {
            display: true,
            text: "Tahun",
            padding: 12,
          },
          grid: {
            display: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    };
  }, []);

  const pieOptions = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        title: {
          display: false,
        },
      },
    };
  }, []);

  useEffect(() => {
    fetchProfileBitode();
    fetchLanding();
  }, []);

  const fetchLanding = () => {
    setLanding((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getLanding }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data: data,
        }));
      } else {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const fetchProfileBitode = () => {
    setProfileBitode({
      ...profileBitode,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getProfileBitode,
      },
      (success, response) => {
        if (success) {
          setProfileBitode({
            ...profileBitode,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setProfileBitode({
            ...profileBitode,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const handleLocalesLanding = (key) => {
    if (landing?.data) {
      if (lang === "jp") {
        return landing?.data[`${key}_jpn`];
      } else if (lang === "en") {
        return landing?.data[`${key}_en`];
      } else {
        return landing?.data[`${key}`];
      }
    }
    return "";
  };

  const handleLocalesProfile = (key, profile) => {
    if (profile) {
      if (lang === "jp") {
        return (
          profile[`${key}_jpn`] ??
          profile[`${key}_en`] ??
          profile[`${key}`] ??
          ""
        );
      } else if (lang === "en") {
        return (
          profile[`${key}_en`] ??
          profile[`${key}_jpn`] ??
          profile[`${key}`] ??
          ""
        );
      } else {
        return (
          profile[`${key}`] ??
          profile[`${key}_jpn`] ??
          profile[`${key}_en`] ??
          ""
        );
      }
    }
    return "";
  };

  return (
    <section className="py-10">
      <div className="flex flex-col space-y-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 place-content-center min-h-85-screen container gap-3">
          <img
            src={`${
              process.env.REACT_APP_API
            }${landing?.data?.about_us_image?.replace("/public", "")}`}
            className="max-w-sm rounded-lg shadow-2xl w-68 h-68 object-cover place-self-center"
          />
          <div className="flex flex-col col-span-2 gap-4">
            <h2>{LANDING_PAGE.ABOUT_US_TITLE}</h2>
            <p className="col-span-2">{handleLocalesLanding("about_us")}</p>
          </div>
        </div>

        <div className="space-y-6 container">
          {lang === "jp" && (
            <div className="w-full">
              <img
                src={aboutus1}
                alt="aboutus2"
                className="w-full h-full object-contain"
              />
            </div>
          )}
          <div className="w-full">
            <img
              src={lang === "jp" ? aboutus2 : aboutus2id}
              alt="aboutus2"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="w-full">
            <img
              src={lang === "jp" ? aboutus3 : aboutus3id}
              alt="aboutus3"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="w-full">
            <img
              src={lang === "jp" ? aboutus4 : aboutus4id}
              alt="aboutus4"
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        <div className="bg-gradient-to-t from-white to-white/0">
          <div className="container">
            <div className="space-y-6">
              <div className="flex flex-col space-y-6">
                <h2 className="pb-6">
                  {LANDING_PAGE.ABOUT_US_BAR_CHART_TITLE}
                </h2>
                <Bar options={options} data={chartData} height={100} />
              </div>

              <div className="flex justify-center">
                <div className="w-160 h-auto">
                  <Pie options={pieOptions} data={pieChartData} height={100} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center gap-4">
              <img
                src={`${
                  process.env.REACT_APP_API
                }${profileBitode?.data?.data?.image?.replace("/public", "")}`}
                className="w-32 h-32 rounded-full mb-6"
              />
              <div className="flex flex-col items-center">
                <h2 className="text-3xl lg:text-4xl">
                  {profileBitode?.data?.data && profileBitode?.data?.data?.name}
                </h2>
                <h5 className="mb-6">
                  {profileBitode?.data?.data &&
                    profileBitode?.data?.data?.position}
                </h5>
              </div>
            </div>
            <p className="whitespace-pre-wrap text-start lg:text-center">
              {profileBitode?.data?.data
                ? handleLocalesProfile("description", profileBitode?.data?.data)
                : ""}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

// const BACKUP = () => {
//   return (
//     <div>
//       <GlobalHeader />
//       <div className="hero min-h-screen">
//         <div className="hero-content flex-col lg:flex-row">
//           <img
//             src={`${
//               process.env.REACT_APP_API
//             }${landing?.data?.about_us_image?.replace("/public", "")}`}
//             className="max-w-sm rounded-lg shadow-2xl w-80 h-80 object-cover"
//           />
//           <div>
//             <h1 className="text-5xl font-bold">
//               {LANDING_PAGE.ABOUT_US_TITLE}
//             </h1>
//             <p className="py-6">{handleLocalesLanding("about_us")}</p>
//           </div>
//         </div>
//       </div>
//       {/* <div className="px-40">
//       <div
//         className="hero min-h-screen"
//         style={{
//           backgroundImage: `url(${
//             process.env.REACT_APP_API
//           }${landing?.data?.organization_background?.replace(
//             "/public",
//             "",
//           )})`,
//         }}
//       >
//         <div className="hero-overlay bg-opacity-60"></div>
//         <div className="hero-content text-center text-neutral-content">
//           <div className="max-w-3xl">
//             <h1 className="mb-5 text-5xl font-bold">
//               {handleLocalesLanding("organization_tittle")}
//             </h1>
//             <p className="mb-5">
//               {handleLocalesLanding("organization_desc")}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div> */}
//       <div className="px-40 space-y-8">
//         {lang === "jp" && (
//           <div className="w-full">
//             <img
//               src={aboutus1}
//               alt="aboutus1"
//               className="w-full h-full object-contain"
//             />
//           </div>
//         )}
//         <div className="w-full">
//           <img
//             src={lang === "jp" ? aboutus2 : aboutus2id}
//             alt="aboutus2"
//             className="w-full h-full object-contain"
//           />
//         </div>
//         <div className="w-full">
//           <img
//             src={lang === "jp" ? aboutus3 : aboutus3id}
//             alt="aboutus3"
//             className="w-full h-full object-contain"
//           />
//         </div>
//         <div className="w-full">
//           <img
//             src={lang === "jp" ? aboutus4 : aboutus4id}
//             alt="aboutus4"
//             className="w-full h-full object-contain"
//           />
//         </div>
//       </div>
//       <div className="px-40">
//         <div className="hero min-h-screen">
//           <div className="hero-content flex flex-col w-full h-full space-y-4">
//             <div className="text-3xl font-bold">
//               {LANDING_PAGE.ABOUT_US_BAR_CHART_TITLE}
//             </div>
//             <Bar options={options} data={chartData} height={100} />
//           </div>
//         </div>
//       </div>
//       <div className="px-40">
//         <div className="hero min-h-screen">
//           <div className="hero-content flex flex-col w-full h-full space-y-4">
//             <div className="text-3xl font-bold">
//               {LANDING_PAGE.ABOUT_US_BAR_CHART_TITLE}
//             </div>
//             <div className="w-[640px]">
//               <Pie data={pieChartData} options={pieOptions} />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex justify-center min-h-screen w-full bg-gradient-to-r from-base-200 py-8 container mx-auto">
//         <div className="hero text-center">
//           <div className="max-w-4xl">
//             <div className="flex flex-col space-y-4 justify-center items-center">
//               <div>
//                 <div className="avatar">
//                   <div className="rounded-full">
//                     {/* <img
//                     src={`${
//                       process.env.REACT_APP_API
//                     }${profileBitode?.data?.data?.image?.replace(
//                       "/public",
//                       "",
//                     )}`}
//                   /> */}
//                     <img src={owner} className="h-24 w-24 rounded-full" />
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 <h1 className="text-3xl font-bold">
//                   {profileBitode?.data?.data && profileBitode?.data?.data?.name}
//                 </h1>
//                 <p>
//                   {profileBitode?.data?.data &&
//                     profileBitode?.data?.data?.position}
//                 </p>
//               </div>
//             </div>
//             <p className="py-6 whitespace-pre-wrap">
//               {profileBitode?.data?.data
//                 ? handleLocalesProfile("description", profileBitode?.data?.data)
//                 : ""}
//             </p>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };
