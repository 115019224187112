import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import cn from "clsx";
import InputMask from "react-input-mask";

const YenInput = React.forwardRef(
  (
    {
      label,
      placeholder,
      suffixIcon,
      size = "lg",
      className = "",
      name,
      onValueChanged = () => null,
      ...rest
    },
    ref,
  ) => {
    const [valueSeparator, setValueSeparator] = useState("");
    const inputSize = {
      sm: "px-3 py-1",
      md: "px-3 py-2",
      lg: "px-3 py-3",
    };

    const { control } = useFormContext();

    const handleOnChange = (event, onChange) => {
      const value = event?.target?.value;
      let number_string = value.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        remaining = split[0].length % 3,
        thousands = split[0].substr(0, remaining),
        yen = split[0].substr(remaining).match(/\d{1,3}/gi);

      if (yen) {
        let separator = remaining ? "." : "";
        thousands += separator + yen.join(".");
      }

      thousands =
        split[1] != undefined ? thousands + "," + split[1] : thousands;

      setValueSeparator(thousands);
      onChange(value);
      onValueChanged(value);
    };

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => (
          // <InputMask mask="99999-9999" maskChar={null} value={value} onChange={onChange} beforeMaskedValueChange={beforeMaskedValueChange} />
          <div className="flex flex-col items-start w-full">
            {label ? (
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                {label}
              </label>
            ) : null}

            <div
              className={cn(
                "mb-4 w-full flex flex-col items-start gap-1",
                className,
              )}
            >
              <div
                className={cn(
                  "relative w-full",
                  "flex items-center space-x-2 text-sm leading-5.6 ease-soft w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed",
                  className,
                  inputSize[size],
                  invalid ? "border-red-600" : "border-gray-300",
                )}
              >
                <label className="text-gray-500">¥</label>
                <input
                  {...rest}
                  ref={ref}
                  placeholder={placeholder}
                  type="text"
                  className="outline-none w-full"
                  // className={cn(
                  //   "text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed",
                  //   className,
                  //   inputSize[size],
                  //   invalid ? "border-red-600" : "border-gray-300",
                  // )}
                  // pattern="^[0-9]*$"
                  value={valueSeparator}
                  onChange={(event) => handleOnChange(event, onChange)}
                  autoComplete="new-password"
                />
                {suffixIcon}
              </div>
              {invalid ? (
                <small className="text-xs text-red-600">{error?.message}</small>
              ) : null}
            </div>
          </div>
        )}
      />
    );
  },
);

export default YenInput;
