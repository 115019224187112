import React, { useEffect, useRef, useState } from "react";
import cn from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "features/auth/authSlice";
import { FaBars } from "react-icons/fa";
import { toggleSidebar } from "features/common/commonSlice";
import { MdDashboard } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { capitalize } from "lodash";
import { useClickOutside } from "hooks";
import { Link } from "react-router-dom";
import HeaderProfile from "assets/header-profile.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const onLogout = () => {
    dispatch(logOut());
  };

  const handleBreadcrumb = () => {
    const path = location.pathname.split("/");

    const arrPath = path
      ?.filter((ex) => ex !== "")
      .map((e) => capitalize(e.split("-").join(" ")));

    return arrPath;
  };

  useEffect(() => {
    handleBreadcrumb();
  }, [location]);

  return (
    <nav
      className="flex flex-wrap items-center justify-between px-0 py-2 mx-6 pt-6 transition-all duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start sticky top-[1%] backdrop-saturate-200 backdrop-blur-2xl bg-white/80 shadow-blur z-110"
      navbar-scroll="true"
    >
      <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
        <nav>
          {/* <!-- breadcrumb --> */}
          <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
            <li className="leading-normal text-sm breadcrumb-item text-gray-500 hover:text-gray-600 transition-all duration-200">
              <MdDashboard
                className="cursor-pointer"
                onClick={() => navigate("/dashboard")}
              />
            </li>
            {handleBreadcrumb()?.map((path, index) => (
              <li
                className={cn(
                  "text-sm pl-2 leading-normal before:float-left before:pr-2 before:text-gray-600 before:content-['/'] cursor-pointer",
                )}
                key={index}
                onClick={() => {
                  if (handleBreadcrumb().length < 2) return;
                  navigate(index + 1 - handleBreadcrumb().length);
                }}
              >
                <a className="opacity-50 text-slate-700">{path}</a>
              </li>
            ))}
          </ol>
          <h6 className="mb-0 font-bold capitalize">
            {handleBreadcrumb().pop()}
          </h6>
        </nav>

        <div className="flex items-center"></div>

        <div
          className="flex mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto justify-end relative items-center gap-4"
          // id="navbar"
        >
          <FaBars
            size={20}
            onClick={() => dispatch(toggleSidebar())}
            className="flex xl:hidden"
          />

          <div
            className="flex items-center cursor-pointer"
            onClick={() => setOpen((prev) => !prev)}
          >
            <img
              src={HeaderProfile}
              alt="avatar image"
              className="inline-flex items-center justify-center w-9 h-9 mr-2 text-white transition-all duration-200 ease-in-out text-sm rounded-circle cursor-pointer"
            />
            <span className="hidden lg:flex">{user?.email}</span>
          </div>
          <div
            ref={dropdownRef}
            className={cn(
              "absolute top-10 flex flex-col w-50 mb-6 bg-white shadow-soft-3xl rounded-md bg-clip-border z-[2]",
              open ? "flex" : "hidden",
            )}
          >
            <ul className="w-full h-auto">
              {user?.role !== "student" && user?.role !== "lpk_induk" ? (
                <Link to="/profile" onClick={() => setOpen(false)}>
                  <li className="flex px-4 py-3 hover:bg-gray-50/50 cursor-pointer">
                    {user?.role === "partner_jepang"
                      ? "プロフィール (Profile)"
                      : "Profile"}
                  </li>
                </Link>
              ) : null}
              <li
                className="flex px-4 py-3 hover:bg-gray-50/50 cursor-pointer"
                onClick={onLogout}
              >
                {user?.role === "partner_jepang"
                  ? "ログアウト (Logout)"
                  : "Logout"}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
