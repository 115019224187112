import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Select,
  Spinner,
} from "components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import Service from "config";
import {
  postEvaluation,
  postEvaluationPersonal,
  putEvaluationPersonal,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCities, getDistricts, getUserLpk } from "features/core/coreSlice";
import moment from "moment";

const EvaluationForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const student_id = state?.student_id ?? null;
  const lesson = state?.lesson ?? null;
  const class_id = state?.class?.id ?? null;
  const class_name = state?.class?.name ?? null;

  const [tab, setTab] = useState(0);

  const [evaluationData, setEvaluationData] = useState([]);
  const [updated, setUpdated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    if (lesson?.subjects?.length > 0) {
      setEvaluationData(
        _.map(lesson?.subjects, (subject) => ({
          ...subject,
          id: subject?.subject?.join(","),
          leasson_id: lesson?.id,
          evaluation_id: subject?.evaluation_id ?? null,
          name: subject?.name,
          attendence: updated
            ? { value: subject?.attendence, label: subject?.attendence }
            : {
                value: "Hadir",
                label: "Hadir",
              },
          ontime: updated
            ? { value: subject?.ontime, label: subject?.ontime }
            : {
                value: "Tepat Waktu",
                label: "Tepat Waktu",
              },
          attitude: updated
            ? { value: subject?.attitude, label: subject?.attitude }
            : {
                value: "Sangat Baik",
                label: "Sangat Baik",
              },
          punctuality: updated
            ? { value: subject?.punctuality, label: subject?.punctuality }
            : {
                value: "Sangat Baik",
                label: "Sangat Baik",
              },
          score: updated ? subject?.score : "",
          total_question: updated ? subject?.total_question : "",
        })),
      );
    }
  }, [lesson, updated]);

  useEffect(() => {
    if (evaluationData[tab]?.evaluation_id) {
      setUpdated(true);
    } else {
      setUpdated(false);
    }
  }, [evaluationData[tab]?.evaluation_id]);

  useEffect(() => {
    if (updated) {
      reset({
        score: evaluationData[tab]?.score,
        total_question: evaluationData[tab]?.total_question,
      });
    }
  }, [updated, evaluationData[tab]]);

  const handleChangeScore = (event) => {
    let { value, name } = event?.target;
    if (event.target.validity.valid) {
      if (value.charAt(0) === "0") value = 0;
      if (parseFloat(value) > 100) value = 100;
      if (parseFloat(value) < 0) value = 0;
      setValue(name, value);
      setEvaluationData((prevState) => {
        let newState = [...prevState];
        newState[tab].score = value;
        return newState;
      });
    }
  };

  const handleChangeTotalQuestion = (event) => {
    let { value, name } = event?.target;
    if (event.target.validity.valid) {
      if (value.charAt(0) === "0") value = 0;
      // if (parseFloat(value) > 100) value = 100;
      if (parseFloat(value) < 0) value = 0;
      setValue(name, value);
      setEvaluationData((prevState) => {
        let newState = [...prevState];
        newState[tab].total_question = value;
        return newState;
      });
    }
  };

  const handleChangeDropdownScore = (value, key) => {
    setEvaluationData((prevState) => {
      let newState = [...prevState];
      newState[tab][key] = value;
      return newState;
    });
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {
        attendence: evaluationData[tab]?.attendence?.value,
        ontime: evaluationData[tab]?.ontime?.value,
        attitude: evaluationData[tab]?.attitude?.value,
        punctuality: evaluationData[tab]?.punctuality?.value,
        score: evaluationData[tab]?.score,
        total_question: evaluationData[tab]?.total_question
          ? parseInt(evaluationData[tab]?.total_question)
          : 0,
        leasson_id: evaluationData[tab]?.leasson_id,
        subject_id: evaluationData[tab]?.id,
        class_id: class_id,
        input_by: "student",
      };

      let valid =
        Boolean(evaluationData[tab]?.score) &&
        Boolean(evaluationData[tab]?.total_question);

      if (updated) {
        params.eval_id = evaluationData[tab]?.evaluation_id ?? "";
      }

      if (valid) {
        setCreateData((prevState) => ({ ...prevState, isLoading: true }));
        fetchAPI(
          {
            service: putEvaluationPersonal,
            params: { id: student_id, params },
          },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses mengubah data!");
              navigate(-1);
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      }
    }
  };

  return (
    <div className="w-full space-y-4">
      <div className="flex justify-between items-center">
        <Label
          text={`Penilaian - ${class_name}`}
          className="font-bold text-2xl"
        />
      </div>

      <div className="tabs w-full">
        {_.map(lesson?.subjects ?? [], (subject, key) => (
          <a
            onClick={() => setTab(key)}
            className={`tab tab-bordered ${tab === key ? "tab-active" : ""}`}
          >
            {subject?.name ?? ""}
          </a>
        ))}
      </div>

      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-1">
          <InputValidation
            label="Materi"
            type="text"
            readOnly
            disabled
            value={lesson?.name}
          />
          <Select
            label="Kehadiran"
            onChange={(value) => handleChangeDropdownScore(value, "attendence")}
            value={evaluationData[tab]?.attendence}
            options={[
              { value: "Hadir", label: "Hadir" },
              { value: "Tidak Hadir", label: "Tidak Hadir" },
            ]}
            placeholder="Masukan Kehadiran"
          />
          <Select
            label="Ketepatan Waktu"
            onChange={(value) => handleChangeDropdownScore(value, "ontime")}
            value={evaluationData[tab]?.ontime}
            options={[
              { value: "Tepat Waktu", label: "Tepat Waktu" },
              { value: "Tidak Tepat Waktu", label: "Tidak Tepat Waktu" },
            ]}
            placeholder="Masukan Ketepatan Waktu"
          />
          <Select
            label="Sopan Santun"
            onChange={(value) => handleChangeDropdownScore(value, "attitude")}
            value={evaluationData[tab]?.attitude}
            options={[
              { value: "Sangat Baik", label: "Sangat Baik" },
              { value: "Baik", label: "Baik" },
              { value: "Kurang Baik", label: "Kurang Baik" },
            ]}
            placeholder="Masukan Sopan Santun"
          />
          <Select
            label="Disiplin Waktu"
            onChange={(value) =>
              handleChangeDropdownScore(value, "punctuality")
            }
            value={evaluationData[tab]?.punctuality}
            options={[
              { value: "Sangat Baik", label: "Sangat Baik" },
              { value: "Baik", label: "Baik" },
              { value: "Kurang Baik", label: "Kurang Baik" },
            ]}
            placeholder="Masukan Disiplin Waktu"
          />
          <InputValidation
            {...register("score", {
              required: {
                value: true,
                message: "Nilai wajib diisi",
              },
            })}
            name="score"
            label="Nilai"
            type="phone"
            pattern="^[0-9]*$"
            value={evaluationData[tab]?.score}
            onChange={handleChangeScore}
            placeholder="0-100"
            isError={{ value: errors.score, message: errors.score?.message }}
          />
          <InputValidation
            {...register("total_question", {
              required: {
                value: true,
                message: "Total soal wajib diisi",
              },
            })}
            name="total_question"
            label="Total soal"
            type="phone"
            pattern="^[0-9]*$"
            value={evaluationData[tab]?.total_question}
            onChange={handleChangeTotalQuestion}
            placeholder="0"
            isError={{
              value: errors.total_question,
              message: errors.total_question?.message,
            }}
          />
        </div>
        <div className="card-actions justify-end">
          <Button
            label={createData?.isLoading ? <Spinner /> : "Kirim"}
            disabled={createData?.isLoading}
            className="w-full"
            theme="primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default EvaluationForm;
