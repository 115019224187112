import axios from "axios";
import Service from "config";

export const signIn = (params) => {
  return axios.post(`${Service.API}/user/login`, params);
};

export const signInPortal = (params) => {
  return axios.post(`${Service.API}/admin/login`, params);
};

export const updatePassword = (params) => {
  return axios.post(`${Service.API}/admin/users/update/password`, params);
};

export const updatePasswordStudent = (params) => {
  return axios.post(`${Service.API}/user/update/password`, params);
};

export const updatePasswordStudentPersonal = (params) => {
  return axios.post(`${Service.API}/user/student/update/password`, params);
};
