import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Spinner,
} from "components";
import { useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { useSelector } from "react-redux";
import { FiEdit, FiEye } from "react-icons/fi";
import {
  getAllProfileBitode,
  getProfileBitode,
  postProfileBitode,
  updateProfileBitode,
} from "services/landing";
import { MdEdit } from "react-icons/md";
import { IoMdCloseCircle, IoMdImage, IoMdTrash } from "react-icons/io";
import { CgSpinner } from "react-icons/cg";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import { uploadAvatar } from "services/user";
import BlankProfile from "assets/img/blank-profile.webp";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import cn from "clsx";
import ModalWrapper from "components/molecules/ModalWrapper";

const ProfileBitode = () => {
  const modalForm = useRef();
  const attachmentRef = useRef();
  const navigate = useNavigate();

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });

  const [tab, setTab] = useState("jp");
  const [article_tittle, setArticleTittle] = useState({
    en: "",
    jp: "",
    id: "",
  });
  const [article_desc, setArticleDesc] = useState({
    en: "",
    jp: "",
    id: "",
  });
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [desc, setDesc] = useState({
    en: "",
    jp: "",
    id: "",
  });
  const [image, setImage] = useState({
    file: null,
    objectUrl: null,
    name: null,
  });
  const [active, setActive] = useState(true);

  const [search, setSearch] = useState("");

  const [selectedData, setSelectedData] = useState(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  useEffect(() => {
    if (selectedData) {
      reset({
        name: selectedData.name,
        position: selectedData.position,
        desc_id: selectedData.description,
        desc_en: selectedData.description_en,
        desc_jp: selectedData.description_jpn,
      });
      setDesc({
        en: selectedData.description_en,
        jp: selectedData.description_jpn,
        id: selectedData.description,
      });
      setActive(selectedData.active);
    } else {
      reset({
        name: "",
        position: "",
        desc_id: "",
        desc_en: "",
        desc_jp: "",
      });
      setActive(true);
    }
  }, [selectedData]);

  const onTriggerUpload = () => {
    attachmentRef?.current?.click();
  };

  const onSelectFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif")
      ) {
        const formData = new FormData();
        formData.append("image", file);
        fetchAPI(
          { service: uploadAvatar, params: formData },
          (success, response) => {
            if (success) {
              const objectUrl = URL.createObjectURL(file);
              const name = file?.name ?? "";
              setImage({ file, objectUrl, name });
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const fetchList = (params) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getProfileBitode,
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onDetail = (data) => {
    navigate("/lesson/detail", { state: { data, id: data?.id } });
  };

  const onEdit = (data) => {
    // modalForm?.current?.click();
    setIsFormModalOpen(true);
    setSelectedData(data);
    // navigate("/lesson/form", { state: { data, id: data?.id } });
  };

  const onSubmit = (data) => {
    try {
      if (data) {
        let params = {
          name: data.name,
          position: data.position,
          description: desc?.id ? desc?.id : "",
          description_jpn: desc?.jp ? desc?.jp : "",
          description_en: desc?.en ? desc?.en : "",
          active: true,
          image: image?.name ? image?.name : "",
        };

        setCreateData((prevState) => ({ ...prevState, isLoading: true }));
        if (selectedData?.id) {
          fetchAPI(
            {
              service: updateProfileBitode,
              params: { id: selectedData?.id, payload: params },
            },
            (success, response) => {
              if (success) {
                setCreateData((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  isSuccess: true,
                  data: response,
                }));
                toast.success("Sukses mengubah data!");
                fetchList(filter);
                reset({
                  name: "",
                  position: "",
                  desc_en: "",
                  desc_id: "",
                  desc_jp: "",
                });
                clearErrors("name");
                modalForm?.current?.click();
                setIsFormModalOpen(false);
                setSelectedData(null);
                setTab("jp");
                setActive(true);
                setImage({
                  file: null,
                  objectUrl: null,
                  name: null,
                });
              } else {
                setCreateData((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  isError: true,
                  data: response,
                  errorMessage: getErrorMessage(response),
                }));
              }
            },
          );
        } else {
          fetchAPI(
            { service: postProfileBitode, params },
            (success, response) => {
              if (success) {
                setCreateData((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  isSuccess: true,
                  data: response,
                }));
                toast.success("Sukses menambahkan data!");
                fetchList(filter);
                reset({ name: "" });
                clearErrors("name");
                modalForm?.current?.click();
                setSelectedData(null);
                setTab("jp");
                setArticleTittle({
                  en: "",
                  jp: "",
                  id: "",
                });
                setArticleDesc({
                  en: "",
                  jp: "",
                  id: "",
                });
                setActive(true);
                setImage({
                  file: null,
                  objectUrl: null,
                  name: null,
                });
              } else {
                setCreateData((prevState) => ({
                  ...prevState,
                  isLoading: false,
                  isError: true,
                  data: response,
                  errorMessage: getErrorMessage(response),
                }));
              }
            },
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderModalForm = () => (
    <>
      <input
        ref={modalForm}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box overflow-y-visible w-[812px] max-w-[812px] overflow-hidden">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <h3 className="font-bold text-lg">
                {selectedData ? "Edit Profil Bitode" : "Tambah Profil Bitode"}
              </h3>
              <div className="flex items-center justify-end space-x-2">
                <div
                  className={`text-xs border ${
                    tab === "jp"
                      ? "border-gray-600 text-gray-600"
                      : "border-gray-300 text-gray-300"
                  } rounded-lg px-2 py-1 cursor-pointer transform transition-transform hover:scale-110`}
                  onClick={() => {
                    setTab("jp");
                  }}
                >
                  Japan
                </div>
                <div
                  className={`text-xs border ${
                    tab === "en"
                      ? "border-gray-600 text-gray-600"
                      : "border-gray-300 text-gray-300"
                  } rounded-lg px-2 py-1 cursor-pointer transform transition-transform hover:scale-110`}
                  onClick={() => {
                    setTab("en");
                  }}
                >
                  English
                </div>
                <div
                  className={`text-xs border ${
                    tab === "id"
                      ? "border-gray-600 text-gray-600"
                      : "border-gray-300 text-gray-300"
                  } rounded-lg px-2 py-1 cursor-pointer transform transition-transform hover:scale-110`}
                  onClick={() => {
                    setTab("id");
                  }}
                >
                  Indonesian
                </div>
              </div>
            </div>
            <div className="w-full px-8 py-4 space-y-4">
              <div className="flex justify-center space-x-8">
                <div className="flex flex-col items-center space-y-8">
                  <div className="w-full">
                    <label className="label">
                      <span className={`label-text`}>&nbsp;</span>
                    </label>
                    <div className="space-y-2 w-full">
                      {image?.objectUrl || selectedData ? (
                        <div className="relative">
                          <div className="relative rounded-full w-[180px] h-[180px] overflow-hidden">
                            <img
                              src={
                                selectedData?.image && !image?.file
                                  ? `${
                                      process.env.REACT_APP_API
                                    }${selectedData?.image?.replace(
                                      "/public",
                                      "",
                                    )}`
                                  : image?.objectUrl
                              }
                              alt=""
                              className="max-h-[180px] rounded-full h-fit w-full object-cover transform transition-transform aspect-square"
                            />
                            <div
                              className="absolute inset-x-0 bottom-0 bg-black/50 text-white text-xs font-medium flex items-center justify-center space-x-1 py-1 transform transition-transform hover:scale-110 cursor-pointer"
                              onClick={onTriggerUpload}
                            >
                              <FiEdit />
                              <span>Edit</span>
                            </div>
                          </div>
                          {!selectedData?.image || image?.file ? (
                            <IoMdCloseCircle
                              onClick={() =>
                                setImage({
                                  file: null,
                                  objectUrl: null,
                                  name: null,
                                })
                              }
                              className="absolute right-[8px] top-[8px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                            />
                          ) : null}
                        </div>
                      ) : (
                        <div
                          className="border border-dashed border-gray-400 text-gray-400 rounded-full w-[180px] h-[180px] p-2 flex items-center justify-center cursor-pointer space-x-2"
                          onClick={onTriggerUpload}
                        >
                          <IoMdImage />
                          <span>Upload Image</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="accent-primary"
                      checked={active}
                      onClick={() => setActive(!active)}
                    />
                    <label>Active</label>
                  </div>
                </div>
                <div className="flex-1 space-y-2 w-full">
                  <InputValidation
                    {...register("name", {
                      required: { value: true, message: "Nama wajib diisi" },
                    })}
                    label="Nama"
                    placeholder="Nama"
                    isError={{
                      value: errors.name,
                      message: errors.name?.message,
                    }}
                  />
                  <InputValidation
                    {...register("position", {
                      required: { value: true, message: "Posisi wajib diisi" },
                    })}
                    label="Position"
                    placeholder="Position"
                    isError={{
                      value: errors.position,
                      message: errors.position?.message,
                    }}
                  />
                  <div className="form-control w-full">
                    <label className="label">
                      <span
                        className={`label-text ${
                          errors[`desc_${tab}`] ? "text-error" : ""
                        }`}
                      >
                        Deskripsi
                      </span>
                    </label>
                    <textarea
                      {...register(`desc_${tab}`, {
                        required: {
                          value: true,
                          message: "Deskripsi wajib diisi",
                        },
                      })}
                      className={`textarea w-full ${
                        errors[`desc_${tab}`]
                          ? "input-error focus:outline-[#f87272]"
                          : "input-bordered"
                      } h-24`}
                      placeholder="Deskripsi"
                      value={desc[tab] ? desc[tab] : ""}
                      onChange={(e) => {
                        setValue(`desc_${tab}`, e.target.value, {
                          shouldValidate: true,
                        });
                        setDesc((state) => ({
                          ...state,
                          [tab]: e.target.value,
                        }));
                      }}
                    ></textarea>
                    {errors[`desc_${tab}`] && (
                      <label className="label">
                        <span className="label-text-alt text-error">
                          {errors[`desc_${tab}`]?.message}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <input
                ref={attachmentRef}
                autoComplete="none"
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                type="file"
                onChange={onSelectFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className="opacity-0 hidden"
              />
            </div>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                type="button"
                onClick={() => {
                  modalForm?.current?.click();
                  setTimeout(() => {
                    setSelectedData(null);
                    setTab("jp");
                    setImage({
                      file: null,
                      objectUrl: null,
                      name: null,
                    });
                    setName("");
                    setPosition("");
                    setDesc({
                      en: "",
                      jp: "",
                      id: "",
                    });
                    setActive(true);
                    reset({
                      name: "",
                      position: "",
                      desc_en: "",
                      desc_id: "",
                      desc_jp: "",
                    });
                    clearErrors("name");
                  }, 300);
                }}
              />
              <Button
                label={
                  createData?.isLoading ? (
                    <CgSpinner className="animate-spin text-2xl text-primary" />
                  ) : (
                    "Kirim"
                  )
                }
                disabled={createData?.isLoading}
                size="sm"
                theme="primary"
              />
            </div>
          </form>
        </div>
        <input
          ref={attachmentRef}
          autoComplete="none"
          accept="image/png, image/jpg, image/jpeg, application/pdf"
          type="file"
          onChange={onSelectFile}
          onClick={(event) => {
            event.target.value = null;
          }}
          className="opacity-0 hidden"
        />
      </div>
    </>
  );

  const renderData2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={"3"}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td colSpan={"3"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <th>1</th>
        <td>
          <div className="flex items-center space-x-4">
            <img
              src={
                response?.data?.data?.image
                  ? `${
                      process.env.REACT_APP_API
                    }${response?.data?.data?.image?.replace("/public", "")}`
                  : BlankProfile
              }
              className="h-20 w-20 object-cover border rounded-lg"
            />
            <div className="space-y-1">
              <div className="whitespace-pre-wrap font-bold">
                {response?.data?.data?.name ?? "-"}
              </div>
              <div className="whitespace-pre-wrap text-xs">
                {response?.data?.data?.position ?? "-"}
              </div>
              <div className="text-xs text-gray-500 break-words whitespace-pre-wrap limit-3-line-of-text">
                {response?.data?.data?.description_en ??
                  response?.data?.data?.description_jpn ??
                  response?.data?.data?.description ??
                  "-"}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="flex items-center justify-end space-x-2">
            <Button
              label={<MdEdit className="text-white" />}
              size="sm"
              theme="warning"
              onClick={() => onEdit(response?.data?.data)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent whitespace-nowrap shadow-transparent"
            colSpan={3}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      <tr>
        <td
          className="p-2 align-middle bg-transparent whitespace-nowrap shadow-transparent"
          colSpan={3}
        >
          <h6>No Data</h6>
        </td>
      </tr>;
    }

    return (
      <tr>
        <td className="p-2 align-middle bg-transparent whitespace-nowrap shadow-transparent px-4">
          <p className="mb-0 font-semibold leading-tight text-xs">1</p>
        </td>
        <td className="p-2 align-middle bg-transparent whitespace-nowrap shadow-transparent">
          <div className="flex px-4 py-1 gap-4 items-center">
            <img
              src={
                response?.data?.data?.image
                  ? `${
                      process.env.REACT_APP_API
                    }${response?.data?.data?.image?.replace("/public", "")}`
                  : BlankProfile
              }
              className="h-20 w-20 object-cover border rounded-lg"
            />
            <div className="flex flex-col flex-auto">
              <h6 className="m-0">
                {/* {data?.tittle_en ?? data?.tittle_jpn ?? data?.tittle ?? "-"} */}
                {response?.data?.data?.name ?? "-"}
              </h6>
              <span>{response?.data?.data?.position ?? "-"}</span>
              <p className="truncate max-h-12 break-words whitespace-pre-wrap text-xs">
                {response?.data?.data?.description_en ??
                  response?.data?.data?.description_jpn ??
                  response?.data?.data?.description ??
                  "-"}
              </p>
            </div>
            {/* <h6 className="mb-0 leading-normal text-sm">
            {data.name ?? "-"}
          </h6> */}
          </div>
        </td>
        <td className="p-2 align-middle bg-transparent whitespace-nowrap shadow-transparent">
          <div className="flex gap-4">
            <a
              className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
              onClick={() => {
                onEdit(response?.data?.data);
              }}
            >
              Edit
            </a>
            {/* <a
            className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
            onClick={() => {
              setSelectedData(data);
              setIsOpenModal(true);
            }}
          >
            Delete
          </a> */}
          </div>
        </td>
      </tr>
    );
  };

  return (
    // <div>
    //   {renderModalForm()}
    //   <div className="w-full space-y-4">
    //     <div className="flex items-center justify-between space-x-4">
    //       <Label text="Profile Bitode" className="font-bold text-2xl" />
    //       {!response?.data?.data ? (
    //         <Button
    //           theme="primary"
    //           label="Tambah Data"
    //           size="sm"
    //           onClick={() => modalForm?.current?.click()}
    //         />
    //       ) : null}
    //     </div>
    //     <div
    //       style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       className="p-9 space-y-8 rounded-xl"
    //     >
    //       <div className="overflow-x-auto border rounded-xl overflow-hidden">
    //         <table className="table w-full">
    //           <thead>
    //             <tr>
    //               <th className="w-16"></th>
    //               <th>Profile</th>
    //               <th></th>
    //             </tr>
    //           </thead>
    //           <tbody>{renderData()}</tbody>
    //         </table>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>Profile Bitode</h5>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div>
            {!response?.data?.data ? (
              <BaseButton
                variant="primary2"
                size="md"
                onClick={() => {
                  setIsFormModalOpen(true);
                }}
              >
                Tambah Data
              </BaseButton>
            ) : null}
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Profile
                  </th>
                  <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      {/* <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal dark:text-white">
                Hapus Data!
              </h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                variant="primary2"
                size="sm"
                // onClick={onDeleteData}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper> */}

      {/* FORM MODAL */}
      <ModalWrapper isOpen={isFormModalOpen} setIsOpen={setIsFormModalOpen}>
        <div className="relative w-full md:min-w-180 h-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto ease-soft-out">
          <div className="relative flex flex-col w-full bg-white border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
            <div className="flex flex-col lg:flex-row items-start justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">
                {selectedData ? "Edit Profil Bitode" : "Tambah Profil Bitode"}
              </h5>
              <div className="relative">
                <ul className="relative flex p-1 list-none bg-transparent rounded-xl gap-1">
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "jp" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("jp")}
                    >
                      <span className="ml-1">Japan</span>
                    </a>
                  </li>
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "en" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("en")}
                    >
                      <span className="ml-1">English</span>
                    </a>
                  </li>
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "id" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-colors border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("id")}
                    >
                      <span className="ml-1">Indonesian</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative flex-auto p-4">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-4 flex flex-col"
              >
                <div className="flex flex-col lg:flex-row justify-around items-center space-x-0 lg:space-x-6 w-full">
                  <div className="space-y-3 w-max flex flex-col items-center">
                    {image?.objectUrl || selectedData ? (
                      <div className="relative">
                        <div className="relative rounded-full w-[180px] h-[180px] overflow-hidden">
                          <img
                            src={
                              selectedData?.image && !image?.file
                                ? `${
                                    process.env.REACT_APP_API
                                  }${selectedData?.image?.replace(
                                    "/public",
                                    "",
                                  )}`
                                : image?.objectUrl
                            }
                            alt=""
                            className="max-h-[180px] rounded-full h-fit w-full object-cover transform transition-transform aspect-square"
                          />
                          <div
                            className="absolute inset-x-0 bottom-0 bg-black/50 text-white text-xs font-medium flex items-center justify-center space-x-1 py-1 transform transition-transform hover:scale-110 cursor-pointer"
                            onClick={onTriggerUpload}
                          >
                            <FiEdit />
                            <span>Edit</span>
                          </div>
                        </div>
                        {!selectedData?.image || image?.file ? (
                          <IoMdCloseCircle
                            onClick={() =>
                              setImage({
                                file: null,
                                objectUrl: null,
                                name: null,
                              })
                            }
                            className="absolute right-[8px] top-[8px] text-3xl cursor-pointer transform transition-transform hover:scale-110"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="border border-dashed border-gray-400 text-gray-400 rounded-full w-[180px] h-[180px] p-2 flex items-center justify-center cursor-pointer space-x-2"
                        onClick={onTriggerUpload}
                      >
                        <IoMdImage />
                        <span>Upload Image</span>
                      </div>
                    )}

                    <div className="flex items-center gap-2 px-1">
                      <input
                        id="active"
                        type="checkbox"
                        checked={active}
                        onClick={() => setActive((prev) => !prev)}
                        className="h-4 w-4 accent-blue-600"
                      />
                      <label
                        className="inline-block ml-1 font-bold text-slate-700 text-xs"
                        htmlFor="active"
                      >
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-grow flex-col w-full inset-0">
                    <BaseInput
                      label="Nama"
                      placeholder="nama"
                      {...register("name", {
                        required: { value: true, message: "Nama wajib diisi" },
                      })}
                      error={errors.name?.message}
                    />
                    <BaseInput
                      label="Position"
                      placeholder="Position"
                      {...register("position", {
                        required: {
                          value: true,
                          message: "Posisi wajib diisi",
                        },
                      })}
                      error={errors.position?.message}
                    />
                    <div className="flex flex-col gap-1 items-start">
                      <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                        Deskripsi
                      </label>
                      <textarea
                        className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none px-3 py-2 h-25"
                        placeholder="Deskripsi"
                        {...register(`desc_${tab}`, {
                          required: {
                            value: true,
                            message: "Deskripsi wajib diisi",
                          },
                        })}
                      />
                      {errors[`desc_${tab}`] ? (
                        <small className="text-xs text-red-600">
                          {errors[`desc_${tab}`]?.message}
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="space-y-4 flex flex-wrap"></div>

                <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                  <BaseButton
                    type="button"
                    variant="base"
                    size="sm"
                    onClick={() => setIsFormModalOpen((prev) => !prev)}
                  >
                    Tidak
                  </BaseButton>
                  <BaseButton type="submit" variant="primary2" size="sm">
                    Ya
                  </BaseButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <input
        ref={attachmentRef}
        autoComplete="none"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        type="file"
        onChange={onSelectFile}
        onClick={(event) => {
          event.target.value = null;
        }}
        className="opacity-0 hidden"
      />
    </>
  );
};

export default ProfileBitode;
