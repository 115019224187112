import { Button, Input } from "components";
import { fetchAPI } from "helpers";
import React, { useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { RiUploadCloud2Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { uploadAttachmentStudent } from "services/user";

const InputUploadFile = ({
  name,
  className,
  label,
  placeholder,
  required = false,
  ...props
}) => {
  const refInput = useRef(name);
  const [value, setValue] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const { control } = useFormContext();
  const triggerRef = () => {
    refInput.current.click();
  };

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    // fileReader.addEventListener("load", () => {
    //   // onUploadMultiple(file);
    // });
    fileReader.readAsDataURL(file);
  };

  const onUpload = (e) => (onChange) => {
    try {
      const file = e.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        const formData = new FormData();
        formData.append("attachment", file);
        fetchAPI(
          { service: uploadAttachmentStudent, params: formData },
          (success, response) => {
            if (success) {
              handleSelectImage(e);
              onChange(e?.target?.files[0]);
            }
          },
        );
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <div>
            <div
              ref={refInput}
              className="form-control w-full"
              onClick={() => triggerRef()}
            >
              {label && (
                <label className="label">
                  <span className={`label-text`}>{label}</span>
                </label>
              )}
              <div
                // {...props}
                className={`${className ?? ""} ${
                  invalid ? "input-error" : "input-bordered"
                } input w-full flex items-center text-sm cursor-pointer hover:outline-1 hover:outline-double hover:outline-gray-400`}
              >
                {value?.name ? value?.name : placeholder}
              </div>
            </div>
            <input
              type="file"
              {...props}
              ref={refInput}
              onChange={(e) => onUpload(e)(onChange)}
              onClick={(event) => {
                event.target.value = null;
              }}
              className={"hidden"}
            />
            {invalid && (
              <label className="label">
                <span className="label-text-alt text-error">
                  {error?.message}
                </span>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default InputUploadFile;
