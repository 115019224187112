import React from "react";
import SelectInput from ".";

const options = [
  { value: "1 Tahun", label: "1 Tahun" },
  { value: "2 Tahun", label: "2 Tahun" },
  { value: "3 Tahun", label: "3 Tahun" },
  { value: "4 Tahun", label: "4 Tahun" },
  { value: "5 Tahun", label: "5 Tahun" },
];

const SelectTrainingPeriod = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectTrainingPeriod;
