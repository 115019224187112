import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { Button, Pagination, Spinner } from "components";
import { useLocation, useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { useSelector } from "react-redux";
import {
  getList,
  getListHiredOnMitra,
  getListNonHired,
  postData,
  updateStatusHired,
} from "services/hire";
import { MdAdd } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { CgSpinner } from "react-icons/cg";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";
import { Link } from "react-router-dom";

const StudentHired = () => {
  const modalForm = useRef();
  const location = useLocation();
  const modalConfirm = useRef();
  const popupUpload = useRef();

  const filterFromParams = location?.state?.filter ?? null;

  const { user } = useSelector(({ auth }) => auth);

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [nonHired, setNonHired] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    status: "",
    page: 1,
  });
  const [filterNonHired, setFilterNonHired] = useState({
    role: null,
    search: "",
    status: "",
    page: 1,
  });

  const [search, setSearch] = useState("");
  const [statusSelected, setStatusSelected] = useState(null);

  const [searchStudentNotHired, setSearchStudentNotHired] = useState("");

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [isOpenModalAddStudent, setIsOpenModalAddStudent] = useState();

  const { formState, reset, clearErrors } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    fetchList(filter);
  }, [filter]);

  useEffect(() => {
    if (filterFromParams) {
      const status = _.find(
        statusOptions,
        (go) => go.value === filterFromParams?.status,
      );
      setStatusSelected(status);
      setFilter((prevState) => ({ ...prevState, status: status?.value }));
    }
  }, [filterFromParams]);

  useEffect(() => {
    if (user?.role === "partner_jepang") fetchListNonHired(filterNonHired);
  }, [filterNonHired]);

  useEffect(() => {
    return () => {
      onChangeSearch.cancel();
    };
  });

  const statusOptions = useMemo(
    () => [
      { value: "graduate", label: "合格した (Lulus)" },
      { value: "non-graduate", label: "通らない (Tidak Lulus)" },
    ],
    [],
  );

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const handleChangePageNonHired = (page) => {
    setFilterNonHired((prevState) => {
      // fetchListNonHired({ ...prevState, page: page.currentPage });
      return { ...prevState, page: page.currentPage };
    });
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const handleChangeSearchStudentNotHired = (e) => {
    setFilterNonHired((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearchStudentNotHired = useMemo((e, type) => {
    setSearchStudentNotHired(e?.target?.value);
    return _.debounce(handleChangeSearchStudentNotHired, 1000);
  }, []);

  const onChangeSearch = useMemo((e, type) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onClickConfirmAddData = (params) => {
    setIsOpenModalAddStudent(true);
    setSelectedData(params);
  };

  const onClickConfirmData = (params) => {
    modalConfirm?.current?.click();
    setIsConfirmModalOpen(true);
    setSelectedData(params);
  };

  const onConfirmData = () => {
    fetchAPI(
      { service: postData, params: { student_id: selectedData?.id } ?? null },
      async (success, response) => {
        if (success) {
          await modalConfirm?.current?.click();
          toast.success("Sukses menambahkan siswa!");
          fetchList(filter);
          fetchListNonHired();
          setSelectedData(null);
          setIsFormModalOpen(false);
          setIsConfirmModalOpen(false);
          setIsOpenModalAddStudent(false);
        }
      },
    );
  };

  const fetchList = (params) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        // service: getListHiredOnMitra,
        service: getList,
        params: {
          filter: params?.search,
          page: params?.page,
          passed: params?.status,
        },
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const fetchListNonHired = (params) => {
    setNonHired({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getListNonHired,
        params: { filter: params?.search, page: params?.page },
      },
      (success, response) => {
        if (success) {
          setNonHired({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setNonHired({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onDetail = (data) => {
    // navigate("/lesson/detail", { state: { data, id: data?.id } });
    setIsConfirmModalOpen(true);
    setSelectedData(data);
  };

  const onUpdateStatus = useCallback((value) => {
    fetchAPI(
      {
        service: updateStatusHired,
        params: {
          id: selectedData?.id,
          payload: {
            student_id: selectedData?.student_id,
            passed: !value ? "1" : "2",
          },
        },
      },
      (success) => {
        if (success) {
          toast.success("Sukses mengubah data!");
          fetchList(filter);
          setSelectedData(null);
          setIsConfirmModalOpen(false);
        }
      },
    );
  });

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={
              user?.role === "lpk_mitra" || user?.role === "lpk_mitra_so"
                ? 7
                : 6
            }
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
            colSpan={
              user?.role === "lpk_mitra" || user?.role === "lpk_mitra_so"
                ? 7
                : 6
            }
          >
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.student_name ?? ""}
              </h6>
            </div>
          </td>
          {(user?.role === "lpk_mitra" || user?.role === "lpk_mitra_so") && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.partner_name ?? "-"}
              </p>
            </td>
          )}
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.date_hired
                ? moment(data?.date_hired).format("DD MMMM YYYY")
                : ""}
            </p>
          </td>
          <td className="px-6 leading-normal align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.passed === "1"
                ? "Tidak Lulus"
                : data?.passed === "2"
                ? "Lulus"
                : "-"}
            </p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            {data?.training_order ? (
              <Link
                to={"/student-management/detail"}
                state={{ id: data?.student_id }}
              >
                <p className="mb-0 font-semibold leading-tight text-xs">
                  {`${data?.training_order?.company_name}, ${data?.training_order?.company_address}`}
                </p>
              </Link>
            ) : (
              <p className="mb-0 font-semibold leading-tight text-xs">{"-"}</p>
            )}
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              {/* {user?.role === "partner_jepang" && (
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onDetail(data)}
                >
                  Edit
                </a>
              )} */}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderDataNonHired = () => {
    if (nonHired?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={2}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (nonHired?.data?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
            colSpan={2}
          >
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(nonHired?.data?.data, (data, key) => {
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data?.name ?? ""}
              </h6>
            </div>
          </td>

          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4 justify-end">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onClickConfirmAddData(data)}
              >
                Add
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          {user?.role === "partner_jepang" ? (
            <div className="flex gap-2 items-start">
              <h5>合格者</h5>
              <h6>(List Siswa Terkirim)</h6>
            </div>
          ) : (
            <h5>List Siswa Terkirim</h5>
          )}

          <p>Menampilkan siswa yang sudah pilih oleh setiap partner jepang.</p>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="w-max md:w-120">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-7"
              onChange={onChangeSearch}
            />
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang"
                      ? "学生名 (Nama Siswa)"
                      : "Nama Siswa"}
                  </th>
                  {(user?.role === "lpk_mitra" ||
                    user?.role === "lpk_mitra_so") && (
                    <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Nama Perusahaan
                    </th>
                  )}
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang"
                      ? "送信日 (Tanggal Terkirim)"
                      : "Tanggal Terkirim"}
                  </th>
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang"
                      ? "状態 (Status)"
                      : "Status"}
                  </th>
                  <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang"
                      ? "求人票 (Training Order)"
                      : "Training Order"}
                  </th>
                  <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={isOpenModalAddStudent}
        setIsOpen={setIsOpenModalAddStudent}
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Tambah Siswa Terkirim</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menambahkan data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setIsOpenModalAddStudent((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onConfirmData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setIsOpenModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                variant="primary2"
                size="sm"
                // onClick={onDeleteData}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={isFormModalOpen} setIsOpen={setIsFormModalOpen}>
        <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl max-h-125 max-w-240">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">Tambah Siswa Terkirim</h5>
            <CgClose
              className="cursor-pointer"
              onClick={() => setIsFormModalOpen((prev) => !prev)}
            />
          </div>
          <div className="relative flex flex-col p-4 overflow-auto">
            <BaseInput
              type="text"
              placeholder="Search..."
              className="h-8 w-80 text-sm"
              onChange={onChangeSearchStudentNotHired}
            />
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  {/* <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th> */}
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    {user?.role === "partner_jepang"
                      ? "学生名 (Nama Siswa)"
                      : "Nama Siswa"}
                  </th>
                  <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                </tr>
              </thead>
              <tbody>{renderDataNonHired()}</tbody>
            </table>
            <div className="flex justify-end pt-2">
              {/* <label className="text-sm">Menampilkan 1 - 10</label> */}
              <Pagination
                pageLimit={nonHired?.data?.meta?.limit}
                totalRecords={nonHired?.data?.meta?.total ?? 0}
                currentPage={nonHired?.data?.meta?.page}
                onPageChanged={handleChangePageNonHired}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Update Status</h5>
              <CgClose
                className="cursor-pointer text-2xl"
                onClick={() => {
                  setIsConfirmModalOpen(false);
                  setSelectedData(null);
                }}
              />
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin akan melakukan update status siswa?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => onUpdateStatus(false)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                type="button"
                variant="primary2"
                size="sm"
                onClick={() => onUpdateStatus(true)}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default StudentHired;
