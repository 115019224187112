import BaseButton from "components/atoms/BaseButton";
import { BaseInput, SelectInput } from "components/v2";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgTrash } from "react-icons/cg";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router";

const FamilyStructure = ({ readOnly }) => {
  const { control } = useFormContext();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const { user } = useSelector(({ auth }) => auth);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "family_structures",
  });
  return (
    <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
      <div className="flex justify-between items-baseline p-6">
        <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
          <h6>家族構成</h6>
          <p>(Struktur Keluarga)</p>
        </div>
        {user?.role !== "partner_jepang" && !readOnly && (
          <BaseButton onClick={() => append({ name: "", relation: "" })}>
            Tambah
          </BaseButton>
        )}
      </div>

      <div className="px-6">
        <table className="table w-full">
          <tbody>
            {_.map(fields, (field, index) => (
              <tr key={field.id}>
                <td className="p-1">
                  <BaseInput
                    name={`family_structures.${index}.name`}
                    placeholder="Nama"
                    disabled={user?.role === "partner_jepang" || readOnly}
                    type="text"
                  />
                </td>
                <td className="p-1" colSpan={index === 0 && 2}>
                  <SelectInput
                    name={`family_structures.${index}.relation`}
                    placeholder="hubungan"
                    isDisabled={user?.role === "partner_jepang" || readOnly}
                    options={[
                      { value: "父 (Ayah)", label: "父 (Ayah)" },
                      { value: "母 (Ibu)", label: "母 (Ibu)" },
                      { value: "祖父 (Kake)", label: "祖父 (Kake)" },
                      { value: "祖母 (Nenek)", label: "祖母 (Nenek)" },
                      {
                        value: "兄 (Kaka laki-laki)",
                        label: "兄 (Kaka laki-laki)",
                      },
                      {
                        value: "姉 (Kaka perempuan)",
                        label: "姉 (Kaka perempuan)",
                      },
                      {
                        value: "弟 (Adik Laki-laki)",
                        label: "弟 (Adik Laki-laki)",
                      },
                      {
                        value: "妹 (Adik Perempuan)",
                        label: "妹 (Adik Perempuan)",
                      },
                    ]}
                    isClearable
                  />
                </td>
                {index > 0 && user?.role !== "partner_jepang" && !readOnly && (
                  <td className="pr-7 w-6 inline-flex">
                    <BaseButton onClick={() => remove(index)}>
                      <CgTrash size={24} />
                    </BaseButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FamilyStructure;
