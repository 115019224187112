import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, QueryClient } from "react-query";

import BaseButton from "components/atoms/BaseButton";
import { BaseInput, Datepicker, SelectInput, YenInput } from "components/v2";
import { postTrainingOrder } from "services/training-order";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";

import SelectCorporate from "components/v2/Form/SelectInput/corporate";
import SelectVisaType from "components/v2/Form/SelectInput/visa-type";
import SelectTrainingPeriod from "components/v2/Form/SelectInput/training-period";

const DEFAULT_VALUES = {
  // address: "",
  // category: "",
  // salary: "",
  // forecast_join_date: "",
  // due_date: "",
  interview_date: "",
  departure_plan: "",
  due_date: "",
  company_entry_plans: "",
  corporate: null,
  director: "",
  company_address: "",
  internship_address: "",
  company_profile: "",
  work_type: "",
  visa_type: null,
  internship_detail: "",
  amount_received: "",
  amount_received_woman: "",
  min_age: "",
  max_age: "",
  education: "",
  desire: "",
  selection_method: "",
  written_test: "",
  skills_test: "",
  training_period: null,
  basic_salary: "",
  allowance: "",
  tax: "",
  health_insurance: "",
  old_age_insurance: "",
  life_money: "",
  example_of_net_salary: "",
  holiday: "",
  working_hour: "",
  bonus: "",
};
const schema = yup.object().shape({
  interview_date: yup.string().required(),
  departure_plan: yup.string().required(),
  company_entry_plans: yup.string().required(),
  corporate: yup.mixed().required(),
  director: yup.string().required(),
  company_address: yup.string().required(),
  internship_address: yup.string().required(),
  // company_profile: yup.string().required(),
  work_type: yup.string().required(),
  visa_type: yup.mixed().required(),
  internship_detail: yup.string().required(),
  due_date: yup.string().required(),
  amount_received: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(),
  amount_received_woman: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(),
  min_age: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(),
  max_age: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(),
  education: yup.string().required(),
  desire: yup.string().required(),
  selection_method: yup.string().required(),
  written_test: yup.string().required(),
  // skills_test: yup.string().required(),
  training_period: yup.mixed().required(),
  basic_salary: yup.string().required(),
  allowance: yup.string().required(),
  tax: yup.string().required(),
  health_insurance: yup.string().required(),
  old_age_insurance: yup.string().required(),
  life_money: yup.string().required(),
  example_of_net_salary: yup.string().required(),
  holiday: yup.string().required(),
  working_hour: yup.string().required(),
  bonus: yup.string().required(),
});

const TrainingOrderForm = () => {
  const navigate = useNavigate();
  const id = null;

  const { user } = useSelector(({ auth }) => auth);

  const queryClient = new QueryClient();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const post = useMutation({
    mutationFn: (payload) => postTrainingOrder(payload),
  });

  useEffect(() => {
    const corporate = methods.watch("corporate");
    if (corporate) {
      methods.resetField("company_address", {
        defaultValue: corporate?.address,
      });
    }
  }, [methods.watch("corporate")]);

  useEffect(() => {
    if (!methods.watch("min_age")) {
      methods.resetField("max_age", { defaultValue: "" });
    }
  }, [methods.watch("min_age")]);

  const onSubmit = (payload) => {
    if (payload) {
      const params = {
        ...payload,
        interview_date: new Date(payload?.interview_date),
        departure_plan: new Date(payload?.departure_plan),
        training_period: payload?.training_period?.value,
        due_date: new Date(payload?.due_date),
        skills_test: payload?.skills_test?.value,
        partner_id: user?.partner_id,
        company_name: payload?.corporate?.label,
        corporate_id: payload?.corporate?.value,
        visa_type: payload?.visa_type?.value,
        amount_received: payload?.amount_received
          ? parseInt(payload?.amount_received)
          : 0,
        amount_received_woman: payload?.amount_received_woman
          ? parseInt(payload?.amount_received_woman)
          : 0,
        holiday: payload?.holiday ? parseInt(payload?.holiday) : 0,
        tax: parseInt(payload?.tax?.split(".").join("")),
        old_age_insurance: parseInt(
          payload?.old_age_insurance?.split(".").join(""),
        ),
        life_money: parseInt(payload?.life_money?.split(".").join("")),
        health_insurance: parseInt(
          payload?.health_insurance?.split(".").join(""),
        ),
        example_of_net_salary: parseInt(
          payload?.example_of_net_salary?.split(".").join(""),
        ),
        basic_salary: parseInt(payload?.basic_salary?.split(".").join("")),
        allowance: parseInt(payload?.allowance?.split(".").join("")),
      };
      delete params.corporate;
      post.mutate(params, {
        onSuccess: (data) => {
          toast.success("Sukses menambahkan data!");
          methods.reset();
          queryClient.invalidateQueries("training-order");
          navigate(-1);
        },
        onError: (err) => {
          console.error(err, "ERR");
        },
      });
    }
  };

  return (
    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>{id ? "Edit Training Order" : "Tambah Training Order"}</h6>
        </div>
        <FormProvider {...methods}>
          <form className="space-y-6 p-6">
            <div className="grid grid-cols-3 gap-x-4 gap-y-2">
              <div className="col-span-3">
                <Datepicker
                  name="interview_date"
                  label={"現地面接日 (Tanggal Wawancara)"}
                  placeholder="Pilih tanggal wawancara"
                />
              </div>
              <div className="col-span-3">
                <Datepicker
                  name="departure_plan"
                  label="日本入国予定日 (Rencana Pemberangkatan)"
                  placeholder="Masukan rencana pemberangkatan"
                />
              </div>
              <div className="col-span-3">
                <Datepicker
                  name="due_date"
                  label="応募期限 (Batas Waktu Apply)"
                  placeholder="Masukan batas waktu apply"
                />
              </div>
              <div className="col-span-3">
                <BaseInput
                  name="company_entry_plans"
                  label="日本入国予定日 (Rencana Masuk Perusahaan)"
                  placeholder="Masukan rencana masuk perusahaan"
                />
              </div>

              {/* Divider */}
              <div className="col-span-3">
                <div className="h-[3px] bg-gray-400 mb-4" />
              </div>

              {/* <BaseInput
                name="company_name"
                label="企業名 (Nama Perusahaan)"
                placeholder="Masukan nama perusahaan"
              /> */}
              <SelectCorporate
                name="corporate"
                label="企業名 (Nama Perusahaan)"
                placeholder="Masukan nama perusahaan"
              />
              <BaseInput
                name="director"
                label="代表者 (Direktor)"
                placeholder="Masukan direktor"
              />
              <BaseInput
                name="company_address"
                label="本店所在地 (Alamat Perusahaan)"
                placeholder="Masukan alamat perusahaan"
                disabled
              />
              <BaseInput
                name="internship_address"
                label="実習実施場所 (Alamat Magang)"
                placeholder="Masukan alamat magang"
              />
              <BaseInput
                name="company_profile"
                label="会社案内 (Profile Perusahaan)"
                placeholder="Masukan Profile Perusahaan"
              />

              {/* Divider */}
              <div className="col-span-3">
                <div className="h-[3px] bg-gray-400 mb-4" />
              </div>

              <BaseInput
                name="work_type"
                label="受入職種 (Jenis Kerja)"
                placeholder="Masukan jenis kerja"
              />
              <SelectVisaType
                name="visa_type"
                label="ビザ申請職種名 (Jenis Visa)"
                placeholder="Masukan jenis visa"
              />
              <BaseInput
                name="internship_detail"
                label="実習作業内容(製品例) (Details Magang)"
                placeholder="Masukan details magang"
              />
              <BaseInput
                name="amount_received"
                label="受入人数 (Jumlah Diterima Laki-laki)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan jumlah diterima laki-laki"
              />
              <BaseInput
                name="amount_received_woman"
                label="受入人数 (Jumlah Diterima Perempuan)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan jumlah diterima perempuan"
              />
              <div className="flex items-end space-x-1">
                <BaseInput
                  name="min_age"
                  label="Minimal Usia"
                  placeholder="Minimal Usia"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <BaseInput
                  name="max_age"
                  label="Maksimal Usia"
                  placeholder="Maksimal Usia"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <BaseInput
                name="education"
                label="学歴 (Pendidikan)"
                placeholder="Masukan pendidikan"
              />
              <BaseInput
                name="desire"
                label="その他希望・要望 (Keinginan / Permintaan Lainnya)"
                placeholder="Masukan keinginan / permintaan lainnya"
              />

              {/* Divider */}
              <div className="col-span-3">
                <div className="h-[3px] bg-gray-400 mb-4" />
              </div>

              <BaseInput
                name="selection_method"
                label="選考方法 (Cara Seleksi)"
                placeholder="Masukan cara seleksi"
              />
              <BaseInput
                name="written_test"
                label="筆記試験 (Tes Tertulis)"
                placeholder="Masukan tes tertulis"
              />
              <SelectInput
                name="skills_test"
                label="実技試験 (Tes Skill)"
                placeholder="Masukan tes skill"
                options={[
                  { value: "Ada", label: "Ada" },
                  { value: "Tidak Ada", label: "Tidak Ada" },
                ]}
              />

              {/* Divider */}
              <div className="col-span-3">
                <div className="h-[3px] bg-gray-400 mb-4" />
              </div>

              <SelectTrainingPeriod
                name="training_period"
                label="講習期間 (Masa Pelatihan)"
                placeholder="Masukan masa pelatihan"
              />
              <YenInput
                name="basic_salary"
                label="基本給 (Gaji Pokok)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan gaji pokok"
              />
              <YenInput
                name="allowance"
                label="手当(ある場合) (Tunjangan (jika ada))"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan tunjangan"
              />
              <YenInput
                name="tax"
                label="所得税 (Pajak)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan pajak"
              />
              <YenInput
                name="health_insurance"
                label="健康保険料 (Asuransi Kesehatan)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan asuransi kesehatan"
              />
              <YenInput
                name="old_age_insurance"
                label="厚生年金保険料 (Asuransi Jaminan Masa Tua)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan asuransi jaminan masa tua"
              />
              <YenInput
                name="life_money"
                label="寮費 (Uang Tinggal)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan uang tinggal"
              />
              <YenInput
                name="example_of_net_salary"
                label="月収例 (Contoh Gajih Bersih)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan contoh gajih bersih"
              />
              <BaseInput
                name="holiday"
                label="年間休日 (Hari Libur)"
                placeholder="Masukan hari libur"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {/* <Datepicker
                name="holiday"
                label="年間休日 (Hari Libur)"
                placeholder="Masukan hari libur"
              /> */}
              <BaseInput
                name="working_hour"
                label="年間労働時間 (Jam Kerja)"
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Masukan jam kerja"
              />
              <BaseInput
                name="bonus"
                label="ボーナス (Bonus)"
                placeholder="Masukan bonus"
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="flex gap-3 py-4 justify-end px-6">
        <BaseButton
          variant="base"
          type="button"
          onClick={() => navigate(-1)}
          disabled={post?.isLoading}
        >
          Kembali
        </BaseButton>
        <BaseButton
          variant="primary2"
          type="button"
          disabled={post?.isLoading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          Kirim{" "}
          {post?.isLoading && (
            <span>
              <CgSpinner className="animate-spin text-sm text-primary" />
            </span>
          )}
        </BaseButton>
      </div>
    </section>
  );
};

export default TrainingOrderForm;
