import React, { useRef, useState } from "react";

import logo from "assets/bitode-logo.png";
import { Link, useLocation } from "react-router-dom";
import GlobalFooter from "components/organisms/GlobalFooter";
import { HiOutlineChevronDown } from "react-icons/hi";
import { getLocales } from "locales";
import cn from "clsx";
import { useClickOutside } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalesLang } from "features/locales/localesSlice";
import { MdClose, MdFormatAlignJustify } from "react-icons/md";
import { FaAlignJustify } from "react-icons/fa";

const GuestLayout = ({ children }) => {
  return (
    <div className="m-0 antialiased font-normal text-left leading-default text-base dark:bg-slate-950 bg-gray-50 text-slate-500 flex flex-col">
      <GlobalHeader />
      <main className="mt-[70px]">{children}</main>
      <GlobalFooter />
    </div>
  );
};

export default GuestLayout;

const GlobalHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();

  const { LANDING_PAGE } = getLocales();
  const dispatch = useDispatch();

  const { lang } = useSelector(({ locales }) => locales);

  const i18Menu = useRef(null);
  const i18MenuMobile = useRef(null);

  useClickOutside(i18Menu, () => {
    setIsOpen(false);
  });

  useClickOutside(i18MenuMobile, () => {
    setIsOpenMobile(false);
  });

  return (
    <header className="w-full h-[70px] flex items-center fixed top-0 z-50 backdrop-saturate-100 backdrop-blur-2xl bg-white/90 shadow-blur">
      <div className="container flex items-center">
        <div className="flex gap-4 items-center">
          <img src={logo} className="h-8" />
          <h6 className="m-0">Bitode Wira Indonesia</h6>
        </div>
        <nav className="mx-auto hidden lg:flex">
          <ul className="flex">
            <Link
              to="/"
              className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
            >
              <p
                className={cn(
                  "m-0 text-sm",
                  location.pathname === "/" && "font-semibold",
                )}
              >
                {LANDING_PAGE.HEADER.MENU.DASHBOARD}
              </p>
            </Link>
            <Link
              to="/article"
              className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
            >
              <p
                className={cn(
                  "m-0 text-sm",
                  location.pathname === "/article" && "font-semibold",
                )}
              >
                {LANDING_PAGE.HEADER.MENU.ARTICLE}
              </p>
            </Link>
            <Link
              to="/about-us"
              className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
            >
              <p
                className={cn(
                  "m-0 text-sm",
                  location.pathname === "/about-us" && "font-semibold",
                )}
              >
                {LANDING_PAGE.HEADER.MENU.ABOUT}
              </p>
            </Link>
            <Link
              to="/contact-us"
              className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
            >
              <p
                className={cn(
                  "m-0 text-sm",
                  location.pathname === "/contact-us" && "font-semibold",
                )}
              >
                {LANDING_PAGE.HEADER.MENU.CONTACT_US}
              </p>
            </Link>
          </ul>
        </nav>
        <div className="hidden gap-4 lg:flex">
          <div className="relative flex" ref={i18Menu}>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <p className="text-sm m-0">{LANDING_PAGE.HEADER.MENU.LANGUAGE}</p>
              <HiOutlineChevronDown className="text-sm" />
            </div>
            <div
              className={cn(
                "flex-col absolute -bottom-34 -left-5 w-40 rounded-2 bg-white shadow-blur",
                isOpen ? "flex" : "hidden",
              )}
            >
              <ul className="rounded-2">
                <li
                  className="flex cursor-pointer hover:bg-gray-100 px-4 py-2"
                  onClick={() => {
                    dispatch(updateLocalesLang("jp"));
                    setIsOpen(false);
                  }}
                >
                  Japan
                </li>
                <li
                  className="flex cursor-pointer hover:bg-gray-100 px-4 py-2"
                  onClick={() => {
                    dispatch(updateLocalesLang("en"));
                    setIsOpen(false);
                  }}
                >
                  English
                </li>
                <li
                  className="flex cursor-pointer hover:bg-gray-100 px-4 py-2 rounded-b-2"
                  onClick={() => {
                    dispatch(updateLocalesLang("id"));
                    setIsOpen(false);
                  }}
                >
                  Indonesian
                </li>
              </ul>
            </div>
          </div>
          <Link
            to="/login"
            className="text-sm font-medium border-2 px-4 py-1 rounded-md uppercase hover:border-blue-300 transition-all duration-300"
          >
            {LANDING_PAGE.HEADER.MENU.LOGIN}
          </Link>
        </div>

        <div className="flex lg:hidden ml-auto overflow-x-hidden">
          <FaAlignJustify
            size={24}
            onClick={() => setNavOpen((prev) => !prev)}
          />
          <div
            className={cn(
              "w-full h-screen absolute top-0 right-0 bg-white z-990 transition-all duration-200 flex flex-col lg:hidden",
              navOpen ? "translate-x-0" : "translate-x-full",
            )}
          >
            <div className="flex items-center gap-2 p-3 border-b">
              <MdClose size={20} onClick={() => setNavOpen((prev) => !prev)} />
              <h6 className="m-0 text-base">Bitode Wira Indonesia</h6>
            </div>

            <nav className="px-3">
              <ul>
                <Link
                  to="/"
                  className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p
                    className={cn(
                      "m-0 text-base",
                      location.pathname === "/" && "font-semibold",
                    )}
                  >
                    {LANDING_PAGE.HEADER.MENU.DASHBOARD}
                  </p>
                </Link>
                <Link
                  to="/article"
                  className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p
                    className={cn(
                      "m-0 text-base",
                      location.pathname === "/article" && "font-semibold",
                    )}
                  >
                    {LANDING_PAGE.HEADER.MENU.ARTICLE}
                  </p>
                </Link>
                <Link
                  to="/about-us"
                  className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p
                    className={cn(
                      "m-0 text-base",
                      location.pathname === "/about-us" && "font-semibold",
                    )}
                  >
                    {LANDING_PAGE.HEADER.MENU.ABOUT}
                  </p>
                </Link>
                <Link
                  to="/contact-us"
                  className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p
                    className={cn(
                      "m-0 text-base",
                      location.pathname === "/contact-us" && "font-semibold",
                    )}
                  >
                    {LANDING_PAGE.HEADER.MENU.CONTACT_US}
                  </p>
                </Link>
                <Link
                  to="/login"
                  className="py-2 hover:border-b hover:border-b-blue-400 cursor-pointer mx-4 h-10 transition-all duration-200"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p
                    className={cn(
                      "m-0 text-base",
                      location.pathname === "/login" && "font-semibold",
                    )}
                  >
                    {LANDING_PAGE.HEADER.MENU.LOGIN}
                  </p>
                </Link>
              </ul>
            </nav>
            <div className="relative flex px-3" ref={i18MenuMobile}>
              <div
                className="flex items-stretch justify-between w-full gap-2 cursor-pointer"
                onClick={() => {
                  setIsOpenMobile((prev) => !prev);
                }}
              >
                <p className="text-base m-0">
                  {LANDING_PAGE.HEADER.MENU.LANGUAGE}
                </p>
                <HiOutlineChevronDown size={20} />
              </div>
              <div
                className={cn(
                  "flex-col absolute -bottom-34 left-0 w-90 rounded-2 bg-white shadow-blur mx-3",
                  isOpenMobile ? "block" : "hidden",
                )}
              >
                <ul className="rounded-2">
                  <li
                    className="flex cursor-pointer hover:bg-gray-100 px-4 py-2"
                    onClick={() => {
                      dispatch(updateLocalesLang("jp"));
                      setIsOpen(false);
                    }}
                  >
                    Japan
                  </li>
                  <li
                    className="flex cursor-pointer hover:bg-gray-100 px-4 py-2"
                    onClick={() => {
                      dispatch(updateLocalesLang("en"));
                      setIsOpen(false);
                    }}
                  >
                    English
                  </li>
                  <li
                    className="flex cursor-pointer hover:bg-gray-100 px-4 py-2 rounded-b-2"
                    onClick={() => {
                      dispatch(updateLocalesLang("id"));
                      setIsOpen(false);
                    }}
                  >
                    Indonesia
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
