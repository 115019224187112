import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Select,
  Spinner,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteAttachmentStudent,
  getDetailStudent,
  postDataStudent,
  putData,
  uploadAttachmentStudent,
  deleteAttachmentStudentById,
  uploadAvatar,
  deleteAvatar,
  getStudentProfile,
  putDataStudentProfile,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CgFileDocument, CgSpinner, CgTrash } from "react-icons/cg";
import { IoMdCloseCircle, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { updatePasswordStudentPersonal } from "services/auth";
import DummyProfile from "assets/img/dummy-profile-pic.png";
import { FaDownload, FaEye } from "react-icons/fa";
import { getHobies } from "features/core/coreSlice";
import BaseButton from "components/atoms/BaseButton";
import BaseInput from "components/atoms/BaseInput";
import ModalWrapper from "components/molecules/ModalWrapper";
import { FileItem } from "pages/StudentManagement/Components";

const Profile = () => {
  const uploadRef = useRef();
  const modalDelete = useRef();
  const uploadLangSkill = useRef();
  const uploadCertExam = useRef();
  const modalResetPassword = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const avatarRef = useRef();

  const { isLoadingHobies, hobies } = useSelector(({ core }) => core);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      personalities: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      family_structures: [
        {
          name: "",
          relation: "",
        },
      ],
      educations: [
        {
          education: null,
          name: "",
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = methods;

  const {
    fields: personalityFields,
    append: appendPersonality,
    remove: removePersonality,
  } = useFieldArray({
    control,
    name: "personalities",
  });

  const {
    fields: familyStructureFields,
    append: appendFamilyStructure,
    remove: removeFamilyStructure,
  } = useFieldArray({
    control,
    name: "family_structures",
  });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: "educations",
  });

  const {
    register: register_form_reset,
    handleSubmit: handleSubmit_form_reset,
    formState: { errors: errors_form_reset },
    reset: reset_form_reset,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const { user } = useSelector(({ auth }) => auth);

  const [avatar, setAvatar] = useState(null);
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState(null);
  const [entriesDate, setEntriesDate] = useState(null);
  const [bmiTotal, setBmiTotal] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);

  const [educationHistorySelected, setEducationHistorySelected] =
    useState(null);
  const [skillSelected, setSkillSelected] = useState(null);

  const [languageSkills, setLanguageSkills] = useState([]);
  const [idLangSkillSelected, setIdLangSkillSelected] = useState(null);
  const [idCertExamSelected, setIdCertExamSelected] = useState(null);
  const [certificationExam, setCertificationExam] = useState([]);

  const [showModalResetPassword, setShowModalResetPassword] = useState(false);

  const [id, setId] = useState(null);

  const [ijazah, setIjazah] = useState({
    value: null,
    name: "ijazah",
    label: "Ijazah",
    isError: { message: "", value: false },
  });
  const [ktp, setKtp] = useState({
    value: null,
    name: "ktp",
    label: "KTP",
    isError: { message: "", value: false },
  });
  const [kk, setKk] = useState({
    value: null,
    name: "kk",
    label: "KK",
    isError: { message: "", value: false },
  });
  const [skck, setSkck] = useState({
    value: null,
    name: "skck",
    label: "SKCK",
    isError: { message: "", value: false },
  });
  const [akteKelahiran, setAkteKelahiran] = useState({
    value: null,
    name: "akte_kelahiran",
    label: "Akte Kelahiran",
    isError: { message: "", value: false },
  });
  const [fotoLayarPutih, setFotoLayarPutih] = useState({
    value: null,
    name: "foto_layar_putih",
    label: "Foto Layar Putih",
    isError: { message: "", value: false },
  });
  const [ketKesehatan, setKetKesehatan] = useState({
    value: null,
    name: "ket_sehat",
    label: "Keterangan Kesehatan",
    isError: { message: "", value: false },
  });
  const [suratIzinOrtu, setSuratIzinOrtu] = useState({
    value: null,
    name: "surat_izin_ortu",
    label: "Surat Izin Orang Tua",
    isError: { message: "", value: false },
  });
  const [sertifikatKomp1, setSertifikatKomp1] = useState({
    value: null,
    name: "sertifikat_komp_1",
    label: "Sertifikat Kompetensi 1",
    isError: { message: "", value: false },
  });
  const [sertifikatKomp2, setSertifikatKomp2] = useState({
    value: null,
    name: "sertifikat_komp_2",
    label: "Sertifikat Kompetensi 2",
    isError: { message: "", value: false },
  });

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [studentCode, setStudentCode] = useState(null);

  const categories = useMemo(() => [
    { value: "Kaigo", label: "Kaigo" },
    { value: "Kensetsu", label: "Kensetsu" },
    { value: "Yosetsu", label: "Yosetsu" },
    { value: "Jidosasebi", label: "Jidosasebi" },
    { value: "Nogyo", label: "Nogyo" },
    { value: "Sokuhingsayjo", label: "Sokuhingsayjo" },
    { value: "Seijogyo", label: "Seijogyo" },
  ]);

  const religions = useMemo(() => [
    { value: "Kristen Protestan", label: "Kristen Protestan" },
    { value: "Kristen Katholik", label: "Kristen Katholik" },
    { value: "Islam", label: "Islam" },
    { value: "Hindu", label: "Hindu" },
    { value: "Buddha", label: "Buddha" },
    { value: "Khonghucu", label: "Khonghucu" },
  ]);

  const bloodTypes = useMemo(() => [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "AB", label: "AB" },
    { value: "O", label: "O" },
  ]);

  const educations = useMemo(() => [
    {
      value: "国立小学校 (SDN)",
      label: "国立小学校 (SDN)",
      type: "elementary_school",
    },
    {
      value: "私立小学校 (SD SWASTA)",
      label: "私立小学校 (SD SWASTA)",
      type: "elementary_school",
    },
    {
      value: "マドラサイブティダイヤー小学校等 (MI)",
      label: "マドラサイブティダイヤー小学校等 (MI)",
      type: "elementary_school",
    },
    {
      value: "国立中学校 (SMPN)",
      label: "国立中学校 (SMPN)",
      type: "junior_high_school",
    },
    {
      value: "私立中学校 (SMP SWASTA)",
      label: "私立中学校 (SMP SWASTA)",
      type: "junior_high_school",
    },
    {
      value: "マドラサ　サナウィヤー中学校等 (MTS)",
      label: "マドラサ　サナウィヤー中学校等 (MTS)",
      type: "junior_high_school",
    },
    {
      value: "国立高校 (SMAN)",
      label: "国立高校 (SMAN)",
      type: "senior_vocational_high_school",
    },
    {
      value: "私立高校 (SMA SWASTA)",
      label: "私立高校 (SMA SWASTA)",
      type: "senior_vocational_high_school",
    },
    {
      value: "マドラサアリヤー (MA)",
      label: "マドラサアリヤー (MA)",
      type: "senior_vocational_high_school",
    },
    {
      value: "国立職業高等学校 (SMKN)",
      label: "国立職業高等学校 (SMKN)",
      type: "senior_vocational_high_school",
    },
    {
      value: "私立職業高等学校 (SMK SWASTA)",
      label: "私立職業高等学校 (SMK SWASTA)",
      type: "senior_vocational_high_school",
    },
    {
      value: "ディプロマ　I (D1)",
      label: "ディプロマ　I (D1)",
      type: "collage",
    },
    {
      value: "ディプロマ　II (D2)",
      label: "ディプロマ　II (D2)",
      type: "collage",
    },
    {
      value: "ディプロマIII (D3)",
      label: "ディプロマIII (D3)",
      type: "collage",
    },
    { value: "学士 1 (S1)", label: "学士 1 (S1)", type: "collage" },
    { value: "学士 2 (S2)", label: "学士 2 (S2)", type: "collage" },
    { value: "学士 3 (S3)", label: "学士 3 (S3)", type: "collage" },
  ]);

  const skills = useMemo(() => [
    { value: "ILMU PENGETAHUAN ALAM", label: "ILMU PENGETAHUAN ALAM" },
    { value: "ILMU PENGETAHUAN SOSIAL", label: "ILMU PENGETAHUAN SOSIAL" },
    { value: "MANAJEMEN PERKANTORAN", label: "MANAJEMEN PERKANTORAN" },
    {
      value: "OTOMATISASI TATA KELOLA PERKANTORAN",
      label: "OTOMATISASI TATA KELOLA PERKANTORAN",
    },
    { value: "TEKNIK OTOMOTIF", label: "TEKNIK OTOMOTIF" },
    { value: "TEKNIK KENDARAAN RINGAN", label: "TEKNIK KENDARAAN RINGAN" },
    {
      value: "TEKNIK KOMPUTER DAN INFORMATIKA",
      label: "TEKNIK KOMPUTER DAN INFORMATIKA",
    },
    {
      value: "TEKNIK KOMPUTER DAN JARINGAN",
      label: "TEKNIK KOMPUTER DAN JARINGAN",
    },
    { value: "MULTIMEDIA", label: "MULTIMEDIA" },
    {
      value: "PEMINATAN ILMU PENGETAHUAN SOSIAL",
      label: "PEMINATAN ILMU PENGETAHUAN SOSIAL",
    },
    { value: "PONDOK PESANTREN ISLAM", label: "PONDOK PESANTREN ISLAM" },
    {
      value: "PEMINATAN MATEMATIKA DAN ILMU PENGETAHUAN ALAM",
      label: "PEMINATAN MATEMATIKA DAN ILMU PENGETAHUAN ALAM",
    },
    { value: "AKUNTANSI DAN KEUANGAN", label: "AKUNTANSI DAN KEUANGAN" },
    {
      value: "AKUNTANSI DAN KEUANGAN LEMBAGA",
      label: "AKUNTANSI DAN KEUANGAN LEMBAGA",
    },
    { value: "TATA BOGA", label: "TATA BOGA" },
    { value: "JASA BOGA", label: "JASA BOGA" },
    {
      value: "TEKNIK DAN BISNIS SEPEDA MOTOR",
      label: "TEKNIK DAN BISNIS SEPEDA MOTOR",
    },
    { value: "REKAYASA PERANGKAT LUNAK", label: "REKAYASA PERANGKAT LUNAK" },
    {
      value: "AGRIBISNIS PRODUKSI TANAMAN",
      label: "AGRIBISNIS PRODUKSI TANAMAN",
    },
    {
      value: "AGRIBISNIS TANAMAN PANGAN DAN HORTIKULTURA",
      label: "AGRIBISNIS TANAMAN PANGAN DAN HORTIKULTURA",
    },
    { value: "PENDIDIKAN DOKTER", label: "PENDIDIKAN DOKTER" },
    { value: "PENDIDIKAN DOKTER GIGI", label: "PENDIDIKAN DOKTER GIGI" },
    { value: "FARMASI", label: "FARMASI" },
    { value: "KESEHATAN MASYARAKAT", label: "KESEHATAN MASYARAKAT" },
    { value: "KEPERAWATAN", label: "KEPERAWATAN" },
    { value: "KEBIDANAN", label: "KEBIDANAN" },
    { value: "ILMU GIZI", label: "ILMU GIZI" },
    { value: "KEDOKTERAN HEWAN", label: "KEDOKTERAN HEWAN" },
  ]);

  useEffect(() => {
    if (user?.id) {
      setId(user?.id);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      reset({
        name: detail?.data?.name,
        ttl: detail?.data?.ttl,
        email: detail?.data?.email,
        phone: detail?.data?.phone,
        address: detail?.data?.address,
        educations: detail?.data?.education_history,
        work_history: detail?.data?.work_history,
        hobby: detail?.data?.hobby_id
          ? {
              value: parseInt(detail?.data?.hobby_id),
              label: _.capitalize(detail?.data?.hobby_name),
            }
          : null,
        skills: detail?.data?.skills
          ? {
              value: detail?.data?.skills,
              label: detail?.data?.skills,
            }
          : null,
        category: detail?.data?.category
          ? {
              value: detail?.data?.category,
              label: detail?.data?.category,
            }
          : null,
        religion: detail?.data?.religion
          ? {
              value: detail?.data?.religion,
              label: detail?.data?.religion,
            }
          : null,
        blood_type: detail?.data?.blood_type
          ? {
              value: detail?.data?.blood_type,
              label: detail?.data?.blood_type,
            }
          : null,
        mastery_of_language: detail?.data?.mastery_of_language,
        link_youtube: detail?.data?.link_youtube,
        old: detail?.data?.old,
        entries_date: detail?.data?.entries_date
          ? moment(detail?.data?.entries_date).format("YYYY-MM-DD")
          : null,
        apprenticeship: detail?.data?.apprenticeship,
        family_structure: detail?.data?.family_structure,
        height: detail?.data?.height,
        weight: detail?.data?.weight,
        bmi: detail?.data?.bmi,
        personality: detail?.data?.personality,
        interest_job: detail?.data?.interest_job,
        family_structures: detail?.data?.family_structure,
        personalities: _.map(detail?.data?.personality, (personality) => ({
          value: personality,
        })),
      });
      setPhone(detail?.data?.phone);
      mappingDocument(detail?.data?.documents);
      detail?.data?.image && setAvatar({ name: detail?.data?.image });
      setHeight(detail?.data?.height);
      setWeight(detail?.data?.weight);
      setBmiTotal(detail?.data?.bmi);
      if (detail?.data?.gender) {
        setGender(detail?.data?.gender);
      }
      if (detail?.data?.entries_date) {
        setEntriesDate(new Date(detail?.data?.entries_date));
      }
      setStudentCode(detail?.data?.code);
    }
  }, [detail]);

  useEffect(() => {
    if (entriesDate) {
      const current = moment();
      const selected = moment(entriesDate);
      const diffYear = selected.diff(current, "years");
      let diffMonth = selected.diff(current, "months");
      const resultOfYear = diffYear < 0 ? diffYear * -1 : 0;
      const resultOfMonth = diffMonth < 0 ? (diffMonth %= 12) * -1 : 0;
      setValue(
        "apprenticeship",
        `${resultOfYear} 年 (tahun) ${resultOfMonth} 月 (bulan)`,
      );
    }
  }, [entriesDate]);

  const mappingDocument = (documents) => {
    const getIjazah = _.find(
      documents,
      (document) => document?.type === "ijazah",
    );
    const getLanguageSkills = _.filter(
      documents,
      (document) => document?.type === "language_skills",
    );
    const getCertExam = _.filter(
      documents,
      (document) => document?.type === "certification_exam",
    );
    const getKtp = _.find(documents, (document) => document?.type === "ktp");
    const getKk = _.find(documents, (document) => document?.type === "kk");
    const getSkck = _.find(documents, (document) => document?.type === "skck");
    const getAkteKelahiran = _.find(
      documents,
      (document) => document?.type === "akte_kelahiran",
    );
    const getFotoLayarPutih = _.find(
      documents,
      (document) => document?.type === "foto_layar_putih",
    );
    const getKetSehat = _.find(
      documents,
      (document) => document?.type === "ket_sehat",
    );
    const getSuratIzinOrtu = _.find(
      documents,
      (document) => document?.type === "surat_izin_ortu",
    );
    const getCertificate1 = _.find(
      documents,
      (document) => document?.type === "sertifikat_komp_1",
    );
    const getCertificate2 = _.find(
      documents,
      (document) => document?.type === "sertifikat_komp_2",
    );

    setLanguageSkills((prevState) => [
      ...prevState,
      ..._.map(getLanguageSkills, (ls) => ({
        value: {
          ...ls,
          name: ls?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
        id: ls.id,
        isError: { message: "", isError: false },
      })),
    ]);
    setCertificationExam((prevState) => [
      ...prevState,
      ..._.map(getCertExam, (ls) => ({
        value: {
          ...ls,
          name: ls?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
        id: ls.id,
        isError: { message: "", isError: false },
      })),
    ]);
    getIjazah &&
      setIjazah((prevState) => ({
        ...prevState,
        value: {
          ...getIjazah,
          name: getIjazah?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
      }));
    getKtp &&
      setKtp((prevState) => ({
        ...prevState,
        value: {
          ...getKtp,
          name: getKtp?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
      }));
    getKk &&
      setKk((prevState) => ({
        ...prevState,
        value: {
          ...getKk,
          name: getKk?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
      }));
    getSkck &&
      setSkck((prevState) => ({
        ...prevState,
        value: {
          ...getSkck,
          name: getSkck?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
      }));
    getAkteKelahiran &&
      setAkteKelahiran((prevState) => ({
        ...prevState,
        value: {
          ...getAkteKelahiran,
          name: getAkteKelahiran?.name?.replace(
            `/${detail?.data?.student_id}/`,
            "",
          ),
        },
      }));
    getFotoLayarPutih &&
      setFotoLayarPutih((prevState) => ({
        ...prevState,
        value: {
          ...getFotoLayarPutih,
          name: getFotoLayarPutih?.name?.replace(
            `/${detail?.data?.student_id}/`,
            "",
          ),
        },
      }));
    getKetSehat &&
      setKetKesehatan((prevState) => ({
        ...prevState,
        value: {
          ...getKetSehat,
          name: getKetSehat?.name?.replace(`/${detail?.data?.student_id}/`, ""),
        },
      }));
    getSuratIzinOrtu &&
      setSuratIzinOrtu((prevState) => ({
        ...prevState,
        value: {
          ...getSuratIzinOrtu,
          name: getSuratIzinOrtu?.name?.replace(
            `/${detail?.data?.student_id}/`,
            "",
          ),
        },
      }));
    getCertificate1 &&
      setSertifikatKomp1((prevState) => ({
        ...prevState,
        value: {
          ...getCertificate1,
          name: getCertificate1?.name?.replace(
            `/${detail?.data?.student_id}/`,
            "",
          ),
        },
      }));
    getCertificate2 &&
      setSertifikatKomp2((prevState) => ({
        ...prevState,
        value: {
          ...getCertificate2,
          name: getCertificate2?.name?.replace(
            `/${detail?.data?.student_id}/`,
            "",
          ),
        },
      }));
  };

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getStudentProfile }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const revalidateDocument = () => {
    const func = [
      setIjazah,
      setKtp,
      setKk,
      setSkck,
      setAkteKelahiran,
      setFotoLayarPutih,
      setKetKesehatan,
      setSuratIzinOrtu,
    ];

    const mapFunc = _.map(func, (item) =>
      item((prevState) => {
        if (!prevState?.value) {
          return {
            ...prevState,
            isError: {
              value: true,
              message: `${prevState?.label} wajib diisi`,
            },
          };
        } else {
          return { ...prevState, isError: { value: false, message: "" } };
        }
      }),
    );
    return mapFunc;
  };

  const validateDocument = () => {
    const value = [
      ijazah?.value,
      ktp?.value,
      kk?.value,
      skck?.value,
      akteKelahiran?.value,
      fotoLayarPutih?.value,
      ketKesehatan?.value,
      suratIzinOrtu?.value,
    ];

    const result = _.every(value, (item) => item);
    return result;
  };

  const [showPass, setShowPass] = useState(false);

  const renderPasswordIcon = () => {
    return (
      <span
        className="absolute top-3 right-2 text-gray-600 cursor-pointer h-6 w-6 text-center"
        onClick={() => setShowPass((prev) => !prev)}
      >
        {showPass ? <IoMdEye /> : <IoMdEyeOff />}
      </span>
    );
  };

  const onSelectCertExamFile = (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        setCertificationExam((prevState) => {
          const formData = new FormData();
          formData.append("image", file);
          fetchAPI(
            { service: uploadAttachmentStudent, params: formData },
            (success, response) => {
              if (!success) {
                toast.error("Ekstensi file tidak valid", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 2500,
                });
              }
            },
          );
          return [
            ...prevState,
            { value: file, isError: { value: false, message: "" } },
          ];
        });
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };
  const onClickDeleteCertExamData = () => {
    modalDelete?.current?.click();
  };

  const onDeleteCertExamFile = (data, index) => {
    try {
      if (data?.id) {
        setIdCertExamSelected(data?.id ?? null);
        onClickDeleteCertExamData();
      } else {
        fetchAPI(
          {
            service: deleteAttachmentStudent,
            params: data?.value?.name,
          },
          (success, response) => {
            if (success) {
              let certExamTemp = [...certificationExam];
              certExamTemp = _.filter(certExamTemp, (v, key) => key !== index);
              setCertificationExam(certExamTemp);
            }
          },
        );
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const onSelectLanguageSkillFile = (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        setLanguageSkills((prevState) => {
          const formData = new FormData();
          formData.append("image", file);
          fetchAPI(
            { service: uploadAttachmentStudent, params: formData },
            (success, response) => {
              if (!success) {
                toast.error("Ekstensi file tidak valid", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 2500,
                });
              }
            },
          );
          return [
            ...prevState,
            { value: file, isError: { value: false, message: "" } },
          ];
        });
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const onClickDeleteLangSkillData = () => {
    modalDelete?.current?.click();
  };

  const onDeleteLanguageSkillFile = (data, index) => {
    try {
      if (data?.id) {
        setIdLangSkillSelected(data?.id ?? null);
        onClickDeleteLangSkillData();
      } else {
        fetchAPI(
          {
            service: deleteAttachmentStudent,
            params: data?.value?.name,
          },
          (success, response) => {
            if (success) {
              let languageSkillsTemp = [...languageSkills];
              languageSkillsTemp = _.filter(
                languageSkillsTemp,
                (v, key) => key !== index,
              );
              setLanguageSkills(languageSkillsTemp);
            }
          },
        );
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const onSelectFile = async (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png")
      ) {
        const size = Math.round(file.size / 1024);
        if (size <= 10000) {
          const formData = new FormData();
          formData.append("image", file);
          fetchAPI(
            { service: uploadAvatar, params: formData },
            (success, response) => {
              if (success) {
                const objectUrl = URL.createObjectURL(file);
                const filename = file?.name ?? "";
                setAvatar({ file, objectUrl, name: filename });
              }
            },
          );
        } else {
          toast.error("Ukuran file maksimal 10MB", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 2500,
          });
        }
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const onSubmit = (data) => {
    const validDocument = validateDocument();
    // if (data && validDocument) {
    if (data) {
      let params = {};

      params.code = studentCode;
      params.name = data?.name;
      params.ttl = data?.ttl;
      params.email = data?.email;
      params.phone = phone;
      params.address = data?.address;
      params.roles = "4";
      params.education_history = JSON.stringify(data?.educations);
      params.hobby =
        methods?.watch("hobby")?.value === "other"
          ? data?.other_hobby
          : methods?.watch("hobby")?.value;
      params.skills = methods?.watch("skills")?.value ?? "";
      params.work_history = data?.work_history;
      params.mastery_of_language = data?.mastery_of_language;
      params.category = methods?.watch("category")?.value ?? "";
      params.gender = gender;
      if (avatar?.name) params.image = avatar?.name;

      params.link_youtube = data?.link_youtube;

      params.province_id = "32";
      params.city_id = "3217";
      params.district_id = "321706";

      params.old = data?.old ? parseInt(data?.old) : null;
      params.entries_date = data?.entries_date
        ? moment(data?.entries_date).format("YYYY-MM-DD")
        : null;
      params.apprenticeship = data?.apprenticeship ?? "";
      params.religion = methods?.watch("religion")?.value ?? "";
      params.family_structure = JSON.stringify(data?.family_structures);
      params.blood_type = methods?.watch("blood_type")?.value ?? "";
      params.height = data?.height ? parseInt(data?.height) : null;
      params.weight = data?.weight ? parseInt(data?.weight) : null;
      params.bmi = bmiTotal ?? "";
      // params.personality = data?.personality ?? "";
      params.personality = _.map(data?.personalities, "value").join(",");
      params.interest_job = data?.interest_job ?? "";

      if (languageSkills?.length > 0)
        params.language_skills = _.map(languageSkills, (v) => v?.value?.name);
      if (certificationExam.length > 0)
        params.certification_exam = _.map(
          certificationExam,
          (v) => v?.value?.name,
        );

      setCreateData((prevState) => ({ ...prevState, isLoading: true }));
      if (id) {
        const documentExist = _.map(detail?.data?.documents, "name");
        if (!_.find(documentExist, (de) => de.includes(ijazah?.value?.name)))
          params.ijazah = ijazah?.value?.name ? [ijazah?.value?.name] : [];
        if (!_.find(documentExist, (de) => de.includes(ktp?.value?.name)))
          params.ktp = ktp?.value?.name ? [ktp?.value?.name] : [];
        if (!_.find(documentExist, (de) => de.includes(kk?.value?.name)))
          params.kk = kk?.value?.name ? [kk?.value?.name] : [];
        if (!_.find(documentExist, (de) => de.includes(skck?.value?.name)))
          params.skck = skck?.value?.name ? [skck?.value?.name] : [];
        if (
          !_.find(documentExist, (de) =>
            de.includes(akteKelahiran?.value?.name),
          )
        )
          params.akte_kelahiran = akteKelahiran?.value?.name
            ? [akteKelahiran?.value?.name]
            : [];
        if (
          !_.find(documentExist, (de) =>
            de.includes(fotoLayarPutih?.value?.name),
          )
        )
          params.foto_layar_putih = fotoLayarPutih?.value?.name
            ? [fotoLayarPutih?.value?.name]
            : [];
        if (
          !_.find(documentExist, (de) => de.includes(ketKesehatan?.value?.name))
        )
          params.ket_sehat = ketKesehatan?.value?.name
            ? [ketKesehatan?.value?.name]
            : [];
        if (
          !_.find(documentExist, (de) =>
            de.includes(suratIzinOrtu?.value?.name),
          )
        )
          params.surat_izin_ortu = suratIzinOrtu?.value?.name
            ? [suratIzinOrtu?.value?.name]
            : [];
        if (
          !_.find(documentExist, (de) =>
            de.includes(sertifikatKomp1?.value?.name),
          ) &&
          sertifikatKomp1?.value?.name
        )
          params.sertifikat_komp_1 = sertifikatKomp1?.value?.name
            ? [sertifikatKomp1?.value?.name]
            : [];
        if (
          !_.find(documentExist, (de) =>
            de.includes(sertifikatKomp2?.value?.name),
          ) &&
          sertifikatKomp2?.value?.name
        )
          params.sertifikat_komp_2 = sertifikatKomp2?.value?.name
            ? [sertifikatKomp2?.value?.name]
            : [];

        fetchAPI(
          { service: putDataStudentProfile, params },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses mengubah data!");
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      } else {
        params.ijazah = ijazah?.value?.name ? [ijazah?.value?.name] : [];
        params.ktp = ktp?.value?.name ? [ktp?.value?.name] : [];
        params.kk = kk?.value?.name ? [kk?.value?.name] : [];
        params.skck = skck?.value?.name ? [skck?.value?.name] : [];
        params.akte_kelahiran = akteKelahiran?.value?.name
          ? [akteKelahiran?.value?.name]
          : [];
        params.foto_layar_putih = fotoLayarPutih?.value?.name
          ? [fotoLayarPutih?.value?.name]
          : [];
        params.ket_sehat = ketKesehatan?.value?.name
          ? [ketKesehatan?.value?.name]
          : [];
        params.surat_izin_ortu = suratIzinOrtu?.value?.name
          ? [suratIzinOrtu?.value?.name]
          : [];
        if (sertifikatKomp1?.value?.name)
          params.sertifikat_komp_1 = sertifikatKomp1?.value?.name
            ? [sertifikatKomp1?.value?.name]
            : [];
        if (sertifikatKomp2?.value?.name)
          params.sertifikat_komp_2 = sertifikatKomp2?.value?.name
            ? [sertifikatKomp2?.value?.name]
            : [];

        params.email = data?.email;
        params.roles = "4";
        params.password = data?.password;
        fetchAPI(
          { service: postDataStudent, params: params },
          (success, response) => {
            if (success) {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isSuccess: true,
                data: response,
              }));
              toast.success("Sukses menambahkan data!");
              navigate(-1);
            } else {
              setCreateData((prevState) => ({
                ...prevState,
                isLoading: false,
                isError: true,
                data: response,
                errorMessage: getErrorMessage(response),
              }));
            }
          },
        );
      }
    }
  };

  const handleOptionsEducation = () => {
    const listExistEducation = _.filter(
      _.map(methods.watch("educations"), (education) => education?.education),
      (data) => data,
    );
    const typeCollections = _.uniq(_.map(listExistEducation, "type"));
    if (listExistEducation?.length > 0) {
      return _.filter(
        educations,
        (data) => !typeCollections.includes(data?.type),
      );
    }
    return educations;
  };

  const handleChangeEntriesDate = (date) => {
    setEntriesDate(date);
    setValue("entries_date", date);
  };

  const handleChangePhone = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("phone", value);
      setPhone(value);
    }
  };

  const handleChangeHeight = (event) => {
    const value = event?.target?.value;
    const valid = event?.target?.validity?.valid;
    valid && setHeight(value);
    let bmi_total =
      parseFloat(weight) /
      ((parseFloat(value) / 100) * (parseFloat(value) / 100));
    if (valid && weight) {
      if (!_.isNaN(bmi_total)) {
        setBmiTotal(bmi_total.toFixed(2));
      } else {
        setBmiTotal(0);
      }
    } else {
      setBmiTotal(null);
    }
  };
  const handleChangeWeight = (event) => {
    const value = event?.target?.value;
    const valid = event?.target?.validity?.valid;
    valid && setWeight(value);
    let bmi_total =
      parseFloat(value) /
      ((parseFloat(height) / 100) * (parseFloat(height) / 100));
    if (valid && height) {
      if (!_.isNaN(bmi_total)) {
        setBmiTotal(bmi_total.toFixed(2));
      } else {
        setBmiTotal(0);
      }
    } else {
      setBmiTotal(null);
    }
  };

  const onPopupResetPassword = () => {
    modalResetPassword?.current?.click();
  };

  const onSubmitResetPassword = (data) => {
    const params = {
      password: data?.old_password,
      new_password: data?.new_password,
    };
    fetchAPI(
      { service: updatePasswordStudentPersonal, params },
      (success, response) => {
        if (success) {
          toast.success("Berhasil reset password!");
          modalResetPassword?.current?.click();
          setShowOldPass(false);
          setShowNewPass(false);
          setShowConfirmPass(false);
          reset_form_reset({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        }
      },
    );
  };

  const renderModalResetPassword = () => (
    <>
      <input
        ref={modalResetPassword}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Reset Password</h3>
          <form onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}>
            <div className="space-y-4 py-4">
              <InputValidation
                {...register_form_reset("old_password", {
                  required: {
                    value: true,
                    message: "Password Lama wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Lama minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showOldPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowOldPass(!showOldPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Lama"
                name="old_password"
                type={showOldPass ? "text" : "password"}
                placeholder="Masukan Password Lama"
                isError={{
                  value: errors_form_reset.old_password,
                  message: errors_form_reset.old_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("new_password", {
                  required: {
                    value: true,
                    message: "Password Baru wajib diisi",
                  },
                  minLength: {
                    value: 8,
                    message: "Password Baru minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showNewPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowNewPass(!showNewPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Password Baru"
                name="new_password"
                type={showNewPass ? "text" : "password"}
                placeholder="Masukan Password Baru"
                isError={{
                  value: errors_form_reset.new_password,
                  message: errors_form_reset.new_password?.message,
                }}
              />
              <InputValidation
                {...register_form_reset("confirm_password", {
                  required: {
                    value: true,
                    message: "Konfirmasi Password wajib diisi",
                  },
                  validate: (val) => {
                    if (watch("new_password") != val) {
                      return "Konfirmasi Password tidak sama dengan pasword baru";
                    }
                  },
                  minLength: {
                    value: 8,
                    message: "Konfirmasi Password minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showConfirmPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    />
                  )
                }
                autoComplete="new-password"
                label="Konfirmasi Password"
                name="confirm_password"
                type={showConfirmPass ? "text" : "password"}
                placeholder="Masukan Konfirmasi Password"
                isError={{
                  value: errors_form_reset.confirm_password,
                  message: errors_form_reset.confirm_password?.message,
                }}
              />
            </div>
            <div className="modal-action">
              <Button
                label={"Tidak"}
                size="sm"
                theme="error"
                type="button"
                onClick={() => {
                  setShowOldPass(false);
                  setShowNewPass(false);
                  setShowConfirmPass(false);
                  reset_form_reset({
                    old_password: "",
                    new_password: "",
                    confirm_password: "",
                  });
                  modalResetPassword?.current?.click();
                }}
              />
              <Button label={"Ya"} size="sm" theme="primary" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );

  return (
    // <div>
    //   {renderModalResetPassword()}
    //   <div className="w-full space-y-4">
    //     <div className="flex justify-between items-center">
    //       <span className="flex space-x-1 items-end">
    //         <Label text="プロフィール" className="font-bold text-2xl" />
    //         <span className="text-sm font-medium text-gray-500">
    //           {"(Profile)"}
    //         </span>
    //       </span>
    //     </div>

    //     <FormProvider {...methods}>
    //       <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
    //         <div className="space-y-8">
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 p-4 flex space-x-6 w-full pb-8"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex flex-col items-center p-4 space-y-6">
    //               <div className="avatar">
    //                 <div className="w-40 rounded-full">
    //                   <img
    //                     alt="avatar-student"
    //                     src={
    //                       detail?.data?.image && !avatar?.file
    //                         ? `${process.env.REACT_APP_API}/uploads/avatar/${
    //                             detail?.data?.user_id
    //                           }/${detail?.data?.image?.replace("/public", "")}`
    //                         : avatar?.objectUrl
    //                         ? avatar?.objectUrl
    //                         : DummyProfile
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //               <Button
    //                 type="button"
    //                 label="Pilih Foto"
    //                 onClick={() => uploadRef.current.click()}
    //               />
    //               <input
    //                 ref={uploadRef}
    //                 autoComplete="none"
    //                 accept="image/png, image/jpg, image/jpeg, application/pdf"
    //                 type="file"
    //                 onChange={onSelectFile}
    //                 onClick={(event) => {
    //                   event.target.value = null;
    //                 }}
    //                 className="opacity-0 hidden"
    //               />
    //             </div>
    //             <div className="w-full">
    //               <div className="flex items-center space-x-4">
    //                 <div className="flex-[1]">
    //                   <InputValidation
    //                     label="学生コード (Kode Siswa)"
    //                     type="text"
    //                     disabled
    //                     value={studentCode}
    //                     placeholder="Masukan Kode Siswa"
    //                   />
    //                 </div>
    //                 <div className="flex-[5]">
    //                   <InputValidation
    //                     {...register("name", {
    //                       required: {
    //                         value: true,
    //                         message: "Nama wajib diisi",
    //                       },
    //                     })}
    //                     label="名前 (Nama)"
    //                     type="name"
    //                     placeholder="Masukan Nama"
    //                     isError={{
    //                       value: errors.name,
    //                       message: errors.name?.message,
    //                     }}
    //                   />
    //                 </div>
    //               </div>
    //               <InputValidation
    //                 {...register("email", {
    //                   required: { value: true, message: "Email wajib diisi" },
    //                 })}
    //                 autoComplete="new-password"
    //                 label="メール (Email)"
    //                 type="email"
    //                 disabled={id}
    //                 placeholder="Masukan Email"
    //                 isError={{
    //                   value: errors.email,
    //                   message: errors.email?.message,
    //                 }}
    //               />
    //               {!id && (
    //                 <InputValidation
    //                   {...register("password", {
    //                     required: {
    //                       value: true,
    //                       message: "Password wajib diisi",
    //                     },
    //                     minLength: {
    //                       value: 8,
    //                       message: "Password minimal 8 karakter",
    //                     },
    //                   })}
    //                   autoComplete="new-password"
    //                   label="パスワード (Password)"
    //                   name="password"
    //                   type="password"
    //                   placeholder="Masukan Password"
    //                   isError={{
    //                     value: errors.password,
    //                     message: errors.password?.message,
    //                   }}
    //                 />
    //               )}
    //             </div>
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 pt-4 pb-8 grid grid-cols-2 gap-x-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <InputValidation
    //               {...register("ttl", {
    //                 required: {
    //                   value: false,
    //                   message: "Tempat Tanggal Lahir wajib diisi",
    //                 },
    //               })}
    //               label="生年月日 (Tempat Tanggal Lahir)"
    //               type="ttl"
    //               placeholder="Masukan Tempat Tanggal Lahir"
    //               isError={{
    //                 value: errors.ttl,
    //                 message: errors.ttl?.message,
    //               }}
    //             />
    //             <InputValidation
    //               {...register("phone", {
    //                 required: {
    //                   value: false,
    //                   message: "No. Handphone wajib diisi",
    //                 },
    //               })}
    //               label="電話番号 (No. Handphone)"
    //               type="phone"
    //               pattern="^[0-9]*$"
    //               value={phone}
    //               onChange={handleChangePhone}
    //               placeholder="Masukan No. Handphone"
    //               isError={{
    //                 value: errors.phone,
    //                 message: errors.phone?.message,
    //               }}
    //             />
    //             <InputValidation
    //               {...register("address", {
    //                 required: { value: false, message: "Alamat wajib diisi" },
    //               })}
    //               label="住所 (Alamat)"
    //               type="address"
    //               placeholder="Masukan Alamat"
    //               isError={{
    //                 value: errors.address,
    //                 message: errors.address?.message,
    //               }}
    //             />
    //             <InputValidation
    //               {...register("work_history", {
    //                 required: {
    //                   value: false,
    //                   message: "Riwayat Pekerjaan wajib diisi",
    //                 },
    //               })}
    //               label="学歴 (Riwayat Pekerjaan)"
    //               type="work_history"
    //               placeholder="Masukan Riwayat Pekerjaan"
    //               isError={{
    //                 value: errors.work_history,
    //                 message: errors.work_history?.message,
    //               }}
    //             />
    //             <div className="flex items-center justify-center space-x-4">
    //               <Select
    //                 {...register("hobby", {
    //                   required: {
    //                     value: false,
    //                     message: "Hobi wajib diisi",
    //                   },
    //                 })}
    //                 label="趣味 (Hobi)"
    //                 options={hobies}
    //                 placeholder="Masukan Nama Hobi"
    //                 onMenuOpen={() => dispatch(getHobies())}
    //                 isLoading={isLoadingHobies}
    //                 isClearable
    //                 isError={{
    //                   value: errors.hobby,
    //                   message: errors.hobby?.message,
    //                 }}
    //               />
    //               {methods?.watch("hobby")?.value === "other" && (
    //                 <InputValidation
    //                   {...register("other_hobby", {
    //                     required: {
    //                       value: false,
    //                       message: "Hobi wajib diisi",
    //                     },
    //                   })}
    //                   label={<>&nbsp;</>}
    //                   type="text"
    //                   placeholder="Masukan Hobi"
    //                   isError={{
    //                     value: errors.other_hobby,
    //                     message: errors.other_hobby?.message,
    //                   }}
    //                 />
    //               )}
    //             </div>
    //             <InputValidation
    //               {...register("old", {
    //                 required: {
    //                   value: false,
    //                   message: "Usia wajib diisi",
    //                 },
    //                 pattern: {
    //                   value: /^[0-9]*$/g,
    //                   message: "Usia hanya bisa diinput oleh angka",
    //                 },
    //               })}
    //               label="年 (Usia)"
    //               type="text"
    //               placeholder="Masukan Usia"
    //               isError={{
    //                 value: errors.old,
    //                 message: errors.old?.message,
    //               }}
    //             />
    //             <Select
    //               {...register("skills")}
    //               label="資格 (Keterampilan)"
    //               options={skills}
    //               placeholder="Masukan Nama Keterampilan"
    //               isClearable
    //             />
    //             <InputValidation
    //               {...register("mastery_of_language", {
    //                 required: {
    //                   value: false,
    //                   message: "Penguasaan Bahasa wajib diisi",
    //                 },
    //               })}
    //               label="母国語以外に できる外国語 (Penguasaan Bahasa)"
    //               type="mastery_of_language"
    //               placeholder="Masukan Penguasaan Bahasa"
    //               isError={{
    //                 value: errors.mastery_of_language,
    //                 message: errors.mastery_of_language?.message,
    //               }}
    //             />
    //             <Select
    //               {...register("category")}
    //               label="職種 (Kategori)"
    //               options={categories}
    //               placeholder="Masukan Nama Kategori"
    //               isClearable
    //             />
    //             <InputValidation
    //               {...register("link_youtube", {
    //                 required: {
    //                   value: false,
    //                   message: "Link youtube wajib diisi",
    //                 },
    //               })}
    //               label="ユーチューブのリンク (Link youtube)"
    //               type="link_youtube"
    //               placeholder="Masukan Link youtube"
    //               isError={{
    //                 value: errors.link_youtube,
    //                 message: errors.link_youtube?.message,
    //               }}
    //             />
    //             <Datepicker
    //               {...register("entries_date", {
    //                 required: {
    //                   value: false,
    //                   message: "Tanggal Masuk wajib diisi",
    //                 },
    //               })}
    //               label={"入国日 (Tanggal Masuk)"}
    //               selected={entriesDate}
    //               onChange={handleChangeEntriesDate}
    //               placeholder="Pilih Tanggal Masuk"
    //               isError={{
    //                 value: errors.entries_date,
    //                 message: errors.entries_date?.message,
    //               }}
    //             />
    //             <InputValidation
    //               {...register("apprenticeship")}
    //               label="勉強期間 (Masa Belajar)"
    //               type="text"
    //               readOnly={true}
    //               placeholder="Masukan Masa Belajar"
    //             />
    //             <Select
    //               {...register("religion")}
    //               label="宗教 (Agama)"
    //               options={religions}
    //               placeholder="Masukan Nama Agama"
    //               isClearable
    //             />
    //             <Select
    //               {...register("blood_type")}
    //               label="血液型 (Golongan Darah)"
    //               options={bloodTypes}
    //               placeholder="Masukan Nama Golongan Darah"
    //               isClearable
    //             />
    //             <div className="flex items-center space-x-4">
    //               <InputValidation
    //                 {...register("height", {
    //                   required: {
    //                     value: false,
    //                     message: "Tinggi badan wajib diisi",
    //                   },
    //                   pattern: {
    //                     value: /^[0-9,]*$/g,
    //                     message: "Tinggi badan hanya bisa diinput oleh angka",
    //                   },
    //                 })}
    //                 label="身長 (Tinggi badan)"
    //                 type="text"
    //                 pattern="^[0-9]*$"
    //                 name="height"
    //                 placeholder="Masukan Tinggi badan"
    //                 onChange={handleChangeHeight}
    //                 value={height}
    //                 isError={{
    //                   value: errors.height,
    //                   message: errors.height?.message,
    //                 }}
    //               />
    //               <InputValidation
    //                 {...register("weight", {
    //                   required: {
    //                     value: false,
    //                     message: "Berat badan wajib diisi",
    //                   },
    //                   pattern: {
    //                     value: /^[0-9,]*$/g,
    //                     message: "Berat badan hanya bisa diinput oleh angka",
    //                   },
    //                 })}
    //                 label="重さ (Berat badan)"
    //                 type="text"
    //                 pattern="^[0-9]*$"
    //                 name="weight"
    //                 placeholder="Masukan Berat badan"
    //                 onChange={handleChangeWeight}
    //                 value={weight}
    //                 isError={{
    //                   value: errors.weight,
    //                   message: errors.weight?.message,
    //                 }}
    //               />
    //             </div>
    //             <InputValidation
    //               label="BMI"
    //               value={bmiTotal}
    //               type="text"
    //               readOnly={true}
    //               placeholder="Masukan BMI"
    //             />
    //             <InputValidation
    //               {...register("interest_job")}
    //               label="興味のある仕事 (Pekerjaan yang diminati)"
    //               type="text"
    //               placeholder="Masukan Pekerjaan yang diminati"
    //             />
    //             <div className="w-full flex space-x-8 py-2 col-span-2">
    //               <label className="label">
    //                 <span className={`label-text`}>
    //                   {"性別 (Jenis Kelamin)"}
    //                 </span>
    //               </label>
    //               <div className="flex space-x-6">
    //                 <div className="flex space-x-2 items-center">
    //                   <input
    //                     type="radio"
    //                     name="gender"
    //                     className="cursor-pointer"
    //                     checked={gender === "L"}
    //                     onChange={() => setGender("L")}
    //                   />
    //                   <label
    //                     className="cursor-pointer"
    //                     onClick={() => setGender("L")}
    //                   >
    //                     L
    //                   </label>
    //                 </div>
    //                 <div className="flex space-x-2 items-center">
    //                   <input
    //                     type="radio"
    //                     name="gender"
    //                     className="cursor-pointer"
    //                     checked={gender === "P"}
    //                     onChange={() => setGender("P")}
    //                   />
    //                   <label
    //                     className="cursor-pointer"
    //                     onClick={() => setGender("P")}
    //                   >
    //                     P
    //                   </label>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 py-4 space-y-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex items-center justify-between">
    //               <div className="text-lg font-medium flex items-end">
    //                 家族構成
    //                 <span className="text-sm font-medium text-gray-500 ml-1">
    //                   {"(Struktur Keluarga)"}
    //                 </span>
    //               </div>
    //               <Button
    //                 label={"Tambah"}
    //                 theme="light"
    //                 size={"sm"}
    //                 type="button"
    //                 onClick={() =>
    //                   appendFamilyStructure({ name: "", relation: "" })
    //                 }
    //               />
    //             </div>
    //             <div>
    //               <table className="table table-compact w-full">
    //                 <tbody>
    //                   {_.map(familyStructureFields, (field, index) => (
    //                     <tr key={field.id}>
    //                       <td>
    //                         <InputValidation
    //                           {...register(`family_structures.${index}.name`)}
    //                           placeholder="Nama"
    //                           type="text"
    //                         />
    //                       </td>
    //                       <td colSpan={index === 0 && 2}>
    //                         <InputValidation
    //                           {...register(
    //                             `family_structures.${index}.relation`,
    //                           )}
    //                           placeholder="hubungan"
    //                           type="text"
    //                         />
    //                       </td>
    //                       {index > 0 && (
    //                         <td className="w-10">
    //                           <Button
    //                             label={<CgTrash className="text-white" />}
    //                             theme="error"
    //                             type="button"
    //                             onClick={() => removeFamilyStructure(index)}
    //                           />
    //                         </td>
    //                       )}
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 py-4 space-y-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex items-center justify-between">
    //               <div className="text-lg font-medium flex items-end">
    //                 過剰
    //                 <span className="text-sm font-medium text-gray-500 ml-1">
    //                   {"(Kelebihan)"}
    //                 </span>
    //               </div>
    //               <Button
    //                 label={"Tambah"}
    //                 theme="light"
    //                 size={"sm"}
    //                 type="button"
    //                 onClick={() => appendPersonality({ value: "" })}
    //               />
    //             </div>
    //             <div>
    //               <table className="table table-compact w-full">
    //                 <tbody>
    //                   {_.map(personalityFields, (field, index) => (
    //                     <tr key={field.id}>
    //                       <td colSpan={index <= 2 && 2}>
    //                         <InputValidation
    //                           {...register(`personalities.${index}.value`)}
    //                           type="text"
    //                           placeholder=""
    //                         />
    //                       </td>
    //                       {index > 2 && (
    //                         <td className="w-10">
    //                           <Button
    //                             label={<CgTrash className="text-white" />}
    //                             theme="error"
    //                             type="button"
    //                             onClick={() => removePersonality(index)}
    //                           />
    //                         </td>
    //                       )}
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 py-4 space-y-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex items-center justify-between">
    //               <div className="text-lg font-medium flex items-end">
    //                 学歴
    //                 <span className="text-sm font-medium text-gray-500 ml-1">
    //                   {"(Riwayat Pendidikan)"}
    //                 </span>
    //               </div>
    //               {methods.watch("educations")?.length < 4 && (
    //                 <Button
    //                   label={"Tambah"}
    //                   theme="light"
    //                   size={"sm"}
    //                   type="button"
    //                   onClick={() =>
    //                     appendEducation({ education: null, name: "" })
    //                   }
    //                 />
    //               )}
    //             </div>
    //             <div>
    //               <table className="table table-compact w-full">
    //                 <tbody>
    //                   {_.map(educationFields, (field, index) => (
    //                     <tr key={field.id}>
    //                       <td>
    //                         <div className="flex-1">
    //                           <Select
    //                             name={`educations.${index}.education`}
    //                             options={handleOptionsEducation()}
    //                             placeholder="Riwayat Pendidikan"
    //                             isClearable
    //                           />
    //                         </div>
    //                       </td>
    //                       <td className="w-1/2" colSpan={index === 0 && 2}>
    //                         <InputValidation
    //                           {...register(`educations.${index}.name`)}
    //                           placeholder="Nama Sekolah/Instansi"
    //                           className="h-10"
    //                           type="text"
    //                         />
    //                       </td>
    //                       {index > 0 && (
    //                         <td className="w-10">
    //                           <Button
    //                             label={<CgTrash className="text-white" />}
    //                             theme="error"
    //                             type="button"
    //                             onClick={() => removeEducation(index)}
    //                           />
    //                         </td>
    //                       )}
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 py-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex items-center justify-between">
    //               <div className="flex items-center space-x-1">
    //                 <div className="text-lg font-medium flex items-center">
    //                   言語スキルテストのアップロード
    //                   <span className="text-sm font-medium text-gray-500 ml-1">
    //                     {"(Upload Ujian Keterampilan Bahasa)"}
    //                   </span>
    //                 </div>
    //                 <small className="text-gray-500">{"(Max 3 file)"}</small>
    //               </div>
    //               {languageSkills?.length < 3 && (
    //                 <Button
    //                   label="Upload File"
    //                   size="sm"
    //                   outline
    //                   type="button"
    //                   onClick={() => uploadLangSkill?.current?.click()}
    //                 />
    //               )}
    //               <input
    //                 ref={uploadLangSkill}
    //                 autoComplete="none"
    //                 accept="image/png, image/jpg, image/jpeg, application/pdf"
    //                 type="file"
    //                 onChange={onSelectLanguageSkillFile}
    //                 onClick={(event) => {
    //                   event.target.value = null;
    //                 }}
    //                 className="opacity-0 hidden"
    //               />
    //             </div>
    //             {languageSkills?.length > 0 ? (
    //               <div className="space-y-3 mt-4">
    //                 {_.map(languageSkills, (ls, key) => (
    //                   <div
    //                     key={key}
    //                     className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${"border-gray-400"}`}
    //                   >
    //                     <div className="flex items-center space-x-2">
    //                       <CgFileDocument />
    //                       <span className="text-gray-500">
    //                         {ls?.value?.name}
    //                       </span>
    //                     </div>
    //                     <div className="flex items-center space-x-2">
    //                       {ls?.value && (
    //                         <a
    //                           href={
    //                             ls?.value?.name && ls?.value?.lastModified
    //                               ? URL.createObjectURL(ls?.value)
    //                               : `${process.env.REACT_APP_API}/uploads/attachment/student/${user?.student_id}/${ls?.value?.name}`
    //                           }
    //                           download={ls?.value?.name}
    //                           target={
    //                             ls?.value?.name?.includes(".doc")
    //                               ? ""
    //                               : "_blank"
    //                           }
    //                           className="text-xs hover:text-gray-500"
    //                           rel="noreferrer"
    //                         >
    //                           {ls?.value?.lastModified ? (
    //                             <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
    //                           ) : (
    //                             <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
    //                           )}
    //                         </a>
    //                       )}
    //                       <IoMdCloseCircle
    //                         className="text-lg hover:scale-110 transition-all cursor-pointer"
    //                         onClick={() => onDeleteLanguageSkillFile(ls, key)}
    //                       />
    //                     </div>
    //                   </div>
    //                 ))}
    //               </div>
    //             ) : null}
    //           </div>
    //           <div
    //             className="bg-gray-50 rounded-lg border-gray-500 px-4 py-4"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="flex items-center justify-between">
    //               <div className="flex items-center space-x-1">
    //                 <div className="text-lg font-medium flex items-center">
    //                   看護師認定試験のアップロード
    //                   <span className="text-sm font-medium text-gray-500 ml-1">
    //                     {"(Upload Ujian Sertifikasi Keperawatan)"}
    //                   </span>
    //                 </div>
    //                 <small className="text-gray-500">{"(Max 3 file)"}</small>
    //               </div>
    //               {certificationExam?.length < 3 && (
    //                 <Button
    //                   label="Upload File"
    //                   size="sm"
    //                   outline
    //                   type="button"
    //                   onClick={() => uploadCertExam?.current?.click()}
    //                 />
    //               )}
    //               <input
    //                 ref={uploadCertExam}
    //                 autoComplete="none"
    //                 accept="image/png, image/jpg, image/jpeg, application/pdf"
    //                 type="file"
    //                 onChange={onSelectCertExamFile}
    //                 onClick={(event) => {
    //                   event.target.value = null;
    //                 }}
    //                 className="opacity-0 hidden"
    //               />
    //             </div>
    //             {certificationExam?.length > 0 ? (
    //               <div className="space-y-3 mt-4">
    //                 {_.map(certificationExam, (ls, key) => (
    //                   <div
    //                     key={key}
    //                     className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${"border-gray-400"}`}
    //                   >
    //                     <div className="flex items-center space-x-2">
    //                       <CgFileDocument />
    //                       <span className="text-gray-500">
    //                         {ls?.value?.name}
    //                       </span>
    //                     </div>
    //                     <div className="flex items-center space-x-2">
    //                       {ls?.value && (
    //                         <a
    //                           href={
    //                             ls?.value?.name && ls?.value?.lastModified
    //                               ? URL.createObjectURL(ls?.value)
    //                               : `${process.env.REACT_APP_API}/uploads/attachment/student/${user?.student_id}/${ls?.value?.name}`
    //                           }
    //                           download={ls?.value?.name}
    //                           target={
    //                             ls?.value?.name?.includes(".doc")
    //                               ? ""
    //                               : "_blank"
    //                           }
    //                           className="text-xs hover:text-gray-500"
    //                           rel="noreferrer"
    //                         >
    //                           {ls?.value?.lastModified ? (
    //                             <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
    //                           ) : (
    //                             <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
    //                           )}
    //                         </a>
    //                       )}
    //                       <IoMdCloseCircle
    //                         className="text-lg hover:scale-110 transition-all cursor-pointer"
    //                         onClick={() => onDeleteCertExamFile(ls, key)}
    //                       />
    //                     </div>
    //                   </div>
    //                 ))}
    //               </div>
    //             ) : null}
    //           </div>
    //           <div
    //             className="bg-gray-50 space-y-4 rounded-lg border-gray-500 px-4 pt-4 pb-8"
    //             style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //           >
    //             <div className="text-lg font-medium flex items-center">
    //               ドキュメントのアップロード
    //               <span className="text-sm font-medium text-gray-500 ml-1">
    //                 {"(Upload Dokumen)"}
    //               </span>
    //             </div>
    //             <div className="space-y-3">
    //               <FileItem
    //                 placeholder="証明書 (Ijazah)"
    //                 label="証明書 (Ijazah)"
    //                 name="ijazah"
    //                 value={ijazah?.value}
    //                 isError={ijazah?.isError}
    //                 onChange={setIjazah}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="IDカード (KTP)"
    //                 label="IDカード (KTP)"
    //                 name="ktp"
    //                 value={ktp?.value}
    //                 isError={ktp?.isError}
    //                 onChange={setKtp}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="株式会社 (KK)"
    //                 label="株式会社 (KK)"
    //                 name="kk"
    //                 value={kk?.value}
    //                 isError={kk?.isError}
    //                 onChange={setKk}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="SKCK (SKCK)"
    //                 label="SKCK (SKCK)"
    //                 name="skck"
    //                 value={skck?.value}
    //                 isError={skck?.isError}
    //                 onChange={setSkck}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="出生証明書 (Akte Kelahiran)"
    //                 label="出生証明書 (Akte Kelahiran)"
    //                 name="akteKelahiran"
    //                 value={akteKelahiran?.value}
    //                 isError={akteKelahiran?.isError}
    //                 onChange={setAkteKelahiran}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="白い画面の写真 (Foto Layar Putih)"
    //                 label="白い画面の写真 (Foto Layar Putih)"
    //                 name="fotoLayarPutih"
    //                 value={fotoLayarPutih?.value}
    //                 isError={fotoLayarPutih?.isError}
    //                 onChange={setFotoLayarPutih}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="健康情報 (Keterangan Kesehatan)"
    //                 label="健康情報 (Keterangan Kesehatan)"
    //                 name="ketKesehatan"
    //                 value={ketKesehatan?.value}
    //                 isError={ketKesehatan?.isError}
    //                 onChange={setKetKesehatan}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="親の許可証 (Surat Izin Orang Tua)"
    //                 label="親の許可証 (Surat Izin Orang Tua)"
    //                 name="suratIzinOrtu"
    //                 value={suratIzinOrtu?.value}
    //                 isError={suratIzinOrtu?.isError}
    //                 onChange={setSuratIzinOrtu}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
    //                 label="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
    //                 name="sertifikatKomp1"
    //                 value={sertifikatKomp1?.value}
    //                 isError={sertifikatKomp1?.isError}
    //                 onChange={setSertifikatKomp1}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //               <FileItem
    //                 placeholder="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
    //                 label="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
    //                 name="sertifikatKomp2"
    //                 value={sertifikatKomp2?.value}
    //                 isError={sertifikatKomp2?.isError}
    //                 onChange={setSertifikatKomp2}
    //                 showLabelBesideValue={true}
    //                 studentId={user?.student_id}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //         <div className="card-actions">
    //           <div className="flex space-x-2 w-full">
    //             {id && (
    //               <Button
    //                 label={"Reset Password"}
    //                 className="flex-1"
    //                 theme="warning"
    //                 type="button"
    //                 onClick={onPopupResetPassword}
    //               />
    //             )}
    //             <Button
    //               label={createData?.isLoading ? <Spinner /> : "Ubah"}
    //               disabled={createData?.isLoading}
    //               // onClick={revalidateDocument}
    //               className="flex-1"
    //               theme="primary"
    //               type="submit"
    //             />
    //           </div>
    //         </div>
    //       </form>
    //     </FormProvider>
    //   </div>
    // </div>
    <section>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl flex gap-3">
              <h6>プロフィール</h6>
              <p>(Profile)</p>
            </div>
            <form className="space-y-6 p-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col lg:flex-row gap-10">
                <div className="flex flex-col items-center gap-3 mx-10">
                  <div className="w-40 h-40 rounded-full overflow-clip flex ">
                    <img
                      alt="avatar-student"
                      src={
                        detail?.data?.image && !avatar?.file
                          ? `${process.env.REACT_APP_API}/uploads/avatar/${
                              detail?.data?.user_id
                            }/${detail?.data?.image?.replace("/public", "")}`
                          : avatar?.objectUrl
                          ? avatar?.objectUrl
                          : DummyProfile
                      }
                      className="w-40 h-40 object-contain"
                    />
                  </div>

                  <input
                    ref={uploadRef}
                    autoComplete="none"
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    type="file"
                    onChange={onSelectFile}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    className="opacity-0 hidden"
                  />
                  <BaseButton
                    variant="outline"
                    type="button"
                    onClick={() => uploadRef.current.click()}
                  >
                    Pilih Foto
                  </BaseButton>
                </div>

                <div className="flex flex-col flex-grow">
                  <div className="flex gap-3">
                    {/* <span> */}
                    <BaseInput
                      label="学生コード (Kode Siswa)"
                      type="text"
                      value={studentCode}
                      placeholder="Masukan kode siswa"
                      disabled
                      className=""
                    />
                    {/* </span> */}
                    <BaseInput
                      label="名前 (Nama)"
                      type="text"
                      placeholder="Masukan nama"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Nama wajib diisi",
                        },
                      })}
                      error={errors.name?.message}
                    />
                  </div>
                  <BaseInput
                    label="メール (Email)"
                    placeholder="Masukan email"
                    type="email"
                    disabled={id}
                    {...register("email", {
                      required: { value: true, message: "Email wajib diisi" },
                    })}
                    error={errors.email?.message}
                  />
                  {!id && (
                    <BaseInput
                      label="パスワード (Password)"
                      type={!showPass ? "password" : "text"}
                      placeholder="Password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Password wajib diisi",
                        },
                        minLength: {
                          value: 8,
                          message: "Password minimal 8 karakter",
                        },
                      })}
                      error={errors.password?.message}
                      suffixIcon={renderPasswordIcon()}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-3">
              <BaseInput
                label="生年月日 (Tempat Tanggal Lahir)"
                placeholder="Masukan Tempat Tanggal Lahir"
                {...register("ttl", {
                  required: {
                    value: false,
                    message: "Tempat Tanggal Lahir wajib diisi",
                  },
                })}
                error={errors.ttl?.message}
              />

              <BaseInput
                {...register("phone", {
                  required: {
                    value: false,
                    message: "No. Handphone wajib diisi",
                  },
                })}
                label="電話番号 (No. Handphone)"
                placeholder="Masukan No. Handphone"
                pattern="^[0-9]*$"
                value={phone}
                type="number"
                onChange={handleChangePhone}
                error={errors.phone?.message}
              />

              <BaseInput
                label="住所 (Alamat)"
                placeholder="Masukan alamat"
                {...register("address", {
                  required: { value: false, message: "Alamat wajib diisi" },
                })}
                error={errors.address?.message}
              />

              <BaseInput
                label="学歴 (Riwayat Pekerjaan)"
                placeholder="Masukan riwayat pekerjaan"
                {...register("work_history", {
                  required: {
                    value: false,
                    message: "Riwayat Pekerjaan wajib diisi",
                  },
                })}
                error={errors.work_history?.message}
              />

              <div className="flex gap-2 w-full">
                <Select
                  {...register("hobby", {
                    required: {
                      value: false,
                      message: "Hobi wajib diisi",
                    },
                  })}
                  label="趣味 (Hobi)"
                  options={hobies}
                  placeholder="Masukan Nama Hobi"
                  onMenuOpen={() => dispatch(getHobies())}
                  isLoading={isLoadingHobies}
                  isClearable
                  isError={{
                    value: errors.hobby,
                    message: errors.hobby?.message,
                  }}
                />

                {methods?.watch("hobby")?.value === "other" && (
                  <BaseInput
                    {...register("other_hobby", {
                      required: {
                        value: false,
                        message: "Hobi wajib diisi",
                      },
                    })}
                    label={<>&nbsp;</>}
                    type="text"
                    placeholder="Masukan Hobi"
                    isError={{
                      value: errors.other_hobby,
                      message: errors.other_hobby?.message,
                    }}
                  />
                )}
              </div>

              <BaseInput
                label="年 (Usia)"
                placeholder="Masukan usia"
                {...register("old", {
                  required: {
                    value: false,
                    message: "Usia wajib diisi",
                  },
                  pattern: {
                    value: /^[0-9]*$/g,
                    message: "Usia hanya bisa diinput oleh angka",
                  },
                })}
                error={errors.old?.message}
              />

              <Select
                {...register("skills")}
                label="資格 (Keterampilan)"
                options={skills}
                placeholder="Masukan Nama Keterampilan"
                isClearable
              />

              <BaseInput
                label="母国語以外にできる外国語 (Penguasaan Bahasa)"
                placeholder="Masukan penguasaan bahasa"
                {...register("mastery_of_language", {
                  required: {
                    value: false,
                    message: "Penguasaan Bahasa wajib diisi",
                  },
                })}
                error={errors.mastery_of_language?.message}
              />

              <Select
                {...register("category")}
                label="職種 (Kategori)"
                options={categories}
                placeholder="Masukan Nama Kategori"
                isClearable
              />

              <BaseInput
                label="ユーチューブのリンク (Link youtube)"
                placeholder="Masukan link youtube"
                {...register("link_youtube", {
                  required: {
                    value: false,
                    message: "Link youtube wajib diisi",
                  },
                })}
                error={errors.link_youtube?.message}
              />

              <Datepicker
                {...register("entries_date", {
                  required: {
                    value: false,
                    message: "Tanggal Masuk wajib diisi",
                  },
                })}
                label={"入国日 (Tanggal Masuk)"}
                selected={entriesDate}
                onChange={handleChangeEntriesDate}
                placeholder="Pilih Tanggal Masuk"
                maxDate={new Date()}
                isError={{
                  value: errors.entries_date,
                  message: errors.entries_date?.message,
                }}
              />

              <BaseInput
                label="勉強期間 (Masa Belajar)"
                placeholder="Masukan masa belajar"
                {...register("apprenticeship")}
              />

              <Select
                {...register("religion")}
                label="宗教 (Agama)"
                options={religions}
                placeholder="Masukan Nama Agama"
                isClearable
              />

              <Select
                {...register("blood_type")}
                label="血液型 (Golongan Darah)"
                options={bloodTypes}
                placeholder="Masukan Nama Golongan Darah"
                isClearable
              />

              <div className="flex gap-2 w-full">
                <BaseInput
                  {...register("height", {
                    required: {
                      value: false,
                      message: "Tinggi badan wajib diisi",
                    },
                    pattern: {
                      value: /^[0-9,]*$/g,
                      message: "Tinggi badan hanya bisa diinput oleh angka",
                    },
                  })}
                  label="身長 (Tinggi badan)"
                  type="text"
                  pattern="^[0-9]*$"
                  name="height"
                  placeholder="Masukan Tinggi badan"
                  onChange={handleChangeHeight}
                  value={height}
                  isError={{
                    value: errors.height,
                    message: errors.height?.message,
                  }}
                />

                <BaseInput
                  {...register("weight", {
                    required: {
                      value: false,
                      message: "Berat badan wajib diisi",
                    },
                    pattern: {
                      value: /^[0-9,]*$/g,
                      message: "Berat badan hanya bisa diinput oleh angka",
                    },
                  })}
                  label="重さ (Berat badan)"
                  type="text"
                  pattern="^[0-9]*$"
                  name="weight"
                  placeholder="Masukan Berat badan"
                  onChange={handleChangeWeight}
                  value={weight}
                  isError={{
                    value: errors.weight,
                    message: errors.weight?.message,
                  }}
                />
              </div>

              <BaseInput
                label="BMI"
                value={bmiTotal}
                type="text"
                readOnly={true}
                placeholder="Masukan BMI"
              />

              <BaseInput
                {...register("interest_job")}
                label="興味のある仕事 (Pekerjaan yang diminati)"
                type="text"
                placeholder="Masukan Pekerjaan yang diminati"
              />

              {/* <div></div> */}

              <div className="flex flex-col self-center">
                <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                  性別 (Jenis Kelamin)
                </label>
                <div className="flex gap-4 mx-2">
                  <label
                    htmlFor="L"
                    className="inline-block ml-1 font-bold text-slate-700 text-xs"
                  >
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="L"
                        name="gender"
                        value="L"
                        checked={gender === "L"}
                        onChange={() => setGender("L")}
                      />
                      男 (L)
                    </div>
                  </label>
                  <label
                    htmlFor="P"
                    className="inline-block ml-1 font-bold text-slate-700 text-xs"
                  >
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        name="gender"
                        value="P"
                        id="P"
                        checked={gender === "P"}
                        onChange={() => setGender("P")}
                      />
                      女 (P)
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
                <h6>家族構成</h6>
                <p>(Struktur Keluarga)</p>
              </div>
              <BaseButton
                onClick={() =>
                  appendFamilyStructure({ name: "", relation: "" })
                }
              >
                Tambah
              </BaseButton>
            </div>

            <div className="px-6">
              <table className="table w-full">
                <tbody>
                  {_.map(familyStructureFields, (fields, index) => (
                    <tr>
                      <td className="p-1">
                        <BaseInput
                          {...register(`family_structures.${index}.name`)}
                          placeholder="Nama"
                          type="text"
                        />
                      </td>
                      <td className="p-1" colSpan={index === 0 && 2}>
                        <BaseInput
                          {...register(`family_structures.${index}.relation`)}
                          placeholder="hubungan"
                          type="text"
                        />
                      </td>
                      {index > 0 && (
                        <td className="pr-7 w-6 inline-flex">
                          <BaseButton
                            onClick={() => removeFamilyStructure(index)}
                          >
                            <CgTrash size={24} />
                          </BaseButton>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
                <h6>過剰</h6>
                <p>(Kelebihan)</p>
              </div>
              <BaseButton onClick={() => appendPersonality({ value: "" })}>
                Tambah
              </BaseButton>
            </div>

            <div className="px-6">
              <table className="table w-full">
                <tbody>
                  {_.map(personalityFields, (field, index) => (
                    <tr key={field.id} className="w-full">
                      <td colSpan={index <= 2 && 2} className="w-full">
                        <BaseInput
                          {...register(`personalities.${index}.value`)}
                          type="text"
                          placeholder=""
                        />
                      </td>
                      {index > 2 && (
                        <td className=" pl-3 w-min inline-flex">
                          <BaseButton onClick={() => removePersonality(index)}>
                            <CgTrash size={24} />
                          </BaseButton>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full">
                <h6>学歴</h6>
                <p>(Riwayat Pendidikan)</p>
              </div>
              <BaseButton
                onClick={() => appendEducation({ education: null, name: "" })}
              >
                Tambah
              </BaseButton>
            </div>

            <div className="px-6">
              <table className="table w-full">
                <tbody>
                  {_.map(educationFields, (fields, index) => (
                    <tr>
                      <td className="p-1">
                        <Select
                          name={`educations.${index}.education`}
                          options={handleOptionsEducation()}
                          placeholder="Riwayat Pendidikan"
                          isClearable
                        />
                      </td>
                      <td className="p-1" colSpan={index === 0 && 2}>
                        <BaseInput
                          {...register(`educations.${index}.name`)}
                          placeholder="Nama Sekolah/Instansi"
                          type="text"
                        />
                      </td>
                      {index > 0 && (
                        <td className="pr-7 w-6 inline-flex">
                          <BaseButton onClick={() => removeEducation(index)}>
                            <CgTrash size={24} />
                          </BaseButton>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
                <h6>言語スキルテストのアップロード</h6>
                <p>(Upload Ujian Keterampilan Bahasa)</p>
                <small>(Max 3 File)</small>
              </div>
              {languageSkills?.length < 3 && (
                <BaseButton
                  variant="outline"
                  onClick={() => uploadLangSkill?.current?.click()}
                >
                  Upload File
                </BaseButton>
              )}

              <input
                ref={uploadLangSkill}
                autoComplete="none"
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                type="file"
                onChange={onSelectLanguageSkillFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className="opacity-0 hidden"
              />
            </div>
            <div className="px-6 pb-6">
              {languageSkills?.length > 0 ? (
                <div className="space-y-3">
                  {_.map(languageSkills, (ls, key) => (
                    <div
                      key={key}
                      className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${"border-gray-400"}`}
                    >
                      <div className="flex items-center space-x-2">
                        <CgFileDocument />
                        <span className="text-gray-500">{ls?.value?.name}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        {ls?.value && (
                          <a
                            href={
                              ls?.value?.name && ls?.value?.lastModified
                                ? URL.createObjectURL(ls?.value)
                                : `${process.env.REACT_APP_API}/uploads/attachment/student/${user?.student_id}/${ls?.value?.name}`
                            }
                            download={ls?.value?.name}
                            target={
                              ls?.value?.name?.includes(".doc") ? "" : "_blank"
                            }
                            className="text-xs hover:text-gray-500"
                            rel="noreferrer"
                          >
                            {ls?.value?.lastModified ? (
                              <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
                            ) : (
                              <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
                            )}
                          </a>
                        )}
                        <IoMdCloseCircle
                          className="text-lg hover:scale-110 transition-all cursor-pointer"
                          onClick={() => onDeleteLanguageSkillFile(ls, key)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
                <h6>看護師認定試験のアップロード</h6>
                <p>(Upload Ujian Sertifikasi Keperawatan)</p>
                <small>(Max 3 File)</small>
              </div>
              {certificationExam?.length < 3 && (
                <BaseButton
                  variant="outline"
                  onClick={() => uploadLangSkill?.current?.click()}
                >
                  Upload File
                </BaseButton>
              )}

              <input
                ref={uploadLangSkill}
                autoComplete="none"
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                type="file"
                onChange={onSelectLanguageSkillFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className="opacity-0 hidden"
              />
            </div>
            <div className="px-6 pb-6">
              {certificationExam?.length > 0 ? (
                <div className="space-y-3">
                  {_.map(certificationExam, (ls, key) => (
                    <div
                      key={key}
                      className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${"border-gray-400"}`}
                    >
                      <div className="flex items-center space-x-2">
                        <CgFileDocument />
                        <span className="text-gray-500">{ls?.value?.name}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        {ls?.value && (
                          <a
                            href={
                              ls?.value?.name && ls?.value?.lastModified
                                ? URL.createObjectURL(ls?.value)
                                : `${process.env.REACT_APP_API}/uploads/attachment/student/${user?.student_id}/${ls?.value?.name}`
                            }
                            download={ls?.value?.name}
                            target={
                              ls?.value?.name?.includes(".doc") ? "" : "_blank"
                            }
                            className="text-xs hover:text-gray-500"
                            rel="noreferrer"
                          >
                            {ls?.value?.lastModified ? (
                              <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
                            ) : (
                              <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
                            )}
                          </a>
                        )}
                        <IoMdCloseCircle
                          className="text-lg hover:scale-110 transition-all cursor-pointer"
                          onClick={() => onDeleteLanguageSkillFile(ls, key)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="flex justify-between items-baseline p-6">
              <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
                <h6>ドキュメントのアップロード</h6>
                <p>(Upload Dokumen)</p>
              </div>
            </div>
            <div className="px-6 pb-6 space-y-3">
              <FileItem
                placeholder="証明書 (Ijazah)"
                label="証明書 (Ijazah)"
                name="ijazah"
                value={ijazah?.value}
                isError={ijazah?.isError}
                onChange={setIjazah}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="IDカード (KTP)"
                label="IDカード (KTP)"
                name="ktp"
                value={ktp?.value}
                isError={ktp?.isError}
                onChange={setKtp}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="株式会社 (KK)"
                label="株式会社 (KK)"
                name="kk"
                value={kk?.value}
                isError={kk?.isError}
                onChange={setKk}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="SKCK (SKCK)"
                label="SKCK (SKCK)"
                name="skck"
                value={skck?.value}
                isError={skck?.isError}
                onChange={setSkck}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="出生証明書 (Akte Kelahiran)"
                label="出生証明書 (Akte Kelahiran)"
                name="akteKelahiran"
                value={akteKelahiran?.value}
                isError={akteKelahiran?.isError}
                onChange={setAkteKelahiran}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="白い画面の写真 (Foto Layar Putih)"
                label="白い画面の写真 (Foto Layar Putih)"
                name="fotoLayarPutih"
                value={fotoLayarPutih?.value}
                isError={fotoLayarPutih?.isError}
                onChange={setFotoLayarPutih}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="健康情報 (Keterangan Kesehatan)"
                label="健康情報 (Keterangan Kesehatan)"
                name="ketKesehatan"
                value={ketKesehatan?.value}
                isError={ketKesehatan?.isError}
                onChange={setKetKesehatan}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="親の許可証 (Surat Izin Orang Tua)"
                label="親の許可証 (Surat Izin Orang Tua)"
                name="suratIzinOrtu"
                value={suratIzinOrtu?.value}
                isError={suratIzinOrtu?.isError}
                onChange={setSuratIzinOrtu}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
                label="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
                name="sertifikatKomp1"
                value={sertifikatKomp1?.value}
                isError={sertifikatKomp1?.isError}
                onChange={setSertifikatKomp1}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
              <FileItem
                placeholder="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
                label="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
                name="sertifikatKomp2"
                value={sertifikatKomp2?.value}
                isError={sertifikatKomp2?.isError}
                onChange={setSertifikatKomp2}
                showLabelBesideValue={true}
                studentId={user?.student_id}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3 py-4 pt-6 justify-end px-6">
          {id ? (
            <BaseButton onClick={() => setShowModalResetPassword(true)}>
              Reset Password
            </BaseButton>
          ) : null}
          <BaseButton variant="base" onClick={() => navigate(-1)}>
            Kembali
          </BaseButton>
          <BaseButton variant="primary2" onClick={handleSubmit(onSubmit)}>
            Kirim
          </BaseButton>
        </div>
      </FormProvider>

      <ModalWrapper
        isOpen={showModalResetPassword}
        setIsOpen={setShowModalResetPassword}
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Reset password</h5>
            </div>
            <div className="relative flex-auto p-4">
              <form
                onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}
                className="space-y-4 w-100"
              >
                <BaseInput
                  label="Password lama"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showOldPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowOldPass((prev) => !prev)}
                    >
                      {showOldPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("old_password", {
                    required: {
                      value: true,
                      message: "Password Lama wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Lama minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.old_password?.message}
                />

                <BaseInput
                  label="Password baru"
                  placeholder="Masukan password baru"
                  size="md"
                  type={showNewPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowNewPass(!showOldPass)}
                    >
                      {showNewPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("new_password", {
                    required: {
                      value: true,
                      message: "Password Baru wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Baru minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.new_password?.message}
                />

                <BaseInput
                  label="Konfirmasi Password"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showConfirmPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowConfirmPass(!showOldPass)}
                    >
                      {showConfirmPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("confirm_password", {
                    required: {
                      value: true,
                      message: "Konfirmasi Password wajib diisi",
                    },
                    validate: (val) => {
                      if (watch("new_password") != val) {
                        return "Konfirmasi Password tidak sama dengan pasword baru";
                      }
                    },
                    minLength: {
                      value: 8,
                      message: "Konfirmasi Password minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.confirm_password?.message}
                />
                <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                  <BaseButton
                    variant="base"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setShowModalResetPassword((prev) => !prev);
                      reset_form_reset();
                    }}
                  >
                    Tidak
                  </BaseButton>
                  <BaseButton variant="primary2" size="sm" type="submit">
                    Ya
                  </BaseButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </section>
  );
};

export default Profile;
