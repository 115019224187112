import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "./app/store";
import Routes from "./routes";
// import { AnimatePresence } from "framer-motion";
import Interceptor from "./interceptor";

import { ToastContainer } from "react-toastify";

import { QueryClientProvider, QueryClient } from "react-query";

import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/soft-ui-dashboard-tailwind.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "/node_modules/flag-icons/css/flag-icons.min.css";

let persistor = persistStore(store);

Interceptor.request(store);
Interceptor.response(store);

const client = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        {/* <AnimatePresence exitBeforeEnter> */}
        <QueryClientProvider client={client}>
          <Routes />
        </QueryClientProvider>
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          autoClose={1500}
          pauseOnHover={false}
        />
        {/* </AnimatePresence> */}
      </PersistGate>
    </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
