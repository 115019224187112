import BaseButton from "components/atoms/BaseButton";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "react-query";
import { deleteTrainingOrder, getTrainingOrder } from "services/training-order";
import { map } from "lodash";
import { CgSpinner } from "react-icons/cg";
import { Pagination } from "components";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLoader } from "Provider/LoaderProvider";
import ModalWrapper from "components/molecules/ModalWrapper";

const thClass =
  "px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70";
const tdClass =
  "p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4";

const TrainingOrder = () => {
  const navigate = useNavigate();
  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const deleteTo = useMutation(deleteTrainingOrder);

  const { user } = useSelector(({ auth }) => auth);

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["training-order", { page, limit }], {
    queryFn: ({ queryKey, signal }) => {
      const params = queryKey[1];
      return getTrainingOrder({ ...params }, signal);
    },
    onError: (err) => console.log(err),
  });

  const handleChangePage = (params) => {
    setPage(params.currentPage);
  };

  const onDetail = (params) => {
    navigate("/training-order/detail", {
      state: { id: params?.id ?? "" },
    });
  };

  const onDelete = (data) => {
    setDeleteModal(true);
    setSelectedData(data);
  };

  const onDeleteData = () => {
    deleteTo.mutate(selectedData?.id, {
      onSuccess: () => {
        stopLoaderOverlay();
        setDeleteModal(false);
        refetch();
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  const renderTbody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={4}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    }

    if (typeof response === "undefined" || response === "") {
      return (
        <tr>
          <td colSpan={4}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <p className="mb-0 font-semibold leading-tight text-xs">
                Tidak ada data
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return map(response?.data, (list, key) => {
      const id =
        parseInt(response?.meta?.page) * parseInt(response?.meta?.limit) -
        parseInt(response?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.company_address ? list?.company_address : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.departure_plan
                ? moment(list?.departure_plan).format("DD MMMM YYYY")
                : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.due_date
                ? moment(list?.due_date).format("DD MMMM YYYY")
                : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <div className="flex gap-4">
              {user?.role === "partner_jepang" && (
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onDelete(list)}
                >
                  Delete
                </a>
              )}
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(list)}
              >
                Detail
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <ModalWrapper isOpen={deleteModal} setIsOpen={setDeleteModal}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setDeleteModal((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <span className="flex space-x-1">
              <h5>求人票</h5>
              <h6>{"(Training Order)"}</h6>
            </span>
            <p>Menampilkan semua training order.</p>
          </div>
          {user?.role === "partner_jepang" && (
            <BaseButton
              variant="primary2"
              size="md"
              className=""
              onClick={() => navigate("/training-order/form")}
            >
              Tambah Data
            </BaseButton>
          )}
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className={thClass}></th>
                  <th className={thClass}>
                    {"会社の住所 (Alamat Perusahaan)"}
                  </th>
                  {/* <th className={thClass}>{"給与区分 (Kategori Gajih)"}</th> */}
                  <th className={thClass}>
                    {"日本への入国予定日 (Tanggal Perkiraan Masuk Jepang)"}
                  </th>
                  <th className={thClass}>{"申請期限 (Batas Waktu Apply)"}</th>
                  <th className={thClass}></th>
                </tr>
              </thead>
              <tbody>{renderTbody()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.meta?.limit}
            totalRecords={response?.meta?.total ?? 0}
            currentPage={response?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>
    </>
  );
};

export default TrainingOrder;
