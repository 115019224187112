import React, { Component, Fragment, useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({
  totalRecords = 0,
  pageLimit = 30,
  pageNeighbours = 1,
  onPageChanged = (f) => f,
  isReset = false,
  currentPage = 1,
  customResetFocusView = false,
}) => {
  let totalPages = Math.ceil(totalRecords / pageLimit);

  useEffect(() => {
    isReset && gotoPage(1);
  }, []);

  const gotoPage = (page) => {
    let currentPage = Math.max(0, Math.min(page, totalPages));

    if (page < 1) {
      currentPage = 1;
    }

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageLimit,
      totalRecords: totalRecords,
    };

    onPageChanged(paginationData);
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    !customResetFocusView && window.scrollTo({ top: 0, behavior: "smooth" });
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    !customResetFocusView && window.scrollTo({ top: 0, behavior: "smooth" });
    // gotoPage(currentPage - pageNeighbours * 2 - 1);
    gotoPage(currentPage - 1);
  };

  const handleMoveLeftThreePage = (evt) => {
    evt.preventDefault();
    !customResetFocusView && window.scrollTo({ top: 0, behavior: "smooth" });
    // gotoPage(currentPage - pageNeighbours * 2 - 1);
    gotoPage(currentPage - 3);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    !customResetFocusView && window.scrollTo({ top: 0, behavior: "smooth" });
    // gotoPage(currentPage + pageNeighbours * 2 + 1);
    gotoPage(currentPage + 1);
  };

  const handleMoveRightThreePage = (evt) => {
    evt.preventDefault();
    !customResetFocusView && window.scrollTo({ top: 0, behavior: "smooth" });
    // gotoPage(currentPage + pageNeighbours * 2 + 1);
    gotoPage(currentPage + 3);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - 3;
      const rightBound = currentPage + 3;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  if (currentPage)
    return (
      <>
        <div className="w-fit overflow-hidden flex border rounded-md divide-x text-sm">
          <div
            className={`px-3 py-2 flex items-center justify-center ${
              parseInt(currentPage) !== 1
                ? "cursor-pointer hover:bg-gray-100"
                : ""
            }`}
            onClick={(event) =>
              parseInt(currentPage) !== 1 && handleMoveLeft(event)
            }
          >
            <FaChevronLeft className={`text-sm`} />
          </div>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <div className="cursor-default" key={index}>
                  <div className="px-4 py-2">
                    <span>...</span>
                  </div>
                </div>
              );

            if (page === RIGHT_PAGE)
              return (
                <div className="cursor-default" key={index}>
                  <div className="px-4 py-2">
                    <span>...</span>
                  </div>
                </div>
              );

            return (
              <div
                key={index}
                onClick={(e) => handleClick(page, e)}
                className={`cursor-pointer`}
              >
                <div
                  className={`px-4 py-2 ${
                    parseInt(currentPage) === parseInt(page)
                      ? "bg-gray-100"
                      : "hover:bg-gray-100"
                  }`}
                >
                  {page}
                </div>
              </div>
            );
          })}
          <div
            className={`px-3 py-2 flex items-center justify-center ${
              parseInt(totalPages) !== parseInt(currentPage)
                ? "cursor-pointer hover:bg-gray-100"
                : ""
            }`}
            onClick={(event) =>
              parseInt(totalPages) !== parseInt(currentPage) &&
              handleMoveRight(event)
            }
          >
            <FaChevronRight className={`text-sm`} />
          </div>
        </div>
      </>
    );

  return null;
};

export default Pagination;
