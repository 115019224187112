import React, { useEffect, useState } from "react";
import { Footer, GlobalHeader, ImageHandler } from "components";
// import { motion } from "framer-motion";
import { useLocation } from "react-router";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  getArticleTopicDetail,
  getArticleTopicRelated,
} from "services/landing";
import { Link } from "react-router-dom";

import ImageEmpty from "assets/img/empty-image.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const ArticleDetail = () => {
  const location = useLocation();
  const { state } = location;
  const { list } = state;
  const id = state?.detail?.id ?? null;

  const { lang } = useSelector(({ locales }) => locales);

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [related, setRelated] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetail();
      fetchRelated();
    }
  }, [id]);

  const handleLocalesArticle = (key, article) => {
    if (article) {
      if (lang === "jp") {
        return (
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          article[`${key}`] ??
          ""
        );
      } else if (lang === "en") {
        return (
          article[`${key}_en`] ??
          article[`${key}_jpn`] ??
          article[`${key}`] ??
          ""
        );
      } else {
        return (
          article[`${key}`] ??
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          ""
        );
      }
    }
    return "";
  };

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: getArticleTopicDetail, params: id },
      (success, response) => {
        const data = _.isArray(response?.data)
          ? _.first(response?.data)
          : response?.data;
        if (success) {
          setDetail((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            data,
          }));
        } else {
          setDetail((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const fetchRelated = () => {
    setRelated((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: getArticleTopicRelated, params: { id, type: "article" } },
      (success, response) => {
        const data = response?.data ?? {};
        if (success) {
          setRelated((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            data,
          }));
        } else {
          setRelated((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  const handleImageRender = (data) => {
    const split = data?.image?.split("/");
    return `${process.env.REACT_APP_API}/${split[1]}/${split[2]}/${data?.id}/${split[3]}`;
  };

  return (
    <div>
      {/* <GlobalHeader fixed={false} /> */}
      <div
        exit={{ y: 50, opacity: 0 }}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.25 }}
        className="flex my-10 mx-20 space-x-10"
      >
        <div className="w-2/3">
          <h1 className="text-3xl mb-6">
            {handleLocalesArticle("tittle", detail?.data)}
          </h1>
          <div className="space-y-4">
            <div className="max-w-6xl">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={1}
                className={`topic-swiper`}
              >
                {_.map(
                  Object.keys(detail?.data?.images ?? {}) ?? [],
                  (elmnt, key) => (
                    <SwiperSlide key={key}>
                      <ImageHandler
                        image={
                          Object.keys(detail?.data?.images ?? {}).length > 0
                            ? `${process.env.REACT_APP_API}${
                                detail?.data?.images?.[key.toString()]?.name
                              }`
                            : ImageEmpty
                        }
                        className="w-full h-80 rounded-lg object-contain"
                      />
                    </SwiperSlide>
                  ),
                )}
              </Swiper>
            </div>
            <div className="text-gray-500 text-xs">
              {detail?.data?.created_at
                ? moment(detail?.data?.created_at).format("DD MMMM YYYY")
                : ""}
            </div>
            <div className="text-sm whitespace-pre-wrap">
              <p>{handleLocalesArticle("description", detail?.data)}</p>
            </div>
          </div>
        </div>
        {related?.data?.length > 0 ? (
          <div className="w-1/3">
            {_.map(related?.data ?? [], (elmnt, key) => (
              <Link to={"/article/detail"} state={{ detail: elmnt }}>
                <div className="flex items-center space-x-4 mb-4">
                  <div className="flex-none w-40 rounded border">
                    <figure>
                      <ImageHandler
                        image={elmnt?.image ? handleImageRender(elmnt) : ""}
                        className="object-cover w-full h-28"
                      />
                    </figure>
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <div className="text-gray-500 text-xs">
                        {elmnt?.created_at
                          ? moment(elmnt?.created_at).format("DD MMMM YYYY")
                          : ""}
                      </div>
                    </div>
                    <p className="text-left font-bold limit-3-line-of-text">
                      {handleLocalesArticle("tittle", elmnt)}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : null}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ArticleDetail;
