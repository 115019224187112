import axios from "axios";
import Service from "config";

export const getList = (params) => {
  return axios.get(
    `${Service.API}/master/lesson?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
    }&filter=${params?.filter ?? ""}`,
  );
};

export const postData = (params) => {
  return axios.post(`${Service.API}/master/lesson`, params);
};

export const getDetail = (id) => {
  return axios.get(`${Service.API}/master/lesson/${id ?? ""}`);
};

export const putData = (params) => {
  return axios.put(
    `${Service.API}/master/lesson/${params?.id ?? ""}`,
    params?.payload ?? {},
  );
};

export const deleteData = (id) => {
  return axios.delete(`${Service.API}/master/lesson/${id ?? ""}`);
};

export const getSubjectList = () => {
  return axios.get(`${Service.API}/master/subject`);
};
