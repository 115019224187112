import React, { useRef, useState } from "react";
import _ from "lodash";
import { Button } from "components";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import {
  deleteAttachmentStudent,
  uploadAttachmentStudent,
  deleteAttachmentStudentById,
} from "services/user";
import { CgFileDocument, CgSpinner } from "react-icons/cg";
import { IoMdCloseCircle } from "react-icons/io";
import { FaDownload, FaEye } from "react-icons/fa";
import { useLocation } from "react-router";
import { Controller, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { useLoader } from "Provider/LoaderProvider";

const FileItem = ({
  name = "",
  label = "",
  showLabelBesideValue = false,
  placeholder = "Upload File",
  studentId,
  onValueChanged,
  readOnly,
}) => {
  const modalDelete = useRef();
  const attachmentRef = useRef();
  const { state } = useLocation();
  const id = studentId || (state?.id ?? null);

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const { control } = useFormContext();

  const [hasLoading, setLoading] = useState(false);

  const upload = useMutation({
    mutationFn: (payload) => uploadAttachmentStudent(payload),
  });

  const deleteFile = useMutation({
    mutationFn: (payload) => deleteAttachmentStudent(payload),
  });

  const _onTriggerSelectFile = () => {
    attachmentRef?.current?.click();
  };

  const handleOnChange = (event, onChange) => {
    const file = event?.target?.files[0];
    const formData = new FormData();
    formData.append("image", file);

    startLoaderOverlay();
    upload.mutate(formData, {
      onSuccess: (data) => {
        stopLoaderOverlay();
        let params = { file, src: URL.createObjectURL(file) };
        onChange(params);
        if (onValueChanged && typeof onValueChanged === "function") {
          onValueChanged(params, (data) => {
            params = {
              ...params,
              result: data,
            };
            onChange(params);
          });
        }
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  const onDeleteAttachment = (value, onChange) => {
    startLoaderOverlay();
    deleteFile.mutate(value?.name, {
      onSuccess: (data) => {
        stopLoaderOverlay();
        onChange(null);
      },
      onError: (error) => {
        stopLoaderOverlay();
        toast.error(getErrorMessage(error));
      },
    });
  };

  const onDeleteDataFromModal = (value, onChange) => {
    try {
      setLoading(true);
      fetchAPI(
        {
          service: deleteAttachmentStudentById,
          params: value?.id,
        },
        (success, response) => {
          setLoading(false);
          if (success) {
            onChange();
          }
        },
      );
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const renderModalDeleteDocument = () => (
    <>
      <input
        ref={modalDelete}
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hapus Dokumen!</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menghapus dokumen ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              type="button"
              size="sm"
              theme="error"
              onClick={() => modalDelete?.current?.click()}
            />
            <Button
              label={"Ya"}
              type="button"
              size="sm"
              theme="primary"
              onClick={onDeleteDataFromModal}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div>
          {/* {renderModalDeleteDocument()} */}
          <div
            className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${
              invalid ? "border-[#f87272]" : "border-gray-400"
            } ${!value?.file && !readOnly && "cursor-pointer"}`}
            onClick={
              !value?.file && !readOnly ? _onTriggerSelectFile : () => null
            }
          >
            <div className="flex items-center space-x-2">
              <CgFileDocument />
              {value?.file ? (
                <span className="text-gray-500">
                  {value?.file?.name}{" "}
                  <small className="text-gray-400">
                    {showLabelBesideValue ? ` | ${label}` : ""}
                  </small>
                </span>
              ) : (
                <span>{placeholder ?? ""}</span>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {value?.file && (
                <a
                  href={
                    value?.file?.name && value?.file?.lastModified
                      ? URL.createObjectURL(value?.file)
                      : `${process.env.REACT_APP_API}/uploads/attachment/student/${id}/${value?.file?.name}`
                  }
                  download={value?.file?.name}
                  target={value?.file?.name?.includes(".doc") ? "" : "_blank"}
                  className="text-xs hover:text-gray-500"
                  rel="noreferrer"
                >
                  {value?.file?.lastModified ? (
                    <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
                  ) : (
                    <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
                  )}
                </a>
              )}
              {hasLoading ? (
                <CgSpinner className="animate-spin text-lg text-primary" />
              ) : (
                value?.file && (
                  <IoMdCloseCircle
                    className="text-lg hover:scale-110 transition-all cursor-pointer"
                    onClick={() => onDeleteAttachment(value?.file, onChange)}
                  />
                )
              )}
            </div>
          </div>
          {invalid && (
            <label className="label">
              <span className="label-text-alt text-error">
                {error?.message}
              </span>
            </label>
          )}
          <input
            ref={attachmentRef}
            autoComplete="none"
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            type="file"
            onChange={(event) => handleOnChange(event, onChange)}
            onClick={(event) => {
              event.target.value = null;
            }}
            className="opacity-0 hidden"
          />
        </div>
      )}
    />
  );
};

export default FileItem;
