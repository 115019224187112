import _ from "lodash";

export const ROLES = ["student", "teacher", "lpk_induk", "trustee"];

export const fetchAPI = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    _.first(error?.response?.data?.errors)?.msg ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
  return message;
};

export const zeroPad = (pad = 0, num) => {
  return num.toString().padStart(pad, "0");
};

export const checkFileSize = ({ max, min, filesize }) => {
  const fileSizeKiloBytes = filesize / 1024;

  let isInvalid = false;
  if (min) {
    if (fileSizeKiloBytes < min) {
      isInvalid = false;
    } else {
      isInvalid = true;
    }
  }

  if (max) {
    if (fileSizeKiloBytes > max) {
      isInvalid = false;
    } else {
      isInvalid = true;
    }
  }

  return isInvalid;
};

export const checkFileMime = ({ ext = [], file }) => {
  const fileName = file?.name ?? "";
  const nameSplitted = fileName?.split(".") ?? [];
  const isValid = ext.includes(nameSplitted?.[nameSplitted.length - 1]);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};
