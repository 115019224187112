import React, { useEffect, useState } from "react";
import SelectInput from ".";
import { useQuery } from "react-query";
import { capitalize, debounce } from "lodash";
import { getToList } from "services/meet";
import moment from "moment";

const SelectTrainingOrder = ({ otherOptions = [], ...props }) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: toOptions, isFetching } = useQuery(
    ["training-order", { page, limit, search }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getToList({ ...params }, signal);
      },
    },
  );

  useEffect(() => {
    if (!isFetching && toOptions?.data?.data) {
      setOptions(
        loadMore
          ? options.concat(
              toOptions?.data?.data?.map((data) => ({
                value: data?.t_id,
                label: `${data?.t_company_name} - ${data?.t_training_period}`,
              })),
            )
          : toOptions?.data?.data?.map((data) => ({
              value: data?.t_id,
              label: `${data?.t_company_name} - ${data?.t_training_period}`,
            })),
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (options?.length < toOptions?.data?.meta?.total) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <SelectInput
      {...props}
      isLoading={isFetching}
      options={[...options, ...otherOptions]}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectTrainingOrder;
