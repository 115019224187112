import React from "react";
import SelectInput from ".";

const options = [
  { value: "Indonesia", label: "Indonesia" },
  { value: "Inggris", label: "Inggris" },
  { value: "Jepang", label: "Jepang" },
];

const SelectMasterLanguage = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectMasterLanguage;
