import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const CheckboxInput = ({ name, label }) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({
                field: { onChange, value, onBlur },
                fieldState: { invalid, error },
            }) => (
                <div className="flex space-x-2 accent-blue-500">
                    <input
                        type="checkbox"
                        id={name}
                        checked={value}
                        onChange={onChange}
                    />
                    <label className="text-sm text-gray-600" htmlFor={name}>
                        {label}
                    </label>
                </div>
            )}
        />
    );
};

export default CheckboxInput;
