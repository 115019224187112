import React from "react";
import SelectInput from ".";

const options = [
  {
    value: "自然科学 (ILMU PENGETAHUAN ALAM)",
    label: "自然科学 (ILMU PENGETAHUAN ALAM)",
  },
  {
    value: "社会科学 (ILMU PENGETAHUAN SOSIAL)",
    label: "社会科学 (ILMU PENGETAHUAN SOSIAL)",
  },
  {
    value: "オフィス管理 (MANAJEMEN PERKANTORAN)",
    label: "オフィス管理 (MANAJEMEN PERKANTORAN)",
  },
  {
    value: "オフィス管理および自動化 (OTOMATISASI TATA KELOLA PERKANTORAN)",
    label: "オフィス管理および自動化 (OTOMATISASI TATA KELOLA PERKANTORAN)",
  },
  {
    value: "自動車科学 (TEKNIK OTOMOTIF)",
    label: "自動車科学 (TEKNIK OTOMOTIF)",
  },
  {
    value: "軽車両工学 (TEKNIK KENDARAAN RINGAN)",
    label: "軽車両工学 (TEKNIK KENDARAAN RINGAN)",
  },
  {
    value: "コンピュータおよび情報工学 (TEKNIK KOMPUTER DAN INFORMATIKA)",
    label: "コンピュータおよび情報工学 (TEKNIK KOMPUTER DAN INFORMATIKA)",
  },
  {
    value: "コンピュータおよびネットワーク工学 (TEKNIK KOMPUTER DAN JARINGAN)",
    label: "コンピュータおよびネットワーク工学 (TEKNIK KOMPUTER DAN JARINGAN)",
  },
  {
    value: "マルチメディア (MULTIMEDIA)",
    label: "マルチメディア (MULTIMEDIA)",
  },
  {
    value: "社会知識専門 (PEMINATAN ILMU PENGETAHUAN SOSIAL)",
    label: "社会知識専門 (PEMINATAN ILMU PENGETAHUAN SOSIAL)",
  },
  {
    value: "イスラム寄宿学校 (PONDOK PESANTREN ISLAM)",
    label: "イスラム寄宿学校 (PONDOK PESANTREN ISLAM)",
  },
  {
    value:
      "数学と自然科学の専門分野 (PEMINATAN MATEMATIKA DAN ILMU PENGETAHUAN ALAM)",
    label:
      "数学と自然科学の専門分野 (PEMINATAN MATEMATIKA DAN ILMU PENGETAHUAN ALAM)",
  },
  {
    value: "会計と財務 (AKUNTANSI DAN KEUANGAN)",
    label: "会計と財務 (AKUNTANSI DAN KEUANGAN)",
  },
  {
    value: "会計および金融機関 (AKUNTANSI DAN KEUANGAN LEMBAGA)",
    label: "会計および金融機関 (AKUNTANSI DAN KEUANGAN LEMBAGA)",
  },
  { value: "料理芸術 (TATA BOGA)", label: "料理芸術 (TATA BOGA)" },
  { value: "料理科目 (JASA BOGA)", label: "料理科目 (JASA BOGA)" },
  {
    value:
      "オートバイのエンジニアリングとビジネス (TEKNIK DAN BISNIS SEPEDA MOTOR)",
    label:
      "オートバイのエンジニアリングとビジネス (TEKNIK DAN BISNIS SEPEDA MOTOR)",
  },
  {
    value: "ソフトウェア工学 (REKAYASA PERANGKAT LUNAK)",
    label: "ソフトウェア工学 (REKAYASA PERANGKAT LUNAK)",
  },
  {
    value: "植物生産 アグリビジネス (AGRIBISNIS PRODUKSI TANAMAN)",
    label: "植物生産 アグリビジネス (AGRIBISNIS PRODUKSI TANAMAN)",
  },
  {
    value:
      "食用作物および園芸のアグリビジネス (AGRIBISNIS TANAMAN PANGAN DAN HORTIKULTURA)",
    label:
      "食用作物および園芸のアグリビジネス (AGRIBISNIS TANAMAN PANGAN DAN HORTIKULTURA)",
  },
  {
    value: "医学教育 (PENDIDIKAN DOKTER)",
    label: "医学教育 (PENDIDIKAN DOKTER)",
  },
  {
    value: "歯科医師教育 (PENDIDIKAN DOKTER GIGI)",
    label: "歯科医師教育 (PENDIDIKAN DOKTER GIGI)",
  },
  { value: "薬局 (FARMASI)", label: "薬局 (FARMASI)" },
  {
    value: "公衆衛生 (KESEHATAN MASYARAKAT)",
    label: "公衆衛生 (KESEHATAN MASYARAKAT)",
  },
  { value: "看護 (KEPERAWATAN)", label: "看護 (KEPERAWATAN)" },
  { value: "助産 (KEBIDANAN)", label: "助産 (KEBIDANAN)" },
  { value: "栄養学 (ILMU GIZI)", label: "栄養学 (ILMU GIZI)" },
  { value: "獣医学 (KEDOKTERAN HEWAN)", label: "獣医学 (KEDOKTERAN HEWAN)" },
];

const SelectSkill = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectSkill;
