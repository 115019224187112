import React, { useRef } from "react";
import { Loader } from "components/v2";

const LoaderContext = React.createContext();

export function LoaderProvider({ children }) {
  const ref = useRef();
  const startLoaderOverlay = () => ref.current.start();
  const stopLoaderOverlay = () => ref.current.stop();
  const value = React.useMemo(
    () => ({ ref, startLoaderOverlay, stopLoaderOverlay }),
    [ref, startLoaderOverlay, stopLoaderOverlay],
  );

  return (
    <LoaderContext.Provider value={value}>
      <Loader ref={ref} />
      {children}
    </LoaderContext.Provider>
  );
}

export const useLoader = () => React.useContext(LoaderContext);
