import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import hero1 from "assets/img/1.jpeg";
import hero2 from "assets/img/2.jpeg";
import hero3 from "assets/img/3.jpeg";
import hero4 from "assets/img/4.jpeg";
import introduction from "assets/img/introduction.png";
import { Button, Footer, GlobalHeader, ImageHandler } from "components";
import { FaBus, FaLanguage } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalesLang } from "features/locales/localesSlice";
import { getLocales } from "locales";
import { fetchAPI, getErrorMessage } from "helpers";
import { getLanding, getProfileBitode, getTopic } from "services/landing";
import BitodeLogo from "assets/logo.png";
import logo from "assets/bitode-logo.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Parallax, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import moment from "moment";
import EmptyData from "components/organisms/EmptyData";
import Slider from "react-slick";

import landingImg from "assets/landing.png";
import landingImg2 from "assets/landing2.png";

let bannerSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

let topicSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LandingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { LANDING_PAGE } = getLocales();

  const organizationSectionRef = useRef();
  const agendaSectionRef = useRef();
  const topicSectionRef = useRef();
  const aboutMeSectionRef = useRef();
  const modalLocales = useRef();
  const modalTopic = useRef();

  const { lang } = useSelector(({ locales }) => locales);

  const [landing, setLanding] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [topic, setTopic] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [topicSelected, setTopicSelected] = useState(null);

  const dummyTopics = [
    {
      title: "Regulasi/sistem ginou jishusei",
      tag: "NEWS",
    },
    {
      title: "Kegiatan bitode wira",
      tag: "ARTICLE",
    },
    {
      title: "Informasi umum",
      tag: "NEWS",
    },
  ];

  useEffect(() => {
    if (location?.hash) {
      handleLocationHash(location?.hash);
    } else {
      window.scrollTo({ behavior: "smooth", top: 0 });
    }
  }, [location?.hash]);

  useEffect(() => {
    fetchLanding();
    fetchTopic();
  }, []);

  const handleLocationHash = (hash) => {
    switch (hash) {
      case "#organization":
        organizationHandleFocus();
        break;
      case "#agenda":
        agendaHandleFocus();
        break;
      case "#article":
        articleHandleFocus();
        break;
      case "#aboutme":
        aboutMeHandleFocus();
        break;

      default:
        window.scrollTo({ behavior: "smooth", top: 0 });
        break;
    }
  };

  const organizationHandleFocus = () => {
    organizationSectionRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const agendaHandleFocus = () => {
    agendaSectionRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const articleHandleFocus = () => {
    topicSectionRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const aboutMeHandleFocus = () => {
    aboutMeSectionRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const fetchLanding = () => {
    setLanding((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getLanding }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data: data,
        }));
      } else {
        setLanding((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const fetchTopic = () => {
    setTopic({
      ...topic,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getTopic,
      },
      (success, response) => {
        if (success) {
          setTopic({
            ...topic,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setTopic({
            ...topic,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const onDetailTopic = (article) => {
    modalTopic?.current?.click();
    setTopicSelected(article);
  };

  const renderModalTopicDetail = () => (
    <>
      <input
        ref={modalTopic}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box w-[720px] max-w-[720px] overflow-hidden">
          <div>
            <div className="flex items-center justify-between">
              <h3 className="font-bold text-lg">
                {handleLocalesTopic("tittle", topicSelected)}
              </h3>
              <CgClose
                className="cursor-pointer text-xl"
                onClick={() => {
                  modalTopic?.current?.click();
                }}
              />
            </div>
          </div>
          <div className="flex-1 py-4 space-y-4 overflow-scroll max-h-[560px] no-scrollbar">
            <figure>
              <img
                src={`${
                  process.env.REACT_APP_API
                }${topicSelected?.image?.replace("/public", "")}`}
                alt="Shoes"
                className="object-cover w-full h-40"
              />
            </figure>
            <p className="text-left text-sm">
              {handleLocalesTopic("description", topicSelected)}
            </p>
          </div>
        </div>
      </div>
    </>
  );

  const handleLocalesLanding = (key) => {
    if (landing?.data) {
      if (lang === "jp") {
        return landing?.data[`${key}_jpn`];
      } else if (lang === "en") {
        return landing?.data[`${key}_en`];
      } else {
        return landing?.data[`${key}`];
      }
    }
    return "";
  };

  const handleLocalesTopic = (key, article) => {
    if (article) {
      if (lang === "jp") {
        return (
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          article[`${key}`] ??
          ""
        );
      } else if (lang === "en") {
        return (
          article[`${key}_en`] ??
          article[`${key}_jpn`] ??
          article[`${key}`] ??
          ""
        );
      } else {
        return (
          article[`${key}`] ??
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          ""
        );
      }
    }
    return "";
  };

  const handleTopicImageRender = (data) => {
    const split = data?.image?.split("/");
    return `${process.env.REACT_APP_API}/${split[1]}/${split[2]}/${data?.id}/${split[3]}`;
  };

  return (
    // <div className="w-full container">
    //   {renderModalTopicDetail()}
    //   <GlobalHeader fixed={false} />
    //   <div>
    //     <div className="relative space-y-4 mb-24">
    //       <Swiper
    //         style={{
    //           "--swiper-navigation-color": "#fff",
    //           "--swiper-pagination-color": "#fff",
    //           height: "550px",
    //         }}
    //         loop={true}
    //         effect={"fade"}
    //         autoplay={{
    //           delay: 2500,
    //           disableOnInteraction: true,
    //         }}
    //         speed={600}
    //         parallax={true}
    //         pagination={{
    //           clickable: true,
    //         }}
    //         navigation={true}
    //         modules={[Parallax, Pagination, Navigation, Autoplay, EffectFade]}
    //         className="banner-swiper"
    //       >
    //         {landing?.data?.banner && (
    //           <SwiperSlide className="relative">
    //             <div className="flex">
    //               <div className="flex flex-col gap-2 w-1/2 justify-center">
    //                 <h1>{handleLocalesLanding("banner_tittle")}</h1>
    //                 <p>{handleLocalesLanding("banner_desc")}</p>
    //               </div>
    //               <img
    //                 src={`${
    //                   process.env.REACT_APP_API
    //                 }${landing?.data?.banner?.replace("/public", "")}`}
    //                 className="w-1/2 h-[300px] object-fill object-center"
    //               />
    //             </div>
    //             <div
    //               className="hero min-h-full"
    //               style={{
    //                 backgroundSize: "contain",
    //                 backgroundImage: `url(${
    //                   process.env.REACT_APP_API
    //                 }${landing?.data?.banner?.replace("/public", "")})`,
    //               }}
    //             >
    //               <div className="hero-overlay bg-opacity-60"></div>
    //               <div className="hero-content text-center text-neutral-content">
    //                 <div className="flex flex-col items-center justify-center">
    //                   <h1 className="mb-5 text-5xl font-bold max-w-lg">
    //                     {handleLocalesLanding("banner_tittle")}
    //                   </h1>
    //                   <p className="mb-5 max-w-md whitespace-pre-wrap">
    //                     {handleLocalesLanding("banner_desc")}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </SwiperSlide>
    //         )}
    //         {landing?.data?.banner_2 && (
    //           <SwiperSlide className="relative">
    //             <div
    //               className="hero min-h-full"
    //               style={{
    //                 backgroundSize: "contain",
    //                 backgroundImage: `url(${
    //                   process.env.REACT_APP_API
    //                 }${landing?.data?.banner_2?.replace("/public", "")})`,
    //               }}
    //             >
    //               <div className="hero-overlay bg-opacity-60"></div>
    //               <div className="hero-content text-center text-neutral-content">
    //                 <div className="flex flex-col items-center justify-center">
    //                   <h1 className="mb-5 text-5xl font-bold max-w-lg">
    //                     {handleLocalesLanding("banner_tittle")}
    //                   </h1>
    //                   <p className="mb-5 max-w-md whitespace-pre-wrap">
    //                     {handleLocalesLanding("banner_desc")}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </SwiperSlide>
    //         )}
    //         {landing?.data?.banner_3 && (
    //           <SwiperSlide className="relative">
    //             <div
    //               className="hero min-h-full"
    //               style={{
    //                 backgroundSize: "contain",
    //                 backgroundImage: `url(${
    //                   process.env.REACT_APP_API
    //                 }${landing?.data?.banner_3?.replace("/public", "")})`,
    //               }}
    //             >
    //               <div className="hero-overlay bg-opacity-60"></div>
    //               <div className="hero-content text-center text-neutral-content">
    //                 <div className="flex flex-col items-center justify-center">
    //                   <h1 className="mb-5 text-5xl font-bold max-w-lg">
    //                     {handleLocalesLanding("banner_tittle")}
    //                   </h1>
    //                   <p className="mb-5 max-w-md whitespace-pre-wrap">
    //                     {handleLocalesLanding("banner_desc")}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </SwiperSlide>
    //         )}
    //       </Swiper>
    //     </div>
    //     bg-base-200
    //     <div
    //       className={`hero min-h-screen ${
    //         topic?.data?.data?.length <= 0 ? "mb-40" : ""
    //       }`}
    //     >
    //       <div className="hero-content flex flex-col space-y-4">
    //         <div className="text-3xl font-bold">
    //           {LANDING_PAGE.FOREWORD_TITLE}
    //         </div>
    //         <img src={BitodeLogo} className="w-80 rounded-lg" />
    //         <div className="space-y-4 text-center">
    //           <p className="whitespace-pre-wrap">
    //             {LANDING_PAGE.FOREWORD_DESC}
    //           </p>
    //           <p>{LANDING_PAGE.FOREWORD_DESC_1}</p>
    //           <p>{LANDING_PAGE.FOREWORD_DESC_2}</p>
    //           <p>{LANDING_PAGE.FOREWORD_DESC_3}</p>
    //           <p>{LANDING_PAGE.FOREWORD_DESC_4}</p>
    //           <p>{LANDING_PAGE.FOREWORD_DESC_5}</p>
    //         </div>
    //       </div>
    //     </div>
    //     {topic?.data?.data?.length > 0 && (
    //       <div ref={topicSectionRef} className="hero min-h-screen w-full">
    //         <div>
    //           <p className="text-5xl text-center font-bold p-4 pb-4">
    //             {LANDING_PAGE.TOPIC_TITLE}
    //           </p>
    //           {topic?.data?.data?.length <= 0 ? (
    //             <div className="mt-6">
    //               <EmptyData />
    //             </div>
    //           ) : (
    //             <div className="max-w-screen-xl w-screen">
    //               <Swiper
    //                 navigation={true}
    //                 modules={[Navigation]}
    //                 slidesPerView={3}
    //                 className={`topic-swiper ${
    //                   topic?.data?.data?.length < 3 && "centered"
    //                 }`}
    //               >
    //                 {_.map(topic?.data?.data ?? [], (elmnt, key) => (
    //                   <SwiperSlide key={key}>
    //                     <div className="flex-none card bg-base-100 shadow-xl mx-6">
    //                       <figure>
    //                         <ImageHandler
    //                           image={
    //                             elmnt?.image
    //                               ? handleTopicImageRender(elmnt)
    //                               : ""
    //                           }
    //                           className="object-cover w-full h-80"
    //                         />
    //                       </figure>
    //                       <div className="card-body p-4">
    //                         <div className="flex items-center space-x-2">
    //                           <div className="text-gray-500 text-xs">
    //                             {elmnt?.created_at
    //                               ? moment(elmnt?.created_at).format(
    //                                   "DD MMMM YYYY",
    //                                 )
    //                               : ""}
    //                           </div>
    //                         </div>
    //                         <p className="text-left font-bold limit-1-line-of-text">
    //                           {handleLocalesTopic("tittle", elmnt)}
    //                         </p>
    //                         <p className="text-left limit-3-line-of-text">
    //                           {handleLocalesTopic("description", elmnt)}
    //                         </p>
    //                         <div className="card-actions">
    //                           <Link
    //                             to={"/topic/detail"}
    //                             state={{ detail: elmnt, list: topic }}
    //                           >
    //                             <Button
    //                               label={LANDING_PAGE.SEE_DETAILS}
    //                               size="sm"
    //                             />
    //                           </Link>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </SwiperSlide>
    //                 ))}
    //               </Swiper>
    //             </div>
    //           )}
    //           <div className="relative rounded-xl overflow-auto">
    //             <div className="max-w-md md:max-w-3xl xl:max-w-7xl mx-auto min-w-0">
    //               <div className="overflow-x-auto flex space-x-8 p-8 no-scrollbar">
    //                 {_.map(topic?.data?.data ?? [], (elmnt, key) => (
    //                   <div
    //                     className="flex-none card w-96 bg-base-100 shadow-xl"
    //                     key={key}
    //                   >
    //                     <figure>
    //                       <img
    //                         src={`${
    //                           process.env.REACT_APP_API
    //                         }${elmnt?.image?.replace("/public", "")}`}
    //                         alt=""
    //                         className="object-cover w-full h-80"
    //                       />
    //                     </figure>
    //                     <div className="card-body p-4">
    //                       <div className="flex items-center space-x-2">
    //                         <div className="text-gray-500 text-xs">
    //                           {elmnt?.created_at
    //                             ? moment(elmnt?.created_at).format(
    //                                 "DD MMMM YYYY",
    //                               )
    //                             : ""}
    //                         </div>
    //                       </div>
    //                       <p className="text-left font-bold limit-1-line-of-text">
    //                         {handleLocalesTopic("tittle", elmnt)}
    //                       </p>
    //                       <p className="text-left limit-3-line-of-text">
    //                         {handleLocalesTopic("description", elmnt)}
    //                       </p>
    //                       <div className="card-actions">
    //                         <Link
    //                           to={"/topic/detail"}
    //                           state={{ detail: elmnt, list: topic }}
    //                         >
    //                           <Button
    //                             label={LANDING_PAGE.SEE_DETAILS}
    //                             size="sm"
    //                           />
    //                         </Link>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 ))}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     <Footer />
    //   </div>
    // </div>
    <section className="min-h-screen flex flex-col overflow-hidden">
      <div className="h-[620px] container py-8">
        <Slider {...bannerSettings}>
          {landing?.data?.banner && (
            <div className="relative">
              <div className="flex flex-col w-full h-full justify-center items-center absolute">
                <div className="bg-gradient-to-t from-black/10 to-black/40 flex flex-col items-center text-white px-4 py-2 rounded-lg">
                  <h5 className="text-white">
                    {handleLocalesLanding("banner_tittle")}
                  </h5>
                  <p>{handleLocalesLanding("banner_desc")}</p>
                </div>
              </div>
              <img
                src={`${
                  process.env.REACT_APP_API
                }${landing?.data?.banner?.replace("/public", "")}`}
                className="h-[500px] w-full object-contain object-center"
              />
            </div>
          )}
          {landing?.data?.banner_2 && (
            <img
              src={`${
                process.env.REACT_APP_API
              }${landing?.data?.banner_2?.replace("/public", "")}`}
              className="h-[500px] w-full object-contain object-center"
            />
          )}
          {landing?.data?.banner_3 && (
            <img
              src={`${
                process.env.REACT_APP_API
              }${landing?.data?.banner_3?.replace("/public", "")}`}
              className="h-[500px] w-full object-contain object-center"
            />
          )}
        </Slider>
      </div>

      <div className="bg-gradient-to-t from-sky-50 to-white py-10">
        <div className="container">
          <div className="flex flex-col space-y-6">
            <h2>{LANDING_PAGE.FOREWORD_TITLE}</h2>
            <div className="relative">
              <div className="flex items-center justify-center">
                <img
                  src={logo}
                  className="w-40 absolute -top-19 right-6 hidden lg:flex"
                />
              </div>
              <div className="flex flex-col whitespace-pre-wrap col-span-4">
                <p className="text-sm">{LANDING_PAGE.FOREWORD_DESC}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-10 ">
        <div className="container">
          <div className="mx-auto flex flex-col space-y-6">
            <h2>{LANDING_PAGE.TOPIC_TITLE}</h2>
            <Slider {...topicSettings} className="flex w-full">
              {_.map(topic?.data?.data ?? [], (topic, key) => (
                <div key={key} className="w-full p-8 flex">
                  <div className="w-full max-w-full mb-6 md:flex-none xl:mb-0 xl:w-3/12">
                    <div className="relative flex flex-col min-w-68 min-h-96 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                      <div className="relative">
                        <a className="flex justify-center shadow-xl rounded-2xl m-2">
                          <ImageHandler
                            image={
                              topic?.image ? handleTopicImageRender(topic) : ""
                            }
                            className="max-h-40 shadow-soft-2xl rounded-2xl object-cover w-full"
                          />
                        </a>
                      </div>
                      <div className="flex flex-col px-2 pt-6 p-4 flex-grow">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                          {topic?.created_at
                            ? moment(topic?.created_at).format("DD MMMM YYYY")
                            : ""}
                        </p>
                        <h5 className="text-base line-clamp-2 h-6 overflow-hidden">
                          {handleLocalesTopic("tittle", topic)}
                        </h5>
                        <p className="mb-2 text-xs flex flex-grow line-clamp-1 h-32 overflow-hidden">
                          {handleLocalesTopic("description", topic)}
                        </p>
                        <div className="flex items-center justify-between">
                          <Link
                            to="/topic/detail"
                            state={{ detail: topic, list: topic }}
                          >
                            <button
                              type="button"
                              className="inline-block px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft border-blue-500 text-blue-500 hover:border-blue-500 hover:bg-transparent hover:text-blue-500 hover:opacity-75 hover:shadow-none active:bg-blue-500 active:text-white active:hover:bg-transparent active:hover:text-blue-500"
                            >
                              {LANDING_PAGE.SEE_DETAILS}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
