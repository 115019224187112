import { createSlice } from "@reduxjs/toolkit";
import { CORE } from "services";
import _ from "lodash";
import { getErrorMessage } from "helpers";

const initialState = {
  isLoadingRoles: false,
  isSuccessRoles: false,
  isErrorRoles: false,
  errorMessageRoles: null,
  roles: [],

  isLoadingHobies: false,
  isSuccessHobies: false,
  isErrorHobies: false,
  errorMessageHobies: null,
  hobies: [],

  isLoadingProvinces: false,
  isSuccessProvinces: false,
  isErrorProvinces: false,
  errorMessageProvinces: null,
  provinces: [],

  isLoadingCities: false,
  isSuccessCities: false,
  isErrorCities: false,
  errorMessageCities: null,
  cities: [],

  isLoadingDistricts: false,
  isSuccessDistricts: false,
  isErrorDistricts: false,
  errorMessageDistricts: null,
  districts: [],

  isLoadingLesson: false,
  isSuccessLesson: false,
  isErrorLesson: false,
  errorMessageLesson: null,
  lessonList: [],

  isLoadingStudent: false,
  isSuccessStudent: false,
  isErrorStudent: false,
  errorMessageStudent: null,
  studentList: [],

  total_lpk: null,
};

export const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    fetchRoles: (state) => {
      state.isLoadingRoles = true;
      state.isSuccessRoles = false;
      state.isErrorRoles = false;
      state.errorMessageRoles = null;
    },
    fetchRolesSuccess: (state, action) => {
      const roles = _.map(action?.payload?.data ?? [], (role) => ({
        value: role?.id,
        label: role?.name,
        slug: role?.slug,
      }));
      state.isLoadingRoles = false;
      state.isSuccessRoles = true;
      state.isErrorRoles = false;
      state.errorMessageRoles = null;
      state.roles = roles;
    },
    fetchRolesFailed: (state, action) => {
      state.isLoadingRoles = false;
      state.isSuccessRoles = false;
      state.isErrorRoles = true;
      state.errorMessageRoles = getErrorMessage(action?.payload?.message);
    },
    fetchHobies: (state) => {
      state.isLoadingHobies = true;
      state.isSuccessHobies = false;
      state.isErrorHobies = false;
      state.errorMessageHobies = null;
    },
    fetchHobiesSuccess: (state, action) => {
      const hobies = _.map(action?.payload?.data ?? [], (hoby) => ({
        value: hoby?.h_id,
        label: _.capitalize(hoby?.h_name),
      }));
      state.isLoadingHobies = false;
      state.isSuccessHobies = true;
      state.isErrorHobies = false;
      state.errorMessageHobies = null;
      state.hobies = hobies.concat({ value: "other", label: "Lain-lain" });
    },
    fetchHobiesFailed: (state, action) => {
      state.isLoadingHobies = false;
      state.isSuccessHobies = false;
      state.isErrorHobies = true;
      state.errorMessageHobies = getErrorMessage(action?.payload?.message);
    },
    fetchProvinces: (state) => {
      state.isLoadingProvinces = true;
      state.isSuccessProvinces = false;
      state.isErrorProvinces = false;
      state.errorMessageProvinces = null;
      state.total_lpk = null;
    },
    fetchProvincesSuccess: (state, action) => {
      const provinces = _.map(action?.payload?.data ?? [], (province) => ({
        value: province?.province_id,
        label: province?.province_name,
        code: province?.province_code,
      }));
      state.isLoadingProvinces = false;
      state.isSuccessProvinces = true;
      state.isErrorProvinces = false;
      state.errorMessageProvinces = null;
      state.provinces = provinces;
    },
    fetchProvincesFailed: (state, action) => {
      state.isLoadingProvinces = false;
      state.isSuccessProvinces = false;
      state.isErrorProvinces = true;
      state.errorMessageProvinces = getErrorMessage(action?.payload?.message);
    },
    fetchCities: (state) => {
      state.isLoadingCities = true;
      state.isSuccessCities = false;
      state.isErrorCities = false;
      state.errorMessageCities = null;
    },
    fetchCitiesSuccess: (state, action) => {
      const cities = _.map(action?.payload?.data ?? [], (city) => ({
        value: city?.city_id,
        label: city?.city_name,
      }));
      state.isLoadingCities = false;
      state.isSuccessCities = true;
      state.isErrorCities = false;
      state.errorMessageCities = null;
      state.cities = cities;
      state.total_lpk = action?.payload?.total_lpk;
    },
    fetchCitiesFailed: (state, action) => {
      state.isLoadingCities = false;
      state.isSuccessCities = false;
      state.isErrorCities = true;
      state.errorMessageCities = getErrorMessage(action?.payload?.message);
    },
    fetchDistricts: (state) => {
      state.isLoadingDistricts = true;
      state.isSuccessDistricts = false;
      state.isErrorDistricts = false;
      state.errorMessageDistricts = null;
    },
    fetchDistrictsSuccess: (state, action) => {
      const districts = _.map(action?.payload?.data ?? [], (district) => ({
        value: district?.district_id,
        label: district?.district_name,
      }));
      state.isLoadingDistricts = false;
      state.isSuccessDistricts = true;
      state.isErrorDistricts = false;
      state.errorMessageDistricts = null;
      state.districts = districts;
    },
    fetchDistrictsFailed: (state, action) => {
      state.isLoadingDistricts = false;
      state.isSuccessDistricts = false;
      state.isErrorDistricts = true;
      state.errorMessageDistricts = getErrorMessage(action?.payload?.message);
    },
    fetchUserLpk: (state) => {
      state.isLoadingUserLpk = true;
      state.isSuccessUserLpk = false;
      state.isErrorUserLpk = false;
      state.errorMessageUserLpk = null;
    },
    fetchUserLpkSuccess: (state, action) => {
      const userLpk = _.map(action?.payload?.data ?? [], (userLpk) => ({
        value: userLpk?.id,
        label: userLpk?.name,
      }));
      state.isLoadingUserLpk = false;
      state.isSuccessUserLpk = true;
      state.isErrorUserLpk = false;
      state.errorMessageUserLpk = null;
      state.userLpk = userLpk;
    },
    fetchUserLpkFailed: (state, action) => {
      state.isLoadingUserLpk = false;
      state.isSuccessUserLpk = false;
      state.isErrorUserLpk = true;
      state.errorMessageUserLpk = getErrorMessage(action?.payload?.message);
    },
    fetchLesson: (state) => {
      state.isLoadingLesson = true;
      state.isSuccessLesson = false;
      state.isErrorLesson = false;
      state.errorMessageLesson = null;
    },
    fetchLessonSuccess: (state, action) => {
      const lessonList = _.map(action?.payload?.data ?? [], (lessonList) => ({
        value: lessonList?.id,
        label: lessonList?.name,
      }));
      state.isLoadingLesson = false;
      state.isSuccessLesson = true;
      state.isErrorLesson = false;
      state.errorMessageLesson = null;
      state.lessonList = lessonList;
    },
    fetchLessonFailed: (state, action) => {
      state.isLoadingLesson = false;
      state.isSuccessLesson = false;
      state.isErrorLesson = true;
      state.errorMessageLesson = getErrorMessage(action?.payload?.message);
    },
    fetchStudent: (state) => {
      state.isLoadingStudent = true;
      state.isSuccessStudent = false;
      state.isErrorStudent = false;
      state.errorMessageStudent = null;
    },
    fetchStudentSuccess: (state, action) => {
      const lessonList = _.map(action?.payload?.data ?? [], (lessonList) => ({
        value: lessonList?.id,
        label: lessonList?.name,
        student_id: lessonList?.student_id,
      }));
      state.isLoadingStudent = false;
      state.isSuccessStudent = true;
      state.isErrorStudent = false;
      state.errorMessageStudent = null;
      state.studentList = lessonList;
    },
    fetchStudentFailed: (state, action) => {
      state.isLoadingStudent = false;
      state.isSuccessStudent = false;
      state.isErrorStudent = true;
      state.errorMessageStudent = getErrorMessage(action?.payload?.message);
    },
  },
});

// this is for dispatch
export const {
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailed,
  fetchHobies,
  fetchHobiesSuccess,
  fetchHobiesFailed,
  fetchProvinces,
  fetchProvincesSuccess,
  fetchProvincesFailed,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailed,
  fetchDistricts,
  fetchDistrictsSuccess,
  fetchDistrictsFailed,
  fetchUserLpk,
  fetchUserLpkSuccess,
  fetchUserLpkFailed,
  fetchLesson,
  fetchLessonSuccess,
  fetchLessonFailed,
  fetchStudent,
  fetchStudentSuccess,
  fetchStudentFailed,
} = coreSlice.actions;

export const getRoles = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchRoles());
    try {
      const response = await CORE.getRoles(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchRolesSuccess(data));
      } else {
        dispatch(fetchRolesFailed(data));
      }
    } catch (error) {
      dispatch(fetchRolesFailed(error.response));
    }
  };
};

export const getHobies = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchHobies());
    try {
      const response = await CORE.getHobies(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchHobiesSuccess(data));
      } else {
        dispatch(fetchHobiesFailed(data));
      }
    } catch (error) {
      dispatch(fetchHobiesFailed(error.response));
    }
  };
};

export const getProvinces = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchProvinces());
    try {
      const response = await CORE.getProvinces(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchProvincesSuccess(data));
      } else {
        dispatch(fetchProvincesFailed(data));
      }
    } catch (error) {
      dispatch(fetchProvincesFailed(error.response));
    }
  };
};

export const getCities = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchCities());
    try {
      const response = await CORE.getCities(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchCitiesSuccess(data));
      } else {
        dispatch(fetchCitiesFailed(data));
      }
    } catch (error) {
      dispatch(fetchCitiesFailed(error.response));
    }
  };
};

export const getDistricts = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchDistricts());
    try {
      const response = await CORE.getDistricts(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchDistrictsSuccess(data));
      } else {
        dispatch(fetchDistrictsFailed(data));
      }
    } catch (error) {
      dispatch(fetchDistrictsFailed(error.response));
    }
  };
};

export const getUserLpk = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchUserLpk());
    try {
      const response = await CORE.getUserLpk(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchUserLpkSuccess(data));
      } else {
        dispatch(fetchUserLpkFailed(data));
      }
    } catch (error) {
      dispatch(fetchUserLpkFailed(error.response));
    }
  };
};

export const getLesson = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchLesson());
    try {
      const response = await CORE.getLesson(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchLessonSuccess(data));
      } else {
        dispatch(fetchLessonFailed(data));
      }
    } catch (error) {
      dispatch(fetchLessonFailed(error.response));
    }
  };
};

export const getStudent = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchStudent());
    try {
      const response = await CORE.getStudent(params);
      const data = response?.data;
      if (data?.success) {
        dispatch(fetchStudentSuccess(data));
      } else {
        dispatch(fetchStudentFailed(data));
      }
    } catch (error) {
      dispatch(fetchStudentFailed(error.response));
    }
  };
};

// this is for configureStore
export default coreSlice.reducer;
