import React from "react";
// import { motion } from "framer-motion";

const AuthLayout = ({ children }) => {
  return (
    <div
      className="flex flex-1 justify-center items-center h-screen bg-slate-100"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.05 }}
    >
      {children}
    </div>
  );
};

export default AuthLayout;
