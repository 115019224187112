import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { zeroPad } from "helpers";
import { downloadCvStudent, getDetailStudent } from "services/user";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  Datepicker,
  BaseInput as Input,
  NumericInput,
  PhoneInput,
  SelectBloodType,
  SelectCategory,
  SelectHobby,
  SelectMasteryLanguage,
  SelectReligion,
  SelectSkill,
} from "components/v2";
import { UploadAvatar } from "components/v2";
import { useMutation, useQuery } from "react-query";
import RadioGroupForm from "components/v2/Form/RadioGroupForm";
import { useLoader } from "Provider/LoaderProvider";
import clsx from "clsx";
import PersonalInfo from "./personal-info";
import Document from "./document";
import Graph from "./graph";
import { Button } from "components";
import { FaDownload } from "react-icons/fa";
import Workplace from "./workplace";
import { Link } from "react-router-dom";

const DEFAULT_VALUE = {
  student_code: "",
  personalities: [
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
  ],
  family_structures: [
    {
      name: "",
      relation: "",
    },
  ],
  link_youtube: [
    {
      url: "",
    },
  ],
  employment_histories: [
    {
      name: "",
      year: "",
    },
  ],
  educations: [
    {
      education: null,
      name: "",
    },
  ],
};

const StudentManagementDetail = () => {
  const cvRef = useRef();
  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const { state } = useLocation();

  const id = state?.id ?? null;
  const total_student = state?.total_student ?? null;

  const { user, token } = useSelector(({ auth }) => auth);

  const methods = useForm({
    defaultValues: DEFAULT_VALUE,
  });

  const [tab, setTab] = useState(0);

  const { data: detail, isLoading } = useQuery(
    ["student-management-detail", { id }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getDetailStudent({ ...params }, signal);
      },
      enabled: !!id,
    },
  );
  const downloadCv = useMutation({
    mutationFn: (payload) => downloadCvStudent(payload),
  });

  const tabMenu = useMemo(
    () => ["Personal Info", "Dokumen", "Grafik", "Tempat Kerja"],
    [user?.role],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (user && user?.code && total_student?.toString?.()) {
      methods.resetField("student_code", {
        defaultValue: `${user?.code}-${moment().format("YY")}${zeroPad(
          3,
          total_student + 1,
        )}`,
      });
    }
  }, [user, total_student]);

  useEffect(() => {
    if (isLoading) {
      startLoaderOverlay();
    } else {
      stopLoaderOverlay();
    }
  }, [isLoading]);

  useEffect(() => {
    if (detail) {
      methods.reset({
        avatar: {
          src: `${process.env.REACT_APP_API}/uploads/avatar/${
            detail?.data?.data?.user_id
          }/${detail?.data?.data?.image?.replace("/public", "")}`,
        },
        student_code: detail?.data?.data?.code,
        name: detail?.data?.data?.name,
        birth_place: detail?.data?.data?.ttl?.split?.(", ")?.[0],
        birth_date: detail?.data?.data?.ttl?.split?.(", ")?.[1]
          ? // ? new Date(moment(detail?.data?.data?.ttl?.split?.(", ")?.[1]))
            moment(detail?.data?.data?.ttl).format("YYYY-MM-DD")
          : "",
        email: detail?.data?.data?.email,
        phone: detail?.data?.data?.phone,
        address: detail?.data?.data?.address,
        educations: detail?.data?.data?.education_history,
        employment_histories:
          typeof detail?.data?.data?.work_history === "string"
            ? [
                {
                  name: detail?.data?.data?.work_history,
                  year: "",
                },
              ]
            : detail?.data?.data?.work_history,
        hobby: detail?.data?.data?.hobby_id
          ? {
              value: parseInt(detail?.data?.data?.hobby_id),
              label: _.capitalize(detail?.data?.data?.hobby_name),
            }
          : null,
        old: detail?.data?.data?.old,
        skills: detail?.data?.data?.skills
          ? {
              value: detail?.data?.data?.skills,
              label: detail?.data?.data?.skills,
            }
          : null,
        mastery_of_language: detail?.data?.data?.mastery_of_language
          ? {
              value: detail?.data?.data?.mastery_of_language,
              label: detail?.data?.data?.mastery_of_language,
            }
          : null,
        entries_date: detail?.data?.data?.entries_date
          ? new Date(detail?.data?.data?.entries_date)
          : null,
        apprenticeship: detail?.data?.apprenticeship,
        religion: detail?.data?.data?.religion
          ? {
              value: detail?.data?.data?.religion,
              label: detail?.data?.data?.religion,
            }
          : null,
        blood_type: detail?.data?.data?.blood_type
          ? {
              value: detail?.data?.data?.blood_type,
              label: detail?.data?.data?.blood_type,
            }
          : null,
        height: detail?.data?.data?.height,
        weight: detail?.data?.data?.weight,
        bmi: detail?.data?.data?.bmi,
        interest_job: detail?.data?.data?.interest_job,
        category: detail?.data?.data?.category
          ? {
              value: detail?.data?.data?.category,
              label: detail?.data?.data?.category,
            }
          : null,
        category_note: detail?.data?.data?.category_note,
        gender: detail?.data?.data?.gender,
        family_structures: _.map(
          detail?.data?.data?.family_structure,
          (family_structure) => ({
            name: family_structure?.name,
            relation: {
              value: family_structure?.relation,
              label: family_structure?.relation,
            },
          }),
        ),
        personalities: _.map(
          detail?.data?.data?.personality,
          (personality) => ({
            value: personality,
          }),
        ),
        link_youtube: _.map(
          detail?.data?.data?.link_youtube,
          (link_youtube) => ({
            url: link_youtube.url,
          }),
        ),
        language_skills: _.filter(
          detail?.data?.data?.documents,
          (document) => document.type === "language_skills",
        ).map((document) => ({
          value: { ...document, name: document?.name?.replace(`/${id}/`, "") },
        })),
        certification_exam: _.filter(
          detail?.data?.data?.documents,
          (document) => document.type === "certification_exam",
        ).map((document) => ({
          value: { ...document, name: document?.name?.replace(`/${id}/`, "") },
        })),
        ijazah: mappingDocument(detail, "ijazah"),
        ktp: mappingDocument(detail, "ktp"),
        kk: mappingDocument(detail, "kk"),
        skck: mappingDocument(detail, "skck"),
        akteKelahiran: mappingDocument(detail, "akte_kelahiran"),
        fotoLayarPutih: mappingDocument(detail, "foto_layar_putih"),
        ketKesehatan: mappingDocument(detail, "ket_sehat"),
        suratIzinOrtu: mappingDocument(detail, "surat_izin_ortu"),
        sertifikatKomp1: mappingDocument(detail, "sertifikat_komp_1"),
        sertifikatKomp2: mappingDocument(detail, "sertifikat_komp_2"),
      });
    }
  }, [detail]);

  useEffect(() => {
    if (methods.watch("entries_date")) {
      const current = moment();
      const selected = moment(methods.watch("entries_date"));
      const diffYear = selected.diff(current, "years");
      let diffMonth = selected.diff(current, "months");
      const resultOfYear = diffYear < 0 ? diffYear * -1 : 0;
      const resultOfMonth = diffMonth < 0 ? (diffMonth %= 12) * -1 : 0;
      methods.setValue(
        "apprenticeship",
        `${resultOfYear} 年 (tahun) ${resultOfMonth} 月 (bulan)`,
      );
    }
  }, [methods.watch("entries_date")]);

  const mappingDocument = (params, key) => {
    const data = _.find(
      params?.data?.data?.documents,
      (document) => document.type === key,
    );
    if (data) {
      return { file: { ...data, name: data?.name?.replace(`/${id}/`, "") } };
    }
    return;
  };

  return (
    <section>
      <FormProvider {...methods}>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-2xl font-bold">Detail Siswa</p>
            {/* <Button
              label={
                <div className="flex space-x-2 font-bold text-center text-xs text-white uppercase align-middle transition-all rounded-lg px-4 py-2 cursor-pointer bg-gradient-to-tl from-blue-700 to-cyan-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                  <FaDownload />
                  <div>Unduh CV Siswa</div>
                </div>
              }
              variant="primary2"
              size="sm"
              onClick={handleCvDownload}
            >
              adf
            </Button> */}
            <Link
              to={`${process.env.REACT_APP_API}/api/admin/cv/${id}`}
              target="_blank"
              style={{
                borderRadius: 8,
                display: "flex",
                alignItems: "center",
                padding: "0px 8px",
                fontSize: 14,
                fontWeight: "medium",
              }}
            >
              <div className="flex space-x-2 font-bold text-center text-xs text-white uppercase align-middle transition-all rounded-lg px-4 py-2 cursor-pointer bg-gradient-to-tl from-blue-700 to-cyan-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                <FaDownload />
                <div>Unduh CV Siswa</div>
              </div>
            </Link>
          </div>
          <div className="space-y-6">
            <div className="flex items-center overflow-x-auto border-b border-gray-400">
              {_.map(tabMenu, (menu, key) => (
                <div
                  key={key}
                  className={clsx({
                    "border-b-4 border-blue-500": key === tab,
                  })}
                >
                  <div
                    onClick={() => setTab(key)}
                    className={clsx(
                      "px-4 pb-2 whitespace-nowrap",
                      {
                        "text-blue-500 font-medium": key === tab,
                      },
                      "hover:cursor-pointer hover:font-medium",
                    )}
                  >
                    {menu}
                  </div>
                </div>
              ))}
            </div>

            {tab === 0 && <PersonalInfo />}
            {tab === 1 && <Document />}
            {tab === 2 && <Graph />}
            {tab === 3 && <Workplace />}
          </div>
        </div>
      </FormProvider>
    </section>
  );
};

export default StudentManagementDetail;
