import React from "react";
import SelectInput from ".";

const options = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "AB", label: "AB" },
  { value: "O", label: "O" },
];

const SelectBloodType = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectBloodType;
