import React, { useEffect, useState } from "react";
import {
  Button,
  Footer,
  GlobalHeader,
  ImageHandler,
  Pagination,
} from "components";
import _ from "lodash";
// import { motion } from "framer-motion";
import { getLocales } from "locales";
import { fetchAPI, getErrorMessage } from "helpers";
import { getArticles } from "services/landing";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import EmptyData from "components/organisms/EmptyData";
import ImageEmpty from "assets/img/empty-image.png";

const Article = () => {
  const { LANDING_PAGE, ARTICLE, EMPY_DATA_TXT } = getLocales();

  const { lang } = useSelector(({ locales }) => locales);

  const [filter, setFilter] = useState({
    page: 1,
  });

  const [articles, setArticles] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, []);

  useEffect(() => {
    fetchArticles(filter);
    return;
  }, [filter]);

  const handleLocalesArticle = (key, article) => {
    if (article) {
      if (lang === "jp") {
        return (
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          article[`${key}`] ??
          ""
        );
      } else if (lang === "en") {
        return (
          article[`${key}_en`] ??
          article[`${key}_jpn`] ??
          article[`${key}`] ??
          ""
        );
      } else {
        return (
          article[`${key}`] ??
          article[`${key}_jpn`] ??
          article[`${key}_en`] ??
          ""
        );
      }
    }
    return "";
  };

  const fetchArticles = ({ page }) => {
    setArticles({
      ...articles,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getArticles,
        params: { page },
      },
      (success, response) => {
        if (success) {
          setArticles({
            ...articles,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setArticles({
            ...articles,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const handleImageRender = (data) => {
    const split = data?.image?.split("/");
    return `${process.env.REACT_APP_API}/${split[1]}/${split[2]}/${data?.id}/${split[3]}`;
  };

  return (
    // <div>
    //   <GlobalHeader fixed={false} />
    //   <motion.div
    //     exit={{ y: 50, opacity: 0 }}
    //     initial={{ y: 50, opacity: 0 }}
    //     animate={{ y: 0, opacity: 1 }}
    //     transition={{ duration: 0.25 }}
    //     className="my-10 mx-44"
    //   >
    //     <h1 className="text-3xl font-bold mb-6">{ARTICLE.TITLE}</h1>
    //     <div className="space-y-6">
    //       {articles?.isLoading ? (
    //         _.map(Array(3), (item, key) => (
    //           <div className="rounded-md w-full mx-auto">
    //             <div className="animate-pulse flex">
    //               <div className="bg-slate-200 rounded w-full h-56"></div>
    //             </div>
    //           </div>
    //         ))
    //       ) : articles?.data?.data?.length <= 0 ? (
    //         <EmptyData />
    //       ) : (
    //         _.map(articles?.data?.data ?? [], (article, key) => (
    //           <div
    //             key={key}
    //             className="card card-side border bg-base-100 shadow-xl overflow-hidden"
    //           >
    //             <ImageHandler
    //               image={
    //                 article?.image ? handleImageRender(article) : ImageEmpty
    //               }
    //               className="max-w-[200px] flex-none object-contain"
    //             />
    //             <div className="card-body">
    //               <div className="flex items-center space-x-2">
    //                 <div className="text-gray-500 text-sm">
    //                   {article?.updated_at
    //                     ? moment(article?.updated_at).format("DD MMMM YYYY")
    //                     : ""}
    //                 </div>
    //               </div>
    //               <h2 className="card-title">
    //                 {handleLocalesArticle("tittle", article)}
    //               </h2>
    //               <p className="limit-2-line-of-text">
    //                 {handleLocalesArticle("description", article)}
    //               </p>
    //               <div className="card-actions">
    //                 <Link
    //                   to={"/article/detail"}
    //                   state={{ detail: article, list: articles }}
    //                 >
    //                   <Button label={LANDING_PAGE.SEE_DETAILS} size="sm" />
    //                 </Link>
    //               </div>
    //             </div>
    //           </div>
    //         ))
    //       )}
    //     </div>
    //     <div className="flex items-center justify-center mt-10">
    //       <Pagination
    //         pageLimit={articles?.data?.meta?.limit}
    //         totalRecords={articles?.data?.meta?.total ?? 0}
    //         currentPage={articles?.data?.meta?.page}
    //         onPageChanged={handleChangePage}
    //       />
    //     </div>
    //   </motion.div>
    //   <Footer />
    // </div>
    <section className="container py-10">
      <div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ y: 50, opacity: 0 }}
        className=""
      >
        <h2 className="mb-6">{ARTICLE.TITLE}</h2>
        <div className="space-y-6">
          {articles?.isLoading ? (
            <div className="rounded-md w-full mx-auto">
              <div className="animate-pulse flex">
                <div className="bg-slate-200 rounded w-full h-56"></div>
              </div>
            </div>
          ) : articles?.data?.data?.length <= 0 ? (
            <EmptyData />
          ) : (
            _.map(articles?.data?.data ?? [], (article, key) => (
              <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="flex-auto p-4">
                  <div className="flex flex-wrap -mx-3">
                    <div className="max-w-full px-3 lg:w-1/2 lg:flex-none">
                      <div className="flex flex-col h-full">
                        <p className="pt-2 mb-1 font-semibold">
                          {article?.updated_at
                            ? moment(article?.updated_at).format("DD MMMM YYYY")
                            : ""}
                        </p>
                        <h5 className="font-bold">
                          {handleLocalesArticle("tittle", article)}
                        </h5>
                        <p className="mb-12 line-clamp-3">
                          {handleLocalesArticle("description", article)}
                        </p>
                        <Link
                          to="/article/detail"
                          state={{ detail: article, list: articles }}
                          className="mt-auto mb-0 font-semibold leading-normal text-sm group text-slate-500"
                        >
                          {LANDING_PAGE.SEE_DETAILS}
                          <i className="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.25 ml-1 leading-normal transition-all duration-200"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="max-w-full px-3 mt-6 ml-auto text-center lg:mt-0 lg:flex-none">
                      <div className="h-full rounded-xl flex items-center">
                        <ImageHandler
                          image={
                            article?.image
                              ? handleImageRender(article)
                              : ImageEmpty
                          }
                          className="w-full lg:max-w-[200px] flex-none object-contain rounded-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex items-center justify-center mt-10">
          <Pagination
            pageLimit={articles?.data?.meta?.limit}
            totalRecords={articles?.data?.meta?.total ?? 0}
            currentPage={articles?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>
    </section>
  );
};

export default Article;
