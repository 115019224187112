import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import {
  Button,
  Datepicker,
  InputValidation,
  Label,
  Select,
  Spinner,
} from "components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchAPI, getErrorMessage } from "helpers";
import Service from "config";
import {
  deleteAvatar,
  getDetail,
  postData,
  putData,
  uploadAvatar,
} from "services/class";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getDistricts,
  getLesson,
  getStudent,
} from "features/core/coreSlice";
import moment from "moment";
import ReactSelect from "react-select";
import { inviteStudentToClass } from "services/user";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ReactDatePicker from "react-datepicker";
import ModalWrapper from "components/molecules/ModalWrapper";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

const ClassForm = () => {
  const modalAddStudent = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = state?.id ?? null;

  const avatarRef = useRef();

  const { lessonList, studentList } = useSelector(({ core }) => core);
  const { user } = useSelector(({ auth }) => auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [lessonSelected, setLessonSelected] = useState(null);
  const [studentSelected, setStudentSelected] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [customValidation, setCustomValidation] = useState({
    lesson: {
      value: undefined,
      message: "",
    },
    student: {
      value: undefined,
      message: "",
    },
  });
  const [year, setYear] = useState(null);
  const [schedule, setSchedule] = useState(null);

  const [createData, setCreateData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [detail, setDetail] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });

  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getLesson());
    dispatch(getStudent());
    return;
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!detail?.isLoading && detail?.data) {
      const newLesson = _.map(detail?.data?.leassons, (lesson) => ({
        value: lesson?.id,
        label: lesson?.name,
      }));
      reset({
        name: detail?.data?.name,
        force: detail?.data?.force,
        academic_year: detail?.data?.academic_year
          ? new Date(detail?.data?.academic_year)
          : null,
        schedule: detail?.data?.schedule
          ? new Date(
              null,
              null,
              null,
              detail?.data?.schedule?.split(":")[0],
              detail?.data?.schedule?.split(":")[1],
            )
          : null,
      });
      if (detail?.data?.academic_year) {
        setYear(new Date(detail?.data?.academic_year));
      }
      if (detail?.data?.schedule) {
        setSchedule(
          new Date(
            null,
            null,
            null,
            detail?.data?.schedule?.split(":")[0],
            detail?.data?.schedule?.split(":")[1],
          ),
        );
      }

      setLessonSelected(newLesson);
      setIsActive(detail?.data?.active);
    }
  }, [detail]);

  const handleChangeAcademicYear = (date) => {
    setYear(date);
    setValue("academic_year", date);
  };

  const handleChangeSchedule = (date) => {
    setSchedule(date);
    setValue("schedule", date);
  };

  const handleChangeLesson = (lesson) => {
    setLessonSelected(lesson);
    setValue("lesson", lesson);
  };

  const handleChangeStudent = (student) => {
    setStudentSelected(student);
    setValue("student", student);
  };

  const handleChangeIsActive = () => {
    setIsActive((state) => !state);
  };

  const fetchDetail = () => {
    setDetail((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI({ service: getDetail, params: id }, (success, response) => {
      const data = response?.data ?? {};
      if (success) {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: true,
          data,
        }));
      } else {
        setDetail((prevState) => ({
          ...prevState,
          isLoading: false,
          isError: true,
          errorMessage: getErrorMessage(response),
        }));
      }
    });
  };

  const onAddStudent = () => {
    modalAddStudent?.current?.click();
    setIsAddStudentModalOpen(true);
  };

  const onSubmitStudent = () => {
    if (studentSelected) {
      const params = {
        id: studentSelected?.student_id ?? null,
        params: { class_id: id },
      };
      fetchAPI(
        { service: inviteStudentToClass, params },
        async (success, response) => {
          if (success) {
            fetchDetail();
            setStudentSelected(null);
            dispatch(getStudent());
          }
          setIsAddStudentModalOpen(false);
        },
      );
    }
  };

  const customValidate = () => {
    setCustomValidation((cv) => {
      return {
        ...cv,
        lesson: {
          value: lessonSelected?.length > 0 ? undefined : 1,
          message: lessonSelected?.length > 0 ? "" : "Materi Wajib Diisi",
        },
        student: {
          value: studentSelected?.length > 0 ? undefined : 1,
          message: studentSelected?.length > 0 ? "" : "Siswa Wajib Diisi",
        },
      };
    });
  };

  const onHandleSubmit = async (event) => {
    // event.preventDefault();
    await customValidate();
    handleSubmit(onSubmit)(event);
  };

  const onSubmit = (data) => {
    if (data) {
      if (lessonSelected?.length > 0) {
        let params = {
          name: data?.name,
          academic_year: moment(year).format("YYYY"),
          force: data?.force,
          schedule: moment(schedule).format("H:mm"),
          leasson_id: _.map(lessonSelected, "value").join(","),
        };

        setCreateData((prevState) => ({ ...prevState, isLoading: true }));
        if (id) {
          params.user_id = `${user?.id?.toString()},${_.map(
            detail?.data?.students,
            "id",
          )}`;
          params.active = isActive;
          if (data) {
            fetchAPI(
              { service: putData, params: { id, payload: params } },
              (success, response) => {
                if (success) {
                  setCreateData((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isSuccess: true,
                    data: response,
                  }));
                  toast.success("Sukses mengubah data!");
                  navigate(-1);
                } else {
                  setCreateData((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isError: true,
                    data: response,
                    errorMessage: getErrorMessage(response),
                  }));
                }
              },
            );
          }
        } else {
          params.user_id = `${user?.id?.toString()},${_.map(
            studentSelected,
            "value",
          )}`;
          if (studentSelected?.length > 0) {
            fetchAPI(
              { service: postData, params: params },
              (success, response) => {
                if (success) {
                  setCreateData((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isSuccess: true,
                    data: response,
                  }));
                  toast.success("Sukses menambahkan data!");
                  navigate(-1);
                } else {
                  setCreateData((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isError: true,
                    data: response,
                    errorMessage: getErrorMessage(response),
                  }));
                }
              },
            );
          }
        }
      }
    }
  };

  const renderModalAddStudent = () => (
    <>
      <input
        ref={modalAddStudent}
        type="checkbox"
        id="modal-add-student"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box overflow-y-visible">
          <h3 className="font-bold text-lg">Bahasa</h3>
          <div className="py-4">
            <Select
              label="Siswa"
              onChange={handleChangeStudent}
              value={studentSelected}
              options={studentList}
              placeholder="Masukan Siswa"
            />
          </div>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              size="sm"
              theme="error"
              onClick={() => modalAddStudent?.current?.click()}
            />
            <Button
              label={"Ya"}
              size="sm"
              theme="primary"
              onClick={onSubmitStudent}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    // <div>
    //   {renderModalAddStudent()}
    //   <div className="w-full space-y-4">
    //     <div className="flex justify-between items-center">
    //       <Label
    //         text={id ? "Edit Kelas" : "Tambah Kelas"}
    //         className="font-bold text-2xl"
    //       />
    //     </div>

    //     <form className="space-y-6" onSubmit={onHandleSubmit}>
    //       <div className="space-y-1">
    //         <InputValidation
    //           {...register("name", {
    //             required: {
    //               value: true,
    //               message: "Nama Kelas wajib diisi",
    //             },
    //           })}
    //           label="Nama Kelas"
    //           type="text"
    //           placeholder="Masukan Nama Kelas"
    //           isError={{ value: errors.name, message: errors.name?.message }}
    //         />
    //         <Datepicker
    //           {...register("academic_year", {
    //             required: { value: true, message: "Tahun Ajaran wajib diisi" },
    //           })}
    //           label={"Tahun Ajaran"}
    //           type="year"
    //           selected={year}
    //           onChange={handleChangeAcademicYear}
    //           dateFormat="yyyy"
    //           placeholder="Pilih Tahun Ajaran"
    //           withPortal
    //           showYearPicker
    //           isError={{
    //             value: errors.academic_year,
    //             message: errors.academic_year?.message,
    //           }}
    //         />
    //         <InputValidation
    //           {...register("force", {
    //             required: {
    //               value: true,
    //               message: "Angkatan wajib diisi",
    //             },
    //           })}
    //           label="Angkatan"
    //           type="text"
    //           placeholder="Masukan Angkatan"
    //           isError={{ value: errors.force, message: errors.force?.message }}
    //         />
    //         <Datepicker
    //           {...register("schedule", {
    //             required: { value: true, message: "Jadwal wajib diisi" },
    //           })}
    //           label={"Jadwal"}
    //           type="time"
    //           selected={schedule}
    //           onChange={handleChangeSchedule}
    //           placeholder="Pilih Jadwal"
    //           withPortal
    //           showTimeSelect
    //           showTimeSelectOnly
    //           timeIntervals={15}
    //           timeCaption="Time"
    //           dateFormat="h:mm aa"
    //           isError={{
    //             value: errors.schedule,
    //             message: errors.schedule?.message,
    //           }}
    //         />
    //         <Select
    //           label="Materi"
    //           onChange={handleChangeLesson}
    //           value={lessonSelected}
    //           options={lessonList}
    //           placeholder="Masukan Materi"
    //           isMulti
    //           isError={customValidation?.lesson}
    //         />
    //         {!id && (
    //           <Select
    //             label="Siswa"
    //             onChange={handleChangeStudent}
    //             value={studentSelected}
    //             options={studentList}
    //             placeholder="Masukan Siswa"
    //             isMulti
    //             isError={customValidation?.student}
    //           />
    //         )}
    //         {id && (
    //           <div className="flex items-center space-x-2 pt-4">
    //             <input
    //               type="checkbox"
    //               className="accent-primary"
    //               checked={isActive}
    //               onChange={handleChangeIsActive}
    //             />
    //             <label>Aktif</label>
    //           </div>
    //         )}
    //         {id && (
    //           <div className="pt-6 space-y-4">
    //             <div className="flex justify-between items-center">
    //               <div className="text-lg font-medium">Siswa</div>
    //               <Button
    //                 theme="light"
    //                 label="Tambah Siswa"
    //                 size="sm"
    //                 type="button"
    //                 onClick={() => onAddStudent()}
    //               />
    //             </div>
    //             <div className="border rounded-lg">
    //               <table className="table w-full">
    //                 <thead>
    //                   <tr>
    //                     <th>Nama</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   {detail?.data?.students?.length <= 0 ||
    //                   !detail?.data?.students ? (
    //                     <tr>
    //                       <td>
    //                         <div className="w-full flex items-center justify-center">
    //                           No Data.
    //                         </div>
    //                       </td>
    //                     </tr>
    //                   ) : (
    //                     _.map(detail?.data?.students, (student, key) => (
    //                       <tr key={key}>
    //                         <td>{student?.name}</td>
    //                       </tr>
    //                     ))
    //                   )}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //       <div className="card-actions justify-end">
    //         <Button
    //           label={createData?.isLoading ? <Spinner /> : "Kirim"}
    //           disabled={createData?.isLoading}
    //           className="w-full"
    //           theme="primary"
    //           type="submit"
    //         />
    //       </div>
    //     </form>
    //   </div>
    // </div>
    <div>
      <form className="space-y-6" onSubmit={handleSubmit(onHandleSubmit)}>
        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <h6>{id ? "Edit Kelas" : "Tambah Kelas"}</h6>
          </div>

          <div className="space-y-6 p-6">
            <BaseInput
              {...register("name", {
                required: {
                  value: true,
                  message: "Nama Kelas wajib diisi",
                },
              })}
              label="Nama Kelas"
              type="text"
              placeholder="Masukan Nama Kelas"
              isError={{ value: errors.name, message: errors.name?.message }}
            />

            <Datepicker
              {...register("academic_year", {
                required: { value: true, message: "Tahun Ajaran wajib diisi" },
              })}
              label={"Tahun Ajaran"}
              type="year"
              selected={year}
              onChange={handleChangeAcademicYear}
              dateFormat="yyyy"
              placeholder="Pilih Tahun Ajaran"
              // withPortal
              showYearPicker
              isError={{
                value: errors.academic_year,
                message: errors.academic_year?.message,
              }}
            />

            <BaseInput
              {...register("force", {
                required: {
                  value: true,
                  message: "Angkatan wajib diisi",
                },
              })}
              label="Angkatan"
              type="text"
              placeholder="Masukan Angkatan"
              isError={{ value: errors.force, message: errors.force?.message }}
            />

            <Datepicker
              {...register("schedule", {
                required: { value: true, message: "Jadwal wajib diisi" },
              })}
              label={"Jadwal"}
              type="time"
              selected={schedule}
              onChange={handleChangeSchedule}
              placeholder="Pilih Jadwal"
              // withPortal
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              isError={{
                value: errors.schedule,
                message: errors.schedule?.message,
              }}
            />

            <Select
              label="Materi"
              onChange={handleChangeLesson}
              value={lessonSelected}
              options={lessonList}
              placeholder="Masukan Materi"
              isMulti
              isError={customValidation?.lesson}
            />

            {!id && (
              <Select
                label="Siswa"
                onChange={handleChangeStudent}
                value={studentSelected}
                options={studentList}
                placeholder="Masukan Siswa"
                isMulti
                isError={customValidation?.student}
                menuPortalTarget={document.body}
              />
            )}

            {id && (
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="accent-primary"
                  checked={isActive}
                  onChange={handleChangeIsActive}
                />
                <label>Aktif</label>
              </div>
            )}
          </div>
        </div>

        {id && (
          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="space-y-6 p-6">
              <div className="pt-6 space-y-4">
                <div className="flex justify-between items-center px-1">
                  <h6>Siswa</h6>
                  {/* <Button
                    theme="light"
                    label="Tambah Siswa"
                    size="sm"
                    type="button"
                    onClick={() => onAddStudent()}
                  /> */}
                  <span>
                    <BaseButton
                      variant="primary2"
                      size="md"
                      type="button"
                      onClick={() => onAddStudent()}
                    >
                      Tambah Data
                    </BaseButton>
                  </span>
                </div>
                <div className="border rounded-lg">
                  {/* <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Nama</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.data?.students?.length <= 0 ||
                      !detail?.data?.students ? (
                        <tr>
                          <td>
                            <div className="w-full flex items-center justify-center">
                              No Data.
                            </div>
                          </td>
                        </tr>
                      ) : (
                        _.map(detail?.data?.students, (student, key) => (
                          <tr key={key}>
                            <td>{student?.name}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table> */}
                  <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                      <tr>
                        <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                          Nama lengkap
                        </th>

                        <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.data?.students?.length <= 0 ||
                      !detail?.data?.students ? (
                        <tr>
                          <td
                            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
                            colSpan={5}
                          >
                            <p>No Data</p>
                          </td>
                        </tr>
                      ) : (
                        _.map(detail?.data?.students, (student, key) => (
                          <tr key={key}>
                            <div className="flex px-4 py-1">
                              <Link
                                to={`student-management/${student?.student_id}`}
                              >
                                <h6 className="mb-0 leading-normal text-sm hover:text-slate-500">
                                  {student.name ?? "-"}
                                </h6>
                              </Link>
                            </div>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalWrapper
          isOpen={isAddStudentModalOpen}
          setIsOpen={setIsAddStudentModalOpen}
        >
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl max-h-125 max-w-135">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Tambah Siswa</h5>
              <CgClose
                className="cursor-pointer"
                onClick={() => setIsAddStudentModalOpen((prev) => !prev)}
              />
            </div>
            <div className="relative flex flex-col p-4">
              <Select
                label="Siswa"
                onChange={handleChangeStudent}
                value={studentSelected}
                options={studentList}
                placeholder="Masukan Siswa"
                disabled
              />
            </div>

            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setIsAddStudentModalOpen((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton
                variant="primary2"
                size="sm"
                onClick={onSubmitStudent}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </ModalWrapper>
        <div className="flex justify-end w-full gap-3">
          <BaseButton
            variant="base"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Tidak
          </BaseButton>
          <BaseButton variant="primary2" type="submit">
            Simpan
          </BaseButton>
        </div>
      </form>
    </div>
  );
};

export default ClassForm;
