import React from "react";
import { map } from "lodash";
import { Pagination } from "components";
import { CgSpinner } from "react-icons/cg";

const Table = ({
  headers = [],
  rows = [],
  pagination = null,
  handleChangePage = () => null,
  isLoading = false,
}) => {
  return (
    <>
      <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
        <thead className="align-bottom">
          <tr>
            {map(headers, (head, index) => (
              <th
                key={index}
                className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-900 opacity-70"
              >
                {head?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={headers?.length}>
                <div className="flex items-center justify-center p-4">
                  <CgSpinner className="text-slate-400 animate-spin" />
                </div>
              </td>
            </tr>
          ) : rows?.length <= 0 ? (
            <tr>
              <td
                colSpan={headers?.length}
                className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-900 opacity-70"
              >
                <div className="flex items-center justify-center">No Data</div>
              </td>
            </tr>
          ) : (
            map(rows, (row, rowIndex) => (
              <tr key={rowIndex}>
                {map(headers, (head, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                  >
                    {head?.render(row?.[head?.id], row, rowIndex)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {pagination && (
        <div className="w-full mt-4 flex justify-end">
          <Pagination
            pageLimit={pagination?.limit}
            totalRecords={pagination?.total ?? 0}
            currentPage={pagination?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      )}
    </>
  );
};

export default Table;
