import React from "react";
import SelectInput from ".";

const options = [
  {
    value: "実習生 (Magang)",
    label: "実習生 (Magang)",
  },
  {
    value: "特定技能 (Tokutei Ginou)",
    label: "特定技能 (Tokutei Ginou)",
  },
];

const SelectVisaType = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectVisaType;
