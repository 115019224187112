import * as lang from "./lang";
import { useSelector } from "react-redux";

export const getLocales = () => {
  const locales = useSelector(({ locales }) => locales);
  // return lang[locales.lang];
  switch (locales.lang) {
    case "id":
      return lang.id;
    case "en":
      return lang.en;
    case "jp":
      return lang.jp;
    default:
      return lang.jp;
  }
};
