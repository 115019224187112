import React from "react";
import SelectInput from ".";

const options = [
  { value: "kaigo (介護職)", label: "kaigo (介護職)" },
  { value: "Umum(一般職種)", label: "Umum(一般職種)" },
  { value: "Bangunan (建設職)", label: "Bangunan (建設職)" },
];

const SelectCategory = ({ ...props }) => {
  return <SelectInput {...props} options={options} />;
};

export default SelectCategory;
