import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Spinner } from "components";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  checkFileMime,
  checkFileSize,
  fetchAPI,
  getErrorMessage,
  zeroPad,
} from "helpers";
import {
  getDetailStudent,
  postDataStudent,
  uploadAvatar,
  putDataStudent,
} from "services/user";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { updatePasswordStudent } from "services/auth";
import BaseButton from "components/atoms/BaseButton";
import BaseInput from "components/atoms/BaseInput";
import {
  Datepicker,
  BaseInput as Input,
  NumericInput,
  PhoneInput,
  SelectBloodType,
  SelectCategory,
  SelectHobby,
  SelectMasteryLanguage,
  SelectReligion,
} from "components/v2";
import ModalWrapper from "components/molecules/ModalWrapper";
import { UploadAvatar } from "components/v2";
import { QueryClient, useMutation, useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RadioGroupForm from "components/v2/Form/RadioGroupForm";
import { useLoader } from "Provider/LoaderProvider";
import {
  CertExam,
  Education,
  EmploymentHistory,
  FamilyStructure,
  FileItem,
  LanguageSkill,
  Personality,
  YoutubeLink,
} from "../Components";

const DEFAULT_VALUE = {
  student_code: "",
  personalities: [
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
  ],
  family_structures: [
    {
      name: "",
      relation: "",
    },
  ],
  link_youtube: [
    {
      url: "",
    },
  ],
  employment_histories: [
    {
      name: "",
      year: "",
    },
  ],
  educations: [
    {
      education: null,
      skill: null,
      name: "",
    },
  ],
};

const StudentManagamentForm = () => {
  const modalResetPassword = useRef();

  const queryClient = new QueryClient();

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const navigate = useNavigate();
  const { state } = useLocation();

  const id = state?.id ?? null;
  const total_student = state?.total_student ?? null;

  const post = useMutation({
    mutationFn: (payload) => postDataStudent(payload),
  });
  const put = useMutation({
    mutationFn: (payload) => putDataStudent(payload),
  });
  const uploadAvatarFile = useMutation({
    mutationFn: (payload) => uploadAvatar(payload),
  });

  const { user } = useSelector(({ auth }) => auth);

  const schema = useMemo(
    () =>
      yup.object({
        avatar: yup
          .mixed()
          .test({
            name: "file_mime",
            message: "Ekstensi file tidak valid",
            test: (value) => {
              if (value && value?.file) {
                return checkFileMime({
                  ext: ["jpg", "jpeg", "png"],
                  file: value?.file,
                });
              }
              return true;
            },
          })
          .test({
            name: "file_size",
            message: "Ukuran file maksimal 2MB",
            test: (value) => {
              if (value) {
                return checkFileSize({
                  max: 2048,
                  filesize: value?.file?.size,
                });
              }
              return true;
            },
          }),
        student_code: yup.string(),
        name: yup.string().required("Nama wajib diisi"),
        email: yup
          .string()
          .required("Email wajib diisi")
          .email("Format email tidak valid"),
        password: yup.string().when({
          is: () => !id,
          then: (v, schema) =>
            yup
              .string()
              .required("Password wajib diisi")
              .min(8, "Password minimal 8 karakter"),
        }),
      }),
    [id],
  );

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUE,
  });

  const {
    register: register_form_reset,
    handleSubmit: handleSubmit_form_reset,
    formState: { errors: errors_form_reset },
    reset: reset_form_reset,
    watch,
  } = useForm();

  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const { data: detail, isLoading } = useQuery(
    ["student-management-detail", { id }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getDetailStudent({ ...params }, signal);
      },
      enabled: !!id,
    },
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (user && user?.code && total_student?.toString?.()) {
      methods.resetField("student_code", {
        defaultValue: `${user?.code}-${moment().format("YY")}${zeroPad(
          3,
          total_student + 1,
        )}`,
      });
    }
  }, [user, total_student]);

  useEffect(() => {
    if (isLoading) {
      startLoaderOverlay();
    } else {
      stopLoaderOverlay();
    }
  }, [isLoading]);

  useEffect(() => {
    const firstError = Object.keys(methods.formState.errors).reduce(
      (field, a) => {
        return methods?.formState?.errors?.[field] ? field : a;
      },
      null,
    );

    if (firstError) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [methods.formState.errors, methods.setFocus]);

  useEffect(() => {
    if (detail) {
      methods.reset({
        student_code: detail?.data?.data?.code,
        avatar: {
          src: `${process.env.REACT_APP_API}/uploads/avatar/${
            detail?.data?.data?.user_id
          }/${detail?.data?.data?.image?.replace("/public", "")}`,
        },
        name: detail?.data?.data?.name,
        birth_place: detail?.data?.data?.ttl?.split?.(", ")?.[0],
        birth_date: detail?.data?.data?.ttl?.split?.(", ")?.[1]
          ? // ? new Date(moment(detail?.data?.data?.ttl?.split?.(", ")?.[1]))
            moment(detail?.data?.data?.ttl).format("DD-MM-YYYY")
          : "",
        email: detail?.data?.data?.email,
        phone: detail?.data?.data?.phone,
        address: detail?.data?.data?.address,
        educations: detail?.data?.data?.education_history,
        employment_histories:
          typeof detail?.data?.data?.work_history === "string"
            ? [
                {
                  name: detail?.data?.data?.work_history,
                  year: "",
                },
              ]
            : detail?.data?.data?.work_history,
        hobby: detail?.data?.data?.hobby_id
          ? {
              value: parseInt(detail?.data?.data?.hobby_id),
              label: _.capitalize(detail?.data?.data?.hobby_name),
            }
          : null,
        old: detail?.data?.data?.old,
        // skills: detail?.data?.data?.skills
        //   ? {
        //       value: detail?.data?.data?.skills,
        //       label: detail?.data?.data?.skills,
        //     }
        //   : null,
        mastery_of_language: detail?.data?.data?.mastery_of_language
          ? {
              value: detail?.data?.data?.mastery_of_language,
              label: detail?.data?.data?.mastery_of_language,
            }
          : null,
        entries_date: detail?.data?.data?.entries_date
          ? new Date(detail?.data?.data?.entries_date)
          : null,
        apprenticeship: detail?.data?.apprenticeship,
        religion: detail?.data?.data?.religion
          ? {
              value: detail?.data?.data?.religion,
              label: detail?.data?.data?.religion,
            }
          : null,
        blood_type: detail?.data?.data?.blood_type
          ? {
              value: detail?.data?.data?.blood_type,
              label: detail?.data?.data?.blood_type,
            }
          : null,
        height: detail?.data?.data?.height,
        weight: detail?.data?.data?.weight,
        bmi: detail?.data?.data?.bmi,
        interest_job: detail?.data?.data?.interest_job,
        category: detail?.data?.data?.category
          ? {
              value: detail?.data?.data?.category,
              label: detail?.data?.data?.category,
            }
          : null,
        category_note: detail?.data?.data?.category_note,
        gender: detail?.data?.data?.gender,
        family_structures: _.map(
          detail?.data?.data?.family_structure,
          (family_structure) => ({
            name: family_structure?.name,
            relation: {
              value: family_structure?.relation,
              label: family_structure?.relation,
            },
          }),
        ),
        personalities: _.map(
          detail?.data?.data?.personality,
          (personality) => ({
            value: personality,
          }),
        ),
        link_youtube: _.map(
          detail?.data?.data?.link_youtube,
          (link_youtube) => ({
            url: link_youtube.url,
          }),
        ),
        language_skills: _.filter(
          detail?.data?.data?.documents,
          (document) => document.type === "language_skills",
        ).map((document) => ({
          value: { ...document, name: document?.name?.replace(`/${id}/`, "") },
        })),
        certification_exam: _.filter(
          detail?.data?.data?.documents,
          (document) => document.type === "certification_exam",
        ).map((document) => ({
          value: { ...document, name: document?.name?.replace(`/${id}/`, "") },
        })),
        ijazah: mappingDocument(detail, "ijazah"),
        ktp: mappingDocument(detail, "ktp"),
        kk: mappingDocument(detail, "kk"),
        skck: mappingDocument(detail, "skck"),
        akteKelahiran: mappingDocument(detail, "akte_kelahiran"),
        fotoLayarPutih: mappingDocument(detail, "foto_layar_putih"),
        ketKesehatan: mappingDocument(detail, "ket_sehat"),
        suratIzinOrtu: mappingDocument(detail, "surat_izin_ortu"),
        sertifikatKomp1: mappingDocument(detail, "sertifikat_komp_1"),
        sertifikatKomp2: mappingDocument(detail, "sertifikat_komp_2"),
      });
    }
  }, [detail]);

  useEffect(() => {
    if (methods.watch("entries_date")) {
      const current = moment();
      const selected = moment(methods.watch("entries_date"));
      const diffYear = selected.diff(current, "years");
      let diffMonth = selected.diff(current, "months");
      const resultOfYear = diffYear < 0 ? diffYear * -1 : 0;
      const resultOfMonth = diffMonth < 0 ? (diffMonth %= 12) * -1 : 0;
      methods.setValue(
        "apprenticeship",
        `${resultOfYear} 年 (tahun) ${resultOfMonth} 月 (bulan)`,
      );
    }
  }, [methods.watch("entries_date")]);

  const mappingDocument = (params, key) => {
    const data = _.find(
      params?.data?.data?.documents,
      (document) => document.type === key,
    );
    if (data) {
      return { file: { ...data, name: data?.name?.replace(`/${id}/`, "") } };
    }
    return;
  };

  const onUpload = (params, cb) => {
    const avatar = methods.watch("avatar");
    const formData = new FormData();
    formData.append("image", avatar?.file);
    if (
      checkFileSize({
        max: 2048,
        filesize: params?.file?.size,
      })
    ) {
      uploadAvatarFile.mutate(formData, {
        onSuccess: (data) => {
          cb(data);
        },
        onError: (error) => {
          toast.error(getErrorMessage(error));
        },
      });
    }
  };

  const handleTtlPayload = (birth_place, birth_date) => {
    if (birth_place && birth_date) {
      return `${birth_place}, ${moment(birth_date).format("DD-MM-YYYY")}`;
    } else if (birth_place) {
      return `${birth_place}`;
    } else if (birth_date) {
      return `${moment(birth_date).format("DD-MM-YYYY")}`;
    } else {
      return "";
    }
  };

  const handleDocumentPayload = (data) => {
    if (data) {
      return [data];
    }
    return [];
  };

  const onSubmit = (data) => {
    if (data) {
      let params = {};
      params.code = data?.student_code;
      params.name = data?.name;
      params.ttl = handleTtlPayload(data?.birth_place, data?.birth_date);
      params.phone = data?.phone;
      params.address = data?.address;
      params.education_history = JSON.stringify(data?.educations);
      params.hobby =
        data?.hobby?.value === "other" ? data?.other_hobby : data?.hobby?.value;
      // params.skills = data?.skills?.value ?? "";
      params.work_history = JSON.stringify(data?.employment_histories);
      params.mastery_of_language = data?.mastery_of_language?.value;
      params.category = data?.category?.value ?? "";
      params.gender = data?.gender;
      if (data?.avatar?.file?.name) {
        params.image = data?.avatar?.file?.name;
      } else if (data?.avatar?.src) {
        const avatarSrc = data?.avatar?.src;
        params.image =
          avatarSrc?.split("/")?.[avatarSrc?.split("/")?.length - 1];
      }

      params.link_youtube = JSON.stringify(data?.link_youtube);

      params.province_id = "32";
      params.city_id = "3217";
      params.district_id = "321706";

      params.old = data?.old ? parseInt(data?.old) : null;
      params.entries_date = data?.entries_date
        ? moment(data?.entries_date).format("YYYY-MM-DD")
        : null;
      params.apprenticeship = data?.apprenticeship ?? "";
      params.religion = data?.religion?.value ?? "";
      params.family_structure = JSON.stringify(
        data?.family_structures?.map((family_structure) => ({
          name: family_structure?.name,
          relation: family_structure?.relation?.value,
        })),
      );
      params.blood_type = data?.blood_type?.value ?? "";
      params.height = data?.height ? parseInt(data?.height) : null;
      params.weight = data?.weight ? parseInt(data?.weight) : null;
      params.bmi = data?.bmi ?? "";
      params.personality = _.map(data?.personalities, "value").join(",");
      params.interest_job = data?.interest_job ?? "";

      if (data?.language_skills?.length > 0)
        params.language_skills = _.map(
          data?.language_skills,
          (v) => v?.value?.name,
        );
      if (data?.certification_exam?.length > 0)
        params.certification_exam = _.map(
          data?.certification_exam,
          (v) => v?.value?.name,
        );

      params.ijazah = handleDocumentPayload(data?.ijazah?.file?.name);
      params.ktp = handleDocumentPayload(data?.ktp?.file?.name);
      params.kk = handleDocumentPayload(data?.kk?.file?.name);
      params.skck = handleDocumentPayload(data?.skck?.file?.name);
      params.akte_kelahiran = handleDocumentPayload(
        data?.akteKelahiran?.file?.name,
      );
      params.foto_layar_putih = handleDocumentPayload(
        data?.fotoLayarPutih?.file?.name,
      );
      params.ket_sehat = handleDocumentPayload(data?.ketKesehatan?.file?.name);
      params.surat_izin_ortu = handleDocumentPayload(
        data?.suratIzinOrtu?.file?.name,
      );
      if (data?.sertifikatKomp1?.file?.name)
        params.sertifikat_komp_1 = handleDocumentPayload(
          data?.sertifikatKomp1?.file?.name,
        );
      if (data?.sertifikatKomp2?.file?.name)
        params.sertifikat_komp_2 = handleDocumentPayload(
          data?.sertifikatKomp2?.file?.name,
        );

      if (id) {
        put.mutate(
          { id, payload: params },
          {
            onSuccess: (data) => {
              toast.success("Sukses mengubah data!");
              queryClient.invalidateQueries("student-mitra-list");
              navigate(-1);
            },
          },
        );
      } else {
        params.email = data?.email;
        params.roles = "4";
        params.password = data?.password;

        post.mutate(params, {
          onSuccess: (data) => {
            toast.success("Sukses menambahkan data!");
            queryClient.invalidateQueries("student-mitra-list");
            navigate(-1);
          },
        });
      }
    }
  };

  const handleHeightChanged = (value) => {
    const weight = methods.watch("weight");
    let bmi_total =
      parseFloat(weight) /
      ((parseFloat(value) / 100) * (parseFloat(value) / 100));
    if (weight) {
      if (!_.isNaN(bmi_total)) {
        methods.resetField("bmi", { defaultValue: bmi_total.toFixed(2) });
      } else {
        methods.resetField("bmi", { defaultValue: 0 });
      }
    } else {
      methods.resetField("bmi", { defaultValue: null });
    }
  };

  const handleWeightChanged = (value) => {
    const height = methods.watch("height");
    let bmi_total =
      parseFloat(value) /
      ((parseFloat(height) / 100) * (parseFloat(height) / 100));
    if (height) {
      if (!_.isNaN(bmi_total)) {
        methods.resetField("bmi", { defaultValue: bmi_total.toFixed(2) });
      } else {
        methods.resetField("bmi", { defaultValue: 0 });
      }
    } else {
      methods.resetField("bmi", { defaultValue: null });
    }
  };

  const onSubmitResetPassword = (data) => {
    const params = {
      id: detail?.data?.user_id,
      password: data?.old_password,
      new_password: data?.new_password,
    };
    fetchAPI(
      { service: updatePasswordStudent, params },
      (success, response) => {
        if (success) {
          toast.success("Berhasil reset password!");
          modalResetPassword?.current?.click();
          setShowOldPass(false);
          setShowNewPass(false);
          setShowConfirmPass(false);
          reset_form_reset({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        }
      },
    );
  };

  const renderPasswordIcon = () => {
    return (
      <span
        className="absolute top-3 right-2 text-gray-600 cursor-pointer h-6 w-6 text-center"
        onClick={() => setShowPass((prev) => !prev)}
      >
        {showPass ? <IoMdEye /> : <IoMdEyeOff />}
      </span>
    );
  };

  return (
    <section>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl flex gap-3">
              <h6>{id ? "学生の編集" : "学生を追加"}</h6>
              <p>{id ? "Edit Siswa" : "Tambah Siswa"}</p>
            </div>
            <form className="space-y-6 p-6">
              <div className="flex flex-col lg:flex-row gap-10">
                <UploadAvatar
                  name={"avatar"}
                  label={"Pilih Foto"}
                  readOnly={user?.role === "partner_jepang"}
                  onValueChanged={onUpload}
                />

                <div className="flex flex-col flex-grow">
                  <div className="flex gap-3">
                    <Input
                      type="text"
                      label="学生コード (Kode Siswa)"
                      placeholder="Masukan kode siswa"
                      name="student_code"
                      disabled
                    />
                    {user?.role !== "partner_jepang" && (
                      <Input
                        name="name"
                        label="名前 (Nama)"
                        type="text"
                        placeholder="Masukan nama"
                      />
                    )}
                  </div>
                  <Input
                    name="email"
                    label="メール (Email)"
                    placeholder="Masukan email"
                    type="email"
                    disabled={id || user?.role === "partner_jepang"}
                  />
                  {!id && (
                    <Input
                      name="password"
                      label="パスワード (Password)"
                      type={!showPass ? "password" : "text"}
                      placeholder="Password"
                      suffixIcon={renderPasswordIcon()}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="grid grid-cols-1 lg:grid-cols-2 p-6 gap-3">
              <div className="flex items-center gap-2 w-full">
                <Input
                  name="birth_place"
                  label="出生地 (Tempat Lahir)"
                  placeholder="Masukan Tempat Lahir"
                  disabled={user?.role === "partner_jepang"}
                />
                <Datepicker
                  name="birth_date"
                  dateFormat="yyyy-MM-dd"
                  label={"生年月日 (Tanggal Lahir)"}
                  placeholder="Pilih tanggal lahir"
                />
              </div>

              <PhoneInput
                name="phone"
                label="電話番号 (No. Handphone)"
                placeholder="Masukan No. Handphone"
                disabled={user?.role === "partner_jepang"}
              />

              <Input
                name="address"
                label="住所 (Alamat)"
                placeholder="Masukan alamat"
                disabled={user?.role === "partner_jepang"}
              />

              <div className="flex gap-2 w-full">
                <SelectHobby
                  name="hobby"
                  label="趣味 (Hobi)"
                  placeholder="Masukan Nama Hobi"
                  isDisabled={user?.role === "partner_jepang"}
                  otherOptions={[{ value: "other", label: "Lain-lain" }]}
                />

                {methods?.watch("hobby")?.value === "other" && (
                  <Input
                    name="other_hobby"
                    label={<>&nbsp;</>}
                    type="text"
                    disabled={user?.role === "partner_jepang"}
                    placeholder="Masukan Hobi"
                  />
                )}
              </div>

              <NumericInput
                name="old"
                label="年齢 (Usia)"
                placeholder="Masukan usia"
                disabled={user?.role === "partner_jepang"}
              />

              <SelectMasteryLanguage
                name="mastery_of_language"
                label="母国語以外にできる外国語 (Penguasaan Bahasa)"
                placeholder="Masukan penguasaan bahasa"
                disabled={user?.role === "partner_jepang"}
              />

              <Datepicker
                name="entries_date"
                label={"入国日 (Tanggal Masuk)"}
                dateFormat="yyyy-MM-dd"
                placeholder="Pilih Tanggal Masuk"
                disabled={user?.role === "partner_jepang"}
                maxDate={new Date()}
              />

              <Input
                name="apprenticeship"
                label="勉強期間 (Masa Belajar)"
                readOnly={true}
                placeholder="Masukan masa belajar"
                disabled={user?.role === "partner_jepang"}
              />

              <SelectReligion
                name="religion"
                label="宗教 (Agama)"
                placeholder="Masukan Nama Agama"
                isDisabled={user?.role === "partner_jepang"}
              />

              <SelectBloodType
                name="blood_type"
                label="血液型 (Golongan Darah)"
                placeholder="Masukan Nama Golongan Darah"
                isDisabled={user?.role === "partner_jepang"}
              />

              <div className="flex gap-2 w-full">
                <NumericInput
                  name="height"
                  label="身長 (Tinggi badan)"
                  placeholder="Masukan Tinggi badan"
                  disabled={user?.role === "partner_jepang"}
                  onValueChanged={handleHeightChanged}
                />

                <NumericInput
                  name="weight"
                  label="重さ (Berat badan)"
                  placeholder="Masukan Berat badan"
                  disabled={user?.role === "partner_jepang"}
                  onValueChanged={handleWeightChanged}
                />
              </div>

              <Input
                name="bmi"
                label="BMI"
                readOnly={true}
                placeholder="Masukan BMI"
                disabled={user?.role === "partner_jepang"}
              />

              <div className="flex gap-2 w-full">
                <SelectCategory
                  name="category"
                  label="カテゴリー (Kategori)"
                  placeholder="Masukan Kategori"
                  disabled={user?.role === "partner_jepang"}
                />
                {methods?.watch("category")?.value === "Umum(一般職種)" && (
                  <Input
                    name="category_note"
                    label={<>&nbsp;</>}
                    disabled={user?.role === "partner_jepang"}
                    placeholder="Masukan Catatan"
                  />
                )}
              </div>

              {/* <div></div> */}

              <RadioGroupForm
                disabled={user?.role === "partner_jepang"}
                name="gender"
                label={"性別 (Jenis Kelamin)"}
                values={[
                  {
                    label: "男 (L)",
                    value: "L",
                  },
                  {
                    label: "女 (P)",
                    value: "P",
                  },
                ]}
              />
            </div>
          </div>

          <FamilyStructure />
          <Personality />
          <YoutubeLink />
          <EmploymentHistory />
          <Education />
          <LanguageSkill />
          <CertExam />

          {user?.role !== "partner_jepang" && (
            <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
              <div className="flex justify-between items-baseline p-6">
                <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
                  <h6>ドキュメントのアップロード</h6>
                  <p>(Upload Dokumen)</p>
                </div>
              </div>
              <div className="px-6 pb-6 space-y-3">
                <FileItem
                  placeholder="証明書 (Ijazah)"
                  label="証明書 (Ijazah)"
                  name="ijazah"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="IDカード (KTP)"
                  label="IDカード (KTP)"
                  name="ktp"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="株式会社 (KK)"
                  label="株式会社 (KK)"
                  name="kk"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="SKCK (SKCK)"
                  label="SKCK (SKCK)"
                  name="skck"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="出生証明書 (Akte Kelahiran)"
                  label="出生証明書 (Akte Kelahiran)"
                  name="akteKelahiran"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="白い画面の写真 (Foto Layar Putih)"
                  label="白い画面の写真 (Foto Layar Putih)"
                  name="fotoLayarPutih"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="健康情報 (Keterangan Kesehatan)"
                  label="健康情報 (Keterangan Kesehatan)"
                  name="ketKesehatan"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="親の許可証 (Surat Izin Orang Tua)"
                  label="親の許可証 (Surat Izin Orang Tua)"
                  name="suratIzinOrtu"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="Daftar Riwayar Hidup"
                  label="Daftar Riwayar Hidup"
                  name="daftarRiwayatHidup"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="Surat Izin Keterangan RT / RW"
                  label="Surat Izin Keterangan RT / RW"
                  name="suratIzinKeteranganRtRw"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="Foto 4,5 x 4,5 (4 lembar)"
                  label="Foto 4,5 x 4,5 (4 lembar)"
                  name="foto4_5x4_5"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="Foto 3 x 4 (4 lembar)"
                  label="Foto 3 x 4 (4 lembar)"
                  name="foto3x4"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="Lulus Bahasa Jepang Lv 4"
                  label="Lulus Bahasa Jepang Lv 4"
                  name="lulusBahasaJepangLv4"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
                  label="コンピテンシー証明書 1 (Sertifikat Kompetensi 1)"
                  name="sertifikatKomp1"
                  showLabelBesideValue={true}
                />
                <FileItem
                  placeholder="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
                  label="コンピテンシー証明書 2 (Sertifikat Kompetensi 2)"
                  name="sertifikatKomp2"
                  showLabelBesideValue={true}
                />
              </div>
            </div>
          )}
        </div>
        {user?.role !== "partner_jepang" && (
          <div className="flex gap-3 py-4 justify-end px-6">
            {id ? (
              <BaseButton
                type="button"
                variant="base"
                onClick={() => setShowModalResetPassword(true)}
              >
                Reset Password
              </BaseButton>
            ) : null}
            <BaseButton
              type="button"
              variant="base"
              disabled={post?.isLoading || put?.isLoading}
              onClick={() => navigate(-1)}
            >
              Kembali
            </BaseButton>
            <BaseButton
              type="submit"
              variant="primary2"
              disabled={post?.isLoading || put?.isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              <div className="flex items-center space-x-2">
                <span>Kirim</span>
                {(post?.isLoading || put?.isLoading) && <Spinner />}
              </div>
            </BaseButton>
          </div>
        )}
      </FormProvider>

      <ModalWrapper
        isOpen={showModalResetPassword}
        setIsOpen={setShowModalResetPassword}
      >
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Reset password</h5>
            </div>
            <div className="relative flex-auto p-4">
              <form
                onSubmit={handleSubmit_form_reset(onSubmitResetPassword)}
                className="space-y-4 w-100"
              >
                <BaseInput
                  label="Password lama"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showOldPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowOldPass((prev) => !prev)}
                    >
                      {showOldPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("old_password", {
                    required: {
                      value: true,
                      message: "Password Lama wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Lama minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.old_password?.message}
                />

                <BaseInput
                  label="Password baru"
                  placeholder="Masukan password baru"
                  size="md"
                  type={showNewPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowNewPass(!showOldPass)}
                    >
                      {showNewPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("new_password", {
                    required: {
                      value: true,
                      message: "Password Baru wajib diisi",
                    },
                    minLength: {
                      value: 8,
                      message: "Password Baru minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.new_password?.message}
                />

                <BaseInput
                  label="Konfirmasi Password"
                  placeholder="Masukan password lama"
                  size="md"
                  type={showConfirmPass ? "text" : "password"}
                  suffixIcon={
                    <span
                      className="absolute top-[6px] right-2 text-gray-700 cursor-pointer"
                      onClick={() => setShowConfirmPass(!showOldPass)}
                    >
                      {showConfirmPass ? <IoMdEye /> : <IoMdEyeOff />}
                    </span>
                  }
                  {...register_form_reset("confirm_password", {
                    required: {
                      value: true,
                      message: "Konfirmasi Password wajib diisi",
                    },
                    validate: (val) => {
                      if (watch("new_password") != val) {
                        return "Konfirmasi Password tidak sama dengan pasword baru";
                      }
                    },
                    minLength: {
                      value: 8,
                      message: "Konfirmasi Password minimal 8 karakter",
                    },
                  })}
                  error={errors_form_reset.confirm_password?.message}
                />
                <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                  <BaseButton
                    variant="base"
                    size="sm"
                    type="button"
                    onClick={() => {
                      setShowModalResetPassword((prev) => !prev);
                      reset_form_reset();
                    }}
                  >
                    Tidak
                  </BaseButton>
                  <BaseButton variant="primary2" size="sm" type="submit">
                    Ya
                  </BaseButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </section>
  );
};

export default StudentManagamentForm;
