import { createSlice } from "@reduxjs/toolkit";
import { AUTH } from "services";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  token: "",
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state, action) => {
      state.token = action.payload;
      state.user = {};
    },
    logIn: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = null;
      state.token = action?.payload?.accessToken;
      state.user = action?.payload?.data;
    },
  },
});

// this is for dispatch
export const { logIn, logOut } = authSlice.actions;

export const signInPortal = (params) => {
  return async (dispatch, getState) => {
    dispatch(logIn());
    try {
      const response = await AUTH.signInPortal(params);
      const data = response?.data;
      // if (data?.success) {
      //   dispatch(logIn(data));
      // } else {
      //   dispatch(logInFailed(data));
      // }
    } catch (error) {
      // dispatch(logInFailed(error.response));
    }
  };
};

// this is for configureStore
export default authSlice.reducer;
