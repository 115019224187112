import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";

const BaseButton = ({
  variant = "primary1",
  size = "md",
  className,
  children,
  ...rest
}) => {
  const buttonVariant = {
    primary1:
      "inline-block font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-blue-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs",
    primary2:
      "inline-block font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-blue-700 to-cyan-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs",
    base: "inline-block font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs",
    outline:
      "inline-block font-bold text-center uppercase align-middle transition-all bg-transparent border rounded-lg cursor-pointer border-blue-500 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-blue-500",
    text: "inline-block font-bold text-center uppercase align-middle transition-all rounded-lg cursor-pointer bg-blue-500/0 leading-pro ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:bg-blue-500/25 hover:scale-102 active:bg-blue/45 text-blue-500",
  };

  const buttonSize = {
    sm: "px-4 py-1.5 text-xs tracking-tight-soft",
    md: "px-6 py-3 text-xs tracking-tight-soft",
    lg: "px-7 py-4 text-sm tracking-normal",
  };

  return (
    <button
      className={cn(className, buttonVariant[variant], buttonSize[size])}
      {...rest}
    >
      {children}
    </button>
  );
};

BaseButton.prototype = {
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.children,
};

export default BaseButton;
