import axios from "axios";
import Service from "config";

export const getDashboard = () => {
  return axios.get(`${Service.API}/admin/dashboard`);
};

export const getRecapScore = () => {
  return axios.get(`${Service.API}/admin/recap-score`);
};

export const getRecapStudent = (params) => {
  return axios.get(`${Service.API}/admin/recap-student`, { params });
};

export const getRecapStudentPassed = (params) => {
  return axios.get(`${Service.API}/admin/recap-student-passed`, { params });
};

export const getRecapStudentSend = (params) => {
  return axios.get(`${Service.API}/admin/recap-student-send`, { params });
};

export const getStudentNotSend = (params) => {
  return axios.get(`${Service.API}/admin/recap-student-notsend`, { params });
};

export const getStudentPassedSend = (params) => {
  return axios.get(`${Service.API}/admin/recap-student-passed-send`, {
    params,
  });
};
