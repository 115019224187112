import axios from "axios";
import Service from "config";

export const getRoles = () => {
  return axios.get(`${Service.API}/admin/users/roles`);
};

export const getProvinces = () => {
  return axios.get(`${Service.API}/master/provinces`);
};

export const getCities = (params) => {
  return axios.post(`${Service.API}/master/cities`, params);
};

export const getDistricts = (params) => {
  return axios.post(`${Service.API}/master/districts`, params);
};

export const getUserLpk = (params) => {
  return axios.get(`${Service.API}/admin/users/get/lpk`);
};

export const getLesson = (params) => {
  return axios.get(
    `${Service.API}/master/lesson?page=${params?.page ?? 1}&limit=${
      params?.limit ?? 10
    }`,
  );
};

export const getHobies = (params, signal) => {
  return axios.get(`${Service.API}/master/hobbies`, { params, signal });
};

export const getStudent = (params) => {
  return axios.get(`${Service.API}/admin/users/get/student`);
};
