import React from "react";
import NoResult from "assets/no-results.png";
import { getLocales } from "locales";

const EmptyData = () => {
  const { EMPY_DATA_TXT } = getLocales();
  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <img src={NoResult} alt="no-results" className="w-40 h-40" />
      <label className="font-bold text-2xl">{EMPY_DATA_TXT}</label>
    </div>
  );
};

export default EmptyData;
