import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

const RadioGroupForm = ({
  name,
  className,
  label,
  required = false,
  textArea = false,
  usingGrid = false,
  gridClassName = "",
  values = [],
  directionColumn = false,
  isCustomOption = false,
  onGetValueOtherOptions = () => {},
  customOption,
  showErrorLabel = true,
  disabled,
  ...props
}) => {
  const { control } = useFormContext();

  const [customValues, setCustomValues] = useState(customOption);

  useEffect(() => {
    setCustomValues(customOption);
  }, [customOption]);

  const handleCustomInputChange = (text) => {
    setCustomValues(text);
    onGetValueOtherOptions(text);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className={`form-control w-full ${className}`}>
          <label className="font-semibold text-sm">
            <span className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
              {label}
            </span>
            {required && <span className="text-[#F04438]">*</span>}
          </label>
          <div
            className={`${
              directionColumn ? "flex-column" : "flex"
            } flex-wrap gap-4 mt-2`}
          >
            {values.map((data, index) => (
              <label key={data.value} className={`text-sm`}>
                <div className="flex gap-2 items-center">
                  <input
                    disabled={disabled}
                    type="radio"
                    name={name}
                    {...props}
                    onChange={() => onChange(data.value)}
                    checked={value === data.value}
                    // className="absolute opacity-0 w-0 h-0"
                  />
                  {data?.label}
                </div>
              </label>
            ))}
          </div>
          {invalid ? (
            <small className="text-xs text-red-600">{error?.message}</small>
          ) : null}
        </div>
      )}
    />
  );
};

export default RadioGroupForm;
