// import React, { useEffect, useState } from "react";
// import _ from "lodash";
// import { AnimatePresence, motion } from "framer-motion";
// import { useNavigate } from "react-router";
// import { useSelector } from "react-redux";
// import { ROLES } from "helpers";
// import {
//   MdClass,
//   MdDashboard,
//   MdHeadsetMic,
//   MdPeopleAlt,
//   MdPlayLesson,
//   MdSportsKabaddi,
//   MdStars,
// } from "react-icons/md";
// import { AiFillCalendar, AiFillSetting } from "react-icons/ai";

// const Sidebar = ({ children }) => {
//   const { user } = useSelector(({ auth }) => auth);

//   const [menu, setMenu] = useState([
//     {
//       icon: <MdDashboard className="w-6 h-6 transition duration-75" />,
//       label: "Dashboard",
//       label_jpn: "ホーム",
//       path: "/dashboard",
//       middleware: ["lpk_induk", "lpk_mitra", "lpk_mitra_so", "partner_jepang"],
//     },
//     {
//       icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
//       label: "User Management",
//       middleware: ["lpk_induk"],
//       children: [
//         {
//           label: "LPK Mitra",
//           path: "/mitra",
//           middleware: ["lpk_induk"],
//         },
//         {
//           label: "Partner Jepang",
//           path: "/japan-partner-management",
//           middleware: ["lpk_induk"],
//         },
//       ],
//     },
//     {
//       icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
//       label: "Siswa",
//       label_jpn: "候補者リスト",
//       path: "/student-management",
//       middleware: ["lpk_mitra", "lpk_mitra_so", "partner_jepang"],
//     },
//     {
//       icon: <MdPlayLesson className="w-6 h-6 transition duration-75" />,
//       label: "Mata Pelajaran",
//       path: "/lesson",
//       middleware: ["lpk_induk"],
//     },
//     {
//       icon: <MdClass className="w-6 h-6 transition duration-75" />,
//       label: "Kelas",
//       path: "/class",
//       middleware: ["lpk_mitra", "lpk_mitra_so"],
//     },
//     {
//       icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
//       label: "Profil",
//       path: "/profile",
//       middleware: ["student"],
//     },
//     {
//       icon: <MdClass className="w-6 h-6 transition duration-75" />,
//       label: "Akademik",
//       path: "/academic",
//       middleware: ["student"],
//     },
//     {
//       icon: <MdSportsKabaddi className="w-6 h-6 transition duration-75" />,
//       label: "Siswa Terkirim",
//       label_jpn: "合格者",
//       path: "/hire",
//       middleware: ["partner_jepang", "lpk_mitra", "lpk_mitra_so"],
//     },
//     {
//       icon: <MdHeadsetMic className="w-6 h-6 transition duration-75" />,
//       label: "Kritik & Saran",
//       label_jpn: "批判と提案",
//       path: "/criticism-suggetions",
//       middleware: ["partner_jepang", "lpk_induk", "lpk_mitra_so"],
//     },
//     {
//       icon: <AiFillCalendar className="w-6 h-6 transition duration-75" />,
//       label: "Siswa Wawancara",
//       path: "/meet",
//       middleware: ["lpk_mitra_so"],
//     },
//     {
//       icon: <AiFillSetting className="w-6 h-6 transition duration-75" />,
//       label: "Settings",
//       middleware: ["lpk_induk"],
//       children: [
//         {
//           label: "General",
//           path: "/settings",
//           middleware: ["lpk_induk"],
//         },
//         {
//           label: "Article",
//           path: "/setting-articles",
//           middleware: ["lpk_induk"],
//         },
//         {
//           label: "Topic",
//           path: "/setting-topics",
//           middleware: ["lpk_induk"],
//         },
//         {
//           label: "Profile Bitode",
//           path: "/profile-bitode",
//           middleware: ["lpk_induk"],
//         },
//       ],
//     },
//   ]);

//   useEffect(() => {
//     let menuTemps = [...menu];
//     menuTemps = _.filter(menuTemps, (menuTemp) =>
//       menuTemp?.middleware?.includes(user?.role),
//     );
//     menuTemps = _.map(menuTemps, (menuTemp) => {
//       const childMenuByRole = _.filter(menuTemp?.children, (child) =>
//         child?.middleware?.includes(user?.role),
//       );
//       return { ...menuTemp, children: childMenuByRole };
//     });
//     setMenu(menuTemps);
//   }, []);

//   return (
//     <div className="flex flex-1 overflow-hidden">
//       <aside
//         className="hidden sm:block w-64 overflow-y-auto bg-gray-50 rounded-lg border-gray-500 m-4"
//         style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
//       >
//         <ul className="space-y-2 m-4">
//           {_.map(menu, (menu, key) => (
//             <MenuItem key={key} {...menu} />
//           ))}
//         </ul>
//       </aside>
//       <motion.main
//         className="flex flex-col flex-1 overflow-y-auto paragraph p-4"
//         key={window.location.pathname}
//         exit={{ opacity: 0 }}
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ delay: 0.05 }}
//       >
//         {children}
//         <div className="h-4" />
//       </motion.main>
//     </div>
//   );
// };

// const MenuItem = ({ path, label, label_jpn = "", children, icon, key }) => {
//   const navigate = useNavigate();
//   const [open, setOpen] = useState(false);

//   const { user } = useSelector(({ auth }) => auth);

//   useEffect(() => {
//     if (children) {
//       if (
//         _.find(children, (submenu) =>
//           location?.pathname?.includes(submenu?.path),
//         )
//       ) {
//         setOpen(true);
//       }
//     }
//   }, [children]);

//   return (
//     <div className={`space-y-2 ${!open ? "cursor-pointer" : ""}`} key={key}>
//       <li
//         className="cursor-pointer"
//         onClick={() => (children?.length > 0 ? setOpen(!open) : navigate(path))}
//       >
//         <div
//           className={`flex items-center p-2 text-base font-normal rounded-lg ${
//             !location?.pathname?.includes(path) && "hover:bg-slate-200"
//           } ${location?.pathname?.includes(path) && "bg-[#580cf81c]"} ${
//             location?.pathname?.includes(path)
//               ? "text-[#580cf8] font-medium"
//               : "text-slate-700"
//           }`}
//         >
//           {icon ? icon : null}
//           {user?.role === "partner_jepang" ? (
//             <div className="ml-3 space-x-1">
//               <span>{label_jpn}</span>
//               <span className="text-[10px]">
//                 <span className="font-medium">{"("}</span>
//                 {label}
//                 <span className="font-medium">{")"}</span>
//               </span>
//             </div>
//           ) : (
//             <span className="ml-3">{label}</span>
//           )}
//         </div>
//       </li>
//       {children?.length > 0 ? (
//         <AnimatePresence
//           initial={false}
//           exitBeforeEnter
//           key="accordion-dashboard"
//         >
//           {open && (
//             <motion.ul
//               key="content"
//               className="ml-6 space-y-1"
//               initial="collapsed"
//               animate="open"
//               exit="collapsed"
//               variants={{
//                 open: { opacity: 1, height: "auto" },
//                 collapsed: { opacity: 0, height: 0 },
//               }}
//             >
//               {_.map(children, (submenu, index) => (
//                 <li index={index} onClick={() => navigate(submenu?.path)}>
//                   <div
//                     className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg cursor-pointer ${
//                       !location?.pathname?.includes(submenu?.path) &&
//                       "hover:bg-slate-200"
//                     } ${
//                       location?.pathname?.includes(submenu?.path) &&
//                       "bg-[#580cf81c]"
//                     } ${
//                       location?.pathname?.includes(submenu?.path)
//                         ? "text-[#580cf8] font-medium"
//                         : "text-slate-700"
//                     }`}
//                   >
//                     {submenu?.icon ? submenu?.icon : null}
//                     <span className="ml-3">
//                       {user?.role === "partner_jepang"
//                         ? submenu?.label_jpn
//                         : submenu?.label}
//                     </span>
//                   </div>
//                 </li>
//               ))}
//             </motion.ul>
//           )}
//         </AnimatePresence>
//       ) : null}
//     </div>
//   );
// };

// export default Sidebar;

import React, { useEffect, useRef, useState } from "react";
import {
  MdClass,
  MdDashboard,
  MdExpandMore,
  MdHeadsetMic,
  MdPeopleAlt,
  MdPlayLesson,
  MdSportsKabaddi,
} from "react-icons/md";
import PropTypes from "prop-types";
import cn from "clsx";
import { AiFillCalendar, AiFillSetting } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import logo from "assets/bitode-logo.png";
import { Disclosure, Transition } from "@headlessui/react";
import { useClickOutside } from "hooks";
import { toggleSidebar } from "features/common/commonSlice";
import { HiOutlineBookOpen } from "react-icons/hi";

const menus = [
  {
    icon: <MdDashboard className="w-6 h-6 transition duration-75" />,
    label: "Dashboard",
    label_jpn: "ホーム",
    path: "/dashboard",
    middleware: ["lpk_induk", "lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
    label: "User Management",
    middleware: ["lpk_induk"],
    children: [
      {
        label: "LPK Mitra",
        path: "/mitra",
        middleware: ["lpk_induk"],
      },
      {
        label: "Partner Jepang",
        path: "/japan-partner-management",
        middleware: ["lpk_induk"],
      },
      {
        label: "Administrasi",
        path: "/administrator-user",
        middleware: ["lpk_induk"],
      },
    ],
  },
  {
    icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
    label: "Siswa",
    label_jpn: "候補者リスト",
    path: "/student-management",
    middleware: ["lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    icon: <MdPlayLesson className="w-6 h-6 transition duration-75" />,
    label: "Mata Pelajaran",
    path: "/lesson",
    middleware: ["lpk_induk"],
  },
  {
    icon: <MdClass className="w-6 h-6 transition duration-75" />,
    label: "Kelas",
    path: "/class",
    middleware: ["lpk_mitra", "lpk_mitra_so"],
  },
  {
    icon: <MdPeopleAlt className="w-6 h-6 transition duration-75" />,
    label: "Profil",
    path: "/profile",
    middleware: ["student"],
  },
  {
    icon: <MdClass className="w-6 h-6 transition duration-75" />,
    label: "Akademik",
    path: "/academic",
    middleware: ["student"],
  },
  {
    icon: <MdSportsKabaddi className="w-6 h-6 transition duration-75" />,
    label: "Siswa Terkirim",
    label_jpn: "合格者",
    path: "/hire",
    middleware: ["partner_jepang", "lpk_mitra", "lpk_mitra_so"],
  },
  {
    icon: <MdHeadsetMic className="w-6 h-6 transition duration-75" />,
    label: "Kritik & Saran",
    label_jpn: "批判と提案",
    path: "/criticism-suggetions",
    middleware: ["partner_jepang", "lpk_induk", "lpk_mitra_so"],
  },
  {
    icon: <HiOutlineBookOpen className="w-6 h-6 transition duration-75" />,
    label: "Training Order",
    label_jpn: "求人票",
    path: "/training-order",
    middleware: ["lpk_mitra", "lpk_mitra_so", "partner_jepang"],
  },
  {
    icon: <AiFillCalendar className="w-6 h-6 transition duration-75" />,
    label: "Daftar Perusahaan",
    label_jpn: "会社一覧",
    path: "/corporate",
    middleware: ["partner_jepang"],
  },
  {
    icon: <AiFillCalendar className="w-6 h-6 transition duration-75" />,
    label: "Siswa Wawancara",
    path: "/meet",
    middleware: ["lpk_mitra_so"],
  },
  {
    icon: <AiFillSetting className="w-6 h-6 transition duration-75" />,
    label: "Settings",
    middleware: ["lpk_induk"],
    children: [
      {
        label: "General",
        path: "/settings",
        middleware: ["lpk_induk"],
      },
      {
        label: "Article",
        path: "/setting-articles",
        middleware: ["lpk_induk"],
      },
      {
        label: "Topic",
        path: "/setting-topics",
        middleware: ["lpk_induk"],
      },
      {
        label: "Profile Bitode",
        path: "/profile-bitode",
        middleware: ["lpk_induk"],
      },
    ],
  },
];

const Sidebar = () => {
  const dispatch = useDispatch();

  const { isSidebarOpen } = useSelector((state) => state.common);
  const { user } = useSelector(({ auth }) => auth);

  const [menuList, setMenuList] = useState(menus);

  const sidebarRef = useRef();

  useClickOutside(sidebarRef, () => {
    if (!isSidebarOpen) return;
    dispatch(toggleSidebar());
  });

  useEffect(() => {
    if (user?.role === "partner_jepang" && user?.is_admin) {
      setMenuList(
        _.filter(
          menus,
          (menu) =>
            menu.path === "/dashboard" || menu.path === "/student-management",
        ),
      );
    }
  }, [user]);

  return (
    <aside
      ref={sidebarRef}
      className={cn(
        "fixed inset-y-0 left-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto transition-all duration-200 -translate-x-full bg-white border-0 shadow-none xl:ml-4 ease-soft-in-out z-990 max-w-64 rounded-2xl xl:translate-x-0 xl:bg-transparent",
        isSidebarOpen ? "translate-x-0 shadow-soft-2xl" : "-translate-x-full",
      )}
    >
      <div className="h-20 flex justify-center items-center">
        <Link
          className="block px-3 py-6 m-0 text-sm whitespace-nowrap text-slate-700 "
          to="/dashboard"
        >
          <img
            src={logo}
            className="inline-block h-full max-w-full transition-all duration-200 ease-soft-in-out max-h-8 dark:hidden"
            alt="main_logo"
          />
          <img
            src={logo}
            className="hidden h-full max-w-full transition-all duration-200 ease-soft-in-out max-h-8 dark:inline-block"
            alt="main_logo"
          />
        </Link>

        <span className="font-semibold transition-all duration-200 ease-soft-in-out text-lg">
          Bitode
        </span>
      </div>

      <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent " />

      <div
        className="items-center block w-full h-auto grow basis-full"
        id="sidenav-collapse-main"
      >
        <ul className="flex flex-col pl-0 mb-0 list-none">
          {_.map(menuList, (menu, index) => (
            <SidebarItem key={index} menu={menu} />
          ))}
        </ul>
      </div>
    </aside>
  );
};

const SidebarItem = ({ menu }) => {
  const location = useLocation();
  const [openSubmenu, setOpenSubmenu] = useState("");

  const { user } = useSelector(({ auth }) => auth);

  const menuChildren = menu.children && menu.children.map((item) => item.path);

  return _.find(menu?.middleware, (test) => user.roles.includes(test)) ? (
    <li
      className="mt-0.5 w-full"
      // onClick={() => {
      //   if (!menu.children) return;
      //   if (openSubmenu === menu.label) {
      //     setOpenSubmenu("");
      //     return;
      //   }
      //   setOpenSubmenu(menu.label);
      // }}
    >
      {menu.children ? (
        <Disclosure
          as="div"
          defaultOpen={
            menu.children && menuChildren.includes(location.pathname)
          }
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full">
                <div
                  to={menu.path}
                  className={cn(
                    "ease-soft-in-out text-sm py-2.7 active my-0 mx-4 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors  dark:opacity-80 w-full ",
                    location.pathname.includes(menu.path) &&
                      "after:ease-soft-in-out ease-soft-in-out text-sm py-2.7 active xl:shadow-soft-xl my-0 mx-4 flex justify-between items-center whitespace-nowrap rounded-lg bg-white px-4 font-semibold text-slate-700 transition-all after:ml-auto after:inline-block after:rotate-180 after:font-bold after:text-slate-800 after:antialiased after:transition-all after:duration-200  dark:opacity-80",
                    menu.children &&
                      menuChildren.includes(location.pathname) &&
                      "after:ease-soft-in-out ease-soft-in-out text-sm py-2.7 active xl:shadow-soft-xl my-0 mx-4 flex justify-between items-center whitespace-nowrap rounded-lg bg-white px-4 font-semibold text-slate-700 transition-all after:ml-auto after:inline-block after:rotate-180 after:font-bold after:text-slate-800 after:antialiased after:transition-all after:duration-200  dark:opacity-80",
                  )}
                >
                  <div className="flex items-center w-full">
                    <div
                      className={cn(
                        "stroke-none shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-slate-500",
                        location.pathname.includes(menu.path) &&
                          "stroke-none shadow-soft-sm bg-gradient-to-br from-blue-700 to-cyan-500 mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-white",
                        menu.children &&
                          menuChildren.includes(location.pathname) &&
                          "stroke-none shadow-soft-sm bg-gradient-to-br from-blue-700 to-cyan-500 mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-white",
                      )}
                    >
                      {menu?.icon}
                    </div>

                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700">
                      {user?.role === "partner_jepang"
                        ? menu?.label_jpn
                        : menu?.label}
                    </span>
                  </div>

                  {menu?.children ? (
                    <MdExpandMore className={open ? "rotate-180" : ""} />
                  ) : null}
                </div>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Disclosure.Panel className="transition-all duration-350">
                  <div className="h-auto overflow-hidden duration-200 transition-all">
                    <ul className="flex flex-wrap pl-4 mb-0 ml-6 list-none transition-all duration-200 ease-soft-in-out">
                      {menu.children.map((child) => (
                        <li className="w-full" key={child.label}>
                          <Link
                            to={child.path}
                            className={cn(
                              "ease-soft-in-out py-1.6 ml-5.4 pl-4 text-sm before:-left-4.5 before:h-1.25 before:w-1.25 relative my-0 mr-4 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-700/50 shadow-none transition-colors before:absolute before:top-1/2 before:-translate-y-1/2 before:rounded-3xl before:bg-slate-700/50 before:content-['']  ",
                              location.pathname.includes(child.path) &&
                                "ease-soft-in-out py-1.6 ml-5.4 pl-4 text-sm before:-left-5 relative my-0 mr-4 flex items-center whitespace-nowrap rounded-lg bg-transparent pr-4 font-bold text-slate-800 shadow-none transition-colors before:absolute before:top-1/2 before:h-2 before:w-2 before:-translate-y-1/2 before:rounded-3xl before:bg-slate-800 before:content-[''] ",
                            )}
                          >
                            <span
                              className={cn(
                                "w-0 text-center transition-all duration-200 opacity-0 pointer-events-none ease-soft-in-out",
                              )}
                            >
                              {" "}
                              D{" "}
                            </span>

                            <span className="transition-all duration-100 pointer-events-none ease-soft">
                              {child.label}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ) : (
        <Link
          to={menu.path}
          className={cn(
            "ease-soft-in-out text-sm py-2.7 active my-0 mx-4 flex items-center px-4 font-medium text-slate-500 shadow-none transition-colors dark:opacity-80",
            location.pathname.includes(menu.path) &&
              "after:ease-soft-in-out ease-soft-in-out text-sm py-2.7 active xl:shadow-soft-xl my-0 mx-4 flex justify-between items-center rounded-lg bg-white px-4 font-semibold text-slate-700 transition-all after:ml-auto after:inline-block after:rotate-180 after:font-bold after:text-slate-800 after:antialiased after:transition-all after:duration-200  dark:opacity-80",
            menu.children &&
              menuChildren.includes(location.pathname) &&
              "after:ease-soft-in-out ease-soft-in-out text-sm py-2.7 active xl:shadow-soft-xl my-0 mx-4 flex justify-between items-center rounded-lg bg-white px-4 font-semibold text-slate-700 transition-all after:ml-auto after:inline-block after:rotate-180 after:font-bold after:text-slate-800 after:antialiased after:transition-all after:duration-200 dark:opacity-80",
          )}
        >
          <div className="flex items-center w-full">
            <div
              className={cn(
                "stroke-none shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-slate-500",
                location.pathname.includes(menu.path) &&
                  "stroke-none shadow-soft-sm bg-gradient-to-br from-blue-700 to-cyan-500 mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-white",
                menu.children &&
                  menuChildren.includes(location.pathname) &&
                  "stroke-none shadow-soft-sm bg-gradient-to-br from-blue-700 to-cyan-500 mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center text-white",
              )}
            >
              {menu?.icon}
            </div>

            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700">
              {user?.role === "partner_jepang"
                ? `${menu?.label_jpn} (${menu?.label})`
                : menu?.label}
            </span>
          </div>

          {menu?.children ? <MdExpandMore /> : null}
        </Link>
      )}

      {/* {menu.children ? (
        openSubmenu === menu.label ? (
          <div className="h-auto overflow-hidden duration-200 transition-all transiti">
            <ul className="flex flex-wrap pl-4 mb-0 ml-6 list-none transition-all duration-200 ease-soft-in-out">
              {menu.children.map((child) => (
                <li className="w-full" key={child.label}>
                  <Link
                    to={child.path}
                    className={cn(
                      "ease-soft-in-out py-1.6 ml-5.4 pl-4 text-sm before:-left-4.5 before:h-1.25 before:w-1.25 relative my-0 mr-4 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-700/50 shadow-none transition-colors before:absolute before:top-1/2 before:-translate-y-1/2 before:rounded-3xl before:bg-slate-700/50 before:content-['']  dark:opacity-60 dark:before:bg-white dark:before:opacity-80",
                      location.pathname.includes(child.path) &&
                        "ease-soft-in-out py-1.6 ml-5.4 pl-4 text-sm before:-left-5 relative my-0 mr-4 flex items-center whitespace-nowrap rounded-lg bg-transparent pr-4 font-bold text-slate-800 shadow-none transition-colors before:absolute before:top-1/2 before:h-2 before:w-2 before:-translate-y-1/2 before:rounded-3xl before:bg-slate-800 before:content-['']  dark:opacity-100 dark:before:bg-white dark:before:opacity-80",
                    )}
                  >
                    <span
                      className={cn(
                        "w-0 text-center transition-all duration-200 opacity-0 pointer-events-none ease-soft-in-out",
                      )}
                    >
                      {" "}
                      D{" "}
                    </span>

                    <span className="transition-all duration-100 pointer-events-none ease-soft">
                      {child.label}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null
      ) : null} */}
    </li>
  ) : null;
};

SidebarItem.prototype = {
  menu: PropTypes.object,
};

export default Sidebar;
