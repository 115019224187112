import { useLoader } from "Provider/LoaderProvider";
import { Select } from "components";
import BaseButton from "components/atoms/BaseButton";
import { BaseInput, Datepicker } from "components/v2";
import { getStudent } from "features/core/coreSlice";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CgClose, CgSpinner } from "react-icons/cg";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  deleteStudent,
  getTrainingOrderDetail,
  passingStudent,
  postAddStudent,
  updateStudent,
} from "services/training-order";
import { toast } from "react-toastify";
import { map } from "lodash";
import ModalWrapper from "components/molecules/ModalWrapper";
import moment from "moment";
import { Link } from "react-router-dom";

const thClass =
  "px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70";
const tdClass =
  "p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4";

const TrainingOrderDetail = () => {
  const dispath = useDispatch();

  const location = useLocation();
  const { user } = useSelector(({ auth }) => auth);

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const methods = useForm();
  const studentMethods = useForm();

  const { studentList } = useSelector(({ core }) => core);

  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [studentSelected, setStudentSelected] = useState(null);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deletedData, setDeletedData] = useState(false);

  const [passStudent, setPassStudent] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const {
    data: detail,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(["training-order-detail", location.state], {
    queryFn: ({ queryKey, signal }) => {
      const params = queryKey[1];
      return getTrainingOrderDetail({ ...params }, signal);
    },
    enabled: !!location.state,
  });

  const renderTbody = () => {
    if (typeof detail?.data?.students === "undefined") {
      return (
        <tr>
          <td colSpan={user?.role === "partner_jepang" ? 4 : 3}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <p className="mb-0 font-semibold leading-tight text-xs">
                Tidak ada data
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return map(detail?.data?.students ?? [], (list, key) => (
      <tr key={key}>
        <td className={tdClass}></td>
        <td className={tdClass}>
          <Link
            to={`/student-management/detail`}
            state={{ id: list?.student_id }}
          >
            <span className="mb-0 font-semibold leading-tight text-xs">
              {list?.student_name ? list?.student_name : "-"}
            </span>
          </Link>
        </td>
        <td className={tdClass}>
          <p className="mb-0 font-semibold leading-tight text-xs">
            {list?.mitra_name ? list?.mitra_name : "-"}
          </p>
        </td>
        {user?.role === "partner_jepang" && (
          <td className={tdClass}>
            <Link
              to={`/student-management/detail`}
              className="mb-0 font-semibold leading-tight text-xs"
              state={{ id: list?.student_id }}
            >
              Detail
            </Link>
          </td>
        )}
      </tr>
    ));
  };

  const mutateAddStudent = useMutation(postAddStudent);
  const mutateUpdateStudent = useMutation(updateStudent);
  const mutateDeleteStudent = useMutation(deleteStudent);
  const mutatePassingStudent = useMutation(passingStudent);

  const handleChangeStudent = (student) => {
    setStudentSelected(student);
    studentMethods.setValue("student", student);
  };

  const handleAddStudent = async () => {
    if (!studentSelected) return;
    startLoaderOverlay();

    const payload = {
      to_id: location.state.id,
      student_id: studentSelected.student_id,
      mitra_id: user.id,
    };

    mutateAddStudent.mutate(payload, {
      onSuccess: () => {
        stopLoaderOverlay();
        setIsAddStudentModalOpen(false);
        refetch();
        toast.success("Berhasil menambahkan data siswa");
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  useEffect(() => {
    !isAddStudentModalOpen && setStudentSelected(null);
  }, [isAddStudentModalOpen]);

  useEffect(() => {
    if (user.role === "lpk_mitra" || user.role === "lpk_mitra_so") {
      dispath(getStudent());
    }
  }, []);

  useEffect(() => {
    if (detail) {
      let defaultValue = {
        ...detail?.data,
        // corporate: detail?.data?.corporate_id
        //   ? {
        //       value: detail?.data?.corporate_id,
        //       label: detail?.data?.company_name,
        //     }
        //   : null,
        company_name: detail?.data?.company_name,
        interview_date: moment(detail?.data?.interview_date).format(
          "YYYY-MM-DD",
        ),
        departure_plan: moment(detail?.data?.departure_plan).format(
          "YYYY-MM-DD",
        ),
        due_date: moment(detail?.data?.due_date).format("YYYY-MM-DD"),
        training_period: detail?.data?.training_period,
      };
      delete defaultValue.age;
      methods.reset(defaultValue);

      // const defaultValueStudent =
      //   typeof detail?.data?.students !== "undefined"
      //     ? detail?.data?.students.map((student) => ({
      //         value: student.id,
      //         student_id: student.student_id,
      //         label: student.student_name,
      //       }))
      //     : null;
      // setStudentSelected(defaultValueStudent);
    }
  }, [isFetching]);

  const onAddStudent = () => {
    setIsAddStudentModalOpen(true);
  };

  const onClickDeleteData = (data) => {
    setConfirmDeleteOpen(true);
    setDeletedData(data);
  };

  const onDeleteData = () => {
    startLoaderOverlay();
    mutateDeleteStudent.mutate(deletedData?.student_id, {
      onSuccess: () => {
        stopLoaderOverlay();
        setConfirmDeleteOpen(false);
        refetch();
        toast.success("Berhasil menghapus data siswa");
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  const updateInterview = (data) => {
    startLoaderOverlay();
    mutateUpdateStudent.mutate(
      {
        student_id: data?.student_id,
        payload: { interview: !data?.interview },
      },
      {
        onSuccess: () => {
          stopLoaderOverlay();
          setConfirmDeleteOpen(false);
          refetch();
          toast.success("Berhasil mengubah status siswa");
        },
        onError: () => {
          stopLoaderOverlay();
        },
      },
    );
  };

  const onClickPassStudent = (data) => {
    setPassStudent(true);
    setSelectedData(data);
  };

  const onUpdateStatus = useCallback(() => {
    startLoaderOverlay();
    mutatePassingStudent.mutate(
      {
        to_id: location?.state?.id,
        payload: { student_id: selectedData?.student_id, status: true },
      },
      {
        onSuccess: () => {
          stopLoaderOverlay();
          setPassStudent(false);
          refetch();
          setSelectedData(false);
          toast.success("Berhasil mengubah status siswa");
        },
        onError: () => {
          stopLoaderOverlay();
        },
      },
    );
  });

  return (
    <section className="flex flex-col gap-6">
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h6>{"Detail Training Order"}</h6>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <CgSpinner className="animate-spin" />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form className="space-y-6 p-6">
              <div className="grid grid-cols-3 gap-x-4 gap-y-2">
                <div className="col-span-3">
                  <Datepicker
                    name="interview_date"
                    label={"現地面接日 (Tanggal Wawancara)"}
                    placeholder="Pilih tanggal wawancara"
                    disabled
                  />
                </div>
                <div className="col-span-3">
                  <Datepicker
                    name="departure_plan"
                    label="日本入国予定日 (Rencana Pemberangkatan)"
                    placeholder="Masukan rencana pemberangkatan"
                    disabled
                  />
                </div>
                <div className="col-span-3">
                  <Datepicker
                    name="due_date"
                    label="応募期限 (Batas Waktu Apply)"
                    placeholder="Masukan batas waktu apply"
                    disabled
                  />
                </div>
                <div className="col-span-3">
                  <BaseInput
                    name="company_entry_plans"
                    label="日本入国予定日 (Rencana Masuk Perusahaan)"
                    placeholder="Masukan rencana masuk perusahaan"
                    disabled
                  />
                </div>

                {/* Divider */}
                <div className="col-span-3">
                  <div className="h-[3px] bg-gray-400 mb-4" />
                </div>

                <BaseInput
                  name="company_name"
                  label="企業名 (Nama Perusahaan)"
                  placeholder="Masukan nama perusahaan"
                  disabled
                />
                {/* <SelectCorporate
                  name="corporate"
                  label="企業名 (Nama Perusahaan)"
                  placeholder="Masukan nama perusahaan"
                  isDisabled
                /> */}
                <BaseInput
                  name="director"
                  label="代表者 (Direktor)"
                  placeholder="Masukan direktor"
                  disabled
                />
                <BaseInput
                  name="company_address"
                  label="本店所在地 (Alamat Perusahaan)"
                  placeholder="Masukan alamat perusahaan"
                  disabled
                />
                <BaseInput
                  name="internship_address"
                  label="実習実施場所 (Alamat Magang)"
                  placeholder="Masukan alamat magang"
                  disabled
                />
                <BaseInput
                  name="company_profile"
                  label="会社案内 (Profile Perusahaan)"
                  placeholder="Masukan Profile Perusahaan"
                  disabled
                />

                {/* Divider */}
                <div className="col-span-3">
                  <div className="h-[3px] bg-gray-400 mb-4" />
                </div>

                <BaseInput
                  name="work_type"
                  label="受入職種 (Jenis Kerja)"
                  placeholder="Masukan jenis kerja"
                  disabled
                />
                <BaseInput
                  name="visa_type"
                  label="ビザ申請職種名 (Jenis Visa)"
                  placeholder="Masukan jenis visa"
                  disabled
                />
                <BaseInput
                  name="internship_detail"
                  label="実習作業内容(製品例) (Details Magang)"
                  placeholder="Masukan details magang"
                  disabled
                />
                <BaseInput
                  name="amount_received"
                  label="受入人数 (Jumlah Diterima Laki-laki)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan jumlah diterima laki-laki"
                  disabled
                />
                <BaseInput
                  name="amount_received_woman"
                  label="受入人数 (Jumlah Diterima Perempuan)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan jumlah diterima perempuan"
                  disabled
                />
                <div className="flex items-end space-x-1">
                  <BaseInput
                    name="min_age"
                    label="Minimal Usia"
                    placeholder="Minimal Usia"
                    disabled
                    onKeyPress={(event) => {
                      if (!/\d/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <BaseInput
                    name="max_age"
                    label="Maksimal Usia"
                    placeholder="Maksimal Usia"
                    disabled
                    onKeyPress={(event) => {
                      if (!/\d/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <BaseInput
                  name="education"
                  label="学歴 (Pendidikan)"
                  placeholder="Masukan pendidikan"
                  disabled
                />
                <BaseInput
                  name="desire"
                  label="その他希望・要望 (Keinginan / Permintaan Lainnya)"
                  placeholder="Masukan keinginan / permintaan lainnya"
                  disabled
                />

                {/* Divider */}
                <div className="col-span-3">
                  <div className="h-[3px] bg-gray-400 mb-4" />
                </div>

                <BaseInput
                  name="selection_method"
                  label="選考方法 (Cara Seleksi)"
                  placeholder="Masukan cara seleksi"
                  disabled
                />
                <BaseInput
                  name="written_test"
                  label="筆記試験 (Tes Tertulis)"
                  placeholder="Masukan tes tertulis"
                  disabled
                />
                {/* <SelectInput
                  name="skills_test"
                  label="実技試験 (Tes Skill)"
                  placeholder="Masukan tes skill"
                  disabled
                  options={[
                    { value: "Ada", label: "Ada" },
                    { value: "Tidak Ada", label: "Tidak Ada" },
                  ]}
                /> */}

                {/* Divider */}
                <div className="col-span-3">
                  <div className="h-[3px] bg-gray-400 mb-4" />
                </div>

                <BaseInput
                  name="training_period"
                  label="講習期間 (Masa Pelatihan)"
                  placeholder="Masukan masa pelatihan"
                  disabled
                />
                <BaseInput
                  name="basic_salary"
                  label="基本給 (Gaji Pokok)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan gaji pokok"
                  disabled
                />
                <BaseInput
                  name="allowance"
                  label="手当(ある場合) (Tunjangan (jika ada))"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan tunjangan"
                  disabled
                />
                <BaseInput
                  name="tax"
                  label="所得税 (Pajak)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan pajak"
                  disabled
                />
                <BaseInput
                  name="health_insurance"
                  label="健康保険料 (Asuransi Kesehatan)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan asuransi kesehatan"
                  disabled
                />
                <BaseInput
                  name="old_age_insurance"
                  label="厚生年金保険料 (Asuransi Jaminan Masa Tua)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan asuransi jaminan masa tua"
                  disabled
                />
                <BaseInput
                  name="life_money"
                  label="寮費 (Uang Tinggal)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan uang tinggal"
                  disabled
                />
                <BaseInput
                  name="example_of_net_salary"
                  label="月収例 (Contoh Gajih Bersih)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan contoh gajih bersih"
                  disabled
                />
                <BaseInput
                  name="holiday"
                  label="年間休日 (Hari Libur)"
                  placeholder="Masukan hari libur"
                  disabled
                />
                <BaseInput
                  name="working_hour"
                  label="年間労働時間 (Jam Kerja)"
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Masukan jam kerja"
                  disabled
                />
                <BaseInput
                  name="bonus"
                  label="ボーナス (Bonus)"
                  placeholder="Masukan jam kerja"
                  disabled
                />
              </div>
            </form>
          </FormProvider>
        )}
      </div>

      {user.roles.includes("partner_jepang") ? (
        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <h6>{"Calon peserta interview"}</h6>
          </div>
          <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500 p-6">
            <thead className="align-bottom">
              <tr>
                <th className={thClass}></th>
                <th className={thClass}>学生の名前 (Nama Siswa)</th>
                <th className={thClass}>パートナーの名前 (Nama Mitra)</th>
                {user?.role === "partner_jepang" && (
                  <th className={thClass}></th>
                )}
              </tr>
            </thead>
            <tbody>{renderTbody()}</tbody>
          </table>
        </div>
      ) : null}

      {user.roles.includes("lpk_mitra") ||
      user.roles.includes("lpk_mitra_so") ? (
        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="space-y-6 p-6">
            <div className="space-y-4">
              <div className="flex justify-between items-center px-1">
                <h6>Calon Siswa Training Order</h6>
                <span>
                  <BaseButton
                    variant="primary2"
                    size="md"
                    type="button"
                    onClick={() => onAddStudent()}
                  >
                    Tambah Data
                  </BaseButton>
                </span>
              </div>
              <div className="border rounded-lg">
                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                  <thead className="align-bottom">
                    <tr>
                      <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        Nama lengkap
                      </th>

                      {/* {user?.role === "lpk_mitra_so" && (
                        <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 text-center">
                          Interview
                        </th>
                      )} */}

                      {user?.role === "lpk_mitra_so" && (
                        <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 text-center">
                          Status
                        </th>
                      )}

                      <th className="px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 text-center">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail?.data?.students?.length <= 0 ||
                    !detail?.data?.students ? (
                      <tr>
                        <td
                          className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent text-center"
                          colSpan={5}
                        >
                          <p>No Data</p>
                        </td>
                      </tr>
                    ) : (
                      map(detail?.data?.students, (student, key) => (
                        <tr key={key}>
                          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                            <div className="flex px-4 py-1">
                              <Link
                                to={`/student-management/detail`}
                                state={{ id: student?.student_id }}
                              >
                                <h6 className="mb-0 leading-normal text-sm hover:text-slate-500">
                                  {student.student_name ?? "-"}
                                </h6>
                              </Link>
                            </div>
                          </td>
                          {/* {user?.role === "lpk_mitra_so" && (
                            <td className="whitespace-normal bg-transparent border-b">
                              <span
                                onClick={() => updateInterview(student)}
                                className="flex justify-center"
                              >
                                {student?.interview ? (
                                  <AiFillCloseSquare className="text-error text-2xl hover:cursor-pointer" />
                                ) : (
                                  <ImCheckboxChecked className="text-success text-lg hover:cursor-pointer" />
                                )}
                              </span>
                            </td>
                          )} */}
                          {user?.role === "lpk_mitra_so" && (
                            <td className={tdClass}>
                              <p className="mb-0 font-semibold leading-tight text-xs text-center">
                                {student?.status == 1 ? "Lulus" : "-"}
                              </p>
                            </td>
                          )}
                          <td className="whitespace-normal bg-transparent border-b">
                            <div className="flex gap-4 justify-center">
                              {student?.mitra_id === user?.id ? (
                                <a
                                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                                  onClick={() => onClickDeleteData(student)}
                                >
                                  Delete
                                </a>
                              ) : (
                                <a className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer">
                                  &nbsp;
                                </a>
                              )}
                              {user?.role === "lpk_mitra_so" &&
                                !student?.status && (
                                  <a
                                    className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                                    onClick={() => onClickPassStudent(student)}
                                  >
                                    Ubah Status
                                  </a>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <ModalWrapper
        isOpen={isAddStudentModalOpen}
        setIsOpen={setIsAddStudentModalOpen}
      >
        <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl max-h-125 max-w-135">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">
              Tambah Calon Siswa Training Order
            </h5>
            <CgClose
              className="cursor-pointer"
              onClick={() => setIsAddStudentModalOpen((prev) => !prev)}
            />
          </div>
          <div className="relative flex flex-col p-4">
            <Select
              label=""
              onChange={handleChangeStudent}
              value={studentSelected}
              options={studentList}
              placeholder="Masukan Siswa"
              disabled
            />
          </div>

          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
            <BaseButton
              variant="base"
              size="sm"
              onClick={() => setIsAddStudentModalOpen((prev) => !prev)}
            >
              Tidak
            </BaseButton>
            <BaseButton variant="primary2" size="sm" onClick={handleAddStudent}>
              Ya
            </BaseButton>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={confirmDeleteOpen} setIsOpen={setConfirmDeleteOpen}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setConfirmDeleteOpen((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={passStudent} setIsOpen={setPassStudent}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Update Status</h5>
              <CgClose
                className="cursor-pointer text-2xl"
                onClick={() => {
                  setPassStudent(false);
                  setSelectedData(null);
                }}
              />
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin akan melakukan update status siswa?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => {
                  setPassStudent(false);
                  setSelectedData(null);
                }}
              >
                Tidak
              </BaseButton>
              <BaseButton
                type="button"
                variant="primary2"
                size="sm"
                onClick={() => onUpdateStatus()}
              >
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </section>
  );
};

export default TrainingOrderDetail;
