import React, { useEffect, useState } from "react";
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import route from "./route";

import My404Component from "pages/My404Component";

import { AuthLayout, BasicLayout } from "components";
import { getProvinces, getRoles, getUserLpk } from "features/core/coreSlice";
import GuestLayout from "components/templates/GuestLayout";

const Routes = () => {
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.auth);
  const [currentRoute, setCurrentRoute] = useState(route);
  // const [currentRoute, setCurrentRoute] = useState(
  //   route?.privateRoute[user?.role]
  //     ? [...route?.privateRoute[user?.role], ...route?.publicRoute]
  //     : route?.publicRoute,
  // );

  useEffect(() => {
    document.title = "Bitode Wira Indonesia";
  }, []);

  useEffect(() => {
    if (token) {
      user?.role === "lpk_induk" && dispatch(getRoles());
      dispatch(getProvinces());
    }
    return;
  }, [token]);

  useEffect(() => {
    if (user?.role) {
      if (user?.role === "partner_jepang" && user?.is_admin) {
        setCurrentRoute(
          _.filter(
            route,
            (route) =>
              route?.allowRoles?.includes(user?.role) &&
              (route?.path === "/dashboard" ||
                route?.path?.includes("/student-management")),
          ),
        );
      } else {
        setCurrentRoute(
          _.filter(route, (route) => route?.allowRoles?.includes(user?.role)),
        );
      }
    } else {
      setCurrentRoute(
        _.filter(route, (route) => route?.routeType === "public"),
      );
    }
  }, [user]);

  return (
    <Router>
      <WrapperRoutes>
        {_.map(_.filter(currentRoute, { routeType: "public" }), (val, key) => {
          const Component = val.element;
          return (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={
                !token || _.isEmpty(user) ? (
                  <GuestLayout>
                    <Component />
                  </GuestLayout>
                ) : (
                  <Navigate
                    to={user?.role === "student" ? "/profile" : "/dashboard"}
                  />
                )
              }
            />
          );
        })}
        {_.map(_.filter(currentRoute, { routeType: "private" }), (val, key) => {
          const Component = val.element;
          return (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={
                token && !_.isEmpty(user) ? (
                  <BasicLayout>
                    <Component />
                  </BasicLayout>
                ) : (
                  <Navigate to={"/"} />
                )
              }
            />
          );
        })}
        <Route path="*" element={<My404Component />} />
      </WrapperRoutes>
    </Router>
  );
};

export default Routes;
