import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import cn from "clsx";

const BaseInput = React.forwardRef(
  (
    {
      label,
      type,
      placeholder,
      suffixIcon,
      size = "lg",
      className = "",
      name,
      textarea,
      ...rest
    },
    ref,
  ) => {
    const inputSize = {
      sm: "px-3 py-1",
      md: "px-3 py-2",
      lg: "px-3 py-3",
    };

    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => (
          <div className="flex flex-col items-start w-full">
            {label ? (
              <label className="inline-block mb-2 ml-1 font-bold text-slate-700 text-xs">
                {label}
              </label>
            ) : null}

            <div
              className={cn(
                "mb-4 w-full flex flex-col items-start gap-1",
                className,
              )}
            >
              {textarea ? (
                <div className="relative w-full">
                  <textarea
                    {...rest}
                    ref={ref}
                    placeholder={placeholder}
                    type={type}
                    className={cn(
                      "text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed min-h-20",
                      className,
                      inputSize[size],
                      invalid ? "border-red-600" : "border-gray-300",
                    )}
                    value={value}
                    onChange={onChange}
                    autoComplete="new-password"
                  />
                  {suffixIcon}
                </div>
              ) : (
                <div className="relative w-full">
                  <input
                    {...rest}
                    ref={ref}
                    placeholder={placeholder}
                    type={type}
                    className={cn(
                      "text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:bg-slate-50 disabled:cursor-not-allowed",
                      className,
                      inputSize[size],
                      invalid ? "border-red-600" : "border-gray-300",
                    )}
                    value={value}
                    onChange={onChange}
                    autoComplete="new-password"
                  />
                  {suffixIcon}
                </div>
              )}
              {invalid ? (
                <small className="text-xs text-red-600">{error?.message}</small>
              ) : null}
            </div>
          </div>
        )}
      />
    );
  },
);

export default BaseInput;
