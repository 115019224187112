import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { ImageHandler, Pagination, Spinner } from "components";
import {
  deleteImage,
  getAllTopic,
  getArticleTopicDetail,
  postArticle,
  updateArticle,
} from "services/landing";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { CgSpinner } from "react-icons/cg";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { toast } from "react-toastify";
import { deleteAvatar, uploadAvatar } from "services/user";
import BlankProfile from "assets/img/blank-profile.webp";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";
import ModalWrapper from "components/molecules/ModalWrapper";
import cn from "clsx";
import { useMutation, useQuery } from "react-query";
import { useLoader } from "Provider/LoaderProvider";

import {
  CheckboxInput,
  BaseInput as Input,
  ModalDeleteConfirmation,
} from "components/v2";

const DEFAULT_VALUES = {
  tittle: "",
  description: "",
  tittle_jpn: "",
  description_jpn: "",
  tittle_en: "",
  description_en: "",
  image: null,
  active: true,
};

const TopicSetting = () => {
  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
  });

  const [tab, setTab] = useState("jp");

  const [search, setSearch] = useState("");

  const [selectedData, setSelectedData] = useState(null);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, reset } = methods;

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(["topic-list", { page: filter?.page, filter: search }], {
    queryFn: ({ queryKey, signal }) => {
      const params = queryKey[1];
      return getAllTopic({ ...params }, signal);
    },
  });

  const post = useMutation({ mutationFn: (payload) => postArticle(payload) });
  const update = useMutation({
    mutationFn: (payload) => updateArticle(payload),
  });
  const getArticleDetail = useMutation({
    mutationFn: (payload) => getArticleTopicDetail(payload),
  });

  useEffect(() => {
    methods.reset(DEFAULT_VALUES);
  }, [isFormModalOpen]);

  useEffect(() => {
    return () => {
      onChangeSearch.cancel();
    };
  });

  useEffect(() => {
    if (selectedData) {
      reset({
        ...selectedData,
        ...mappingImageWhenGetDetail(selectedData?.id, {
          default_image: selectedData?.image,
          image: selectedData?.images,
        }),
      });
    } else {
      reset(DEFAULT_VALUES);
    }
  }, [selectedData]);

  const mappingImageWhenGetDetail = (id, image) => {
    let result = { ...image };
    result.image = _.map(result.image, (image) => ({
      image_id: image?.id,
      file: null,
      objectUrl: `${process.env.REACT_APP_API}${image?.name}`,
      name: image?.name?.replace(`/article/article/${id}/`, ""),
    }));
    return result;
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onEdit = (data) => {
    startLoaderOverlay();
    getArticleDetail.mutate(data?.id, {
      onSuccess: ({ data }) => {
        data = data?.data;
        setIsFormModalOpen(true);
        setSelectedData(data);
        stopLoaderOverlay();
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const onSubmitTopic = (data) => {
    try {
      if (data) {
        let params = {
          tittle: data?.tittle ? data?.tittle : "",
          tittle_jpn: data?.tittle_jpn ? data?.tittle_jpn : "",
          tittle_en: data?.tittle_en ? data?.tittle_en : "",
          description: data?.description ? data?.description : "",
          description_jpn: data?.description_jpn ? data?.description_jpn : "",
          description_en: data?.description_en ? data?.description_en : "",
          active: data?.active ?? false,
          image: _.map(data?.image, "name"),
          default_image:
            data?.image?.length > 0 ? _.first(data?.image)?.name : "",
          categories: "topic",
        };

        startLoaderOverlay();
        if (selectedData?.id) {
          update.mutate(
            { id: selectedData?.id, payload: params },
            {
              onSuccess: (data) => {
                setIsFormModalOpen(false);
                toast.success("Sukses mengubah data!");
                refetch();
                stopLoaderOverlay();
              },
              onError: () => {
                stopLoaderOverlay();
              },
            },
          );
        } else {
          post.mutate(params, {
            onSuccess: (data) => {
              setIsFormModalOpen(false);
              toast.success("Sukses menambahkan data!");
              refetch();
              stopLoaderOverlay();
            },
            onError: () => {
              stopLoaderOverlay();
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageRender = (data) => {
    const split = data?.image?.split("/");
    return `${process.env.REACT_APP_API}/${split[1]}/${split[2]}/${data?.id}/${split[3]}`;
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={5}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      <tr>
        <td
          className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
          colSpan={5}
        >
          <h6>No Data</h6>
        </td>
      </tr>;
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1 gap-4 items-center">
              <ImageHandler
                image={data?.image ? handleImageRender(data) : BlankProfile}
                className="h-20 w-20 object-cover border rounded-lg"
              />
              <div className="flex flex-col flex-auto">
                <h6 className="m-0">
                  {data?.tittle_en ?? data?.tittle_jpn ?? data?.tittle ?? "-"}
                </h6>
                <p className="truncate max-h-12 break-words whitespace-pre-wrap text-xs">
                  {data?.description_en ??
                    data?.description_jpn ??
                    data?.description ??
                    "-"}
                </p>
              </div>
            </div>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => {
                  onEdit(data);
                }}
              >
                Edit
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>Topics Setting</h5>
        </div>
        <div className="flex justify-between px-4 py-2">
          <div className="w-max md:w-120">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-7"
              onChange={onChangeSearch}
            />
          </div>

          <div>
            <BaseButton
              variant="primary2"
              size="md"
              onClick={() => {
                setIsFormModalOpen(true);
              }}
            >
              Tambah Data
            </BaseButton>
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                  <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                    Topic
                  </th>
                  <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      {/* FORM MODAL */}
      <ModalWrapper isOpen={isFormModalOpen} setIsOpen={setIsFormModalOpen}>
        <div className="relative w-full m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto ease-soft-out overflow-hidden">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">
                {selectedData ? "Edit Topik" : "Tambah Topik"}
              </h5>
              <div className="relative">
                <ul className="relative flex p-1 list-none bg-transparent rounded-xl gap-1">
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "jp" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("jp")}
                    >
                      <span className="ml-1">Japan</span>
                    </a>
                  </li>
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "en" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("en")}
                    >
                      <span className="ml-1">English</span>
                    </a>
                  </li>
                  <li
                    className={cn(
                      "z-30 text-center rounded-lg px-4 cursor-pointer transition-shadow",
                      tab === "id" && "shadow-soft-xxs font-semibold",
                    )}
                  >
                    <a
                      className="z-30 block w-full px-0 py-1 mb-0 transition-colors border-0 rounded-lg ease-soft-in-out bg-inherit text-slate-700"
                      onClick={() => setTab("id")}
                    >
                      <span className="ml-1">Indonesian</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative flex-auto p-4">
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmitTopic)}
                  className="space-y-4"
                >
                  <FormTopic tab={tab} />
                  <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
                    <BaseButton
                      type="button"
                      variant="base"
                      size="sm"
                      onClick={() => setIsFormModalOpen((prev) => !prev)}
                    >
                      Tidak
                    </BaseButton>
                    <BaseButton type="submit" variant="primary2" size="sm">
                      Ya
                    </BaseButton>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default TopicSetting;

const FormTopic = ({ tab }) => {
  const attachmentRef = useRef();
  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const { control } = useFormContext();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imgSelected, setImgSelected] = useState(null);

  const uploadImg = useMutation({
    mutationFn: (payload) => uploadAvatar(payload),
  });
  const deleteImg = useMutation({
    mutationFn: (payload) => deleteAvatar(payload),
  });
  const deleteImgById = useMutation({
    mutationFn: (payload) => deleteImage(payload),
  });

  const {
    fields: img,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "image",
  });

  const onTriggerUpload = () => {
    attachmentRef?.current?.click();
  };

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    if (
      file.type.includes("jpg") ||
      file.type.includes("jpeg") ||
      file.type.includes("png") ||
      file.type.includes("jfif")
    ) {
      const formData = new FormData();
      formData.append("image", file);

      startLoaderOverlay();
      uploadImg.mutate(formData, {
        onSuccess: (data) => {
          const objectUrl = URL.createObjectURL(file);
          const name = file?.name ?? "";
          append({ file, objectUrl, name });
          stopLoaderOverlay();
        },
        onError: (error) => {
          stopLoaderOverlay();
        },
      });
    }
  };

  const onDeleteImg = (data, key) => {
    if (data?.image_id) {
      setDeleteOpen(true);
      setImgSelected({ id: data?.image_id, index: key });
    } else {
      startLoaderOverlay();
      deleteImg.mutate(data?.name, {
        onSuccess: (data) => {
          remove(key);
          stopLoaderOverlay();
        },
        onError: (error) => {
          stopLoaderOverlay();
        },
      });
    }
  };

  const onDeleteImageById = () => {
    startLoaderOverlay();
    deleteImgById.mutate(imgSelected?.id, {
      onSuccess: (data) => {
        remove(imgSelected?.index);
        setImgSelected(null);
        setDeleteOpen(false);
        stopLoaderOverlay();
      },
      onError: (error) => {
        stopLoaderOverlay();
      },
    });
  };

  return (
    <>
      {tab === "id" && (
        <>
          <Input name={`tittle`} placeholder="Judul" />
          <Input name={`description`} placeholder="Deskripsi" />
        </>
      )}
      {tab === "jp" && (
        <>
          <Input name={`tittle_jpn`} placeholder="Judul" />
          <Input name={`description_jpn`} placeholder="Deskripsi" />
        </>
      )}
      {tab === "en" && (
        <>
          <Input name={`tittle_en`} placeholder="Judul" />
          <Input name={`description_en`} placeholder="Deskripsi" />
        </>
      )}
      <div className="space-y-4 flex flex-wrap">
        {img?.length < 10 && (
          <div
            className="border border-dashed border-gray-400 text-gray-400 rounded-lg p-2 flex items-center justify-between w-full cursor-pointer"
            onClick={() => !uploadImg?.isLoading && onTriggerUpload()}
          >
            <div className="flex items-center space-x-2">
              <IoMdImage />
              <span>Upload Image</span>
            </div>
            {uploadImg?.isLoading && (
              <CgSpinner className="animate-spin text-xl text-primary" />
            )}
          </div>
        )}
        <div className="flex items-center space-x-2 overflow-auto">
          {_.map(img, (image, key) => (
            <div className="relative flex-none">
              <img
                src={image.objectUrl}
                alt=""
                className="w-20 h-20 object-contain border rounded"
              />
              <IoMdCloseCircle
                className="absolute right-[4px] top-[4px] cursor-pointer transform transition-transform hover:scale-110"
                onClick={() => onDeleteImg(image, key)}
              />
            </div>
          ))}
        </div>
      </div>
      <CheckboxInput name="active" label="Active" />

      <input
        ref={attachmentRef}
        autoComplete="none"
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        type="file"
        onChange={onSelectFile}
        onClick={(event) => {
          event.target.value = null;
        }}
        className="opacity-0 hidden"
      />

      <ModalDeleteConfirmation
        open={deleteOpen}
        setOpen={setDeleteOpen}
        onDelete={onDeleteImageById}
      />
    </>
  );
};
