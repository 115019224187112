import React, { useEffect, useState } from "react";
import SelectInput from ".";
import { useQuery } from "react-query";
import { capitalize, debounce } from "lodash";
import { getStudentToList } from "services/meet";

const SelectStudentTo = ({ otherOptions = [], id, ...props }) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  const { data: studentToOptions, isFetching } = useQuery(
    ["student-to", { id, payload: { page, limit, search } }],
    {
      queryFn: ({ queryKey, signal }) => {
        const params = queryKey[1];
        return getStudentToList({ ...params }, signal);
      },
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (!isFetching && studentToOptions?.data?.data?.students) {
      setOptions(
        loadMore
          ? options.concat(
              studentToOptions?.data?.data?.students?.map((data) => ({
                value: data?.st_student_id,
                label: data?.s_name,
              })),
            )
          : studentToOptions?.data?.data?.students?.map((data) => ({
              value: data?.st_student_id,
              label: data?.s_name,
            })),
      );
    }
  }, [isFetching]);

  const onMenuScrollToBottom = () => {
    if (options?.length < studentToOptions?.data?.meta?.total) {
      setLoadMore(true);
      setPage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    setSearch(value);
    setLoadMore(false);
    setPage(1);
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <SelectInput
      {...props}
      isLoading={isFetching}
      options={[...options, ...otherOptions]}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onInputChange={onSearch}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectStudentTo;
