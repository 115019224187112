import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import {
  Button,
  InputValidation,
  Label,
  Pagination,
  Select,
  Spinner,
} from "components";
import { useLocation, useNavigate } from "react-router";
import { fetchAPI, getErrorMessage } from "helpers";
import { deleteDataStudent, getListStudentByMitraId } from "services/user";
import {
  MdAdd,
  MdAddCircleOutline,
  MdEdit,
  MdRemoveRedEye,
} from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CgSpinner } from "react-icons/cg";
import { BiFemaleSign, BiMaleSign } from "react-icons/bi";
import { SlNote } from "react-icons/sl";
import { AvatarData } from "pages/StudentManagement";
import moment from "moment";
import FormNote from "./formNote";
import BaseInput from "components/atoms/BaseInput";
import cn from "clsx";

const MitraStudent = () => {
  const formNoteRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id ?? null;
  const mitra_name = location?.state?.mitra_name ?? null;
  const graduate_student = location?.state?.graduate_student ?? null;
  const modalDelete = useRef();

  const { user } = useSelector(({ auth }) => auth);

  const [response, setResponse] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    data: null,
  });
  const [filter, setFilter] = useState({
    role: null,
    search: "",
    page: 1,
    passed: "all",
  });

  const [tab, setTab] = useState("all");

  const [search, setSearch] = useState("");

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    !graduate_student && fetchList(filter);
  }, [filter]);

  useEffect(() => {
    if (graduate_student) {
      setTab("passed");
      fetchList({ ...filter, passed: "passed" });
    }
  }, [graduate_student]);

  const fetchList = ({ role, page, search, passed }) => {
    setResponse({
      ...response,
      isLoading: true,
    });
    fetchAPI(
      {
        service: getListStudentByMitraId,
        params: { page, search, id, passed: passed === "all" ? false : true },
      },
      (success, response) => {
        if (success) {
          setResponse({
            ...response,
            isLoading: false,
            isSuccess: true,
            data: response,
          });
        } else {
          setResponse({
            ...response,
            isLoading: false,
            isError: true,
            errorMessage: getErrorMessage(response),
          });
        }
      },
    );
  };

  const handleChangeTab = (tab) => {
    setTab(tab);
    setFilter((prevState) => ({ ...prevState, passed: tab }));
  };

  const handleChangePage = (page) => {
    setFilter((prevState) => ({ ...prevState, page: page.currentPage }));
  };

  const onDetail = (params) => {
    navigate("/student-management/detail", {
      state: { id: params?.student_id ?? "" },
    });
  };

  const onEdit = (params) => {
    navigate("/student-management/form", { state: { id: params?.id ?? "" } });
  };

  const handleChangeSearch = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const onChangeSearch = useMemo((e) => {
    setSearch(e?.target?.value);
    return _.debounce(handleChangeSearch, 1000);
  }, []);

  const onClickDeleteData = (params) => {
    modalDelete?.current?.click();
    setSelectedData(params);
  };

  const onDeleteData = () => {
    fetchAPI(
      { service: deleteDataStudent, params: selectedData?.id ?? null },
      (success, response) => {
        if (success) {
          fetchList(filter);
        }
        modalDelete?.current?.click();
      },
    );
  };

  const handleNote = (data) => {
    formNoteRef?.current?.click();
    setSelectedData(data);
  };

  const onSubmitNote = () => {
    setSelectedData(null);
    fetchList(filter);
  };

  const onClearFormNote = () => {
    setSelectedData(null);
  };

  const renderModal = () => (
    <>
      <input
        ref={modalDelete}
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hapus Data!</h3>
          <p className="py-4">
            {`Apakah anda yakin ingin menghapus data ini?`}
          </p>
          <div className="modal-action">
            <Button
              label={"Tidak"}
              size="sm"
              theme="error"
              onClick={() => modalDelete?.current?.click()}
            />
            <Button
              label={"Ya"}
              size="sm"
              theme="primary"
              onClick={onDeleteData}
            />
          </div>
        </div>
      </div>
    </>
  );

  const renderDataLpkInduk2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={8}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0 || !response?.data?.data) {
      return (
        <tr>
          <td colSpan={8}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <th>{id}</th>
          {user?.role !== "partner_jepang" && <td>{list?.name ?? ""}</td>}
          <td>
            <AvatarData data={list} />
          </td>
          <td>{list?.ttl ? list?.ttl : "-"}</td>
          {user?.role !== "partner_jepang" && (
            <td>{list?.address ? list?.address : "-"}</td>
          )}
          <td className="text-center">{list?.old ? list?.old : "-"}</td>
          <td>
            {list?.entries_date
              ? moment(list?.entries_date).format("DD MMMM YYYY")
              : "-"}
          </td>
          {tab === "passed" && (
            <td className="whitespace-normal w-80 flex items-center justify-center space-x-2">
              {list?.note && (
                <div className="w-full">
                  <span className="text-sm limit-3-line-of-text">
                    {list?.note}
                  </span>
                </div>
              )}
              <SlNote
                className="transition-transform hover:scale-110 hover:cursor-pointer"
                onClick={() => handleNote(list)}
              />
            </td>
          )}
          <td>
            <div className="flex items-center justify-end space-x-2">
              <Button
                label={<MdRemoveRedEye className="text-white" />}
                size="sm"
                theme="info"
                onClick={() => onDetail(list)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderDataLpkInduk = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={tab === "passed" ? 9 : 8}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      return (
        <tr>
          <td
            className="p-2 align-middle text-center bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={tab === "passed" ? 9 : 8}
          >
            <p>No Data</p>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          {user?.role !== "partner_jepang" && (
            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              <div className="flex px-4 py-1">
                <h6 className="mb-0 leading-normal text-sm">
                  {data.name ?? "-"}
                </h6>
              </div>
            </td>
          )}
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <AvatarData data={data} />
          </td>
          <td className="px-6 bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.ttl ? data.ttl : "-"}
            </p>
          </td>
          {user?.role !== "partner_jepang" && (
            <td className="px-6 leading-normal text-left align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
              <p className="mb-0 font-semibold leading-tight text-xs">
                {data?.address ? data?.address : "-"}
              </p>
            </td>
          )}
          <td className="px-6 text-left align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.old ? data?.old : "-"}
            </p>
          </td>
          <td className="px-6 text-left align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.entries_date
                ? moment(data?.entries_date).format("DD MMMM YYYY")
                : "-"}
            </p>
          </td>
          {tab === "passed" && (
            <td className="px-6 text-left align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
              {data?.note && (
                <p className="mb-0 font-semibold leading-tight text-xs">
                  {data?.note}
                </p>
              )}
            </td>
          )}
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Detail
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData2 = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td colSpan={"6"}>
            <div className="w-full flex items-center justify-center">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    } else if (response?.data?.data?.length <= 0 || !response?.data?.data) {
      return (
        <tr>
          <td colSpan={"6"}>
            <div className="w-full flex items-center justify-center">
              No Data.
            </div>
          </td>
        </tr>
      );
    }

    return _.map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <th>{id}</th>
          <td>{list?.name ?? ""}</td>
          <td>{list?.email ?? ""}</td>
          <td>{list?.ttl ?? ""}</td>
          <td>{list?.mastery_of_language ?? ""}</td>
          <td>
            <div className="flex items-center justify-end space-x-2">
              <Button
                label={<MdRemoveRedEye className="text-white" />}
                size="sm"
                theme="info"
                onClick={() => onDetail(list)}
              />
              {user?.role !== "partner_jepang" && (
                <Button
                  label={<MdEdit className="text-white" />}
                  size="sm"
                  theme="warning"
                  onClick={() => onEdit(list)}
                />
              )}
              {user?.role !== "partner_jepang" && (
                <Button
                  label={<IoMdTrash className="text-white" />}
                  size="sm"
                  theme="error"
                  onClick={() => onClickDeleteData(list)}
                />
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData = () => {
    if (response?.isLoading) {
      return (
        <tr>
          <td
            className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
            colSpan={6}
          >
            <Spinner />
          </td>
        </tr>
      );
    }

    if (response?.data?.data?.length <= 0) {
      <tr>
        <td
          className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent"
          colSpan={6}
        >
          <h6>No Data</h6>
        </td>
      </tr>;
    }

    return _.map(response?.data?.data, (data, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex px-4 py-1">
              <h6 className="mb-0 leading-normal text-sm">
                {data.name ?? "-"}
              </h6>
            </div>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.email ?? "-"}
            </p>
          </td>
          <td className="p-2 leading-normal text-center align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.ttl ?? "-"}
            </p>
          </td>
          <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <p className="mb-0 font-semibold leading-tight text-xs">
              {data?.mastery_of_language ?? "-"}
            </p>
          </td>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
            <div className="flex gap-4">
              <a
                className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                onClick={() => onDetail(data)}
              >
                Detail
              </a>
              {user?.role !== "partner_jepang" && (
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => {
                    setSelectedData(data);
                    // setIsOpenModal(true);
                  }}
                >
                  Edit
                </a>
              )}
              {user?.role !== "partner_jepang" && (
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => {
                    setSelectedData(data);
                    // setIsOpenModal(true);
                  }}
                >
                  Delete
                </a>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    // <div>
    //   {renderModal()}
    //   <FormNote
    //     ref={formNoteRef}
    //     data={selectedData}
    //     onClearFormNote={onClearFormNote}
    //     onSubmit={onSubmitNote}
    //   />
    //   <div className="w-full space-y-4">
    //     <div className="space-y-1">
    //       <Label
    //         text={`List Siswa - ${mitra_name ?? ""}`}
    //         className="font-bold text-2xl"
    //       />
    //       <Label
    //         text={`Menampilkan siswa yang dibuat oleh mitra ${
    //           mitra_name ?? ""
    //         }.`}
    //         className="text-sm text-gray-500"
    //       />
    //     </div>
    //     <div className="tabs">
    //       <a
    //         className={`tab tab-bordered ${tab === "all" && "tab-active"}`}
    //         onClick={() => handleChangeTab("all")}
    //       >
    //         {"全生徒 (Semua Siswa)"}
    //       </a>
    //       <a
    //         className={`tab tab-bordered ${tab === "passed" && "tab-active"}`}
    //         onClick={() => handleChangeTab("passed")}
    //       >
    //         {`大学院生 (Siswa Lulus) (${
    //           response?.data?.totalStudentPassed ?? 0
    //         })`}
    //       </a>
    //     </div>
    //     <div
    //       style={{ boxShadow: "1px 1px 5px 1px #0000001F" }}
    //       className="p-9 space-y-8 rounded-xl"
    //     >
    //       <div className="flex items-center space-x-4">
    //         <div className="flex items-center flex-1 space-x-4">
    //           <div>
    //             <InputValidation
    //               type="text"
    //               placeholder="Search..."
    //               className="h-8 w-80 text-sm"
    //               onChange={onChangeSearch}
    //             />
    //           </div>
    //           <div className="flex items-center flex-1 space-x-4">
    //             <div className="flex items-center space-x-1 text-gray-500">
    //               <BiMaleSign />
    //               <div className="text-sm">{`Laki-laki : ${
    //                 response?.data?.totalStudentMale ?? 0
    //               }`}</div>
    //             </div>
    //             <div className="flex items-center space-x-1 text-gray-500">
    //               <BiFemaleSign />
    //               <div className="text-sm">{`Perempuan : ${
    //                 response?.data?.totalStudentFemale ?? 0
    //               }`}</div>
    //             </div>
    //           </div>
    //         </div>
    //         {(user?.role === "lpk_mitra" || user?.role === "lpk_mitra_so") && (
    //           <Button
    //             theme="primary"
    //             label="Tambah Data"
    //             size="sm"
    //             onClick={() => navigate("/student-management/form")}
    //           />
    //         )}
    //       </div>
    //       <div className="overflow-x-auto border rounded-xl overflow-hidden">
    //         <table className="table w-full">
    //           <thead>
    //             {user?.role === "lpk_induk" ? (
    //               <tr>
    //                 <th></th>
    //                 <th>Nama</th>
    //                 <th>Foto</th>
    //                 <th>Tempat ,Tanggal Lahir</th>
    //                 <th>Alamat Lengkap</th>
    //                 <th className="text-center">Usia</th>
    //                 <th>Tanggal Masuk Belajar</th>
    //                 {tab === "passed" && (
    //                   <th className="flex items-center justify-center w-full">
    //                     Catatan
    //                   </th>
    //                 )}
    //                 <th></th>
    //               </tr>
    //             ) : (
    //               <tr>
    //                 <th></th>
    //                 <th>Nama</th>
    //                 <th>Email</th>
    //                 <th>Ttl</th>
    //                 <th>Penguasaan Bahasa</th>
    //                 <th></th>
    //               </tr>
    //             )}
    //           </thead>
    //           <tbody>
    //             {user?.role === "lpk_induk"
    //               ? renderDataLpkInduk()
    //               : renderData()}
    //           </tbody>
    //         </table>
    //       </div>
    //       <div className="flex justify-end">
    //         <label className="text-sm">Menampilkan 1 - 10</label>
    //         <Pagination
    //           pageLimit={response?.data?.meta?.limit}
    //           totalRecords={response?.data?.meta?.total ?? 0}
    //           currentPage={response?.data?.meta?.page}
    //           onPageChanged={handleChangePage}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
          <h5>{`List Siswa - ${mitra_name}` ?? ""}</h5>
          <p>{`Menampilkan siswa yang dibuat oleh mitra ${
            mitra_name ?? ""
          }.`}</p>
        </div>

        <div className="flex px-6 gap-4">
          <a className="cursor-pointer" onClick={() => handleChangeTab("all")}>
            <p
              className={cn(
                "text-base",
                tab === "all" && "border-b-2 border-b-blue-500 font-medium ",
              )}
            >
              全生徒 (Semua Siswa)
            </p>
          </a>
          <a
            className="cursor-pointer"
            onClick={() => handleChangeTab("passed")}
          >
            <p
              className={cn(
                "text-base",
                tab === "passed" && "border-b-2 border-b-blue-500 font-medium ",
              )}
            >
              大学院生 (Siswa Lulus)
            </p>
          </a>
        </div>

        <div className="flex flex-col lg:flex-row justify-between px-4 py-2 gap-4 lg:items-center">
          <div className="w-full lg:w-120 flex gap-2">
            <BaseInput
              placeholder="Search..."
              size="md"
              className="w-full lg:w-7"
              onChange={onChangeSearch}
            />
          </div>

          <div className="flex flex-grow w-full gap-2 font-medium">
            <div className="flex items-baseline gap-1">
              <BiMaleSign />
              <p>{`Laki-laki: ${response?.data?.totalStudentMale ?? 0}`}</p>
            </div>
            <div className="flex items-baseline gap-1">
              <BiFemaleSign />
              <p>{`Perempuan: ${response?.data?.totalStudentFemale ?? 0}`}</p>
            </div>
          </div>

          <div className="w-full lg:w-max flex justify-end h-max">
            {/* <BaseButton
              variant="primary2"
              size="md"
              className=""
              onClick={() => navigate("/mitra/form")}
            >
              Tambah Data
            </BaseButton> */}
          </div>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                {user?.role === "lpk_induk" ? (
                  <tr>
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Nama
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Foto
                    </th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Tempat, Tanggal Lahir
                    </th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Alamat Lengkap
                    </th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Usia
                    </th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Tanggal Masuk Belajar
                    </th>
                    {tab === "passed" && (
                      <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        Catatan
                      </th>
                    )}
                    <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                  </tr>
                ) : (
                  <tr>
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 w-10"></th>
                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Nama
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Email
                    </th>
                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Tempat, Tanggal Lahir
                    </th>
                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      Penguasaan Bahasa
                    </th>
                    <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70 w-24"></th>
                  </tr>
                )}
              </thead>
              <tbody>
                {user?.role === "lpk_induk"
                  ? renderDataLpkInduk()
                  : renderData()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.data?.meta?.limit}
            totalRecords={response?.data?.meta?.total ?? 0}
            currentPage={response?.data?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>
    </>
  );
};

export default MitraStudent;
