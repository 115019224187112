import React from "react";

import { Header, Sidebar } from "components";
import { useSelector } from "react-redux";
import { LoaderProvider } from "Provider/LoaderProvider";
// import { motion } from "framer-motion";

const BasicLayout = ({ children }) => {
  const { user } = useSelector(({ auth }) => auth);

  return (
    <div className="m-0 antialiased font-normal text-left leading-default text-base dark:bg-slate-950 bg-gray-50 text-slate-500 ">
      <LoaderProvider>
        <Sidebar />
        <main className="relative h-full min-h-screen transition-all duration-200 ease-soft-in-out xl:ml-68 rounded-xl">
          <Header />
          <main
            className="flex flex-col flex-1 overflow-y-auto paragraph p-8"
            key={window.location.pathname}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.05 }}
          >
            {children}
            <div className="h-4" />
          </main>
        </main>
      </LoaderProvider>
    </div>
  );
};

export default BasicLayout;
