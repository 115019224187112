import axios from "axios";
import Service from "config";

export const getCorporateList = async (params, signal) => {
  const { data } = await axios.get(`${Service.API}/admin/partner/corporate`, {
    params,
  });
  return data;
};

export const getCorporateDetail = async (params) => {
  const { data } = await axios.get(
    `${Service.API}/admin/partner/corporate/${params?.id}`,
  );

  return data;
};

export const postCorporate = async (params) => {
  const data = await axios.post(
    `${Service.API}/admin/partner/corporate`,
    params,
  );
  return data;
};

export const putCorporate = async (params) => {
  const data = await axios.put(
    `${Service.API}/admin/partner/corporate/${params?.id}`,
    params?.payload,
  );
  return data;
};
