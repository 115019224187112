import axios from "axios";
import Service from "config";
import { chunk, map } from "lodash";

export const getAdministratorUser = async (params, signal) => {
  const data = axios.get(`${Service.API}/admin/users?filter=partner_admin`, {
    params,
    signal,
  });

  return data;
};

export const getAdministratorUserDetail = (id) => {
  const data = axios.get(`${Service.API}/admin/users/${id ?? ""}`);
  return data;
};

export const postAdministratorUser = async (params) => {
  const { data } = await axios.post(
    `${Service.API}/admin/users/partner-admin`,
    params,
  );
  return data;
};

export const putAdministratorUser = async (params) => {
  const { data } = await axios.put(
    `${Service.API}/admin/users/${params?.id ?? ""}`,
    params?.payload ?? {},
  );
  return data;
};
