import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  localesSelected: false,
  lang: "jp",
};

export const localesSlice = createSlice({
  name: "locales",
  initialState,
  reducers: {
    editLocalesLang: (state, action) => {
      state.lang = action?.payload;
    },
  },
});

// this is for dispatch
export const { editLocalesLang } = localesSlice.actions;

export const updateLocalesLang = (params) => {
  return (dispatch) => {
    dispatch(editLocalesLang(params));
  };
};

// this is for configureStore
export default localesSlice.reducer;
