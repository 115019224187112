import authSlice from "features/auth/authSlice";
import commonSlice from "features/common/commonSlice";
import coreSlice from "features/core/coreSlice";
import localesSlice from "features/locales/localesSlice";

const rootReducer = {
  auth: authSlice,
  core: coreSlice,
  locales: localesSlice,
  common: commonSlice,
};

export default rootReducer;
