import { useLoader } from "Provider/LoaderProvider";
import BaseButton from "components/atoms/BaseButton";
import { ModalDeleteConfirmation } from "components/v2";
import React, { useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { FaDownload, FaEye } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import {
  deleteAttachmentStudent,
  deleteAttachmentStudentById,
  uploadAttachmentStudent,
} from "services/user";
import _ from "lodash";
import { getErrorMessage } from "helpers";

const CertExam = ({ readOnly = false }) => {
  const uploadCertExam = useRef();

  const { state } = useLocation();
  const id = state?.id ?? null;

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { control, handleSubmit } = useFormContext();

  const { user } = useSelector(({ auth }) => auth);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "certification_exam",
  });

  const upload = useMutation({
    mutationFn: (payload) => uploadAttachmentStudent(payload),
  });
  const deleteFile = useMutation({
    mutationFn: (payload) => deleteAttachmentStudent(payload),
  });
  const deleteFileById = useMutation({
    mutationFn: (payload) => deleteAttachmentStudentById(payload),
  });

  const onSelectFile = (event) => {
    try {
      const file = event.target.files[0];
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png") ||
        file.type.includes("jfif") ||
        file.type.includes("pdf")
      ) {
        const formData = new FormData();
        formData.append("image", file);

        startLoaderOverlay();
        upload.mutate(formData, {
          onSuccess: (data) => {
            stopLoaderOverlay();
            append({ value: file });
          },
          onError: (error) => {
            stopLoaderOverlay();
            toast.error(getErrorMessage(error));
          },
        });
      } else {
        toast.error("Ekstensi file tidak valid", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const onTriggerDeleteModal = (data, index) => {
    if (data?.value?.id) {
      setSelectedFile({ data, index });
      setDeleteOpen(true);
    } else {
      startLoaderOverlay();
      deleteFile.mutate(data?.value?.name, {
        onSuccess: (data) => {
          stopLoaderOverlay();
          remove(index);
          setDeleteOpen(false);
        },
        onError: (error) => {
          stopLoaderOverlay();
          toast.error(getErrorMessage(error));
        },
      });
    }
  };

  const onDeleteCertExamFile = (data, index) => {
    if (selectedFile?.data?.value?.id) {
      startLoaderOverlay();
      deleteFileById.mutate(selectedFile?.data?.value?.id, {
        onSuccess: (data) => {
          stopLoaderOverlay();
          remove(selectedFile?.index);
          setDeleteOpen(false);
        },
        onError: (error) => {
          stopLoaderOverlay();
          toast.error(getErrorMessage(error));
        },
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex justify-between items-baseline p-6">
          <div className="pb-0 mb-0 bg-white rounded-t-2xl flex gap-3 h-full items-baseline">
            <h6>看護師認定試験のアップロード</h6>
            <p>(Upload Ujian Sertifikasi Keperawatan)</p>
            <small>(Max 3 File)</small>
          </div>
          {fields?.length < 3 &&
            user?.role !== "partner_jepang" &&
            !readOnly && (
              <BaseButton
                variant="outline"
                onClick={() => uploadCertExam?.current?.click()}
              >
                Upload File
              </BaseButton>
            )}

          <input
            ref={uploadCertExam}
            autoComplete="none"
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            type="file"
            onChange={onSelectFile}
            onClick={(event) => {
              event.target.value = null;
            }}
            className="opacity-0 hidden"
          />
        </div>
        <div className="px-6 pb-6">
          {fields?.length > 0 ? (
            <div className="space-y-3">
              {_.map(fields, (ls, key) => (
                <div
                  key={key}
                  className={`flex items-center justify-between border border-dashed rounded p-2 text-gray-400 ${"border-gray-400"}`}
                >
                  <div className="flex items-center space-x-2">
                    <CgFileDocument />
                    <span className="text-gray-500">{ls?.value?.name}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    {ls?.value && (
                      <a
                        href={
                          ls?.value?.name && ls?.value?.lastModified
                            ? URL.createObjectURL(ls?.value)
                            : `${process.env.REACT_APP_API}/uploads/attachment/student/${id}/${ls?.value?.name}`
                        }
                        download={ls?.value?.name}
                        target={
                          ls?.value?.name?.includes(".doc") ? "" : "_blank"
                        }
                        className="text-xs hover:text-gray-500"
                        rel="noreferrer"
                      >
                        {ls?.value?.lastModified ? (
                          <FaDownload className="text-sm hover:scale-110 transition-all cursor-pointer" />
                        ) : (
                          <FaEye className="text-sm hover:scale-110 transition-all cursor-pointer" />
                        )}
                      </a>
                    )}
                    <IoMdCloseCircle
                      className="text-lg hover:scale-110 transition-all cursor-pointer"
                      onClick={() => onTriggerDeleteModal(ls, key)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <ModalDeleteConfirmation
        open={deleteOpen}
        setOpen={setDeleteOpen}
        onDelete={handleSubmit(onDeleteCertExamFile)}
      />
    </>
  );
};

export default CertExam;
