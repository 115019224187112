import { signInPortal } from "features/auth/authSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
// import { motion } from "framer-motion";

import { AuthLayout, Button, InputValidation, Spinner } from "components";
import { CgSpinner } from "react-icons/cg";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const Login = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [showPass, setShowPass] = useState(false);

  const { isLoading } = useSelector(({ auth }) => auth);

  const onSubmit = (data) => {
    data && dispatch(signInPortal(data));
  };

  return (
    <AuthLayout>
      <div
        className="card w-96 bg-base-100 shadow-lg"
        exit={{ y: 50, opacity: 0 }}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.25 }}
      >
        <div className="card-body">
          <div className="text-3xl font-bold">Login</div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-2">
              <InputValidation
                {...register("email", {
                  required: { value: true, message: "Email wajib diisi" },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Format email tidak valid",
                  },
                })}
                label="Email"
                type="text"
                placeholder="Email"
                isError={{
                  value: errors.email,
                  message: errors.email?.message,
                }}
              />
              <InputValidation
                {...register("password", {
                  required: { value: true, message: "Password wajib diisi" },
                  minLength: {
                    value: 8,
                    message: "Password minimal 8 karakter",
                  },
                })}
                rightIcon={
                  showPass ? (
                    <IoMdEye
                      className="cursor-pointer"
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <IoMdEyeOff
                      className="cursor-pointer"
                      onClick={() => setShowPass(!showPass)}
                    />
                  )
                }
                label="Password"
                type={showPass ? "text" : "password"}
                placeholder="123456789"
                isError={{
                  value: errors.password,
                  message: errors.password?.message,
                }}
              />
            </div>
            <div className="card-actions justify-end">
              <Button
                label={
                  isLoading ? (
                    <CgSpinner className="animate-spin text-2xl text-white" />
                  ) : (
                    "Masuk"
                  )
                }
                disabled={isLoading}
                theme="primary"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
