import React, { useState, useEffect } from "react";
import {
  Button,
  Datepicker,
  Footer,
  GlobalHeader,
  InputValidation,
} from "components";
import { CgSpinner } from "react-icons/cg";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
// import { motion } from "framer-motion";
import { getLocales } from "locales";
import { fetchAPI, getErrorMessage } from "helpers";
import { submitContactUs } from "services/landing";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import BaseInput from "components/atoms/BaseInput";
import BaseButton from "components/atoms/BaseButton";

const ContactUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CONTACT_US } = getLocales();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [showPass, setShowPass] = useState(false);
  const [cooperation_date, setCooperationDate] = useState(null);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [requestDetail, setRequestDetail] = useState("");
  const [submitData, setSubmitData] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    errorMessage: "",
  });
  const [categorySelected, setCategorySelected] = useState([]);

  const { lang } = useSelector(({ locales }) => locales);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, []);

  const handleChangeCooperationDate = (date) => {
    setCooperationDate(date);
    setValue("cooperation_date", date);
  };

  const handleChangePhone = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("telp", value);
      setPhone(value);
    }
  };

  const handleChangeAreaCode = (event) => {
    const value = event?.target?.value;
    if (event.target.validity.valid) {
      setValue("area_code", value);
      setAreaCode(value);
    }
  };

  const handleChangeCategory = (data) => {
    let categorySelectedTemp = [...categorySelected];
    const index = _.findIndex(categorySelectedTemp, (value) => value === data);
    if (index === -1) {
      categorySelectedTemp.push(data);
    } else {
      categorySelectedTemp = _.filter(categorySelectedTemp, (v) => v !== data);
    }
    setCategorySelected(categorySelectedTemp);
  };

  const onSubmit = (data) => {
    const params = {
      email: data?.email ?? "",
      personal_name: data?.name ?? "",
      company_name: data?.company_name ?? "",
      telphone: `${areaCode}${phone}`,
      country: data?.country ?? "",
      category: categorySelected,
      detail: requestDetail ?? "",
      address: address ?? "",
    };

    setSubmitData((prevState) => ({ ...prevState, isLoading: true }));
    fetchAPI(
      { service: submitContactUs, params: params },
      (success, response) => {
        if (success) {
          setSubmitData((prevState) => ({
            ...prevState,
            isLoading: false,
            isSuccess: true,
            data: response,
          }));
          window.scrollTo({ top: 0, behavior: "smooth" });
          toast.success("Data berhasil dikirim!", {
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          setSubmitData((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            data: response,
            errorMessage: getErrorMessage(response),
          }));
        }
      },
    );
  };

  return (
    <div>
      {/* <GlobalHeader fixed={false} /> */}
      <div
        exit={{ y: 50, opacity: 0 }}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.25 }}
        className="flex justify-center mb-10 lg:mb-40"
      >
        <div className="max-w-4xl w-full">
          <div className="flex flex-col justify-center flex-1 p-4 mt-4 lg:mt-20 lg:text-left mr-10">
            <h2>{CONTACT_US.TITLE}</h2>
            <p className="">{CONTACT_US.DESCRIPTION}</p>
          </div>
          <div className="flex-1 w-full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4 grid grid-cols-2 gap-2">
                <BaseInput
                  {...register("name", {
                    required: {
                      value: true,
                      message: CONTACT_US.REQUIRED_NAME,
                    },
                  })}
                  label={CONTACT_US.NAME}
                  type="name"
                  placeholder={CONTACT_US.PLACEHOLDER_NAME}
                  isError={{
                    value: errors.name,
                    message: errors.name?.message,
                  }}
                />
                <BaseInput
                  {...register("company_name", {
                    required: {
                      value: true,
                      message: CONTACT_US.REQUIRED_COMPANY_NAME,
                    },
                  })}
                  label={CONTACT_US.COMPANY_NAME}
                  type="name"
                  placeholder={CONTACT_US.PLACEHOLDER_COMPANY_NAME}
                  isError={{
                    value: errors.company_name,
                    message: errors.company_name?.message,
                  }}
                />
                <div className="col-span-2">
                  <div className="flex items-center w-full space-x-2">
                    <div className="flex-[1]">
                      <BaseInput
                        {...register("area_code", {
                          required: {
                            value: true,
                            message: CONTACT_US.REQUIRED_AREA_CODE,
                          },
                        })}
                        className
                        label={CONTACT_US.AREA_CODE}
                        type="telp"
                        pattern="^[0-9]*$"
                        value={areaCode}
                        onChange={handleChangeAreaCode}
                        placeholder={"+81"}
                        isError={{
                          value: errors.area_code,
                          message: errors.area_code?.message,
                        }}
                      />
                    </div>
                    <div className="flex-[3]">
                      <BaseInput
                        {...register("telp", {
                          required: {
                            value: true,
                            message: CONTACT_US.REQUIRED_PHONE_NUMBER,
                          },
                          minLength: {
                            value: 8,
                            message: CONTACT_US.MIN_LENGTH_PHONE_NUMBER,
                          },
                          maxLength: {
                            value: 13,
                            message: CONTACT_US.MAX_LENGTH_PHONE_NUMBER,
                          },
                        })}
                        label={CONTACT_US.PHONE_NUMBER}
                        type="telp"
                        pattern="^[0-9]*$"
                        value={phone}
                        onChange={handleChangePhone}
                        placeholder={CONTACT_US.PLACEHOLDER_PHONE_NUMBER}
                        isError={{
                          value: errors.telp,
                          message: errors.telp?.message,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <BaseInput
                  {...register("email", {
                    required: {
                      value: true,
                      message: CONTACT_US.REQUIRED_EMAIL,
                    },
                  })}
                  autoComplete="new-password"
                  label={CONTACT_US.EMAIL}
                  type="email"
                  placeholder={CONTACT_US.PLACEHOLDER_EMAIL}
                  isError={{
                    value: errors.email,
                    message: errors.email?.message,
                  }}
                />
                <BaseInput
                  {...register("country", {
                    required: {
                      value: true,
                      message: CONTACT_US.REQUIRED_COUNTRY,
                    },
                  })}
                  label={CONTACT_US.COUNTRY}
                  type="country"
                  placeholder={CONTACT_US.PLACEHOLDER_COUNTRY}
                  isError={{
                    value: errors.country,
                    message: errors.country?.message,
                  }}
                />
                <div className="form-control w-full col-span-2">
                  <label className="label">
                    <span className={`label-text`}>{CONTACT_US.ADDRESS}</span>
                  </label>
                  <textarea
                    className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                    placeholder={CONTACT_US.PLACEHOLDER_ADDRESS}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="form-control w-full col-span-2 py-4">
                  <label className="label font-bold">
                    {CONTACT_US.CATEGORY_LABEL}
                  </label>
                  <div className="space-y-2">
                    {_.map(CONTACT_US.CATEGORY_ITEM, (item, key) => (
                      <div key={key} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="accent-primary"
                          onClick={() => handleChangeCategory(item)}
                          checked={_.includes(categorySelected, item)}
                        />
                        <label>{item}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-control w-full col-span-2">
                  <label className="label">
                    <span className={`label-text`}>
                      {CONTACT_US.REQUEST_DETAIL}
                    </span>
                  </label>
                  <textarea
                    className="text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:ring-1 focus:ring-blue-300 focus:outline-none px-3 py-2 h-25"
                    placeholder={CONTACT_US.PLACEHOLDER_REQUEST_DETAIL}
                    value={requestDetail}
                    onChange={(e) => setRequestDetail(e.target.value)}
                  />
                </div>
                <div className="form-control w-full col-span-2">
                  <label className="label">
                    <span className={`label-text`}>&nbsp;</span>
                  </label>
                  {/* <Button
                    // label={
                    //   isLoading ? (
                    //     <CgSpinner className="animate-spin text-2xl text-white" />
                    //   ) : (
                    //     "Login"
                    //   )
                    // }
                    // disabled={isLoading}
                    label={CONTACT_US.SUBMIT}
                    theme="primary"
                    type="submit"
                  /> */}
                  <BaseButton
                    className="w-full"
                    size="lg"
                    type="submit"
                    variant="primary2"
                  >
                    {CONTACT_US.SUBMIT}
                  </BaseButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ContactUs;
