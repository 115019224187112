import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "react-query";
import { map } from "lodash";
import { CgSpinner } from "react-icons/cg";
import cn from "clsx";
import { useSelector } from "react-redux";

import { Pagination } from "components";
import BaseButton from "components/atoms/BaseButton";
import { getAdministratorUser } from "services/administrator";
import ModalWrapper from "components/molecules/ModalWrapper";
import { deleteData } from "services/user";
import { useLoader } from "Provider/LoaderProvider";

const thClass =
  "px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70";
const tdClass =
  "p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4";

const Administrator = () => {
  const navigate = useNavigate();

  const { startLoaderOverlay, stopLoaderOverlay } = useLoader();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [selectedData, setSelectedData] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const user = useSelector(({ auth }) => auth);

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["administrator-user", { page, limit }], {
    queryFn: ({ queryKey, signal }) => {
      const params = queryKey[1];
      return getAdministratorUser({ ...params }, signal);
    },
  });

  const deleteAdmin = useMutation({
    mutationFn: (payload) => deleteData(payload),
  });

  const handleChangePage = (params) => {
    setPage(params.currentPage);
  };

  const onEdit = (params) => {
    navigate("/administrator-user/form", {
      state: params?.id,
    });
  };

  const onClickDeleteData = (params) => {
    setSelectedData(params);
    setOpenModalDelete(true);
  };

  const onDeleteData = () => {
    startLoaderOverlay();
    deleteAdmin.mutate(selectedData?.id ?? null, {
      onSuccess: () => {
        refetch();
        setSelectedData(null);
        setOpenModalDelete(false);
        stopLoaderOverlay();
      },
      onError: () => {
        stopLoaderOverlay();
      },
    });
  };

  const renderTbody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={6}>
            <div className="flex items-center justify-center px-4 py-2 w-full">
              <CgSpinner className="animate-spin text-2xl text-primary" />
            </div>
          </td>
        </tr>
      );
    }

    return map(response?.data?.data, (list, key) => {
      const id =
        parseInt(response?.data?.meta?.page) *
          parseInt(response?.data?.meta?.limit) -
        parseInt(response?.data?.meta?.limit) +
        key +
        1;
      return (
        <tr key={key}>
          <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent px-4">
            <p className="mb-0 font-semibold leading-tight text-xs">{id}</p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.name ? list?.name : "-"}
            </p>
          </td>
          <td className={tdClass}>
            <p className="mb-0 font-semibold leading-tight text-xs">
              {list?.email ? list?.email : "-"}
            </p>
          </td>
          {/* <td className={tdClass}>
              <p className="mb-0 font-semibold leading-tight text-xs">
                {list?.role ? list?.role : "-"}
              </p>
            </td> */}
          <td className={cn(tdClass, "w-[240px]")}>
            <div className="flex gap-4">
              <>
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onEdit(list)}
                >
                  Edit
                </a>
                <a
                  className="font-semibold leading-tight text-xs text-slate-400 cursor-pointer"
                  onClick={() => onClickDeleteData(list)}
                >
                  Delete
                </a>
              </>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
        <div className="flex items-center justify-between px-4 py-2">
          <div className="p-6 pb-0 mb-0 bg-white rounded-t-2xl">
            <span className="flex space-x-1">
              <h6>{"Administrasi"}</h6>
            </span>
            <p>Menampilkan semua user administrasi.</p>
          </div>
          <BaseButton
            variant="primary2"
            size="md"
            className=""
            onClick={() => navigate("/administrator-user/form")}
          >
            Tambah Data
          </BaseButton>
        </div>
        <div className="flex-auto px-0 pt-0 pb-2 mb-2">
          <div className="p-0 overflow-x-auto">
            <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="align-bottom">
                <tr>
                  <th className={thClass}></th>
                  <th className={thClass}>Nama</th>
                  <th className={thClass}>Email</th>
                  {/* <th className={thClass}>Role</th> */}
                  <th className={cn(thClass, "w-[240px]")}></th>
                </tr>
              </thead>
              <tbody>{renderTbody()}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full p-4 flex justify-end">
          <Pagination
            pageLimit={response?.meta?.limit}
            totalRecords={response?.meta?.total ?? 0}
            currentPage={response?.meta?.page}
            onPageChanged={handleChangePage}
          />
        </div>
      </div>

      <ModalWrapper isOpen={openModalDelete} setIsOpen={setOpenModalDelete}>
        <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
          <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
              <h5 className="mb-0 leading-normal">Hapus Data!</h5>
            </div>
            <div className="relative flex-auto p-4">
              <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
            </div>
            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
              <BaseButton
                variant="base"
                size="sm"
                onClick={() => setOpenModalDelete((prev) => !prev)}
              >
                Tidak
              </BaseButton>
              <BaseButton variant="primary2" size="sm" onClick={onDeleteData}>
                Ya
              </BaseButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default Administrator;
