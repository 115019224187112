import React from "react";

const Input = React.forwardRef(
  ({ className = "", hookForm = {}, ...props }, ref) => (
    <div className="flex items-center relative">
      <input
        ref={ref}
        {...hookForm}
        {...props}
        className={className}
        style={{ paddingRight: props?.rightIcon ? 45 : 0 }}
      />
      {props?.rightIcon && (
        <div className="absolute right-4">{props?.rightIcon}</div>
      )}
    </div>
  ),
);

export default Input;
