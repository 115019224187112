import React from "react";
import ModalWrapper from "components/molecules/ModalWrapper";
import BaseButton from "components/atoms/BaseButton";
import Spinner from "components/atoms/Spinner";

const ModalDeleteConfirmation = ({
  open,
  setOpen,
  onDelete,
  isLoading = false,
}) => {
  return (
    <ModalWrapper isOpen={open} setIsOpen={setOpen}>
      <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
        <div className="relative flex flex-col w-full bg-white pointer-events-auto dark:bg-gray-950 bg-clip-padding rounded-xl outline-0 shadow-soft-xl">
          <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
            <h5 className="mb-0 leading-normal">Hapus Data!</h5>
          </div>
          <div className="relative flex-auto p-4">
            <p>{`Apakah anda yakin ingin menghapus data ini?`}</p>
          </div>
          <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl gap-2">
            <BaseButton
              variant="base"
              size="sm"
              disabled={isLoading}
              onClick={() => setOpen((prev) => !prev)}
            >
              Tidak
            </BaseButton>
            <BaseButton
              variant="primary2"
              size="sm"
              onClick={onDelete}
              disabled={isLoading}
            >
              Ya {isLoading && <Spinner />}
            </BaseButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalDeleteConfirmation;
