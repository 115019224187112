import axios from "axios";
import Service from "config";

export const getFeedback = (params) => {
  return axios.get(
    `${Service.API}/admin/feedback?page=${
      params?.page ?? 1
    }&limit=${5}&search=${params?.search ?? ""}`,
  );
};

export const getFeedbackDetail = (params) => {
  return axios.get(`${Service.API}/admin/feedback/${params}`);
};

export const getPartnerFeedback = (params) => {
  return axios.get(
    `${Service.API}/admin/partner/feedback?page=${
      params?.page ?? 1
    }&limit=${5}&search=${params?.search ?? ""}`,
  );
};

export const getPartnerFeedbackDetail = (params) => {
  return axios.get(`${Service.API}/admin/partner/feedback/${params}`);
};

export const postFeedback = (params) => {
  return axios.post(`${Service.API}/admin/partner/feedback`, params?.payload);
};

export const putFeedback = (params) => {
  return axios.put(
    `${Service.API}/admin/partner/feedback/${params?.id}`,
    params?.payload,
  );
};
